import './home.scss';
import HomeBanner from '~/components/home/components/banner';
import EpisodesList from '~/components/episodes/episodes';
import ExtraContent from '~/components/extra-content/extraContent';
import Newsletter from '~/components/newsletter/newsletter';

import { Container } from 'react-bootstrap';

import mock from '../../mock';

export default function HomeView() {
  return (
    <div id="home">
      <HomeBanner />
      <EpisodesList />
      <ExtraContent section='home' />
      <Newsletter />
      <section id="brands" className="brands">
        <Container className="d-flex justify-content-center">
          <h4>{mock?.home?.brand?.title}</h4>
          {mock.home.brand.brands.map((brand, index) => (
            <div key={`brand-${index}`}>
              <img src={brand.img} alt={brand.alt} />
            </div>
          ))}
        </Container>
      </section>
    </div>
  );
}
