import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '~/images/trampapo-logo.png';
import cathoLogo from '~/images/catho-logo.png';
import cathoLogoInverse from '~/images/logo-catho-inverse.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import './nav-header.scss';

import Scroll, { Link as LinkScroll } from 'react-scroll';

const episodesLink = (location) => {
  if (location.pathname === '/') {
    return (
      <>
        <LinkScroll
          className="nav-link link-scroll"
          to="episodes"
          spy={true}
          smooth={true}
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:episodios"
        >
          Episódios
        </LinkScroll>
      </>
    );
  } else {
    return (
      <>
        <Link
          className="nav-link"
          to="/?section=episodes"
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:episodios"
        >
          Episódios
        </Link>
      </>
    );
  }
};

const extraContentLink = (location) => {
  if (location.pathname === '/') {
    return (
      <>
        <LinkScroll
          className="nav-link link-scroll"
          to="extra-content"
          spy={true}
          smooth={true}
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:conteudos-extras"
        >
          Conteúdos Extras
        </LinkScroll>
      </>
    );
  } else {
    return (
      <>
        <Link
          className="nav-link"
          to="/?section=extra-content"
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:conteudos-extras"
        >
          Conteúdos Extras
        </Link>
      </>
    );
  }
};

const newsletterLink = (location) => {
  if (location.pathname === '/') {
    return (
      <>
        <LinkScroll
          className="nav-link link-scroll"
          to="newsletter"
          spy={true}
          smooth={true}
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:cadastre-se"
        >
          Cadastre-se
        </LinkScroll>
      </>
    );
  } else {
    return (
      <>
        <Link
          className="nav-link"
          to="/?section=newsletter"
          data-gtm-event-category="candidatos:catho:trampapo"
          data-gtm-event-action="lp-trampapo:header-interacao"
          data-gtm-event-label="link:cadastre-se"
        >
          Cadastre-se
        </Link>
      </>
    );
  }
};

function NavbarC() {
  const location = useLocation();

  Scroll.scroller.scrollTo('root');

  useEffect(() => {
    const qs = queryString.parse(location.search);
    if (qs.section) {
      // eslint-disable-next-line
      switch (qs.section) {
        case 'episodes':
          Scroll.scroller.scrollTo('episodes', {
            smooth: true,
          });
          break;
        case 'extra-content':
          Scroll.scroller.scrollTo('extra-content', {
            smooth: true,
          });
          break;
        case 'newsletter':
          Scroll.scroller.scrollTo('newsletter', {
            smooth: true,
          });
          break;
      }
    }
  });

  return (
    <Navbar
      bg="none"
      expand="lg"
      variant="custom"
      sticky="top"
      collapseOnSelect={true}
    >
      <Container fluid>
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Trampapo Logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="gtm-class">
          <Nav className="mr-auto">
            <Link
              className="nav-link home"
              to="/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:home"
            >
              Home
            </Link>
            <Link
              className="nav-link"
              to="/sobre"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:sobre"
            >
              Sobre
            </Link>
            {episodesLink(location)}
            {extraContentLink(location)}
            {newsletterLink(location)}
            <Link
              className="nav-link"
              to="/primeira-temporada"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:1a-temporada"
            >
              1ª Temporada
            </Link>
          </Nav>
          <div className="nav-right d-flex align-items-center">
            <a
              href="https://www.catho.com.br"
              className="logo"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:catho"
            >
              <img src={cathoLogo} alt="Catho Logo" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/trampapo.podcast/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/trampapo.podcast/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCRMawSRyjjyjwOXorWLFO2Q"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:youtube-header"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
          <div className="nav-right d-flex align-items-center mobile">
            <a
              href="https://www.catho.com.br/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:header-interacao"
              data-gtm-event-label="link:catho"
            >
              Conheça a Catho: <br />
              <img src={cathoLogoInverse} alt="Catho Logo" />
            </a>
            <div className="d-flex">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/trampapo.podcast/"
                className="facebook"
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:header-interacao"
                data-gtm-event-label="link:facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/trampapo.podcast/"
                className="instagram"
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:header-interacao"
                data-gtm-event-label="link:instagram"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCRMawSRyjjyjwOXorWLFO2Q"
                className="youtube"
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:header-interacao"
                data-gtm-event-label="link:youtube-header"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarC;
