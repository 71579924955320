import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import "./App.scss";

import NavbarHeader from "./components/nav/nav-header.js";
import Footer from "./components/footer/footer.js";
import HomeView from "./components/home/home";
import EpisodeView from "./components/episode/episode";
import AboutView from "./components/about/about";
import Season01 from "./components/season-01/season01";
import SitemapHtml from "./components/sitemap";
import ExtraContent from "./view/ExtraContent";
import loadAsyncScripts from './components/html/loadAsyncScripts';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import FontSizeChanger from 'react-font-size-changer';
import Acessibility from "./components/acessibility/acessibility";
import thumbnail from "./images/share-img.jpg";

function App() {
  const [firstCall, setFirstCall] = useState(true);
  const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

  useEffect(() => {
    if (firstCall) {
      setFirstCall(false);
      window.addEventListener('load', () => {
        loadAsyncScripts();
      });
    }
  }, [firstCall]);


  return (
    <>
      <Helmet>
        <title>Trampapo - Atualidades e Dicas - Insights do Mercado</title>
        <meta property="og:title" content="Trampapo - Atualidades e Dicas - Insights do Mercado" />

        <meta
          name="description"
          content="Seu Podcast de Insights, Desenvolvimento Profissional e Tendências de Mercado. Conversa Com Dados Sobre Tendências de Mercado Para Te Ajudar a Planejar Sua Carreira. "
        />
        <meta
          property="og:description"
          content="Seu Podcast de Insights, Desenvolvimento Profissional e Tendências de Mercado. Conversa Com Dados Sobre Tendências de Mercado Para Te Ajudar a Planejar Sua Carreira."
        />

        <meta property="og:url" content="https://www.trampapo.com.br" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://www.trampapo.com.br${thumbnail}`}
        />

        <meta name="keywords" content="trampapo,podcast,catho,episódios" />
        <link rel="canonical" href="https://www.trampapo.com.br/" />
      </Helmet>
      <Router basename={basename}>
        <div className="App">

          <FontSizeChanger
            targets={['h2', 'h3', 'h4', 'h5', 'p', 'a']}
            options={{
              stepSize: 2,
              range: 3
            }}
          />
          <NavbarHeader />

          <Acessibility />

          <Route
            render={({ location, match }) => (
              <TransitionGroup>
                <CSSTransition
                  in={match != null}
                  key={location.key}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <Switch location={location}>
                    <Route path="/" exact component={HomeView} />
                    <Route
                      path="/episodes/:episodeSlug"
                      component={EpisodeView}
                    />
                    <Route path="/primeira-temporada" component={Season01} />
                    <Route path="/sobre" component={AboutView} />
                    <Route
                      path="/conteudo-extra/:slug"
                      component={ExtraContent}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />

          <Footer />
          <SitemapHtml />
        </div>
      </Router>
    </>
  );
}

export default App;
