import { Helmet } from "react-helmet";
import "./about.scss";
import bgBalao01 from '~/images/bg-balao-01.png'
import bgBalao03 from '~/images/bg-balao-03.png'
import aboutBg01 from '~/images/about-bg-01.png'
import aboutBg01Mobile from '~/images/mercado-e-seu-02.png'
import hostAna from '~/images/ana-paula-xongani.png'
import hostRica from '~/images/ricardo-morais.png'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'

function AboutView() {

    return (
      <div id="about" className="about">
        <Helmet>
            <title>Sobre o Trampapo - O mercado falado no Plural </title>
            <meta name="description" content="A pandemia trouxe várias mudanças. No mercado de trabalho, a principal delas foi o resgate do cuidado com o valor do capital humano. Conheça o Trampapo" />
            <link rel="canonical" href={`https://www.trampapo.com.br/sobre`} />
        </Helmet>
        <Container className="about__container banner-wrap">
            <Link to="/" className="back">
                <FontAwesomeIcon icon={faArrowLeft} /> voltar ao inicio
            </Link>
            <div className="about__container__banner">
                <img src={bgBalao01} alt="Balão"/>
            </div>
        </Container>

        <div className="about__block block-01">
            <Container className="about__container">
                <Row className="d-flex">
                    <Col md={6}>
                        <h1>Sobre o Trampapo - O mercado falado no plural</h1>
                        <p>A pandemia trouxe várias mudanças. No mercado de trabalho, a principal delas foi o resgate do cuidado com o valor do capital humano. Das empresas à sociedade, o respeito com as mais diversas histórias de vida se tornou regra. Valorizar a pluralidade se tornou parte da rotina de todos: líderes, gestores e claro, colaboradores.</p>
                        <p>Ou seja: a humanização das relações e o respeito à diversidade agora é a norma. Pois os tempos que vivemos atualmente pedem isso.</p>
                    </Col>
                    <Col md={6}>
                        <img src={aboutBg01} className="showDesktop" alt="Trampapo 1"/>
                        <img src={aboutBg01Mobile} className="showMobile" alt="Trampapo 2"/>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="about__block block-02">
            <Container className="about__container">
                <Row className="d-flex flex-row-reverse">
                    <Col md={6}>
                        <p>É com base nisso que o Trampapo agora tem um novo foco: as pessoas. Afinal, para a Catho e para o mercado, elas são o patrimônio mais importante dentre os ativos de qualquer organização.</p>
                        <p>Nessa nova temporada, vamos estabelecer uma relação entre carreira, questões sociais e diferentes vivências, trazendo à luz recortes específicos e discussões fundamentais para engajar, empoderar e estimular pessoas como você a conquistarem seu espaço – e sua voz – nessa nova realidade. </p>
                        <p>Bora ouvir o mercado falado no plural?</p>
                    </Col>
                    <Col md={6}>
                        <img src={bgBalao03} alt="Trampapo 3"/>
                    </Col>
                </Row>
            </Container>
        </div>
      
        <div className="hosts">
            <div className="hosts__wrap hosts__wrap__ana">
                <Container>
                    <Row className="d-flex">
                        <Col md={6}>
                            <h3 className="showMobile">Hosts</h3>
                            <h2 className="showMobile">Ana Paula Xongani</h2>
                            <img src={hostAna} alt="Ana Paula Xongai"/>
                        </Col>
                        <Col md={6}>
                            <h3 className="showDesktop">Hosts</h3>
                            <h2 className="showDesktop">Ana Paula Xongani</h2>
                            <p>Empreendedora de moda afro-brasileira, ela é influenciadora e apresentadora do programa Se Essa Roupa Fosse Minha, do GNT. Com mais de 200 mil seguidores no Instagram e 94 mil no Youtube, ela aborda temas sempre importantes e urgentes, sem deixar de lado a leveza e a ternura. É como ela costuma dizer: “minha militância é afetiva”.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="hosts__wrap hosts__wrap__ricardo">
                <Container>
                    <Row className="d-flex">
                        <Col md={6}>
                            <h2 className="showMobile">Ricardo Morais</h2>
                            <img src={hostRica} alt="Ricardo Morais"/>
                        </Col>
                        <Col md={6}>
                            <h2 className="showDesktop">Ricardo Morais</h2>
                            <p>Gerente Sênior de Marketing na Catho, Ricardo também é Professor na FIA e Mentor na ProBono. Formado em Economia pela Universidade Estadual de Campinas (Unicamp), tem um MBA em Business Administration and General Management pelo Insper. Com uma vasta experiência profissional, já passou por empresas de diferentes setores, de multinacionais a startups, sempre atuando em posições de liderança.</p>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>

      </div>
    );
}

export default AboutView;