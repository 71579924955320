import "./episode.scss";

import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import logo from "~/images/mini-trampapo-logo.png";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import ExtraContent from "~/components/extra-content/extraContent";
import SocialShare from "~/components/socialShare/socialShare";
import Mock from "../../mock";

import Transcriptions from "../../mock/transcriptions";

function EpisodeView({ history }) {
  let { episodeSlug } = useParams();

  const currentEpisode = Mock.episodes.find(
    (episode) => episode.slug === episodeSlug
  );

  const renderTranscription = () => {
    const transcription = Transcriptions.find(
      (episode) => episode.id === currentEpisode.id
    );

    if (!transcription) return;

    const content = {__html: transcription.content};

    return (
      <Row className="episode__container__transcription">
        <Col>
          <div className="title">Transcrição:</div>
          <div className="content" dangerouslySetInnerHTML={content} />
        </Col>
      </Row>
    );
  };

  function goToNextEpisode() {
    const currentIndex = Mock.episodes.indexOf(currentEpisode);
    const nextIndex = (currentIndex + 1) % Mock.episodes.length;
    const nextItem = Mock.episodes[nextIndex];

    if (nextItem.type === "content") {
      history.push(`/conteudo-extra/${nextItem.slug}`);
    } else {
      history.push(`/episodes/${nextItem.slug}`);
    }
  }

  if (!currentEpisode) {
    history.push("/");
  }

  return (
    <div id="episode" className="episode">
      <Helmet>
        <title>{currentEpisode.title}</title>
        <meta name="description" content={`${currentEpisode.about.substr(0, 147)}...`} />
        <meta
          property="og:description"
          content="Ouça este episódio do Trampapo, seu podcast sobre o mercado falado no plural! 💜🎧"
        />
        <meta
          property="og:image"
          content={`https://www.trampapo.com.br${currentEpisode.thumbnail}`}
        />
        <meta name="author" content={`${currentEpisode.guests.map(guest => guest.name).join(',')}`} />
        <meta name="keywords" content={`trampapo,episódio,${currentEpisode.categories.join(',')}`} />
        <meta property="og:url" content={`https://www.trampapo.com.br/episodes/${currentEpisode.slug}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://www.trampapo.com.br/episodes/${currentEpisode.slug}`} />

      </Helmet>
      <Container className="episode__container">
        <Link to="/" className="back">
          <FontAwesomeIcon icon={faArrowLeft} /> voltar ao inicio
        </Link>
        <Row md={2} className="mt-5 episode__container__row">
          <Col className="episode__container__row__thumb">
            <img src={currentEpisode.thumbnail} alt={currentEpisode.title} />
          </Col>
          <Col className="episode__container__row__description">
            <small>#{currentEpisode.id}</small>
            <h1>{currentEpisode.title}</h1>
            {currentEpisode.categories.map((category, index) => {
              let categoryText = category;

              const notLastCategory =
                index + 1 < currentEpisode.categories.length;
              if (notLastCategory) {
                categoryText = `${categoryText}, `;
              }

              return (
                <span key={category} className="tag">
                  {categoryText}
                </span>
              );
            })}

            <span className="guest mt-4 mb-2">Convidados:</span>

            {currentEpisode.guests.map((episode, index) => (
              <p key={index} className="m-0 p-0">
                {episode.name}
                {episode.job !== undefined &&
                  <span> - {episode.job}</span>
                }
              </p>
            ))}
          </Col>
        </Row>

        <Row md={2}>
          <iframe title={currentEpisode.title} className="episode__audio" height="54px" frameborder="no" scrolling="no" seamless src={currentEpisode.player}></iframe>
        </Row>

        <Row md={2} className="episode__container__about">
          <Col md={9} className="pr-5">
            <div className="title">Sobre:</div>
            <p>{currentEpisode.about}</p>
          </Col>
          <Col md={3} className="text-center d-flex align-items-center justify-content-end">
            <img src={logo} alt={currentEpisode.title} />
          </Col>
        </Row>

        {renderTranscription()}
      </Container>

      <SocialShare handleOnPress={goToNextEpisode} contentType='episode' />

      <ExtraContent hasBottomLine section='episode' />
    </div>
  );
}

export default EpisodeView;
