import logoAbrh from "../images/brands/abrh.png";
import logoArtplan from "../images/brands/artplan.png";
import logoBayer from "../images/brands/bayer.png";
import logoCampseg from "../images/brands/campseg.png";
import logoCiandt from "../images/brands/ciandt.png";
import logoDeloitte from "../images/brands/deloitte.png";
import logoDextra from "../images/brands/dextra.png";
import logoEmbracon from "../images/brands/embracon.png";
import logoEmpiricus from "../images/brands/empiricus.png";
import logoErnstYoung from "../images/brands/ernst-young.png";
import logoEstacio from "../images/brands/estacio.png";
import logoGpa from "../images/brands/gpa.png";
import logoIvg from "../images/brands/instituto-vasselo-goldoni.png";
import logoLoggi from "../images/brands/loggi.png";
import logoMezcla from "../images/brands/mezcla.png";
import logoMm360 from "../images/brands/mm360.png";
import logoMutato from "../images/brands/mutato.png";
import logoO2Filmes from "../images/brands/o2-filmes.png";
import logoOfficeless from "../images/brands/officeless.png";
import logoPucCampinas from "../images/brands/puc-campinas.png";
import logoRappi from "../images/brands/rappi.png";
import logoSenai from "../images/brands/senai-sp.png";
import logoSodexo from "../images/brands/sodexo.png";
import logoStefanini from "../images/brands/stefanini.png";
import logoUxmp from "../images/brands/uxmp.png";
import logoWomakersCode from "../images/brands/womakers-code.png";

export const home = {
  brand: {
    title: "Marcas convidadas",
    brands: [
      {
        alt: "Associação Brasileira de Recursos Humanos",
        img: logoAbrh
      },
      {
        alt: "Agência Artplan",
        img: logoArtplan
      },
      {
        alt: "Bayer",
        img: logoBayer
      },
      {
        alt: "Grupo CAMPSEG",
        img: logoCampseg
      },
      {
        alt: "Ci&T",
        img: logoCiandt
      },
      {
        alt: "Deloitte Brasil",
        img: logoDeloitte
      },
      {
        alt: "Dextra",
        img: logoDextra
      },
      {
        alt: "Consórcio Embracon",
        img: logoEmbracon
      },
      {
        alt: "Empiricus",
        img: logoEmpiricus
      },
      {
        alt: "Ernst Young",
        img: logoErnstYoung
      },
      {
        alt: "Universidade Estácio de Sá",
        img: logoEstacio
      },
      {
        alt: "GPA",
        img: logoGpa
      },
      {
        alt: "Instituto Vasselo Goldoni",
        img: logoIvg
      },
      {
        alt: "Loggi",
        img: logoLoggi
      },
      {
        alt: "Mezcla",
        img: logoMezcla
      },
      {
        alt: "Movimento Mulher 360",
        img: logoMm360
      },
      {
        alt: "Mutato",
        img: logoMutato
      },
      {
        alt: "O2 Filmes",
        img: logoO2Filmes
      },
      {
        alt: "Officeless",
        img: logoOfficeless
      },
      {
        alt: "PUC Campinas",
        img: logoPucCampinas
      },
      {
        alt: "Rappi",
        img: logoRappi
      },
      {
        alt: "SENAI SP",
        img: logoSenai
      },
      {
        alt: "Sodexo",
        img: logoSodexo
      },
      {
        alt: "Stefanini",
        img: logoStefanini
      },
      {
        alt: "UX para Minas Pretas",
        img: logoUxmp
      },
      {
        alt: "WoMakers Code",
        img: logoWomakersCode
      }
    ]
  },
  extraContent: {
    title: "Conteúdos Extras"
  }
};
