const GoogleAnalyticsEvent = (category, action, label) => {
  if (window.trackerPageAnalytics) {
    window.trackerPageAnalytics.variablesDataLayer.event = 'event';
    window.trackerPageAnalytics.variablesDataLayer.eventCategory = category;
    window.trackerPageAnalytics.variablesDataLayer.eventAction = action;
    window.trackerPageAnalytics.variablesDataLayer.eventLabel = label;
    window.trackerPageAnalytics.send();
  }
};

export default GoogleAnalyticsEvent;