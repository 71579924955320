import axios from 'axios';

export const saveNewsletterFactory = (TRAMPAPO_API) => async (name, email, phone) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${TRAMPAPO_API.url}/cadastro`,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': TRAMPAPO_API.token
            },
            data: {
                name,
                email,
                phone
            }
        });
        const { data } = response;
        if (data.result !== true) {
            throw new Error(data.errors);
        }
    } catch (error) {
        // TODO: Log into instana
        console.log(`Unable to save data, API Error`, error)
        throw new Error(
            `Erro ao cadastrar na newsletter ${error}`
            )
    }
}