import { saveNewsletterFactory } from './requests';

const TRAMPAPO_API = {
    url: 'https://www.catho.com.br/lp/api/trampapo',
    token: 'ecK876dp3HjgBQGjS25bdq8MQmQ749Q6b32NWq8J2dY7YpJ9ba5e2CZAx8q627Cx'
};

const saveNewsletter = saveNewsletterFactory(TRAMPAPO_API)

export {
    saveNewsletter,
}