import './footer.scss';

import { Container } from 'react-bootstrap';
import cathoLogo from '~/images/catho-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <footer>
    <Container className="mx-auto">
      <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row gtm-class">
        <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row">
          <p>Produzido por{' '}</p>
          <a
            href="https://www.catho.com.br"
            className="logo mt-3 mt-lg-0 ml-0 ml-lg-4"
            data-gtm-event-category="candidatos:catho:trampapo"
            data-gtm-event-action="lp-trampapo:footer-interacao"
            data-gtm-event-label="link:catho"
          >
            <img src={cathoLogo} alt="Catho Logo" />
          </a>
        </div>
        <div className="divisor d-none d-lg-inline-block"></div>
        <div className="social d-flex align-items-center justify-content-center flex-column flex-lg-row mt-5 mt-lg-0">
          <p>siga o trampapo</p>
          <div className="mt-3 ml-0 mt-lg-0 ml-lg-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/trampapo.podcast/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:footer-interacao"
              data-gtm-event-label="link:facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href=" https://www.instagram.com/trampapo.podcast/"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:footer-interacao"
              data-gtm-event-label="link:instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>  
          </div>
        </div>
      </div>
      <p className="copyright mx-auto text-center">&copy; 2020 Catho - Todos os conteúdos são marcas registradas dos seus respectivos proprietários</p>
      <p className="text-center" style={{marginTop: 20}}><a style={{color: '#1250C4', textDecoration: 'underline', fontSize:12, fontWeight: 'bold'}} rel="noreferrer" href="https://seguro.catho.com.br/terms/politica-cookies-privacidade-v5.html?_ga=2.195009133.1598624517.1621254260-1765183696.1609182427&_gac=1.83866724.1618508522.Cj0KCQjwyN-DBhCDARIsAFOELTnijAIjVq2BfXLyDDJDHL4waeWiINmCRzVu3sEIlWAQg_2zTUKQEIwaAg1MEALw_wcB" title="Politica de privacidade" target="_blank">Politica de privacidade</a></p>
    </Container>
  </footer>
);

export default Footer;
