import "./episodes.scss";

import { useState } from "react";

import { Container, InputGroup, Form, Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Mock from "../../mock";

import GoogleAnalyticsEvent from "~/components/google-analytics/googleAnalyticsEvent";

export default function EpisodesList({ season = "" }) {
  const [filteredCategory, setFilteredCategory] = useState("");
  const [showSeeMore, setShowSeeMore] = useState(true);

  const episodesAudio = Mock.episodes.filter(
    (episode) => episode.type !== "content"
  );

  let filteredEpisodes = episodesAudio.filter((episode) => {
    if (filteredCategory === "" && season === "") return true;
    if (filteredCategory === "" && season === episode.season) return true;
    
    const categories = episode.categories;
    const isVisible = categories.includes(filteredCategory);

    if (isVisible && season === "") return true;
    if (isVisible && season === episode.season) return true;
    
    return false
  });

  if (showSeeMore) {
    filteredEpisodes = filteredEpisodes.slice(0, 6);
  }

  const categories = Mock.categories;

  return (
    <section id="episodes" className="episodes">
      <Container>
        {season === 1 &&
            <h1>Primeira Temporada</h1>
        }
        <header className="d-flex align-items-center justify-content-between">
          <h2>Episódios</h2>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faFilter} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="select"
              onChange={(event) => {
                setFilteredCategory(event.currentTarget.value);
                GoogleAnalyticsEvent(
                  'candidatos:catho:trampapo',
                  'lp-trampapo:episodios-interacao',
                  'selected:' + event.currentTarget.value
                )
              }}
            >
              <option value="">Todas</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </header>
        <div className="episodes__list gtm-class">
          <Row md={3}>
            {filteredEpisodes.map((episode) => (
              <Col key={episode.id}>
                <div className="episodes__list__card">
                  <div className="thumb">
                    <Link
                      to={`/episodes/${episode.slug}`}
                      title={episode.title}
                      data-gtm-event-category="candidatos:catho:trampapo"
                      data-gtm-event-action="lp-trampapo:episodios-interacao"
                      data-gtm-event-label={'link:episodio:' + episode.title}
                    >
                      <img src={episode.thumbnail} alt={episode.title} />
                    </Link>
                  </div>
                  <div className="d-flex episodes__list__card__description align-items-center">
                    <div>
                      <small>#{episode.id}</small>
                      <h3>
                        <Link
                          to={`/episodes/${episode.slug}`}
                          title={episode.title}
                          data-gtm-event-category="candidatos:catho:trampapo"
                          data-gtm-event-action="lp-trampapo:episodios-interacao"
                          data-gtm-event-label={'link:episodio:' + episode.title}
                        >
                          {episode.title}
                        </Link>
                      </h3>
                      {episode.categories.map((category, index) => {
                        let categoryText = category;

                        const notLastCategory =
                          index + 1 < episode.categories.length;
                        if (notLastCategory) {
                          categoryText = `${categoryText}, `;
                        }

                        return (
                          <span key={category} className="tag">
                            {categoryText}
                          </span>
                        );
                      })}
                    </div>
                    <div>
                      <Link
                        to={`/episodes/${episode.slug}`}
                        className="play"
                        data-gtm-event-category="candidatos:catho:trampapo"
                        data-gtm-event-action="lp-trampapo:episodios-interacao"
                        data-gtm-event-label={'play:' + episode.title}
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {showSeeMore && filteredCategory === "" ? (
            <div className="text-center">
              <button
                onClick={() => {
                  setShowSeeMore(!showSeeMore);
                }}
                className="btn rounded-pill btn-outline-dark pl-5 pr-5 font-weight-bold"
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:episodios-interacao"
                data-gtm-event-label="botao:mais-episodios"
              >
                Mais episódios
              </button>
            </div>
          ) : null}
        </div>
      </Container>
    </section>
  );
}
