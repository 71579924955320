
import "./season01.scss";
import { Helmet } from "react-helmet";

import EpisodesList from '~/components/episodes/episodes';
import Newsletter from '~/components/newsletter/newsletter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const scrollToEpisodes = () => {
    const element = document.getElementById('episodes');
    window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 120,
        behavior: 'smooth',
    });
}

function Season01() {
    return (
        <div id="season-01" className="season-01">
            <Helmet>
                <title>Primeira Temporada Trampapo</title>
                <meta name="description" content="Conheça todos os episódios da primeira temporada do TRAMPAPO e veja diversas dicas de profissionais para te ajudar na sua carreira. " />
                <link rel="canonical" href={`https://www.trampapo.com.br/primeira-temporada`} />

            </Helmet>
            <div className="banner">
                <div onClick={scrollToEpisodes} className="scroll-down">
                    <FontAwesomeIcon className="fa-lg" icon={faChevronDown} />
                    <FontAwesomeIcon className="fa-2x" icon={faChevronDown} />
                </div>
            </div>

            <EpisodesList season={1} />
            <Newsletter />
        </div>
    )
}

export default Season01;