// Content type "title-text" | "banner" | "text" | "image" | "title"
// Episode type "audio" | "content"

import thumb_01_01 from '../images/episodes/trampapo_01-01.jpg';
import thumb_01_02 from '../images/episodes/trampapo_01-02.jpg';
import thumb_01_03 from '../images/episodes/trampapo_01-03.jpg';
import thumb_01_04 from '../images/episodes/trampapo_01-04.jpg';
import thumb_01_05 from '../images/episodes/trampapo_01-05.jpg';
import thumb_01_06 from '../images/episodes/trampapo_01-06.jpg';
import thumb_01_07 from '../images/episodes/trampapo_01-07.jpg';
import thumb_01_08 from '../images/episodes/trampapo_01-08.png';
import thumb_01_09 from '../images/episodes/trampapo_01-09.png';
import thumb_01_10 from '../images/episodes/trampapo_01-10.png';
import thumb_01_11 from '../images/episodes/trampapo_01-11.png';
import thumb_01_12 from '../images/episodes/trampapo_01-12.jpg';
import thumb_02_13 from '../images/episodes/trampapo_02_13.jpg';
import thumb_02_14 from '../images/episodes/trampapo_02_14.jpg';
import thumb_02_15 from '../images/episodes/trampapo_02_15.jpg';
import thumb_02_16 from '../images/episodes/trampapo_02_16.jpg';
import thumb_02_17 from '../images/episodes/trampapo_02_17.jpg';
import thumb_02_18 from '../images/episodes/trampapo_02_18.jpg';
import thumb_02_19 from '../images/episodes/trampapo_02_19.jpg';
import thumb_02_20 from '../images/episodes/trampapo_02_20.jpg';
import thumb_02_21 from '../images/episodes/trampapo_02_21.jpg';
import thumb_02_22 from '../images/episodes/trampapo_02_22.jpg';
import thumb_02_23 from '../images/episodes/trampapo_02_23.jpg';
import thumb_02_24 from '../images/episodes/trampapo_02_24.jpg';
import thumb_02_25 from '../images/episodes/trampapo_02_25.jpg';
import thumb_02_26 from '../images/episodes/trampapo_02_26.jpg';
import thumb_02_27 from '../images/episodes/trampapo_02_27.jpg';
import thumb_02_28 from '../images/episodes/trampapo_02_28.jpg';
import thumb_02_29 from '../images/episodes/trampapo_02_29.jpg';
import thumb_02_30 from '../images/episodes/trampapo_02_30.jpg';
import thumb_02_31 from '../images/episodes/trampapo_02_31.jpg';
import thumb_02_32 from '../images/episodes/trampapo_02_32.jpg';

import content_01_thumb from '../images/extra-contents/thumb_01.jpg';
import content_01_featured from '../images/extra-contents/featured_01.jpg';
import content_01_guest from '../images/extra-contents/guest_01_renato_carvalho.png';

import content_02_thumb from '../images/extra-contents/conteudo_extra_02.jpg';
import content_02_featured from '../images/extra-contents/conteudo_extra_02.jpg';
import content_02_guest_1 from '../images/extra-contents/guest_02_1_barbara_lima_trampapo.png';
import content_02_guest_2 from '../images/extra-contents/guest_02_2_marco_ornellas_trampapo.png';

import content_03_thumb from '../images/extra-contents/conteudo_extra_03.jpg';
import content_03_featured from '../images/extra-contents/conteudo_extra_03.jpg';
import content_03_guest_1 from '../images/extra-contents/guest_03_1_duda_almeida.png';
import content_03_guest_2 from '../images/extra-contents/guest_03_2_renato_braga_sacco.png';

import content_04_thumb from '../images/extra-contents/thumb_04.jpg';
import content_04_featured from '../images/extra-contents/featured_04.jpg';
import content_04_guest_01 from '../images/extra-contents/guest_04_fernanda_nagano.png';
import content_04_guest_02 from '../images/extra-contents/guest_04_renata_prestes.png';

import content_05_thumb from '../images/extra-contents/conteudo_extra_05.jpg';
import content_05_featured from '../images/extra-contents/conteudo_extra_05.jpg';
import content_05_guest_01 from '../images/extra-contents/guest_05_bianca_machado.png';
import content_05_guest_02 from '../images/extra-contents/guest_vitor_cruz.png';

import content_06_thumb from '../images/extra-contents/conteudo_extra_06_18.jpg';
import content_06_featured from '../images/extra-contents/conteudo_extra_06_18.jpg';
import content_06_guest_01 from '../images/extra-contents/guest_06_patricia_anunciada.png';
import content_06_guest_02 from '../images/extra-contents/guest_06_camila_almeida.png';

import content_07_thumb from '../images/extra-contents/conteudo_extra_07_19.jpg';
import content_07_featured from '../images/extra-contents/conteudo_extra_07_19.jpg';
import content_07_guest_01 from '../images/extra-contents/guest_7_1_vivian maternativa.png';
import content_07_guest_02 from '../images/extra-contents/guest_7_2_mariana_luz.png';

import content_08_thumb from '../images/extra-contents/conteudo_extra_08_20.jpg';
import content_08_guest_01 from '../images/extra-contents/guest_08_1_karen_santos.png';
import content_08_guest_02 from '../images/extra-contents/guest_08_2_cynthia_zanoni.png';

import content_09_thumb from '../images/extra-contents/conteudo_extra_09_21.jpg';
import content_09_guest_01 from '../images/extra-contents/guest_09_1_sheynna.png';

import content_10_thumb from '../images/extra-contents/conteudo_extra_10_22.jpg';
import content_10_guest_01 from '../images/extra-contents/guest_10_01_caue_oliveira.png';
import content_10_guest_02 from '../images/extra-contents/guest_10_02_talita_tiemi.png';

import content_11_thumb from '../images/extra-contents/conteudo_extra_11_23.jpg';
import content_11_guest_01 from '../images/extra-contents/guest_11_01_yasmin_vitoria.png';
import content_11_guest_02 from '../images/extra-contents/guest_11_01_danielle_torres.png';

import content_12_thumb from '../images/extra-contents/conteudo_extra_12_24.jpg';
import content_12_guest_01 from '../images/extra-contents/guest_12_01_priscylla.png';
import content_12_guest_02 from '../images/extra-contents/guest_12_02_lisiane_lemos.png';

import content_13_thumb from '../images/extra-contents/conteudo_extra_13_25.jpg';
import content_13_guest_01 from '../images/extra-contents/guest_13_01_ale_santos.png';
import content_13_guest_02 from '../images/extra-contents/guest_13_02_akin_abaz.png';

import content_14_thumb from '../images/extra-contents/conteudo_extra_14_26.jpg';
import content_14_guest_01 from '../images/extra-contents/guest_14_01.jpg';
import content_14_guest_02 from '../images/extra-contents/guest_14_02.jpg';

import content_15_thumb from '../images/extra-contents/conteudo_extra_15_27.jpg';
import content_15_guest_01 from '../images/extra-contents/guest_15_01.png';
import content_15_guest_02 from '../images/extra-contents/guest_15_02.png';

import content_16_thumb from '../images/extra-contents/conteudo_extra_16_28.jpg';
import content_16_guest_01 from '../images/extra-contents/guest_16_01.png';
import content_16_guest_02 from '../images/extra-contents/guest_16_02.png';

import content_17_thumb from '../images/extra-contents/conteudo_extra_17_29.jpg';
import content_17_guest_01 from '../images/extra-contents/guest_17_01.png';

import content_18_thumb from '../images/extra-contents/conteudo_extra_18_30.jpg';
import content_18_guest_01 from '../images/extra-contents/guest_18_01.png';
import content_18_guest_02 from '../images/extra-contents/guest_18_02.png';

import content_19_thumb from '../images/extra-contents/conteudo_extra_19_31.jpg';
import content_19_guest_01 from '../images/extra-contents/guest_19_01.png';
import content_19_guest_02 from '../images/extra-contents/guest_19_02.png';

import content_20_thumb from '../images/extra-contents/conteudo_extra_20_32.jpg';
import content_20_guest_01 from '../images/extra-contents/guest_20_01.png';
import content_20_guest_02 from '../images/extra-contents/guest_20_02.png';

export const episodes = [
  {
    id: 32,
    date: '28/05/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Fortalecendo sua imagem profissional: marketing pessoal',
    slug: 'fortalecendo-sua-imagem-profissional-marketing-pessoal',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/ce3e6a40-0255-433f-982a-ea4e1fc18936?dark=false',
    about:
      'Como você se apresenta para o mundo? Marketing pessoal, para muitos, pode parecer arrogância, mas é uma ferramenta para se empoderar de suas características, habilidades, experiências e valores. É essencial para se destacar no mercado de trabalho! No último episódio desta temporada do Trampapo, Ana Paula Xongani e Ricardo Morais recebem Bianca Carmignani, psicóloga e Head de RH da Nespresso Brasil; e Maytê Carvalho, escritora, professora de retórica na ESPM e diretora de estratégia da TBWA/Chiat Day, para uma conversa sobre boas impressões, persuasão e orgulho. Para conquistar boas posições no mercado, as redes sociais podem ser tão importantes quanto um bom currículo, elas são um palco para a sua narrativa profissional e seus projetos, assumir as suas vulnerabilidades e vender você por quem você é. Quer aprender mais sobre marketing pessoal? Aperte o play!',
    guests: [
      {
        name: 'Bianca Carmignani',
        job: 'Senior HR Business Partner Nestlé Nespresso',
      },
      {
        name: 'Maytê Carvalho',
        job: 'Comunicóloga, Escritora, Professora de Retórica e Diretora de estratégia de negócios na TBWAChiat Day',
      },
    ],
    thumbnail: thumb_02_32,
  },
  {
    id: 31,
    date: '21/05/2021',
    categories: ['DIVERSIDADE'],
    title: 'Comunidade: Quem poderá nos ajudar?',
    slug: 'comunidade-quem-podera-nos-ajudar',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/e944c022-366d-4be1-9746-61912751847f?dark=false',
    about:
      'Sabe o muro da desigualdade? E aí, em qual dos lados desse muro você nasceu? Existe uma separação na nossa sociedade que define quem vai ter acesso à boa educação e ótima estrutura no lar e na saúde. Normalmente, essas pessoas são as mesmas que ocupam as lideranças das empresas e excluem do mercado de trabalho formal aqueles que vivem nas comunidades e não atendem as exigências elitistas dos processos seletivos, que apenas uma pequena parcela privilegiada da população tem acesso. Mas e as comunidades, quem poderá ajudar? Neste Trampapo, Ana Paula Xongani e Ricardo Morais recebem duas instituições que são referência em impacto social e no desenvolvimento de jovens. Lê Maestro, cofundador e diretor de educação da Rede Gerando Falcões, e Fabiana Fragiacomo, gerente de marketing do Instituto Ayrton Senna, falam sobre privilégios, empatia, meritocracia, capitalismo selvagem e muito mais para refletir a favor da criação de oportunidades por meio da responsabilidade social e do investimento na educação. Vem fazer parte desta conversa, aperte o play!',
    guests: [
      {
        name: 'Fabiana Fragiácomo',
        job: 'Head of marketing do Instituto Ayrton Senna',
      },
      {
        name: 'Alex do Santos (LeMaestro) ',
        job: 'Co - Fundador da REDE Gerando Falcões',
      },
    ],
    thumbnail: thumb_02_31,
  },
  {
    id: 30,
    date: '14/05/2021',
    categories: ['DIVERSIDADE'],
    title: 'Demitindo a LGBTfobia',
    slug: 'demitindo-a-lgbtfobia',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/d7c00f99-740b-4806-a8ab-d0f373f9ab55?dark=false',
    about:
      'Mesmo com muitos avanços, ainda é visível no mercado de trabalho a rejeição das pessoas que não seguem o "padrão" de sexualidade ou identidade de gênero. Se você nunca sentiu que precisava fingir ser outra pessoa, esconder alguns fatos da sua vida ou mudar sua aparência para conseguir um emprego, esse episódio é pra você. É pra você aprender sobre a realidade dos outros e sobre as desigualdades. Mas esse Trampapo é pra você também que sentiu que para conseguir emprego ou uma promoção era necessário se esconder. É pra refletir sobre a comunidade LGBTQIAP+ e como a discriminação ainda está presente nas empresas e deixando de fora profissionais taletosos. Para isso, contamos com a ajuda de Leticia Sayuri, consultora de diversidade do banco Santander e cofundadora da Rede de Mulheres LBTQ+, também recebemos Louie Ponto, criadora de conteúdo e mestra em literatura na linha de crítica feminista e estudo de gênero. Quer fazer parte desse papo? Aperte o play!',
    guests: [
      {
        name: 'Leticia Sayuri',
        job: 'Consultora de Diversidade do Banco Santander e Cofundadora da Rede de Mulheres LBTQ+',
      },
      {
        name: 'Louie Ponto',
        job: 'Criadora de conteúdo e Mestra em literatura',
      },
    ],
    thumbnail: thumb_02_30,
  },
  {
    id: 29,
    date: '07/05/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Criatividade para ter sucesso profissional',
    slug: 'criatividade-para-ter-sucesso-profissional',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/9aed9564-c57e-4c56-92f0-ad9c532bcb99?dark=false',
    about:
      'A criatividade é uma ferramenta que pertence ao nosso repertório desde a infância, mas por motivos estruturais e sociais desaprendemos a exercitar e começamos a depender de fórmulas de "sucesso garantido". O que nos leva a uma pergunta: como ser criativo no trabalho? Neste Trampapo, Ana Paula Xongani recebe Luiz Eduardo Serafim, Gerente de Marketing Corporativo na 3M Brasil, professor universitário e palestrante. Ele defende que a criatividade não é só fundamental para o mercado de trabalho, mas sim para todos os âmbitos da nossa vida e que ela pode nos trazer muita felicidade. A criatividade pode ser estimulada quando expandimos nosso repertório cultural e intelectual, mas também observando as necessidades à nossa volta e encontrando soluções criativas para os problemas que surgem. Já no ambiente empresarial, essa qualidade precisa ser encorajada, as lideranças precisam garantir a confiança e a segurança entre seus colaboradores. Essa é a base para criar soluções inovadoras e de grande impacto! Quer fazer parte desta conversa? Aperte o play!',
    guests: [
      {
        name: 'Luiz Eduardo Serafim',
        job: 'Gerente de Marketing Corporativo na 3M',
      },
    ],
    thumbnail: thumb_02_29,
  },
  {
    id: 28,
    date: '30/04/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Como eu vou trabalhar no futuro?',
    slug: 'como-eu-vou-trabalhar-no-futuro',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/be9456a6-3081-4dd0-b662-8a05b66762ac?dark=false',
    about:
      'O que esperar do futuro? Como se preparar para aquilo que ainda não é realidade e para profissões que ainda não existem? Nesta semana no Trampapo, Ana Paula Xongani recebe Vanessa Mathias, fundadora da White Rabbit, agência especializada em observar tendências e sinais futuros, e Cristiane Silva Pinto, gerente de marketing e soluções no Google Brasil e fundadora do AfroGooglers, para descobrir o que vai acontecer, considerando que está sendo criado hoje. Estudos indicam que 15% das carreiras que conhecemos podem desaparecer nos próximos 5 anos e apenas 7% serão criadas neste futuro emergente. Será que a sua profissão é uma delas? Trabalhos voltados para o desenvolvimento de capacidades, cuidado e relacionamento com outras pessoas continuarão sendo fundamentais para a sociedade, porém, as funções manuais e de fácil automatização passarão por um processo de especialização. A evolução do trabalho pode mudar o mercado que conhecemos em questão de meses, assim como a criação de serviços de streaming abalou as locadoras de filmes ao redor do mundo e os apps de caronas mudaram a forma como nos locomovemos. Quer conhecer os sinais do que está por vir e as habilidades que serão indispensáveis para o profissional do futuro? Aperte o play!',
    guests: [
      {
        name: 'Christiane Silva Pinto',
        job: 'Gerente de Marketing no Google Brasil e fundadora do AfroGooglers',
      },
      {
        name: 'Vanessa Mathias',
        job: 'Fundadora da White Rabbit',
      },
    ],
    thumbnail: thumb_02_28,
  },
  {
    id: 27,
    date: '23/04/2021',
    categories: ['DIVERSIDADE'],
    title: 'PcD e o Mito da incapacidade',
    slug: 'PcD-e-o-mito-da-incapacidade',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/a94e4aa1-0869-4074-8914-a42c239abd8e?dark=false',
    about:
      'O que define capacidade no mercado de trabalho? Para muitos, a única resposta deveria ser o currículo, mas não podemos deixar de lembrar que o preconceito ainda limita o desenvolvimento da sociedade. É fácil perceber que as pessoas com deficiência estão sendo deixadas de lado quando elas não conseguem acessar fisicamente certos espaços e também quando notamos que não as temos em nosso entorno nas empresas e círculos sociais, mas precisamos também treinar o olhar para perceber que as pessoas com deficiência não são incapazes. A capacidade de um profissional não pode ser atrelada a uma deficiência ou a um corpo que é considerado “normal”. Pessoas são pessoas e cada uma tem alguma dificuldade, mas para que todos tenham o mesmo desempenho, é preciso que as condições e oportunidades sejam as mesmas para todos. Por isso, neste episódio, Ana Paula Xongani convida Tatiana Hennemann, gerente de Supply Chain na Natura, uma empresa exemplar no quesito inclusão, e Beto Maia, que é desenvolvedor, paratleta e criador de conteúdo, que comenta suas experiências e seus empregos sendo uma pessoa com deficiência. Quer fazer parte desta conversa que abre oportunidades de inclusão e respeito? Aperte o play!',
    guests: [
      {
        name: 'Beto Maia',
        job: 'Desenvolvedor, Paratleta e Criador de conteúdo',
      },
      {
        name: 'Tatiana Hennemann',
        job: 'Gerente de Supply Chain na Natura',
      },
    ],
    thumbnail: thumb_02_27,
  },
  {
    id: 26,
    date: '16/04/2021',
    categories: ['DIVERSIDADE'],
    title: 'Talentos não envelhecem: 50+ no mercado',
    slug: 'talentos-nao-envelhecem-50-no-mercado',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/6e4985fc-0172-4238-af71-b6fb9b4e11ab?dark=false',
    about:
      'Experientes, conectados e conhecem a solução para boa parte dos problemas. Os profissionais maduros têm praticamente todas as qualidades que o mercado deseja. Então, por que a idade seria um problema? Neste Trampapo, Ana Paula Xongani e Ricardo Morais recebem Amanda Fernandes, coordenadora de RH da CashMe, e Layla Vallias, mercadóloga e co-fundadora do Guia Longevidade & Hype50+, para uma conversa sobre o mercado de trabalho para os profissionais com mais de 50 anos. Com o aumento da população idosa, as mudanças previdenciárias e da sociedade, é emergencial pensar em soluções para acabar com os preconceitos etários e com a cultura que exclui e invalida os mais experientes das empresas. Como podemos incentivar a permanência e a entrada dos idosos no mercado? Como eles podem contribuir para a saúde e inovação dos negócios? Quer fazer parte dessa conscientização e movimento? Aperte o play!',
    guests: [
      {
        name: 'Layla Vallias',
        job: 'Mercadóloga e Co-fundadora do Guia Longevidade & Hype50+',
      },
      {
        name: 'Amanda Fernandes',
        job: 'Coordenadora de Recursos Humanos da CashMe',
      },
    ],
    thumbnail: thumb_02_26,
  },
  {
    id: 25,
    date: '09/04/2021',
    categories: ['DIVERSIDADE'],
    title: 'Afrofuturismo e antirracismo no mercado de trabalho',
    slug: 'afrofuturismo-e-antirracismo-no-mercado-de-trabalho',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/f506b545-762f-4863-b24f-0243a84920ac?dark=false',
    about:
      'Como será o amanhã? Será que depois de tantas desigualdades raciais, a sociedade vai continuar avançando às custas da desvalorização de certas vidas? Será que no futuro a tecnologia vai ser acessível para todos ou as soluções superinteligentes vão continuar sendo um privilégio de poucos grupos? Será que no futuro a população preta vai estar integrada aos desenvolvimentos científicos, tecnológicos e sociais? E se esse futuro pudesse, desde já, ser pensado também para e pela a comunidade negra? Neste episódio, Ana Paula Xongani e Ricardo Morais recebem o escritor afrofuturista, Ale Santos, e o CEO da Infopreta, Akin Abaz, para debater o atual cenário para pessoas negras no mercado de trabalho e as soluções para torná-lo antirracista a partir das perspectivas afrofuturistas. Quer entender mais sobre esse conceito e saber como podemos transformar o futuro e o presente do mercado de trabalho? Aperte o play!',
    guests: [
      {
        name: 'Akin Abaz',
        job: 'CEO da Infopreta e Consultor  de Inovação',
      },
      {
        name: 'Ale Santos',
        job: 'Autor Afrofuturista e Comunicador Digital',
      },
    ],
    thumbnail: thumb_02_25,
  },
  {
    id: 24,
    date: '02/04/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Como ser um profissional de alta performance',
    slug: 'como-ser-um-profissional-de-alta-performance',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/da016258-61fc-4458-902d-a5dd356b31e2?dark=false',
    about:
      'O mercado de trabalho mudou rapidamente e as qualidades que as empresas mais valorizam também mudaram. Quais são as características dos profissionais de alta performance, que conseguem promoções e propostas de trabalho com frequência? Neste episódio, Ana Paula Xongani recebe Lisiane Lemos, gerente de desenvolvimento de negócios, advogada, professora de MBA e conselheira; e Priscylla Haddad, gerente de desenvolvimento organizacional, talento e cultura na Coca-Cola FEMSA; que compartilham suas histórias de erros e acertos, dão dicas de como se qualificar gratuitamente e alcançar melhores resultados de forma saudável, sem ultrapassar os limites do corpo e da mente. Elas contam também que estar atento ao que acontece no mundo e aberto para desaprender e aprender é uma regra. Quer saber mais? Aperte o play!',
    guests: [
      {
        name: 'Lisiane Lemos',
        job: 'Gerente de Desenvolvimento de Negócios, Advogada, Professora de MBA e Conselheira',
      },
      {
        name: 'Priscylla Haddad',
        job: 'Gerente Desenvolvimento Organizacional, Talento e Cultura na Coca-Cola FEMSA',
      },
    ],
    thumbnail: thumb_02_24,
  },
  {
    id: 23,
    date: '19/03/2021',
    categories: ['DIVERSIDADE'],
    title: 'Pessoas trans: trampos e vivências',
    slug: 'pessoas-trans-trampo-e-vivencias',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/8d002ca3-1a8d-4456-9b5b-babaa0cd53d7?dark=false',
    about:
      'Você sabia que apenas 4% da população trans está formalmente inserida no mercado de trabalho? Neste Trampapo, Ana Paula Xongani recebe duas mulheres trans do mundo corporativo, Danielle Torres, da KPMG, e Yasmin Vitória, da Salesforce Brasil, que contam suas vivências, jornadas e lutas neste mercado de trabalho que ainda é tão fechado para a transgeneridade. Para elas, o certo é inegociável. Precisamos de medidas para alcançar o respeito e uma transformação social definitiva, afinal, ainda faltam oportunidades nas empresas, falta conhecimento e empatia por parte dos líderes e colegas de trabalho. Quer entender alguns dos motivos que fazem essa desigualdade existir e por que essa conversa é mais que importante para todas as pessoas?  É fácil, aperte o play!',
    guests: [
      {
        name: 'Yasmin Vitória',
        job: 'Associada de sucesso do cliente latam na Salesforce',
      },
      {
        name: 'Danielle Torres',
        job: 'Departamento de prática profissional da KPMG',
      },
    ],
    thumbnail: thumb_02_23,
  },
  {
    id: 22,
    date: '19/03/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Como não odiar as segundas-feiras?',
    slug: 'como-nao-odiar-as-segundas-feiras',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/0c672a44-5e2c-4efb-9155-282ccb06b682?dark=false',
    about:
      'A síndrome da segunda-feira já atingiu a todos nós pelo menos uma vez. Perceber que o final de semana se foi e mesmo quando gostamos do nosso emprego ficamos ansiosos com o recomeço do trabalho. No Trampapo de hoje, Ana Paula Xongani recebe Talita Tiemi, psicóloga e consultora de RH na UP! Capital Humano, e Cauê Oliveira, da Great Place To Work (GPTW), que nos explicam que o trabalho não pode ser a nossa única atividade e que devemos buscar satisfação e equilíbrio em todos os campos da vida. Mas como encontramos entusiasmo nas empresas? Como lidar com empregos que não gostamos? Como superar os problemas com a liderança e de relacionamentos no trabalho? Além disso, tem várias histórias contadas pelos convidados para você refletir sobre propósito, valores, felicidade e motivação. Que tal apertar o play e continuar essa conversa?',
    guests: [
      {
        name: 'Cauê Oliveira',
        job: 'Consultor e Diretor de Capacitação at Great Place to Work Brasil',
      },
      {
        name: 'Talita Tiemi Pereira',
        job: 'Consultora e Fundadora da UP! Capital Humano',
      },
    ],
    thumbnail: thumb_02_22,
  },
  {
    id: 21,
    date: '12/03/2021',
    categories: ['DIVERSIDADE'],
    title: 'Profissão tem gênero?',
    slug: 'profissao-tem-genero',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/ac58ea75-b8ec-4c11-bc31-b72acc1069de?dark=false',
    about:
      'A sociedade muitas vezes impõe preconceitos e regras que limitam o desenvolvimento dos futuros profissionais desde a infância, afinal meninas também podem ser grandes líderes e meninos também são capazes de exercer o cuidado. Hoje no Trampapo, Ana Paula Xongani e Ricardo Morais recebem Sheynna Hakim Rossignol, que é formada em engenharia civil, liderou nas áreas de construção, finanças, administração, tecnologia e é a atual Gerente Geral do Chama the app, para uma conversa fundamental sobre a relação gênero x profissão e como ela coloca barreiras no caminho das pessoas, limita capacidades de aprendizado e prejudica sonhos. Qual é o papel das empresas no combate do machismo e da masculinidade tóxica? Como o acesso à informação tem diminuído a desigualdade e valorizado as qualidades humanas? Trabalhamos para nos sentirmos completos, felizes e nada pode nos impedir de atingirmos os nossos objetivos. Quer participar desta conversa? Aperte o play!',
    guests: [
      {
        name: 'Sheynna Hakim Rossignol',
        job: 'Presidente | Conselheira de Digital | Investidora de Startups',
      },
    ],
    thumbnail: thumb_02_21,
  },
  {
    id: 20,
    date: '05/03/2021',
    categories: ['DIVERSIDADE'],
    title: 'Mulheres na tecnologia: you code, girl!',
    slug: 'mulheres-na-tecnologia',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/28f3881b-208b-4040-92e9-1c088d01675e?dark=false',
    about:
      'Sabia que o mercado de tecnologia, assim como muitos outros, é predominantemente masculino? Apenas 20% das pessoas que trabalham com TI são mulheres e apenas 15% dos alunos de ciência da computação e engenharia são mulheres. Mas o que dificulta a participação delas nesta indústria? O machismo estrutural e o processo de educação das meninas são os principais responsáveis, por isso as perspectivas de mudança ainda parecem distantes. Vamos entender melhor o papel da mulher no desenvolvimento da tecnologia? Ana Paula Xongani e Ricardo Morais recebem Karen Santos, CEO da UX para Minas Pretas, e Cynthia Zanoni, fundadora da WoMakersCode, para entender a luta diária pela democratização deste mercado. A tecnologia impacta diretamente a vida das pessoas e as empresas estão cada vez mais tecnológicas, mas ela só será verdadeiramente para todxs quando ela for construída por todxs. Quer saber mais? Aperte o play!',
    guests: [
      {
        name: 'Karen Santos',
        job: 'CEO da UX para Minas Pretas',
      },
      {
        name: 'Cynthia Zanoni',
        job: 'Fundadora da WoMakersCode',
      },
    ],
    thumbnail: thumb_02_20,
  },
  {
    id: 19,
    date: '26/02/2021',
    categories: ['CAUSAS'],
    title: 'Trabalho de mãe',
    slug: 'trabalho-de-mae',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/08a5da3f-3809-4d65-b7df-007f7f652c6f?dark=false',
    about:
      'Certamente, você já ouviu falar que mulheres dão prejuízos às empresas porque engravidam. Ou, então, que o papel de cuidar dos filhos é principalmente das mulheres, enquanto o dos homens é ser o provedor financeiro. Pode ser também que você tenha sido obrigada a deixar sua carreira de lado para se dedicar à maternidade, ou que nenhuma empresa tenha te dado a oportunidade de trabalhar só porque você tem criança pequena. Até onde vão os preconceitos e obstáculos da sociedade para as mães? Como garantir o bem-estar e cuidado das nossas futuras gerações se as comunidades não se responsabilizam por elas? Como criar conscientização sobre a paternidade ativa e o valor do trabalho doméstico? Quais políticas de amparo à parentalidade as empresas de todos os portes podem oferecer? Xongani e Ricardo recebem Vivian Abukater, mãe, sócia e diretora executiva da Maternativa e Mariana Luz, mãe e diretora CEO da fundação Maria Cecilia Souto Vidigal, para responder essas questões.  Aperte o play e descubra como a maternidade pode ser potência em sua vida profissional!',
    guests: [
      {
        name: 'Vivian Abukater',
        job: 'Sócia da Rede Maternativa',
      },
      {
        name: 'Mariana Luz',
        job: 'CEO - Fundação Maria Cecilia Souto Vidigal',
      },
    ],
    thumbnail: thumb_02_19,
  },
  {
    id: 18,
    date: '19/02/2021',
    categories: ['QUALIFICAÇÃO'],
    title: 'Qualificação: um chamado para a liberdade',
    slug: 'qualificacao-um-chamado-para-a-liberdade',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/f94f5564-5474-4416-8815-630d618057ca?dark=false',
    about:
      'Exigente e competitivo. É assim que se encontra o atual mercado de trabalho. Mas na corrida por um emprego, será mesmo que todos largam juntos nessa? Como ficam aqueles que não tiveram acesso a uma boa educação? No episódio #18 do Trampapo, Ana Paula Xongani e Ricardo Morais falam sobre como a qualificação pode te libertar para uma vida profissional mais feliz e para um mercado de trabalho mais diverso. Para essa conversa, recebemos Patricia Anunciada, educadora e pesquisadora das relações étnico-raciais, e Camila Almeida, líder da Arco Academy e design estratégico, que comentam sobre educação humanizada e seu processo de inovação, desigualdades sociais, meritocracia, escola do futuro e a relação entre qualificação e empregabilidade. Como podemos romper as barreiras e ajudar com a ascensão das pessoas menos favorecidas? As empresas podem contribuir para isso? Quais são as desvantagens de se adotar um sistema EAD? Além disso, tem dicas de qualificação gratuita ou barata! Aperte o play e descubra que a educação deve ser prática para "todes" e visar a liberdade sempre!',
    guests: [
      {
        name: 'Patrícia Anunciada',
        job: 'Educadora e pesquisadora das relações étnico-raciais',
      },
      {
        name: 'Camila Almeida',
        job: 'Líder da Arco Academy e design estratégico na Arco Hub de Inovação',
      },
    ],
    thumbnail: thumb_02_18,
  },
  {
    id: 17,
    date: '15/02/2021',
    categories: ['LIDERAÇÃO'],
    title: 'Chefe x líder: a humanização da gestão',
    slug: 'chefe-x-lider-a-humanizacao-da-gestao',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/e828f412-36c3-435f-8e0b-1e228b03f478?dark=false',
    about:
      'Dentro de uma empresa existem vários setores e todos eles são coordenados por alguém. Os comandantes dessas áreas podem ser: chefes que só sabe mandar nas pessoas ou líderes que inspiram seus colaboradores a inovarem. E o seu patrão, é um chefe ou um líder? Você sabe quais são as diferenças entre eles? No episódio #17 do Trampapo, Ana Paula Xongani e Ricardo Morais recebem Bianca Machado, Gerente Sênior na Catho Empresas, e Vitor Cruz, palestrante e treinador de líderes para uma conversa sobre as principais diferenças de atitude e conduta entre líder e chefe. Nas telonas, figuras caricatas como Sr. Burns dos Simpsons, Dr. House e Michael Scott de The Office, alimentaram o estereótipo do chefe narcisista, autoritário, tóxico e às vezes até incompetente. É só ficção ou eles existem na vida real? Os convidados ainda comentam dos assédios morais no ambiente de trabalho, como funciona a síndrome do impostor, a importância de ouvir e se comunicar, valorização do feedback, autoliderança e o que é a tal Gestão Humanizada. Afinal, quais são as maiores dificuldades para se mudar para este modelo de gestão? Dentro do corporativismo, é possível acreditar em liderança, humanidade e diversidade Genuína? Vem com a gente, aperte o play e descubra como ser um bom líder e um ótimo liderado!',
    guests: [
      {
        name: 'Bianca Machado',
        job: 'Sênior Manager Catho',
      },
      {
        name: 'Vitor Cruz',
        job: 'Empresário | Palestrante nas áreas de Liderança',
      },
    ],
    thumbnail: thumb_02_17,
  },
  {
    id: 16,
    date: '08/02/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'GenZ no mercado: estágio, aprendizagem trainee',
    slug: 'genz-no-mercado-estagio-aprendizagem-trainee',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/cb055654-2393-43c2-934b-be19545a24c7?dark=false',
    about:
      'Sabemos que os jovens precisam vencer várias barreiras até entrar no mercado de trabalho e, muitas vezes, essa tarefa torna-se mais difícil do que parece! Poucas oportunidades, falta de experiência, cargos específicos e a concorrência alta são fatores que podem desanimar qualquer um na busca por emprego. No episódio #16 do Trampapo, Ana Paula Xongani e Ricardo Morais falam sobre os desafios enfrentados pelos jovens na inserção ao mercado de trabalho. Recebemos para esta conversa Renata Prestes, Recruiter na área de Talent Acquisition da Bayer Brasil, e Fernanda Nagano, Gerente de Recursos Humanos e Talent Acquisition da Bayer Brasil, para explicar tudo sobre os programas de estágio, trainee e jovem aprendiz. Afinal, como aproveitar ao máximo esses programas para trilhar uma carreira bem sucedida? Além disso, vamos comentar sobre como os jovens podem se impor dentro das empresas almejando um crescimento profissional e o poder que a mentoria tem para colaboradores de primeira viagem no mundo corporativo. Qual é o destino da geração Z em um mercado cada vez mais restrito, digital, desgastado, com crise econômica e ainda enfrentando uma pandemia? O que são as tão faladas habilidades do futuro? Aperte o play e confira muitas dicas para você que está começando sua jornada profissional!',
    guests: [
      {
        name: 'Fernanda Nagano',
        job: 'Gerente de Recursos Humanos e Talent Acquisition da Bayer ',
      },
      {
        name: 'Renata Prestes',
        job: 'Human Resources Recruiter da Bayer',
      },
    ],
    thumbnail: thumb_02_16,
  },
  {
    id: 15,
    date: '01/02/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Empregos dos sonhos: o que são? Onde estão? Como se reproduzem?',
    slug: 'empregos-dos-sonhos-o-que-sao-onde-estao-como-se-reproduzem',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/a5039943-6504-4773-b933-c7f4d323c28e?dark=false',
    about:
      'Fazer o que gosta, na área escolhida, ganhando bem e tendo reconhecimento. Quem nunca sonhou com esse emprego perfeito? Mas será que isso existe mesmo? Neste programa, Ana Paula Xongani e Ricardo Morais mergulham de cabeça no mundo dos empregos dos sonhos para descobrir se as oportunidades de conquistas são iguais para todos e quais são os caminhos para encontrar a tal profissão ideal. Até porque, hoje, as carreiras mais conhecidas e tradicionais, como medicina, direito e educação, deixam de ser as principais opções. Com a dinâmica da internet, existem novos tipos de empregos, como digital influencer, social media e atletas de "e-sports". Afinal, como se arriscar em trabalhos não convencionais? Como conseguir apoio nesta empreitada?  Como lidar com as frustrações de não alcançar os objetivos e com os prós e contras do mercado? Recebemos Renato Braga Sacco, que já foi jogador de futebol e hoje é Coordenador das categorias de base do Taboão da Serra, e Duda de Almeida, Roteirista na 02 Filmes, atuando hoje para a Globoplay, e que já escreveu a série Sintonia, da Netflix, para essa conversa. Aperte o play, inspire-se e questione-se com a gente sobre sua vida profissional!',
    guests: [
      {
        name: 'Duda de Almeida',
        job: 'Roteirista',
      },
      {
        name: 'Renato Sacco',
        job: 'Jogador aposentado',
      },
    ],
    thumbnail: thumb_02_15,
  },
  {
    id: 14,
    date: '25/01/2021',
    categories: ['DIVERSIDADE'],
    title: 'Tudo é feito de gente',
    slug: 'tudo-e-feito-de-gente',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/ddc5ffcf-e087-4d72-84a3-1e0119f690d1?dark=false',
    about:
      'Provavelmente você já ouviu aquela frase "Para ser valorizado, é preciso valorizar". Pois é, ela se aplica no mundo corporativo também. Falar de mudança social, tomar atitudes e pensar no trabalhador como um ser humano e não como recurso é fundamental, pois tudo é feito de gente para gente! Ana Paula Xongani e Ricardo Morais recebem Bárbara Lima, Coordenadora de Comunicação e Diversidade na Mutato, e Marco Ornellas, Consultor e Psicólogo, para um bate-papo superimportante sobre o olhar do mercado de trabalho para as pessoas e as diversidades. Para isso, precisamos entender: está faltando propósito verdadeiro para as empresas? As campanhas da Magazine Luiza, com o programa de trainee para negros, e do Burguer King, que fez doação para ONGS LGBTQIA+, mostraram como as marcas estão olhando cada vez mais para causas legítimas. Fique esperto que ainda vamos falar dos problemas com a licença maternidade, visibilidade para o profissional com deficiência, leis de cotas e muito mais! Então, chega junto, aperte o play e vem com a gente nesta conversa!',
    guests: [
      {
        name: 'Barbara Lima',
        job: 'Coordenadora de Comunicação e Diversidade na Mutato',
      },
      {
        name: 'Marco Ornellas',
        job: 'Consultor em Desenvolvimento Organizacional, Coach, Palestrante, Designer e Escritor residente',
      },
    ],
    thumbnail: thumb_02_14,
  },
  {
    id: 13,
    date: '18/01/2021',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Efeitos da pandemia: o novo normal do mercado de trabalho',
    slug: 'efeitos-da-pandemia-o-novo-normal-do-mercado-de-trabalho',
    type: 'audio',
    season: 2,
    player:
      'https://player.simplecast.com/59b3483f-3a3e-49cb-a1b0-6a23562558b6?dark=false',
    about:
      'Quantas vezes você já leu ou ouviu, desde o início da pandemia, que estamos no "novo normal"? Nesses tempos difíceis tivemos que encarar vários transtornos e transformações, e no mercado de trabalho não foi diferente! No episódio de estreia da 2ª temporada do Trampapo, Ana Paula Xongani e Ricardo Morais conversam com Renato Carvalho, Fundador do Movimento Officeless, sobre como foi a adaptação das empresas que adotaram o home office, mudanças nas rotinas do trampo, a empatia de chefe com funcionário e os impactos sociais. Até porque, muitas pessoas da periferia que não têm acesso à rede ou estrutura adequada em casa, tiveram que se expor ao vírus para colocar comida na mesa. E a saúde mental, como fica nisso tudo? Qual é o impacto positivo que a pandemia está trazendo para a diversidade no mercado? Será que teve um aumento na consciência de "todes" nós? Fica esperto que ainda tem o quadro Manda o Papo e muitas dicas extracurriculares! Não perca tempo, aperte o play e confira agora!',
    guests: [
      {
        name: 'Ana Paula Xongani',
        job: 'influenciadora e  apresentadora do programa Se Essa Roupa Fosse Minha - GNT',
      },
      {
        name: 'Ricardo Morais',
        job: 'Gerente Sênior de Marketing da Catho',
      },
      {
        name: 'Renato Carvalho',
        job: 'Sócio fundador e COO da Officeless',
      },
    ],
    thumbnail: thumb_02_13,
  },
  {
    id: 12,
    date: '15/07/2020',
    categories: ['CARREIRA'],
    title: 'Insights da primeira temporada: melhores momentos',
    slug: 'insights-da-primeira-temporada-melhores-momentos',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/0cf17859-98e3-4936-a202-6770a5b1b7df?dark=false',
    about:
      'Que tal uma seleção dos principais destaques da primeira temporada do Trampapo para você tirar insights incríveis para a sua carreira? Executivos de mais de 25 empresas compartilharam conteúdos e experiências para ajudar você a se inspirar e alcançar cada vez mais sucesso profissional. Entre as empresas convidadas participaram grandes nomes como Catho, Grupo Pão de Açúcar, SENAI-SP, PUC, Stefanini Brasil, Rappi, Loggi, Movimento Mulher 360 e muitas mais. Dê o play e fique por dentro do que conversamos sobre tendências e diversidade no mercado de trabalho.',
    guests: [
      {
        name: 'Tais Pinheiro',
        job: 'Diretora de Estratégia e Criatividade na Edelman Brasil',
      },
      {
        name: 'Ricardo Morais',
        job: 'Gerente Sênior de Marketing da Catho',
      },
      {
        name: 'Aline Prates',
        job: 'Gerente de Planejamento no Grupo Artplan',
      },
      {
        name: 'Helen Torresan',
        job: 'Coordenadora de Recrutamento & Seleção do Grupo A.Yoshii',
      },
    ],
    thumbnail: thumb_01_12,
  },
  {
    id: 11,
    date: '30/04/2020',
    categories: ['CARREIRA'],
    title: 'Usando a internet a favor da carreira',
    slug: 'usando-a-internet-a-favor-da-carreira',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/cc7ef747-ea73-4970-82de-cf9b6e24b8fe?dark=false',
    about:
      'Para uma era digital, recrutamento digital. O uso das ferramentas disponíveis na web para buscar novos profissionais é um caminho sem volta. As empresas estão presentes neste ambiente e todos aqueles que buscam por um novo emprego também precisam estar. Quer saber como os recrutadores usam a internet para procurar, conhecer mais profundamente e atrair candidatos? As redes sociais, por exemplo, podem influenciar muito na escolha - ou na rejeição - de profissionais nos processos seletivos. Descubra as principais dicas para usar os recursos online a favor da sua carreira e da sua próxima contratação neste bate-papo com Ricardo Morais, Gerente Sênior de Marketing da Catho; Helen Torresan, Coordenadora de Recrutamento & Seleção do Grupo A.Yoshii; Tais Pinheiro, Diretora de Estratégia e Criatividade na Edelman Brasil; e Aline Prates, Gerente de Planejamento no Grupo Artplan.',
    guests: [
      {
        name: 'Tais Pinheiro',
        job: 'Diretora de Estratégia e Criatividade na Edelman Brasil',
      },
      {
        name: 'Ricardo Morais',
        job: 'Gerente Sênior de Marketing da Catho',
      },
      {
        name: 'Aline Prates',
        job: 'Gerente de Planejamento no Grupo Artplan',
      },
      {
        name: 'Helen Torresan',
        job: 'Coordenadora de Recrutamento & Seleção do Grupo A.Yoshii',
      },
    ],
    thumbnail: thumb_01_11,
  },
  {
    id: 10,
    date: '15/04/2020',
    categories: ['PcD'],
    title: 'Pessoas com deficiência: vaga de emprego é um direito',
    slug: 'pessoas-com-deficiencia-vaga-de-emprego-e-um-direito',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/b80ff0dd-6215-4691-93ca-71e611659aa8?dark=false',
    about:
      'A inclusão de pessoas com deficiência no mercado de trabalho é um assunto emergencial. Mesmo com a Lei de Cotas, as oportunidades de emprego e desenvolvimento de carreira não são igualitárias para esse grupo. Falta acolhimento, escuta, credibilidade e a valorização da diversidade nos ambientes profissionais. Vamos discutir sobre os desafios, as barreiras e as práticas recomendadas com marcas que estão engajadas com a verdadeira inclusão de pessoas com deficiência? Ouça o bate-papo com participação da Maiara Tortorette, Gerente de Comunicação Corporativa na Catho; Laís Markarian, Coordenadora de Recrutamento na Loggi; Djalma Scartezini, Psicólogo, Comunicador e Palestrante de Diversidade; e Maria Schneider, Diretora de RH no Grupo Pão de Açúcar.',
    guests: [
      {
        name: 'Maria Schneider',
        job: 'Diretora de RH no Grupo Pão de Açúcar',
      },
      {
        name: 'Laís Markarian',
        job: 'Coordenadora de Recrutamento na Loggi',
      },
      {
        name: 'Maiara Tortorette',
        job: 'Gerente de Comunicação Corporativa na Catho',
      },
      {
        name: 'Djalma Scartezini',
        job: 'Psicólogo; Comunicador e Palestrante de Diversidade',
      },
    ],
    thumbnail: thumb_01_10,
  },
  {
    id: 9,
    date: '01/04/2020',
    categories: ['ENTREVISTA'],
    title: 'Sem pegadinhas: as verdades sobre entrevistas de emprego',
    slug: 'sem-pegadinhas-as-verdades-sobre-entrevistas-de-emprego',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/32bc70e9-f6d0-4ceb-ad5f-8624a43f28a3?dark=false',
    about:
      'Dez. Esse é o número médio de candidatos que um recrutador entrevista para preencher uma vaga. Achou assustador, né? Pode ser muito difícil se destacar em processo seletivo, principalmente quando você concorre com muitos profissionais qualificados. Mas vamos facilitar essa etapa para você: neste episódio, Bianca Machado, Gerente Sênior B2B na Catho; Silvio Xavier, Gerente de RH no Carrefour; Gilberto Sobrinho, Diretor na Caccuri Advisors; e Fernanda Lelli, Coordenadora de Tech Recruting na Loggi, esclarecem as principais recomendações para ir bem na entrevista de emprego, como contar sua história, falar de suas competências, quais perguntas ousadas positivamente podem ser feitas e também como apresentar seus pontos negativos sem manchar sua imagem.',
    guests: [
      {
        name: 'Bianca Machado',
        job: 'Gerente Sênior B2B na Catho',
      },
      {
        name: 'Fernanda Lelli',
        job: ' Coordenadora de Tech Recruting - Loggi',
      },
      {
        name: 'Gilberto Sobrinho',
        job: 'Diretor associado na Caccuri Advisors - Latin America Talent Services',
      },
      {
        name: 'Silvio Xavier',
        job: 'Gerente de Recursos Humanos - Carrefour',
      },
    ],
    thumbnail: thumb_01_09,
  },
  {
    id: 8,
    date: '16/03/2020',
    categories: ['CAUSAS'],
    title: 'Diversidade: desafios da inclusão no mercado',
    slug: 'diversidade-desafios-da-inclusao-no-mercado',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/18badd3d-4f4d-4dc5-af92-4ac5cbdd2c3a?dark=false',
    about:
      'Em um país que tem 56% da sua população negra e parda, não é justo que essa fatia represente 64% dos desempregados, menos de 5% dos cargos executivos e de gerência e, desses, apenas 0,4% sejam ocupados por mulheres negras. Assim como também não é aceitável que 38% das empresas tenham restrições para contratar pessoas LGBTQIA+ ou que 67% dos pertencentes desse grupo não se sintam confortáveis em se assumir em seus ambientes profissionais.\nApesar dos crescentes debates a respeito da diversidade no mercado de trabalho, os grupos minorizados ainda não encontram igualdade de oportunidades na carreira, respeito às suas condições e ambientes acolhedores e inclusivos. Confira nesse bate-papo os caminhos para a verdadeira diversidade, como as empresas podem se posicionar para enfrentar e erradicar o preconceito e as vantagens que essa pauta provoca no mercado. Participam da conversa Roni Silva, Analista Sênior de Comunicação na Catho; Denise Brito, Gerente de Comunicação e Diversidade na Sodexo; e Heloisa Paula, Co-Fundadora da Mezcla Diversidade.',
    guests: [
      {
        name: 'Roni Silva',
        job: 'Analista Sênior de Comunicação na Catho',
      },
      {
        name: 'Denise Brito',
        job: 'Gerente de Comunicação e Diversidade na Sodexo',
      },
      {
        name: 'Heloisa Paula',
        job: 'Co-Fundadora da Mezcla Diversidade',
      },
    ],
    thumbnail: thumb_01_08,
  },
  {
    id: 7,
    date: '02/03/2020',
    categories: ['CAUSAS'],
    title: 'Mulher no trabalho: desconstruindo a desigualdade de gêneros',
    slug: 'mulher-no-trabalho-desconstruindo-a-desigualdade-de-generos',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/d75aa613-dd34-432b-8591-c41c73b0299f?dark=false',
    about:
      'Apesar da crescente presença das mulheres no mercado de trabalho, definitivamente, as oportunidades não são as mesmas quando comparamos homens e mulheres. Fatores como a responsabilização da mãe na criação dos filhos, a dupla jornada de trabalho e a discriminação ainda abrem precedentes para a desigualdade quando falamos de ocupação de cargos de alto nível, remuneração salarial, desenvolvimento de carreira e empregabilidade. Para discutir isso, o Trampapo reuniu grandes mulheres em um podcast com muita informação, dicas e apoio para lutarmos por um mercado mais justo e igualitário. Ouça o papo com Mônica Herrero - CEO Brasil da Stefanini; Maiara Tortorette - Gerente de Comunicação na Catho; Edna Vasselo Goldoni - Presidente do Instituto Vasselo Goldoni; e Margareth Goldenberg - Sócia Diretora na Goldenberg Diversidade | Gestora do Movimento Mulher 360',
    guests: [
      {
        name: 'Mônica Herrero',
        job: 'CEO Brasil da Stefanini',
      },
      {
        name: 'Maiara Tortorette',
        job: 'Gerente de Comunicação na Catho',
      },
      {
        name: 'Edna Vasselo Goldoni',
        job: 'Presidente do Instituto Vasselo Goldoni',
      },
      {
        name: 'Margareth Goldenberg',
        job: 'Sócia Diretora na Goldenberg Diversidade | Gestora do Movimento Mulher 360',
      },
    ],
    thumbnail: thumb_01_07,
  },
  {
    id: 6,
    date: '14/02/2020',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Estou na estatística: encarando o cenário de desemprego',
    slug: 'estou-na-estatistica-encarando-o-cenario-de-desemprego',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/6d65fc57-d823-42a8-b4dc-7511ef3efde8?dark=false',
    about:
      'O que há por dentro das estatísticas de desemprego? Existem muitas variáveis que os profissionais precisam estar atentos para enfrentar esse momento de dificuldades. Tem regiões e profissões que sofrem mais com a crise econômica? E as áreas que continuam em situação pleno emprego, onde estão? Como superar a baixo autoestima e os fatores emocionais? Quais as tendências de geração de emprego e quais habilidades exigidas pelo mercado? Descubra todas as dicas para aumentar sua competitividade neste papo com Ricardo Terra, Diretor Regional do SENAI-SP; Felipe Miras, Gerente de Estudos Estratégicos na Catho, Brenda Donato, Diretora de Recursos Humanos na EMBRACON; e Aguinaldo Neri - Psicólogo e Consultor de RH na PUC.',
    guests: [
      {
        name: 'Ricardo Terra',
        job: 'Diretor Regional do SENAI-SP',
      },
      {
        name: 'Felipe Miras',
        job: 'Gerente de Estudos Estratégicos na Catho',
      },
      {
        name: 'Brenda Donato',
        job: 'Diretora de Recursos Humanos na EMBRACON',
      },
      {
        name: 'Aguinaldo Neri',
        job: 'Psicólogo e Consultor de RH na PUC',
      },
    ],
    thumbnail: thumb_01_06,
  },
  {
    id: 5,
    date: '31/01/2020',
    categories: ['QUALIFICAÇÃO'],
    title: 'Os impactos da qualificação na carreira',
    slug: 'os-impactos-da-qualificacao-na-carreira',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/12c7f315-0ca2-4ece-a6bc-6a54620cfe43?dark=false',
    about:
      'A relação conhecimento x empregabilidade é indiscutível há muito tempo. Mas nem tudo em torno da qualificação profissional permanece na mesma. Quais novas áreas de estudo têm alta demanda de vagas? O nome da universidade ainda pesa no currículo? Como ter o diferencial da busca por conhecimento em uma sociedade em constante transformação tecnológica? E quais conceitos agregam mais valor aos profissionais na visão mercadológica? Hard e soft skills são conceitos que chegaram pra ficar, já está antenado no assunto? O Trampapo desmistifica esse tema em um bate papo com Renan Brandão, Diretor Geral da Universidade Estácio de Sá; Liliane Andrade, Analista de Recursos Humanos na DEXTRA; Ana Carnaúba, Gerente da Área de Talent na Deloitte; e Fernando Gaiofatto, Gerente de Marketing na Catho Educação.',
    guests: [
      {
        name: 'Renan Brandão',
        job: 'Diretor Geral da Universidade Estácio de Sá',
      },
      {
        name: 'Liliane Andrade',
        job: 'Analista de Recursos Humanos na DEXTRA',
      },
      {
        name: 'Ana Carnaúba',
        job: 'Gerente da Área de Talent na Deloitte',
      },
      {
        name: 'Fernando Gaiofatto',
        job: 'Gerente de Marketing na Catho Educação',
      },
    ],
    thumbnail: thumb_01_05,
  },
  {
    id: 4,
    date: '15/01/2020',
    categories: ['QUALIFICAÇÃO'],
    title: 'Como conquistar mais, de verdade, em 2020',
    slug: 'como-conquistar-mais-de-verdade-em-2020',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/210ed851-226f-4987-a28d-343a0d6b6811?dark=false',
    about:
      'Até mesmo para os mais céticos, o início de um novo ano é um momento de otimismo, de recarregar a esperança por dias melhores e de se planejar para novas conquistas, sejam elas crescer na carreira profissional, melhorar a vida financeira ou, ainda, ter mais autocuidado e saúde. Mas a questão é: como fazer diferente em 2020 e, realmente, ter disciplina para ter sucesso nas metas? Descubra neste bate-papo com Ricardo Morais, Gerente Sênior de Marketing da Catho; Felipe Arrais, Editor na Empiricus; e Leonardo Cirino, CMO da Smart Fit.',
    guests: [
      {
        name: 'Ricardo Morais',
        job: 'Gerente Sênior de Marketing da Catho',
      },
      {
        name: 'Felipe Arrais',
        job: 'Editor na Empiricus',
      },
      {
        name: 'Leonardo Cirino',
        job: 'CMO da Smart Fit',
      },
    ],
    thumbnail: thumb_01_04,
  },
  {
    id: 3,
    date: '16/12/2019',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Áreas em alta: tendências e preparo para o futuro',
    slug: 'areas-em-alta-tendencias-e-preparo-para-o-futuro',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/c58dd9d4-8b15-4c0d-b9ec-301c50c99e04?dark=false',
    about:
      'Tomar as melhores decisões para se encontrar no mercado de trabalho é mais fácil com uma análise detalhada das oportunidades que ele apresenta. Com base em dados estatísticos, neste episódio do TRAMPAPO, você descobre quais setores estão com mais ofertas de vagas, quais oferecem os melhores salários e as projeções para o futuro, além de entender quais competências são necessárias para acompanhar as movimentações do mercado, os comportamentos e atitudes esperadas e as influências da economia, da transformação digital e das startups no cenário de empregos. Papo reto mandado pelo Fabrício Kuriki, Gerente de Business Intelligence na Catho; Raphael Daolio, Diretor de Parcerias Estratégicas na Rappi; e Ivan Neves, People Sr. Manager na CI&T.',
    guests: [
      {
        name: 'Fabrício Kuriki',
        job: 'Gerente de Business Intelligence na Catho',
      },
      {
        name: 'Raphael Daolio',
        job: 'Diretor de Parcerias Estratégicas na Rappi',
      },
      {
        name: 'Ivan Neves',
        job: 'People Sr. Manager na CI&T',
      },
    ],
    thumbnail: thumb_01_03,
  },
  {
    id: 2,
    date: '02/12/2019',
    categories: ['CURRÍCULO'],
    title: "Currículo em foco: capture a atenção dos RH's",
    slug: 'curriculo-em-foco-capture-a-atencao-dos-rhs',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/f652865a-cd37-44a8-a14f-8bd077003598?dark=false',
    about:
      'Em um mercado competitivo, os recrutadores precisam otimizar cada vez mais o processo de seleção de currículos. De acordo com um levantamento da Catho, eles levam apenas 5 segundos por candidato na etapa inicial de triagem. Ou seja, é preciso cativar a atenção do selecionador em pouquíssimo tempo. Será possível? Descubra neste episódio as melhores práticas para ser bem avaliado e como aumentar a compatibilidade do seu CV com a vaga a qual está se candidatando, além de entender o papel da Inteligência Artificial na análise do seu currículo e como preencher o documento para fazer uma transição de carreira. Quem conduz esse papo é a Bianca Machado, Gerente Sênior na Catho; Cynthia Abrahão, Gerente Executiva de RH da Venturus; e Zaida Goulart, Gerente Corporativo de Gente e Gestão da Campseg.',
    guests: [
      {
        name: 'Zaida Goulart',
        job: 'Gerente Corporativo de Gente e Gestão da Campseg',
      },
      {
        name: 'Bianca Machado',
        job: 'Gerente Sênior Comercial da Catho',
      },
      {
        name: 'Cynthia Abrahão Pedroso',
        job: 'Gerente Executiva RH da Venturus',
      },
    ],
    thumbnail: thumb_01_02,
  },
  {
    id: 1,
    date: '21/11/2019',
    categories: ['MERCADO DE TRABALHO'],
    title: 'Panorama do mercado de trabalho',
    slug: 'panorama-do-mercado-de-trabalho',
    type: 'audio',
    season: 1,
    player:
      'https://player.simplecast.com/1f0d7712-2e1b-4863-b1a7-3561e15d07fe?dark=false',
    about:
      'Quais desafios e oportunidades compõem o atual mercado de trabalho no Brasil? Quais são as tendências de busca e oferta? Como a revolução tecnológica afeta a estratégia de carreira dos profissionais? Em meio a recessão, ainda há espaço para o trabalho vocacionado? Ou ainda: os novos formatos de trabalho e a terceirização no setor de serviços são heróis ou vilões? Descubra como você deve estruturar seu preparo e autoconfiança para movimentar sua carreira neste bate-papo com Eliane Navarro Rosandiski, economista e professora-extensionista da PUC; Fabiola Lencastre, Business Analytics e diretora da Associação Brasileira de Recursos Humanos; e João Eduardo Vaz Caetano, gerente sênior de Marketing e Produto da Catho.',
    guests: [
      {
        name: 'Eliane Rosandiski',
        job: 'Professora-extensionista da PUC',
      },
      {
        name: 'João Caetano',
        job: 'Gerente Sênior Marketing Produto Catho',
      },
      {
        name: 'Fabiola Lencastre',
        job: 'Diretora Associação Brasileira RH',
      },
    ],
    thumbnail: thumb_01_01,
  },
  {
    id: 119,
    date: '28/05/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'como-vender-seu-trabalho',
    title: 'Você sabe vender bem o seu trabalho? ',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_20_thumb,
        alt: 'Você sabe vender bem o seu trabalho?',
      },
      {
        type: 'text',
        text: '<span style="font-size:22px; font-family:Arial, Helvetica, sans-serif">Reconhecimento profissional para ter sucesso</span>',
      },
      {
        type: 'text',
        text: '<strong>O que significa vender bem o próprio trabalho?</strong> Nesse momento talvez muitas pessoas pensem que essa pergunta não é necessária por acharem que “um bom trabalho se vende sozinho”. Mas nem sempre isso é verdade. Ser um profissional com ótimas entregas, comprometido e com várias qualidades não garante que seu trabalho será reconhecido. E reconhecimento significa ter a chave para muitas portas no mercado de trabalho.',
      },
      {
        type: 'text',
        text: 'No âmbito profissional, ter reconhecimento profissional é ser percebido pelo trabalho que presta, o que é essencial para quem quer ascender na carreira, seja na empresa atual ou em outros rumos. No contexto empresarial é mandatório construir uma trajetória focando ser reconhecido por líderes, colegas e parceiros como alguém bom no que se faz, o que resulta em aumentos salariais, promoções, convites para outros empregos e de propostas de negócios. Sabe o networking? Então, qualquer um pode fazer uma famosa “rede de contatos profissionais”, adicionar várias dessas pessoas e manter contato nas redes sociais, mas poucos são verdadeiramente bem reconhecidos pelo o que fazem por todos esses profissionais.',
      },
      {
        type: 'text',
        text: '<strong>Mas como obter esse reconhecimento? Fazer bem o meu trabalho não é o suficiente?</strong><br>Infelizmente, não. Se mostrar é preciso e se você é do time dos mais tímidos, seria bacana experimentar aos poucos aumentar sua exposição para poder também desfrutar dos benefícios de uma vida com reconhecimento profissional. Afinal, por vezes, profissionais mega talentosos ficam de fora do campo de visão simplesmente por não saberem ao certo como dar visibilidade ao seu desempenho, se desmotivam e, de fato, passam a ter piores resultados.',
      },
      {
        type: 'text',
        text: '<span style="font-size:18px; font-family:Arial, Helvetica, sans-serif">Como se vender nos processos seletivos? Currículo e entrevista</span>',
      },
      {
        type: 'text',
        text: 'A começar pela famosa porta de entrada do mercado de trabalho, os processos seletivos para vagas de emprego são os primeiros desafios que encaramos quando, jovens, queremos entrar no mercado. E a partir daí já é requerida essa qualidade: <strong>saber vender o próprio peixe. Mas que peixe?</strong><br> Se você, lá no comecinho do texto, concordou com a frase “um bom trabalho se vende sozinho”, pare pra pensar que em qualquer processo de entrevista em uma nova empresa você não está mostrando o seu trabalho, e sim vendendo ele. Você não manda pro recrutador a planilha X ou o relatório Y pra conseguir a vaga. Você fala sobre eles. Saber se vender é necessário em todas as etapas que envolvem crescimentos na carreira.<br>Para quem está buscando emprego e quer saber como se vender da melhor forma, listamos algumas dicas:',
      },
      {
        type: 'text',
        text: '<span style="font-size:22px; font-family:Arial, Helvetica, sans-serif">Como se vender no currículo</span><br> <strong>&nbsp;&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;&nbsp; Faça uma carta de apresentação Uma carta</strong><br> de apresentação é um diferencial em relação aos outros candidatos e aumenta as chances de ser selecionado. Essa carta é um texto que acompanha o currículo na hora da candidatura a uma vaga e é personalizado de acordo com a vaga e empresa em questão. Com uma boa escrita, você mostrará sua clareza na comunicação, tornará a conversa mais pessoal e apresentará com mais riqueza seus conhecimentos, habilidades, experiências e perfil.',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;<strong>Faça currículos específicos por cargo</strong><br> Vender bem tem a ver justamente com mostrar as principais características que o comprador está interessado. Alguém que quer comprar uma bola de futebol não quer saber como essa bola funciona no basquete. E o mesmo é com o currículo. Às vezes, um currículo muito completo pode jogar contra o candidato, pois as informações que são relevantes para a vaga em questão ficam sem destaque. Por isso, avalie os requisitos das vagas para as quais você quer se candidatar e elabore diferentes versões de currículo que atendam às diferentes necessidades das vagas. O mesmo vale para as cartas de apresentação.',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;<strong>Esteja pronto e confortável para falar sobre seu currículo e carta de apresentação</strong><br> Esses documentos são um breve esboço para parte de sua entrevista. Tenha ciência que tudo o que você escrever, poderá ser questionado e por isso mentir ou exagerar pode te desclassificar da vaga dos seus sonhos.',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;<strong>Crie um portfólio com seus trabalhos</strong><br>Coloque em seus currículos e em sites como o da Catho o link para a sua página na web onde os recrutadores e gestores possam conhecer mais sobre o seu trabalho. Muitos profissionais, como quem trabalha em áreas de criação, criam canais de exposição para seus textos, campanhas, designs, artes e tudo mais o que for relevante, o que dá a eles a vantagem de estar à frente na decisão de quem contrata. Afinal, ter acesso ao trabalho do profissional antes da entrevista desperta mais interesse. É como se “o trabalho publicado ali já agradou, agora só falta conhecer a pessoa”. Para isso você precisa entender qual ferramenta é mais adequada e usual para a área em que você atua. Por exemplo, você pode usar o Medium para publicar textos, pode criar uma apresentação pública na nuvem com imagens, gráficos e textos, pode usar uma rede social com suas produções visuais, um canal onde você tenha uma marca pessoal sobre o tema, etc.',
      },
      {
        type: 'text',
        text: '<span style="font-size:22px; font-family:Arial, Helvetica, sans-serif">Como se vender na entrevista</span><br><strong>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Aprenda a contar sua história</strong><br> Saber contar bem as histórias não é só para entreter a criançada. No mundo profissional, saber contar a própria história é fazer com que as pessoas se conectem a você, deem credibilidade ao seu potencial e queiram que você faça parte de seus projetos. Já ouviu falar em storytelling? Então, essa técnica que tem sido tão falada ultimamente nada mais é que dominar a prática de falar com outros. Algo que provavelmente você já está bastante familiarizado. Mas é falar do jeito certo, de modo que envolva o ouvinte com a história. Portanto, procure saber mais sobre essa técnica e treine para a hora da entrevista. Entenda em seu discurso aquilo que é capaz de fazer com que as pessoas tenham empatia por você, o que faria com que elas notassem sua força, sua determinação, seus valores? Essas são as mensagens. Mas o jeito de falar, dependerá da sua personalidade e do quanto você aplicará os conhecimentos de oratória e storytelling.',
      },
      {
        type: 'text',
        text: '<strong>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Faça perguntas</strong><br> Sabe quando acontecem aquelas reuniões, treinamentos ou aulas constrangedoras onde a pessoa que está apresentando fala várias coisas interessantes, complexas e que muito provavelmente gerariam dúvidas, mas na hora que perguntam “alguém tem dúvida?” fica um silêncio ensurdecedor? Pois é. A sensação que fica é, das duas, uma: ou ninguém se interessou pelo o que foi dito, ou são todos retraídos ou tímidos demais. E nenhuma das opções é bacana quando se trata da entrevista de emprego. Se o entrevistador já disse tudo o que tinha previsto e você não fez nenhuma pergunta, é quase certo que a impressão deixada foi uma dessas. Não perguntar demonstra falta de interesse ou pouca desenvoltura para falar e se posicionar, o que pode afastar as empresas de você. E o inverso é verdadeiro. Quando há uma troca, onde as duas pessoas envolvidas na entrevista se questionam e se esclarecem igualmente, a impressão causada tende a ser mais positiva. Fazendo questionamentos sobre a rotina, a empresa, a equipe e trabalho você demonstra interesse e que está comprometido em ter uma boa relação com os novos empregadores.',
      },
      {
        type: 'text',
        text: '<strong>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;Mostre seus valores</strong><br> As empresas não buscam mais só por perfis técnicos. Hoje em dia as empresas dão tanta importância ao perfil comportamental quanto às qualificações que os profissionais têm. Com culturas organizacionais cada vez melhores definidas, as empresas procuram funcionários que se adaptem aos valores da instituição e da equipe. Ter um ótimo currículo para a função não é uma garantia que a pessoa não vai se sentir um peixe fora d’água na empresa. É o que as empresas chamam de compatibilidade cultural. Como as pessoas se comportam na empresa? O que as motiva? O que as fazem produzir melhor? Tudo isso é tão importante quanto experiências, formação e conhecimento. Por isso, não tenha receio de mostrar e falar daquilo que é importante para você, para suas perspectivas de vida, seus princípios éticos e etc.',
      },
      {
        type: 'text',
        text: '<strong>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp;Prove o que você diz</strong><br> Em tempos de alta competitividade no mercado de trabalho, tudo o que pode ser um diferencial deve ser explorado justamente porque existem muitos profissionais qualificados disponíveis no mercado. Esse é mais um dos motivos pra você provar aquilo que diz. Na entrevista, apenas dizer que sabe e parecer que sabe pode não ser o suficiente para convencer o entrevistador. Então é importante fornecer dados que comprovem e deem credibilidade ao que você conta. Cite nomes de parceiros que você trabalhou, nomes de programas que você colaborou ou desenvolveu, cite resultados em números sobre os projetos que você se dedicou, fale o nome das pessoas que estavam junto com você nas maiores empreitadas, cite sites onde as pessoas possam encontrar o seu trabalho e assim por diante. Nós humanos nos sentimos mais seguros e confortáveis quando ouvimos sobre elementos facilmente comprováveis.',
      },
      {
        type: 'text',
        text: '<span style="font-size:18px; font-family:Arial, Helvetica, sans-serif"> Criando sua Marca Pessoal usando Marketing Pessoal</span>',
      },
      {
        type: 'text',
        text: 'Agora, considerando que você vai fazer tudo isso em suas entrevistas de emprego, talvez você ainda esteja com dúvidas de como identificar de fato seus maiores destaques para mostrar ao recrutador. E tudo bem! Não é uma tarefa fácil mesmo reconhecer nossos pontos fortes e nos afirmarmos como autoridade naquilo que fazemos. Como os outros podem nos reconhecer bons em algo se nem nós mesmo nos reconhecemos? Como contar bem a própria história pra que ela nos favoreça no presente, sendo que muitas vezes nem sabemos qual a marca que queremos construir e o legado que queremos deixar? ',
      },
      {
        type: 'text',
        text: 'Essas respostas virão com muito autoconhecimento. E pra ajudar você pode desenhar um plano de marketing pessoal para a sua marca pessoal. ',
      },
      {
        type: 'text',
        text: 'O <strong>marketing pessoal</strong> é um conjunto de técnicas que uma pessoa pode usar para promover a sua <strong>marca pessoal</strong>. O objetivo é transmitir com sucesso mensagens sobre si para conquistar objetivos, seja vender melhor o próprio trabalho dentro da empresa para conseguir promoções, seja conquistar novos clientes, patrocinadores ou mudar de empresa. Mas antes de começar a usar essas técnicas, é preciso definir uma marca pessoal, afinal, você precisa saber o que quer transmitir antes de pensar em como fazer isso.',
      },
      {
        type: 'text',
        text: 'Sua marca pessoal é quem você é. Porém, sua marca pessoal se baseia em alguns pilares que são respostas para perguntas como: <strong>Pelo o que você é conhecido? Sua equipe conta com você para quê? Seus gestores sempre te elogiam pelo quê?</strong>',
      },
      {
        type: 'text',
        text: '<strong>E seus valores?</strong> Quando damos luz aos nossos valores damos luz àquilo que consideramos como o mais importante de nossas vidas. São onde se espelham nossos princípios éticos e morais inegociáveis. Seus valores incluem o que? Respeito? Família? Justiça social? Diversidade? Inovação? Lucratividade? Esse entendimento vai nortear o que você transmitirá sobre si em todos os momentos. E esses valores serão julgados compatíveis ou não com as oportunidades de trabalho. <br> Se suas atitudes não representam seus valores, se você, por exemplo, aceita um emprego em uma empresa que não tem os mesmos valores que você, qual mensagem você vai transmitir? Ser coerente é um dos fatores essenciais de uma marca pessoal forte.',
      },
      {
        type: 'text',
        text: 'Depois de compreendidos esses pilares de valores e potenciais, é importante ter consciência em todas as suas apresentações pessoais, em todos os seus canais de contato, refletindo até mesmo em sua postura, sua forma de falar, nos conteúdos que você compartilha nas redes sociais, nos seus posicionamentos e até na sua escrita. Com tempo esse projeto que é fortalecer sua imagem pessoal e vender bem o seu trabalho renderá frutos. Vamos resumir os benefícios para a sua vida profissional: <br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Receber uma promoção ou aumento salarial<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Falar em nome da marca e ganhar visibilidade<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Mudar de emprego e ganhar mais<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Trabalhar com aquilo que se ama, sem deixar o propósito de lado<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Ser indicado por colegas de trabalho para outras oportunidades<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Chamar a atenção da empresa que você sempre sonhou em trabalhar<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Aumentar seu networking<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Posicionar-se como um especialista e gerar oportunidades como palestrante;<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Conquistar mais clientes para o seu negócio<br> &nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;&nbsp; Empreender com apoio de aliados e parceiros<br>',
      },
      {
        type: 'text',
        text: 'Vem saber mais! PODCAST 🎧',
      },
      {
        type: 'text',
        text: '<strong>#32 Fortalecendo sua imagem profissional: marketing pessoal</strong><br> Como você se apresenta para o mundo? Marketing pessoal, para muitos, pode parecer arrogância, mas é uma ferramenta para se empoderar de suas características, habilidades, experiências e valores. É essencial para se destacar no mercado de trabalho! No último episódio desta temporada do Trampapo, Ana Paula Xongani e Ricardo Morais recebem <strong>Bianca Carmignani, psicóloga e Head de RH da Nespresso Brasil; e Maytê Carvalho, escritora, professora de retórica na ESPM e diretora de estratégia da TBWA/Chiat Day</strong>, para uma conversa sobre boas impressões, persuasão e orgulho. Para conquistar boas posições no mercado, as redes sociais podem ser tão importantes quanto um bom currículo, elas são um palco para a sua narrativa profissional e seus projetos, assumir as suas vulnerabilidades e vender você por quem você é. Quer aprender mais sobre marketing pessoal? <a href="https://www.trampapo.com.br/"> Ouça o episódio!</a>',
      },
      {
        type: 'text',
        text: 'As convidadas que estiveram presente deram uma aula de como vender bem o próprio trabalho e deixaram algumas dicas de conteúdo e de inspirações.',
      },
      {
        type: 'text',
        text: '<a href="https://www.linkedin.com/in/maytecarvalho/">Maytê Carvalho</a> | <a href="https://www.instagram.com/maytecarvalhos/"> @maytecarvalhos</a><br> É comunicóloga e professora de Retórica nos cursos de extensão da ESPM. Hoje atua como diretora de estratégia de negócios na TBWAChiat Day, uma das top 5 agências de publicidade dos Estados Unidos, onde reside e atua profissionalmente. Foi eleita pela Revista GQ da Editora Globo uma das 6 grandes mulheres líderes de startup do Brasil por seu app Beleza de Farmácia, que chegou a ser app número um na AppStore. Ela reuniu seus aprendizados empíricos e eruditos sobre a Persuasão de sua práxis como publicitária e empresária: da captação de investimentos de sua empresa no reality show Shark Tank, negociações na vida real e nas salas de reunião de O aprendiz ao desenvolvimento de estratégias de comunicação para as marcas mais valiosas do mundo na publicidade. Como dica de conteúdo para continuar essa conversa, ela recomendou seu livro:<br><br><strong>Livro - Persuasão: Como usar a retórica e a comunicação persuasiva na sua vida pessoal e profissional - Maytê Carvalho</strong><br>Você quer ter mais protagonismo, voz ativa e capacidade de influenciar decisões em sua vida? Não importa qual seja o seu trabalho, muito do seu sucesso dependerá da sua capacidade de influenciar e persuadir os outros. Em outras palavras, conseguir que digam “sim” aos seus pedidos. Seja negociando com fornecedores, vendendo seu produto para compradores, liderando funcionários, pedindo aumento ou discutindo a relação com namorado(a) e familiares… Persuadir é preciso para que tenhamos êxito em nossa vida pessoal e profissional. Neste livro, a autora Maytê Carvalho prepara o leitor de maneira prática para os desafios relacionados à comunicação e expressão. Persuasão é um guia para ser lido e consultado ao longo de toda a vida.',
        image: content_20_guest_01,
        alt: 'Maytê Carvalho',
      },
      {
        type: 'text',
        text: 'Ela também recomendou uma de suas inspirações, <strong>Bozoma Saint John <a href="https://www.instagram.com/badassboz/">@badassboz</a>, diretora de marketing global da Netflix.</strong>',
      },
      {
        type: 'text',
        text: '<a href="https://www.linkedin.com/in/bianca-carmignani-5667459/">Bianca Carmignani</a> é RH Business Partner Sênior na Nestlé Nespresso. A experiente profissional desenvolveu sua carreira em RH somando mais de 15 anos de trabalho em Sourcing Estratégico, Treinamento e Desenvolvimento de Carreira, Gestão de Mudanças e Recrutamento. Ao longo de sua trajetória teve forte atuação nos segmentos da indústria de alimentos e bebidas. Formada em psicologia, tem MBA Executivo em administração e negócios pela BSP, Business School São Paulo, e pela FGV, Fundação Getúlio Vargas. Em sua passagem pelo nosso podcast ela deixou várias dicas de conteúdos e inspirações sobre o tema:<br><br><br><strong>Livro - Comece pelo porquê: Como grandes líderes inspiram pessoas e equipes a agir - Simon Sinek</strong><br> Por que algumas pessoas e organizações são mais inovadoras, admiradas e lucrativas do que outras? Por que algumas despertam grande lealdade por parte de clientes e funcionários? Para Simon Sinek, a resposta está no forte senso de propósito que as inspira a darem o melhor de si para uma causa expressiva – o porquê. Ilustrando suas ideias com as fascinantes histórias de Martin Luther King, Steve Jobs e os irmãos Wright, Simon mostra que as pessoas só irão se dedicar de corpo e alma a um movimento, ideia, produto ou serviço se compreenderem o verdadeiro propósito por trás deles. Nesse livro, você verá como pensam, agem e se comunicam os líderes que exercem a maior influência, e também descobrirá um modelo a partir do qual as pessoas podem ser inspiradas, movimentos podem ser criados e organizações, construídas. E tudo isso começa pelo porquê.',
        image: content_20_guest_02,
        alt: 'Bianca Carmignani',
      },
      {
        type: 'text',
        text: '<strong>Livro - Descubra seus pontos fortes - Marcus Buckingham e Donald O. Clifton</strong> <br> Para ajudar você a descobrir quais são e como aprimorar seus talentos e de seus colaboradores, este livro traz um programa em torno do teste Descubra a fonte de seus pontos fortes. Ou autores descobriram que a maioria das empresas dá pouca ou nenhuma atenção aos pontos fortes de seus funcionários. Preferem investir tempo e dinheiro na tarefa de corrigir suas fraquezas, achando que desse modo as pessoas atingirão a excelência. Por outro lado, a pesquisa revelou que os profissionais bem-sucedidos compartilham um segredo simples, mas poderoso - usam suas energias para aprimorar aquilo que fazem melhor, deixando seus pontos fracos em segundo plano. E, assim, tornam-se cada vez mais competentes, produtivos e felizes.',
      },
      {
        type: 'text',
        text: '<strong>Livro - Mindset: A nova psicologia do sucesso - Carol S. Dweck</strong><br> A autora, professora de psicologia na Universidade Stanford e especialista internacional em sucesso e motivação, desenvolveu, ao longo de décadas de pesquisa, um conceito fundamental: a atitude mental com que encaramos a vida, que ela chama de “mindset”, é crucial para o sucesso. Dweck revela de forma brilhante como o sucesso pode ser alcançado pela maneira como lidamos com nossos objetivos. O mindset não é um mero traço de personalidade, é a explicação de por que somos otimistas ou pessimistas, bem-sucedidos ou não. Ele define nossa relação com o trabalho e com as pessoas e a maneira como educamos nossos filhos. É um fator decisivo para que todo o nosso potencial seja explorado.',
      },
      {
        type: 'text',
        text: '<strong>Livro - O Poder do Hábito – Charles Duhigg</strong><br>Durante dois anos, uma jovem transformou quase todos os aspectos de sua vida. Parou de fumar, correu uma maratona e foi promovida. Em um laboratório, neurologistas descobriram que os padrões dentro do cérebro dela mudaram de maneira fundamental. Publicitários da P&G  tentavam desesperadamente descobrir como vender um novo produto chamado Febreze, que estava prestes a se tornar um dos maiores fracassos na história da empresa. De repente, um deles detecta um padrão quase imperceptível - e, com uma sutil mudança na campanha publicitária, Febreze começa a vender um bilhão de dólares por ano. Um diretor executivo pouco conhecido assume uma das maiores empresas norte-americanas. Seu primeiro passo é atacar um único padrão entre os funcionários - a maneira como lidam com a segurança no ambiente de trabalho -, e logo a empresa começa a ter o melhor desempenho no índice Dow Jones. O que todas essas pessoas têm em comum? Conseguiram ter sucesso focando em padrões que moldam cada aspecto de nossas vidas. Tiveram êxito transformando hábitos. Com perspicácia e habilidade, Charles Duhigg apresenta um novo entendimento da natureza humana e seu potencial para a transformação.',
      },
      {
        type: 'text',
        text: '<strong>Livro - Os 7 Hábitos das Pessoas Altamente Eficazes|Lições poderosas para a transformação pessoal - Stephen Covey</strong><br> Os 7 hábitos das pessoas altamente eficazes é uma abordagem holística e integrada à solução dos problemas pessoais e profissionais baseada em princípios. Mostra como alcançar a paz de espírito e adquirir confiança por meio dos alicerces do comportamento humano no caráter e da compreensão dos princípios, não se detendo apenas a práticas. Os 7 hábitos mudaram não apenas o que as pessoas pensam sobre influência e realização pessoal, como também as atitudes motivadas por essas ideias. Os 7 hábitos, na verdade, modificaram a linguagem que usamos, a partir dos seguintes conceitos: seja proativo; comece com o objetivo em mente; primeiro o mais importante; pense ganha/ganha; procure primeiro compreender, depois ser compreendido; crie sinergia; afine o instrumento.',
      },
      {
        type: 'text',
        text: '<strong>Livro - A coragem de ser imperfeito – Brené Brown</strong><br> Como aceitar a própria vulnerabilidade, vencer a vergonha e ousar ser quem você é? Viver é experimentar incertezas, riscos e se expor emocionalmente. Mas isso não precisa ser ruim. Como mostra Brené Brown, a vulnerabilidade não é uma medida de fraqueza, mas a melhor definição de coragem. Quando fugimos de emoções como medo, mágoa e decepção, também nos fechamos para o amor, a aceitação e a criatividade. Por isso, as pessoas que se defendem a todo custo do erro e do fracasso acabam se frustrando e se distanciando das experiências marcantes que dão significado à vida. Por outro lado, as que se expõem e se abrem para coisas novas são mais autênticas e realizadas, ainda que se tornem alvo de críticas e de inveja. É preciso lidar com os dois lados da moeda para se ter uma vida plena. Em sua pesquisa pioneira sobre vulnerabilidade, Brené Brown concluiu que fazemos uso de um verdadeiro arsenal contra a vergonha de nos expor e a sensação de não sermos bons o bastante, e que existem estratégias eficazes para serem usadas nesse “desarmamento”. Neste livro, ela apresenta suas descobertas e estratégias bem-sucedidas, toca em feridas delicadas e provoca grandes insights, desafiando-nos a mudar a maneira como vivemos e nos relacionamos.',
      },
      {
        type: 'text',
        text: '<a href="https://www.ted.com/talks/brene_brown_the_power_of_vulnerability?language=pt-br">Ted TALKS: O poder da vulnerabilidade – Brene Brown</a><br> Brené Brown estuda a conexão humana, nossa habilidade de sentir empatia, pertencer, amar. Em uma palestra comovente e divertida no TEDxHouston, ela compartilha uma percepção profunda de sua pesquisa, que a levou a uma busca pessoal para conhecer a si mesma e entender a humanidade. Uma palestra para compartilhar.',
      },
      {
        type: 'text',
        text: '<a href="https://www.ted.com/talks/guy_winch_why_we_all_need_to_practice_emotional_first_aid?language=pt-br"> Ted TALKS: Por que nós todos precisamos praticar primeiros socorros emocionais - Guy Winch</a><br> Todos nós vamos ao médico quando estamos meio gripados ou com uma dor lancinante. Então por que não procuramos um profissional da saúde quando sentimos dores emocionais: culpa, perda, solidão? "Muitos de nós lidamos com os problemas emocionais por conta própria", diz Guy Winch. Mas nós não temos que fazer isso sozinhos. Ele é convincente ao afirmar que devemos praticar higiene emocional; cuidando das nossas emoções, nossas mentes, com a mesma diligência que cuidamos do nosso corpo.',
      },
      {
        type: 'text',
        text: '<a href="https://www.ted.com/talks/ruth_chang_how_to_make_hard_choices?language=pt-br">Ted TALKS: Como fazer escolhas difíceis - Ruth Chang</a><br> Uma palestra que pode literalmente mudar sua vida. Que carreira eu deveria seguir? Será que deveria terminar... Ou me casar?! Onde eu deveria morar? Grandes decisões como essas podem ser agonizantemente difíceis. Mas é porque nós pensamos nelas de maneira errada, diz a filósofa Ruth Chang. Ela oferece uma nova estrutura poderosa para dar forma a quem realmente somos.',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3',
      },
    ],
    thumbnail: content_20_thumb,
  },
  {
    id: 118,
    date: '21/05/2021',
    categories: ['DIVERSIDADE'],
    slug: 'mercado-feito-para-excluir-pessoas',
    title: 'O mercado de trabalho é feito para excluir?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_19_thumb,
        alt: 'O mercado de trabalho é feito para excluir?',
      },
      {
        type: 'text',
        text: 'Com o Trampapo queremos disseminar a ideia de que é IMPOSSÍVEL desvincular o mercado de trabalho das questões sociais que atravessam a nossa população. Acreditamos que ao dar visibilidade aos problemas que existem nele, a gente cria conscientização nas pessoas que formam as empresas e que podem promover a mudança para corrigir esses tais problemas. Nossa função é explicar que é possível diminuir as desigualdades sociais que são alimentadas pela falta de trabalho e, muitas vezes, pelo olhar preconceituoso de quem contrata.',
      },
      {
        type: 'text',
        text: 'Se você parar pra observar as estatísticas e diversos relatórios de organizações focadas em impacto social, vai perceber que existe um círculo vicioso que condiciona as pessoas que sempre estiveram no topo das hierarquias a continuarem sempre nesse topo, assim como seus descendentes. E esse mesmo círculo condiciona os mais pobres a sempre ocuparem os cargos com piores salários e a não terem condições de oferecer melhores oportunidades aos seus filhos e familiares. A estrutura empresarial que temos hoje e que sempre esteve presente, desde a formação do modelo de trabalho que conhecemos hoje, preza e favorece certos padrões de comportamento e características e esse é um dos principais motivos para essa desigualdade.',
      },
      {
        type: 'text',
        text: '<strong>E quais características são favoráveis? Quem tá no topo? Quem tá sendo excluído? Quem está na informalidade, nos subempregos, no desemprego?</strong>',
      },
      {
        type: 'text',
        text: '“Privilégios? Eu mereci!”',
      },
      {
        type: 'text',
        text: 'Talvez você conheça aquele famoso <a href="https://youtu.be/Cvy90UMYQx4">meme que invadiu as redes sociais</a>, que era um áudio de uma jovem afirmando exaltadamente que mereceu os privilégios para ocupar um cargo na empresa de seu pai. E isso tem muito a ver com essas perguntas feitas acima. Isso tem a ver com a ideia de <strong>meritocracia, um mito que alimenta a desigualdade</strong> e favorece quem “sai na frente” no mercado de trabalho por, simplesmente, ter nascido em família mais abastada ou que puderam proporcionar bom estudo e trabalho.',
      },
      {
        type: 'text',
        text: 'No caso do meme, podemos fazer uma relação de como funciona o senso comum sobre o que é merecimento. Ou seja, na opinião pública e naquilo que aprendemos ao longo da vida, com as escolas nos dando notas por mérito e nos reprovando por demérito, com as empresas praticando políticas de bonificação individual, o discurso da meritocracia fica muito forte.',
      },
      {
        type: 'text',
        text: '<strong>Na meritocracia acredita-se que todos são igualmente capazes e possibilitados a conquistar qualquer coisa</strong>, mas anula-se o fato de que as diferentes populações têm pontos de partida diferentes também. Pontos de partida que por vezes trazem no caminho obstáculos como <strong>a falta de acesso a direitos básicos e mínimos para capacitar uma pessoa para o mercado de trabalho.</strong>',
      },
      {
        type: 'text',
        text: 'A jovem do meme, por exemplo, acredita que ela realmente deve ocupar aquele cargo porque, afinal, a empresa é do pai dela. E independente de qual seja a qualificação profissional dessa jovem, essa situação demonstra que quem ocupa os bons cargos nas empresas, normalmente, são as pessoas privilegiadas de alguma maneira: seja ganhando o emprego dos familiares ou seja frequentando as escolas e instituições que pouquíssimas pessoas têm acesso. <strong>É o tal do círculo vicioso</strong>. Quem já tem uma boa posição social é quem tem a posse da decisão de contratar as pessoas e, normalmente, nessas decisões prevalecem os mesmos padrões: pessoas que frequentaram universidades elitizadas, onde o valor da mensalidade às vezes é 3 vezes maior do que a renda média do brasileiro. <strong>E assim, dá nisso: o mercado é feito para excluir pessoas.</strong>',
      },
      {
        type: 'text',
        text: 'E apesar dos pontos de partida serem diferentes, principalmente em um país desigual como o nosso, os custos de vida e a sociedade exigem resultados iguais de todos. Esperam que todos, apesar das dificuldades, tenham forças para ter uma carreira bem sucedida, que vençam a pobreza, que passem em concursos públicos e vestibulares mega concorridos. Tudo igual. Tem meritocracia nisso?',
      },
      {
        type: 'text',
        text: 'É frágil esse discurso. Muitos de nós possuímos privilégios em comparação a outros indivíduos, sem mesmo nos dar conta que os temos. Por isso é importante questionar os paradigmas de contratação, de inclusão, de como os empregos chegam nas comunidades periféricas, questionar o cumprimento de direitos básicos que serão vitais para um jovem ter uma carreira ou não. Não vale só se apegar a sua realidade e achar que só os currículos semelhantes aos seus devem estar nas empresas famosas e nos cargos de liderança. É preciso democratizar oportunidades!',
      },
      {
        type: 'text',
        text: '<span style="font-size:28px; font-family:Arial, Helvetica, sans-serif">Como o mercado de trabalho pode ser inclusivo com os grupos minoritários?</span>',
      },
      {
        type: 'text',
        text: '<strong>Quem são os grupos minoritários no mercado de trabalho?</strong><br>Quando falamos “minoritário”, quer dizer que são grupos que, independente de serem volumosos em população ou não, são de alguma forma mais vulneráveis, marginalizados ou excluídos do mercado de trabalho. Significa uma situação de desvantagem social.<br> Esses grupos podem ser discriminados por diversos motivos. Podem ser por motivos étnicos, religiosos, de gênero, de sexualidade, linguísticos, físicos ou culturais. A única razão que sempre está presente é o preconceito. E o preconceito nada mais é do que a falta de conhecimento sobre um assunto para o qual já foi tirada uma conclusão.',
      },
      {
        type: 'text',
        text: 'Agora vamos pensar o seguinte: se uma pessoa, no alto do seu privilégio vivendo em condomínios de luxo, com estudos de ponta, atividades extracurriculares e totalmente alheia a qualquer cultura periférica, alheia a realidade de pobreza de quem vive com muito pouco dinheiro, e que detém o poder de contratar pessoas, vai ter a consciência da importância de gerar inclusão? Será que ela vai estar disposta a contratar alguém que não estudou inglês, não tem diploma ou fez uma faculdade popular? Pode ser que sim e esperamos que sim! Mas nem sempre é que ocorre, pois a discriminação é o solo onde a desigualdade e o desemprego nascem. Além disso, é essencial refletir que não é possível ter empatia com aquilo que não se conhece. Se as pessoas que nos governam e que dirigem as empresas não estiverem verdadeiramente em contato com as causas sociais e preocupadas em promover inclusão, o mercado de trabalho permanecerá dispensando os mais pobres, as pessoas LGBTI+, os negros, as pessoas com deficiência, os idosos e assim por diante. São necessárias medidas intencionais para dar equidade aos grupos minoritários.',
      },
      {
        type: 'text',
        text: '<strong>Entre brancos e negros</strong>, por exemplo, é inquestionável que existem muitas desconformidades. Todo o contexto histórico que negou aos negros, aqui no Brasil, direitos tão simples como própria dignidade gerou influencias na forma como as pessoas pretas são interpretadas e credibilizadas até hoje, o que reflete na exclusão e desfavorecimento no mercado de trabalho. O estudo “Desigualdades Sociais por Cor ou Raça”, divulgado pelo Instituto Brasileiro de Geografia e Estatística (IBGE), mostra que os trabalhadores brancos possuem, em média, renda 74% superior em relação a pretos e pardos - maior parcela da população brasileira (56%). A renda média mensal do brasileiro branco é de R$ 2.796. Enquanto entre pretos e pardos é R$ 1.608. Para cada R$ 1.000 que é destinado a um trabalhador branco, paga-se R$ 575 para um trabalhador preto ou pardo. E as diferenças só aumentam de acordo com o grau de hierarquia dentro das empresas. Quase 70% das vagas para cargos gerenciais no país são preenchidas por brancos. Além disso, 66% dos desocupados e dos subutilizados são pretos e pardos.',
      },
      {
        type: 'text',
        text: 'Pensando nesses números, o incômodo que devemos ter é notar que os negros estão em desvantagem no mercado de trabalho, apresentam os piores indicadores de renda. E a consequência disso também é a sua causa: piores condições de moradia, escolaridade, acesso a bens e serviços, além de estarem mais sujeitos à violência. Como que muda esse círculo?',
      },
      {
        type: 'text',
        text: 'Mas não apenas os negros estão mais propensos a terem desvantagens no mercado profissional.<strong> A Comunidade Trans</strong> possivelmente seja o grupo mais vulnerável nessa estrutura. Um levantamento realizado pela Associação Nacional de Travestis e Transexuais (Antra), com base em dados colhidos nas diversas regionais da entidade, informa que 90% das pessoas trans recorrem a essa profissão ao menos em algum momento da vida e que <strong>somente 4% desse grupo tem acesso ao mercado de trabalho formal</strong>. Essa exclusão é motivada principalmente pelo preconceito e, em muitos casos, a baixa escolaridade. Como se fosse uma culpabilização que as pessoas trans sofrem por serem quem são. Enquanto o mercado de trabalho não se abre para essa comunidade, a consequência é a exposição a riscos e más condições de vida e trabalho. Enquanto os contratantes, empresários, governantes e sociedade não se voltarem para promover a inclusão profissional e social de pessoas trans, esse grupo vai ser minoritário em direitos.',
      },
      {
        type: 'text',
        text: 'E enfim, com relação às populações de baixa renda e às comunidades periféricas, não pode ser diferente. A empregabilidade no Brasil é um privilégio elitizado, com acesso à formação e não apenas a treinamento. Ou seja, quem recebe oportunidades são os profissionais de alta qualificação, que representam menos de 1% da população brasileira. Com a precariedade do ensino fundamental, perpetuada na baixa qualidade da maioria dos cursos superiores, é uma barreira à formação de profissionais adequados ao mercado de trabalho do século XXI.',
      },
      {
        type: 'text',
        text: 'Portanto, até que as estatísticas mudem, após muita medida intencional por parte da iniciativa pública, privada, coletiva e individual, o mercado de trabalho vai continuar excluindo pessoas. Até que haja a promoção de ações efetivas e coordenadas em larga escala, de formar ecossistemas com o setor público, as universidades/centros de pesquisa, e os investidores, a desigualdade e o mercado vão se retroalimentar.',
      },
      {
        type: 'text',
        text: '<span style="font-size:28px; font-family:Arial, Helvetica, sans-serif">Quais ações promovem a inclusão?</span>',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp;Ações afirmativas para o acesso ao ensino superior, que possibilitam uma menor desigualdade de oportunidades a negros, grupos étnicos e sociais minoritários, como as cotas, o ProUni, SISU, etc;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; Ações afirmativas para maior participação feminina na política, estabelecendo, por exemplo, a porcentagem mínima de 30% para candidaturas femininas em cada partido;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; Ações afirmativas que asseguram um percentual mínimo dos cargos públicos e nas empresas de médio e grande porte para as pessoas com deficiência;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; Leis que propõem viabilizar o livre acesso de pessoas com deficiência a edificações e vias públicas;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; Apoio financeiro, habitacional e educacional para pessoas em condição de vulnerabilidade social;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; Oferecimento de apoio à saúde mental e ao desenvolvimento de habilidades socioemocionais;',
      },
      {
        type: 'text',
        text: '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;&nbsp; etc.',
      },
      {
        type: 'text',
        text: 'Vem saber mais! PODCAST 🎧',
      },
      {
        type: 'text',
        text: '<strong>#31 Comunidade: quem poderá nos ajudar?</strong><br />Sabe o muro da desigualdade? E aí, em qual dos lados desse muro você nasceu? Existe uma separação na nossa sociedade que define quem vai ter acesso à boa educação e ótima estrutura no lar e na saúde. Normalmente, essas pessoas são as mesmas que ocupam as lideranças das empresas e excluem do mercado de trabalho formal aqueles que vivem nas comunidades e não atendem as exigências elitistas dos processos seletivos, que apenas uma pequena parcela privilegiada da população tem acesso. Mas e as comunidades, quem poderá ajudar? Neste Trampapo, Ana Paula Xongani e Ricardo Morais recebem duas instituições que são referência em impacto social e no desenvolvimento de jovens. Lê Maestro, cofundador e diretor de educação da <strong>Rede Gerando Falcões</strong>, e Fabiana Fragiacomo, gerente de marketing do <strong>Instituto Ayrton Senna</strong>, falam sobre privilégios, empatia, meritocracia, capitalismo selvagem e muito mais para refletir a favor da criação de oportunidades por meio da responsabilidade social e do investimento na educação. Vem fazer parte desta conversa!',
      },
      {
        type: 'text',
        text: '<strong>Lê Maestro é cofundador da Rede Gerando Falcões</strong>, onde atua na direção da área de educação e na coordenação da expansão da ONG. Lê nasceu na zona leste da cidade de SP e durante adolescência sonhava ser skatista profissional, mas aos 16 anos se tornou dependente químico, fato que o levou a uma situação de quase perder a vida. Diante disso, buscou se recuperar da dependência e se dedicar às suas vocações e projetos. Hoje, livre das drogas, acredita que pode mudar o mundo com apoio aos jovens em situação de vulnerabilidade social por meio da educação, empregabilidade, arte, esportes e cultura urbana. Lê é habilitado pela Universidade Federal de Santa Catarina para agir no combate às drogas pela prevenção e aconselhar dependentes químicos. Foi nomeado pelo Fórum Econômico Mundial um 15 jovens brasileiros que podem mudar o mundo.',
        image: content_19_guest_01,
        alt: 'Lê Maestro',
      },
      {
        type: 'text',
        text: '<strong>Em sua participação no Trampapo, Lê deixou dicas extracurriculares:</strong>',
      },
      {
        type: 'text',
        text: '“A gente tem um blog no nosso site <strong ><a style="color:#000000; text-decoration:underline" href="https://gerandofalcoes.com/conheca">gerandofalcoes.com</a></strong>, e a gente fala sobre todos esses temas que a gente trouxe aqui para a nossa discussão.',
      },
      {
        type: 'text',
        text: 'E duas dicas que eu queria dar, também fazendo jabá, mas um jabá que não é só pra a gente, mas um artigo que saiu na <strong>Folha, “Quando a favela fala, é melhor escutar, é melhor ouvir”. É um artigo que Edu Lyra, Preto Zezé e o Celso Athayde</strong>, trazem um diagnóstico sobre periferias e favelas, sobre como ajudar, que é incrível.',
      },
      {
        type: 'text',
        text: 'E acabou de sair hoje uma matéria no Valor Econômico, falando do ESG que a gente lança aqui na Gerando Falcões. E pra quem quiser saber mais sobre isso, dá uma olhadinha lá no <strong>Valor Econômico</strong>. Está incrível.”',
      },
      {
        type: 'text',
        text: '<strong><a style="color:#000000; text-decoration:underline" href="https://www.linkedin.com/in/fabiana-fragiacomo-311ba28" rel="nofollow">Fabiana Fragiacomo</a> </strong><strong> é gerente-executiva de Comunicação e Marketing do Instituto Ayrton Senna.</strong><br>Tem experiência de 25 anos em estratégia de marketing e comunicação em grandes instituições nacionais e internacionais (Unibanco, BankBoston, Itaú), atuando na liderança de equipes, projetos e ações de comunicação, posicionamento e negócios. Há alguns anos, mudou o rumo da carreira a partir de uma imersão no Vale do Silício, mergulhando no universo de inovação, impacto social e futuro. Hoje, lidera a estratégia de branding, produção de conteúdo para educadores e sociedade do Instituto, além do trabalho de advocacy de marketing de causa com forte trabalho interdisciplinar e sistêmico interno e externo. Tem bacharel e pós-graduação em Propaganda e Marketing e cursou Gestão de Políticas Públicas com base em evidências no Insper.',
        image: content_19_guest_02,
        alt: 'Fabiana Fragiacomo',
      },
      {
        type: 'text',
        text: '<strong>Em sua participação no Trampapo, Fabi deixou dicas extracurriculares:</strong>',
      },
      {
        type: 'text',
        text: '“<strong><a style="color:#000000; text-decoration:underline" href="https://institutoayrtonsenna.org.br/pt-br.html">No site do Instituto Ayrton Senna</a></strong>, a gente tem uma área de conteúdo com uma série inteira, um bloco inteiro, sobre habilidades socioemocionais. Estão ali o que elas são, como são desenvolvidas, e dicas de como desenvolver para pais e para educadores. O instituto é muito baseado em ciências, em evidências científicas, a gente só coloca coisas que a gente conseguiu testar, e com apoio da ciência.  Então, eu acho muito legal, porque tudo o que está ali é de ótima qualidade.',
      },
      {
        type: 'text',
        text: 'Vou fazer também um “jabá” aqui, que a gente tem, <strong>15 de junho vamos ter um evento internacional de motivação</strong>, onde a gente vai explicar o que é a motivação pelos olhos da ciência, quais as habilidades que a gente tem aí, alçar, como elas se combinam, a gente vai explicar um pouco. Tirar um pouco do senso comum, só a motivação como a gente compreende. É um evento que vai ter gente grande e a gente vai ter o William Kamkwamba que fez <strong>“O menino que descobriu o vento”, na Netflix</strong>. Ele mesmo vai estar lá no nosso evento, a gente vai ter <strong>Gisele Bündchen</strong>, enfim... É um evento bastante bacana, e vai ser bem amplificado. Vai ficar gravado. É um evento o dia inteiro, e ele é online e gratuito para todos os educadores, e gente que se interessa por educação e aprendizagem.',
      },
      {
        type: 'text',
        text: 'E também a gente tem um <strong>livro de criatividade</strong> que vocês vão gostar, que também é um estudo profundo da OCDE, e o Instituto Ayrton Senna foi o único que participou desse livro com projetos de criatividade.',
      },
      {
        type: 'text',
        text: 'Por último, eu vou deixar um <strong>livro que se chama “Utopia para realistas”</strong>, é de um holandês, acho que algumas pessoas devem conhecer, é um livro pequeno, mas é um livro super interessante para gente repensar o impacto social, formas de repensar na sociedade.”',
      },
      {
        type: 'text',
        text: 'Nossos hosts, <strong>Ana Paula Xongani e Ricardo Morais</strong>, também deixaram ótimas recomendações para você continuar essa conversa:',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani</strong><br>“Eu queria indicar o <strong>livro do Gênero, Raça e Classe,</strong> que fala sobre essas intersecções, de Angela Davis, clássico para ter na sua biblioteca.',
      },
      {
        type: 'text',
        text: 'Quero indicar para quem gosta de um filminho, dar um pouco de risada, de série clássica também já, <strong>Dear White People, Cara Gente Branca</strong>, que fala sobre privilégios.',
      },
      {
        type: 'text',
        text: 'Vou indicar, que Lê me lembrou aqui de <strong>uma entrevista que eu fiz</strong>.  <a style="color:#000000; text-decoration:underline" href="https://www.youtube.com/watch?v=QcjqrtUgiYc">Conversando com Preto Zezé da CUFA | Alô Comunidade com Xongani | Salon Line</a>',
      },
      {
        type: 'text',
        text: 'E para terminar minha indicação jabá, lá na área de conteúdo do Instituto Ayrton Senna, eu apresentei um <strong>podcast chamado “Nada sei”</strong>. Um podcast incrível que a gente fala sobre educação, para pais, educadores, professores, pra todo mundo que quer pensar em educação. Educação é a base das transformações que a gente quer.”',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais</strong><br>“Tem uma trilogia de livros de um professor de Harvard, o nome dele é Michael J. Sandel.  São três livros, o primeiro é: <strong>Justiça, o que é fazer a coisa certa?</strong> Então, ele explica o que a gente hoje fala de justiça, como entender e como a justiça muda ao longo do tempo. O outro é: <strong>A Tirania do Mérito: o que aconteceu com o bem comum</strong>, onde ele quebra essa história do mérito em todas as possibilidades, tanto no trabalho, e dia a dia, e de como isso é um erro e vai destruindo a sociedade. Grande desigualdade. E por último:<strong> O que o dinheiro não compra? Os limites morais do mercado</strong>. Que vem de base, é o que a gente está falando da busca do capitalismo selvagem. Essa trilogia dele é muito importante. Hoje, a base e até alguns cursos dele de justiça são até abertos na internet, se procurar, você encontra como fazer e é um professor de Harvard. Então, é alguém que estudou bastante pra falar isso tudo para a gente.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3',
      },
    ],
    thumbnail: content_19_thumb,
  },
  {
    id: 117,
    date: '14/05/2021',
    categories: ['DIVERSIDADE'],
    slug: 'lgbti-mercado-de-trabalho',
    title:
      'LGBTI+ e Mercado de Trabalho: o que você precisa saber para ajudar na inclusão e no acolhimento',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_18_thumb,
        alt: 'LGBTI+ e Mercado de Trabalho: o que você precisa saber para ajudar na inclusão e no acolhimento',
      },
      {
        type: 'text',
        text: '<strong>Por que aprender sobre a causa LGBTI+ para ser um profissional melhor?</strong>',
      },
      {
        type: 'text',
        text: 'Se você é ouvinte do Trampapo, você já sabe. Mas aos leitores que estão de passagem, precisamos explicar. Somos grandes defensores da diversidade no mercado de trabalho. Acreditamos que o <strong>olhar interseccional</strong> para a sociedade deve ser uma regra. Se queremos um país menos desigual, é necessário olhar para os recortes populacionais e ver quais são as pessoas que mais sofrem com o desemprego, quais ocupam os subempregos, quais têm os piores salários, quais estão na informalidade e também quem são os grupos privilegiados em posições de liderança nas empresas, nas presidências e com os melhores salários. ',
      },
      {
        type: 'text',
        text: 'Ou seja, entender como funciona a <strong>estrutura de cargos e salários no mercado brasileiro</strong> é fundamental para nos fazer conhecedores das oportunidades e das falhas do mercado e para nos capacitar para <strong>promover ambientes de trabalho inclusivos e acolhedores</strong>. Hoje a diversidade vem, felizmente, ganhando espaço nas organizações por se provar necessária no combate a injustiças sociais e por propiciar pluralidade de ideias e visões que potencializam os resultados, lucros e inovações. Logo, ser um profissional, e principalmente uma pessoa, que valoriza as diferenças humanas e que se compromete com a diversidade é algo que passa a ser exigido pelas empresas que estão atentas a essa cultura.',
      },
      {
        type: 'text',
        text: 'Considerando tudo isso, agir intencionalmente para combater cada <strong>foco de exclusão e/ou marginalização</strong> é uma das estratégias de transformação social e do mercado de trabalho. Assim como fazemos aqui no Trampapo. Produzimos conteúdos que são fontes de informação que podem ajudar a educar uma parcela da população de trabalhadores. E se as empresas e o mercado de trabalho são feitos por pessoas, estamos tendo a oportunidade de contribuir com a modificação e evolução dele. Por meio da informação, acreditamos que as pessoas melhoram o mundo empresarial!',
      },
      {
        type: 'text',
        text: 'E finalmente, como olhar separadamente para cada grupo é necessário, não podíamos deixar de falar da <strong>comunidade LGBTI+</strong>. Conhecer o movimento LGBTI+ é importante para todas as pessoas interessadas em diversidade e inclusão, mas também para ser um colega, líder ou liderado, que ajuda a receber bem as pessoas LGBTI+ em seus ambientes de trabalho. Além da inclusão, o acolhimento após a chegada do novo colaborador é indispensável para proporcionar um ambiente seguro para a expressão, a liberdade, o aprendizado e o crescimento.',
      },
      {
        type: 'text',
        text: 'Portanto, abra-se para <strong>aumentar seu repertório</strong> sobre o assunto e ajudar a <strong>construir iniciativas</strong> que podem fazer a diferença para essa população nas organizações e na sociedade como um todo. Conhecendo mais sobre o movimento de luta por direitos, sobre os conceitos e motivações da causa, você será certamente um profissional que pode ajudar empresas a caminharem para um caminho mais inclusivo e diverso.',
      },
      {
        type: 'text',
        text: '<strong>Movimento e Comunidade LGBTI+</strong>',
      },
      {
        type: 'text',
        text: 'Pra começar, nossa dica é: aprenda sobre o que se trata a comunidade. Poucas coisas transmitem mais descaso com a pauta do que falar a sigla errada ou minimizar a importância de alguma das letras. A luta LGBTI+ acredita justamente que não existe respeito pela metade. Você não pode achar que L (lésbicas) e G (gays) é algo válido, mas esquecer do restante dos grupos e nem se preocupar em saber do que se trata. Por isso, vamos conhecer alguns termos e conceitos:',
      },
      {
        type: 'text',
        text: '<strong>Termos do Movimento LGBTI+</strong>',
      },

      {
        type: 'text',
        text: '<strong>Sexo biológico ou designado:</strong> Quando nascemos, ou ainda durante a gestação, um gênero é atribuído a nós. Trata-se de um rótulo que diz respeito a um conjunto de características biológicas, informações cromossômicas, genitais, capacidades reprodutivas e características fisiológicas que recebemos ao nascer. A maioria das pessoas são classificadas como macho ou fêmea, mas também há combinações dos dois fatores que dizem respeito à intersexualidade',
      },

      {
        type: 'text',
        text: '<strong>Identidade de gênero:</strong> Uma pessoa que é designada a um gênero quando nasce pode ter uma outra percepção internalizada sobre si. As pessoas podem se identificar com outros gêneros, sendo do gênero masculino ou feminino ou não-binário (pessoas cuja identidade de gênero não é masculina nem feminina). Sem amarras ao que foi imposto pelo sexo biológico no nascimento, nossa identidade de gênero trata-se do ser íntimo de cada um. É referente à compatibilidade da essência do indivíduo com o gênero e de como ele se sente',
      },

      {
        type: 'text',
        text: '<strong>Expressão de gênero:</strong> Nós construímos uma expressão de gênero com base nas práticas discursivas verbais e não verbais. Podemos expressar o gênero feminino, masculino ou não-binário em sociedade por meio de gestos, atitudes, comportamentos, roupas e estilo',
      },

      {
        type: 'text',
        text: '<strong>Transgênero e travesti:</strong> O T da sigla representa o termo Trans, que aborda as travestilidades e transgeneridades. Quando uma pessoa se identifica com o gênero oposto ao qual lhe foi atribuído no nascimento, ela faz parte desse grupo. As identidades trans incluem também as travestis, que são as pessoas que se identificam com o gênero feminino e se reconhecem travestis. Esse reconhecimento cabe à própria pessoa, podendo ter como motivação o peso político da luta das travestis ou por outros motivos.',
      },

      {
        type: 'text',
        text: '<strong>Não-binário:</strong> Não necessariamente as pessoas precisam se identificar como machos ou fêmeas. Não-binária é a pessoa que pode não se identificar com nenhum dos dois gênero (homem-mulher), fluindo entre os dois ao perceber-se como parte de ambos, ou ainda pode se reconhecer com uma identidade dissociada destes, não acreditar e querer contestar a construção social dos gêneros',
      },

      {
        type: 'text',
        text: '<strong>Cisgênero ou Cis - Homem Cis e Mulher Cis:</strong> A pessoa que se identifica com o gênero que lhe foi designado é a pessoa cis. O homem cis é o homem que se identifica como homem desde o seu nascimento e a mulher cis é a mulher que se identifica como mulher desde o nascimento.',
      },

      {
        type: 'text',
        text: '<strong>Orientação sexual:</strong> A orientação sexual e afetiva de uma pessoa indica diz respeito à quais gêneros ou sexos ela sente atração',
      },

      {
        type: 'text',
        text: '<strong>Homossexualidade:</strong> As pessoas que se sentem atraídas e/ou se relacionam com pessoas do mesmo gênero que elas são homossexuais.',
      },

      {
        type: 'text',
        text: '<strong>Bissexualidade:</strong> As pessoas que se sentem atraídas e/ou se relacionam com pessoas de ambos os gêneros – homens e mulheres - são bissexuais',
      },

      {
        type: 'text',
        text: '<strong>Heterossexualidade:</strong> As pessoas que se sentem atraídas e/ou se relacionam com pessoas do gênero oposto são heterossexuais',
      },

      {
        type: 'text',
        text: '<strong>LGBTI+:</strong> Essa sigla integra lésbicas, gays, bissexuais, pessoas trans e intersexuais. Porém para abranger outras orientações, identidades e expressões de gênero, o símbolo + (mais) faz parte da sigla.',
      },

      {
        type: 'text',
        text: '<strong>Lésbica:</strong> As mulheres, sejam elas cis ou trans, que se sentem atraídas e/ ou se relacionam com outras mulheres são lésbicas',
      },

      {
        type: 'text',
        text: '<strong>Gay:</strong> Os homens, sejam eles cis ou trans, que se sentem atraídos e/ ou se relacionam com outros homens são gays',
      },

      {
        type: 'text',
        text: '<strong>Assexualidade:</strong> As pessoas que não sentem atração sexual por outras são assexuais.',
      },

      {
        type: 'text',
        text: '<strong>Pansexualidade:</strong> As pessoas que sentem atração e ou/ se relacionam por todas as pessoas são pansexuais',
      },

      {
        type: 'text',
        text: '<strong>Luta da Comunidade LGBTI+ por direitos</strong>',
      },

      {
        type: 'text',
        text: '<strong>O que é o movimento LGBTI?</strong> Em uma sociedade que padroniza comportamentos, pessoas, relacionamentos, vestimentas e tudo o que se pode imaginar, era de se esperar que um movimento que contestasse a estrutura de gênero, familiar, de matrimônio e de relacionamento fosse uma afronta. Essas estruturas fazem parte de um alicerce de como a sociedade funciona em um país como o Brasil. Mas não por isso deixou de existir luta e organização para buscar pelos direitos civis e sociais das pessoas LGBTI+. Apesar de não ser um movimento unificado, principalmente por trazer tantas diversidades, aqui e em outros países ele foi necessário para uma série de transformações e conquistas que ajudam a combater o preconceito, a intolerância, a violência e exclusão. ',
      },

      {
        type: 'text',
        text: 'Historicamente, considerando tempos antigos e atuais e também vários lugares do mundo, a comunidade LGBTI+ é vítima de violações aos seus direitos e à humanidade. Em vários tempos e momentos os atos de violência eram promovidos pelos próprios códigos penais, que buscavam combater a homossexualidade. Fortes exemplos disso são fatos tristes como o genocídio da população LBGTI+ durante o avanço do nazismo, que levava essas pessoas aos centros de extermínio e de concentração; e os episódios, nesse mesmo regime alemão, onde médicos e psiquiatras submetiam as pessoas da comunidade a sessões de tortura, castração, choques, lobotomia, banhos de gelo e até mesmo estupros corretivos, tudo isso sob o pretexto de cura.',
      },

      {
        type: 'text',
        text: 'Mas não pense que isso era uma exclusividade dos ditadores. Nos Estados Unidos, país que sempre representou o progressismo, não faz tanto tempo que a homossexulidade foi descriminalizada. Apenas em 1972 a maioria dos estados começaram a modificar seus códigos penais, mas apenas em 2003 a lei foi 100% abolida. Ao redor do mundo, as relações homossexuais ainda são consideradas crime em 73 países, segundo dados recentes da associação internacional ILGA (International Lesbian, Gay, Bisexual, Trans and Intersex Association), que monitora as leis relacionadas ao tema desde 2005. ',
      },

      {
        type: 'text',
        text: 'Entendendo esse conturbado histórico de opressão, extermínio, violência e abuso, é inevitável reconhecer que são necessárias medidas reparatórias e também para proteger a integridade da população. Por isso, <strong>as últimas décadas foram marcadas por lutas e conquistas de direitos.</strong> O Movimento LGBT brasileiro nasceu em um contexto de grande repressão e injustiça social: a Ditadura Militar, que foi de 1964 a 1985. O movimento surgiu com encontros e publicações que foram essenciais para o crescimento e o amadurecimento da pauta no Brasil.',
      },
      {
        type: 'text',
        text: '<strong>Lutas e conquistas LGBTI+ no Brasil</strong>',
      },
      {
        type: 'text',
        text: 'Vamos conhecer alguns episódios fundamentais para o avanço de direitos e representatividade para as pessoas da comunidade LGBTI+.',
      },

      {
        type: 'text',
        text: '<strong>Em 1978</strong><br /><strong>surge o primeiro grupo de direitos LGBTI+</strong>, o Somos: Grupo de Afirmação Homossexual, surgiu com o objetivo de lutar pela causa da comunidade LGBT. Mas em geral era mais representada por homens gays.',
      },

      {
        type: 'text',
        text: '<strong>No mesmo ano, o jornal Lampião da Esquina</strong> surgiu e tinha cunho abertamente homossexual, apesar de abordar também outras importantes questões sociais. Uma de suas principais ações era denunciar a violência contra a população LGBT.',
      },

      {
        type: 'text',
        text: '<strong>Em 1980</strong><br /><strong>Ocorreu o primeiro protesto da causa — contra a “Operação Limpeza”</strong> promovida pelo delegado José Richetti no centro de São Paulo. Nessas “higienizações sociais”, a polícia militar passava pelas áreas de frequência gay da República, no centro de São Paulo/SP e prendia por “vadiagem”. Homossexuais, travestis e prostitutas eram agredidos e violados. Até que grupos de homossexuais, movimentos negro e feminista, organizaram um protesto nas escadarias do Theatro Municipal de São Paulo em junho de 1980. Foi a primeira vez que se marchou contra a repressão e o preconceito lgbtfobico no Brasil ( antes de o termo existir). ',
      },

      {
        type: 'text',
        text: '<strong>Em 1983</strong><br /><strong>As mulheres do Grupo Ação Lésbica Feminista (Galf) deram início a um protesto no Ferro’s Bar</strong>, em São Paulo, que ficou conhecido como “o pequeno <strong>Stonewall brasileiro</strong>”. No contexto da ditadura militar, ele era um dos principais pontos de encontro e discussão da pauta lésbica, até que houve denúncias à polícia por violações aos valores das famílias. Além disso, o Galf passou a produzir e a circular de forma independente  o jornal “Chanacomchana”, focado no público lésbico entre 1981 e 1987 na capital paulista. Com isso, as ativistas invadiram o interior do Ferro’s e leram um manifesto lésbico contra a censura do bar, exigindo que a venda do jornal fosse permitida e que elas fossem respeitadas. Após o levante, o dono do Ferro’s Bar pediu desculpas às mulheres e permitiu a venda do “Chanacomchana” no local. Esse evento foi liderado por Rosely Roth, que morreu em 2003 e fixou a data no calendário como o Dia do Orgulho Lésbico - 19 de agosto. Em seus nove anos de existencia, o Grupo Ação Lésbica Feminista (Galf) foi fundamental para a criação dos dias da Visibilidade e do Orgulho Lésbico.',
      },

      {
        type: 'text',
        text: '<strong>Em 1988</strong><br /><strong>A Constituição de 1988. Artigo 3º objetivo fundamental da república</strong>: promover o bem de todos, sem preconceitos de origem, raça, sexo, cor, idade e quaisquer outras formas de discriminação. Esse registro deveria assegurar todos os direitos da comunidade LGBTI+ e demais grupos marginalizados.',
      },

      {
        type: 'text',
        text: '<strong>Em 1990</strong> <br /><strong>17 de maio de 1990, data reconhecida como o Dia Mundial contra a LGBTfobia</strong>, marcou o dia em que a OMS (Organização Mundial da Saúde) cedeu à luta pela “<strong>despatologização</strong>” da homossexualidade. Essa foi uma campanha nacional para retirar a homossexualidade do código de doenças. ',
      },

      {
        type: 'text',
        text: '<strong>Em 1995</strong><br /><strong>A categoria “T” foi incorporada à sigla</strong> que antes era comumente conhecida como GLS (Gays, Lésbicas e Simpatizantes). ',
      },

      {
        type: 'text',
        text: '<strong>Em 1997</strong><br /><strong>Começa a história da maior Parada do Orgulho LGBTI+ do país, em São Paulo</strong>. A primeira Parada do Orgulho Gay reuniu mais de 2 mil pessoas contra a discriminação e a violência sofridas por gays, lésbicas e travestis. O objetivo era sensibilizar a sociedade para o convívio respeitoso com as diferenças, pressionando o Estado a garantir os direitos da comunidade homossexual. Organizações como o Grupo Gay da Bahia e o Grupo Atobá apontavam um número crescente de crimes contra a comunidade GLT. Na década de 1990, foram registrados 1.256 casos de assassinatos por homofobia. A edição de 2011 apresentou o maior número de participantes de sua história, tendo presentes estimados 4 milhões de pessoas. Ela é a maior edição do mundo.',
      },

      {
        type: 'text',
        text: '<strong>A partir dos anos 2000</strong><br />Com a popularização das redes virtuais houve um incremento significativo da pesquisa sobre sexualidade em várias áreas do conhecimento e o aumento do número de grupos em prol da diversidade sexual no interior das universidades e nas organizações. Houve também a ampliação da visibilidade social com o processo de segmentação de mercado. Surgimento de produtos e serviços específicos para o público LGBTI+ - oportunidade de negócio, intensificando a representatividade de pessoas públicas LGBTI+: artistas, políticos, atletas, publicidade.',
      },

      {
        type: 'text',
        text: '<strong>Em 2008</strong><br /><strong>O processo de resignação sexual</strong>, conhecido como mudança de sexo, começou a ser oferecido pela rede de saúde pública (SUS) em 2008, mas apenas em 2010 o processo cirúrgico passou a ser realmente realizado. No entanto, a espera ainda é longa, uma vez que pode levar mais de 20 anos para o procedimento ser feito.',
      },

      {
        type: 'text',
        text: '<strong>Em 2011</strong><br /><strong>Somente em 2011 o STF aprovou a união estável homoafetiva</strong>. A primeira tentativa de legalizar essa união civil no país foi o projeto de lei 1151 da deputada Marta Suplicy, em 1995. Mas a Justiça aprovou a união apenas em 2011.',
      },

      {
        type: 'text',
        text: '<strong>Em 2013</strong><br /><strong>O casamento foi aprovado pelo Conselho Nacional de Justiça</strong> com uma resolução que obriga cartórios a realizar a união entre pessoas do mesmo gênero. Hoje, não há no país uma lei específica sobre o tema. O casamento é legal, mas o que garante os casamentos e uniões estáveis entre pessoas do mesmo gênero é a jurisprudência.',
      },
      {
        type: 'text',
        text: '<strong>A adoção</strong>, que é gerida na legislação que protege a criança e o adolescente, passou a ser permitida pois em nenhum momento o Estatuto da Criança e do Adolescente (ECA) cita que o gênero dos cônjuges para adoção afetaria a formação do adotado. Entretanto, só poderia ser concedida a adoção a casais em matrimônio ou se comprovada a unidade segura, ou seja, após a aceitação jurídica de casamento ou união estável de pessoas do mesmo gênero.',
      },
      {
        type: 'text',
        text: '<strong>Em 2018</strong><br /><strong>A alteração do nome no registro civil</strong> foi conquistada após mais de 40 anos de luta da comunidade.. Esse direito é uma enorme conquista para as pessoas transexuais. Finalmente, em 2018, começou a ser aceito a alteração do nome social para as pessoas transexuais e travestis. No judiciário, recentemente foi autorizado pelo STF a alteração do nome no registro civil sem a necessidade de cirurgia de mudança de fenótipo (tópico citado acima), assim, é possível fazer a alteração e incluir o novo nome nos documentos pessoais enfrentando menos burocracia.',
      },

      {
        type: 'text',
        text: '<strong>Em 2019</strong><br /><strong>STF decide que a discriminação</strong> por orientação sexual e identidade de gênero passa a ser <strong>considerada um crime</strong>. A homofobia e a transfobia não estavam na legislação penal brasileira, ao contrário de outros tipos de preconceito, o que indicava omissão diante das violências sofridas por esse grupo.',
      },

      {
        type: 'text',
        text: '<strong>Em 2020</strong><br /><strong>A doação de sangue</strong> por homossexuais só foi permitida no dia 8 de maio de 2020.',
      },
      {
        type: 'text',
        text: '<strong>Em 2021</strong><br />Somente neste ano a <strong>primeira mulher travesti conseguiu finalizar o processo de adoção no Brasil.</strong> Alexya Salvador adotou três filhos – dois deles são crianças trans.',
      },
      {
        type: 'text',
        text: '<strong>Atualmente a Diversidade</strong> está sendo vista como um diferencial estratégico nas organizações. Sobretudo a partir de 2010 cada vez mais as entidades LGBTI+ adentraram o mercado empresarial e de trabalho, emergindo novas frentes de integração e de cooptação. Essa proximidade maior com movimentos e organizações que trazem a agenda LGBTI+ faz com que empresas busquem atender as necessidades desse público tanto em seus produtos, serviços e atendimentos como também internamente, trazendo a comunidade para o quadro de colaboradores para incluir o grupo e agregar suas ideias.',
      },
      {
        type: 'text',
        text: '<strong>Faça parte do movimento pela causa LGBTI+</strong>',
      },
      {
        type: 'text',
        text: 'Ser uma pessoa aliada ao movimento é ser alguém que compreende a dimensão dos valores e dos problemas da comunidade. Alguém que se engaja com a causa por saber que é uma questão de salvar vidas e propiciar mais saúde mental para as pessoas LGBTI+. Mesmo que você não seja da comunidade nem ame alguém que seja, busque apoiar e respeitar independentemente do gênero, orientação sexual, identidade de gênero ou expressão de gênero. Mas para respeitar, é preciso conhecer. É preciso estudar, saber quais são os direitos que ainda não foram conquistados, saber quais são os direitos que não estão sendo respeitados, é preciso conhecer as estatísticas sobre as pessoas LBGTI no quesito emprego, saúde, violência. É necessário conhecer referências que te ensinem e te mostrem a naturalidade que mora na diferença. Quem não pertence a esse grupo precisa se sentir responsável pela opressão e intencionalmente se movimentar para aprender e ensinar os outros, educar filhos sem preconceitos, contratar pessoas sem fazer distinções por preconceito e tudo isso! Não se cale diante de uma piada LGBTfobica. Não ache que xingar alguém de ‘veado’, ‘gay’, ‘traveco’, ‘sapatão, ‘maria-homem’ é algo inocente. O primeiro passo para não ofender é por meio da informação.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST</strong> 🎧',
      },
      {
        type: 'text',
        text: '<strong>#30 Demitindo a LGBTfobia</strong><br />Mesmo com muitos avanços, ainda é visível no mercado de trabalho a rejeição das pessoas que não seguem o "padrão" de sexualidade ou identidade de gênero. Se você nunca sentiu que precisava fingir ser outra pessoa, esconder alguns fatos da sua vida ou mudar sua aparência para conseguir um emprego, esse episódio é pra você. É pra você aprender sobre a realidade dos outros e sobre as desigualdades. Mas esse Trampapo é pra você também que sentiu que para conseguir emprego ou uma promoção era necessário se esconder. É pra refletir sobre a comunidade LGBTQIAP+ e como a discriminação ainda está presente nas empresas e deixando de fora profissionais taletosos. Para isso, contamos com a ajuda de <strong>Leticia Sayuri, consultora de diversidade do banco Santander e cofundadora da Rede de Mulheres LBQT+, e também recebemos Louie Ponto, criadora de conteúdo e mestra em literatura na linha de crítica feminista e estudo de gênero.</strong> Quer fazer parte desse papo? Aperte o play!',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.youtube.com/louieponto" rel="nofollow">Louie Ponto</a> | <a href="https://www.instagram.com/pontolouie/" rel="nofollow">@pontolouie</a></strong><br />é atualmente uma das principais referências de criadores de conteúdo nas redes brasileiras com foco na comunidade LGBTI+. Aos 29 anos ela conta com mais de 668 mil inscritos em seu canal no Youtube, onde propõe conversas sobre feminismo, sexualidade, identidade de gênero, comportamento, vegetarianismo, saúde mental e outros temas importantes. Ela é formada em Letras e tem um mestrado em Literatura na linha de crítica feminista e estudo de gênero.',
        image: content_18_guest_02,
        alt: 'Louie Ponto',
      },
      {
        type: 'text',
        text: '<strong>Em sua passagem pelo Trampapo</strong>, no quadro #DicaExtracurricular, a Louie indicou dois perfis que contribui para a pauta LGBTQ+ e para inclusão, são eles:',
      },
      {
        type: 'text',
        text: '<a href="https://www.instagram.com/indigenaslgbtq/" target="_blank"><strong>@Indigenaslgbtq</strong></a> e <a href="https://www.instagram.com/lorenaeltzz/" target="_blank"><strong>@lorenaeltzz</strong></a><br />Segundo Louie, são conteúdos didáticos e de vivências pessoais dos próprios influenciadores. Louie completou sua fala com uma sugestão geral para todos: “Conheçam pessoas LGBT+, conheçam nossas histórias, nossos trabalhos, nossa arte. Escutem o que nós temos a dizer, nossa música, leiam o que estamos escrevendo. A gente cresce, a gente se sensibiliza e evoluímos como seres humanos e enquanto sociedade quando conhecemos pessoas com vivências diferentes. A diversidade é bonita, sobretudo importante para gente e para o desenvolvimento do mundo.”',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.linkedin.com/in/lkissu/" rel="nofollow">Letícia Sayuri</a></strong><br />é Especialista em Diversidade & Inclusão e Pesquisadora em Gênero, Feminismo e Estudos LGBTI+. Atua no Banco Santander há mais de 5 anos e por lá exerce consultoria de diversidade, inclusão e engajamento. Foi uma das responsáveis por fundar a Academia Santander e pela implementação do Posicionamento de Diversidade do Santander. Com seu trabalho, o banco foi reconhecido 4 vezes consecutivas como uma das melhores empresas para a mulher trabalhar, pela GPTW Brasil, e como a Empresa mais diversa da Indústria Financeira, pelo Guia Exame de Diversidade 2020. Além disso, é cofundadora da Rede Brasileira de Mulheres LBTQ+, que propõe visibilidade, networking e inclusão no trabalho. Tem formação em Administração de Empresas e MBA em Inovação, Conhecimento e Tecnologia pela FIA, onde seu tema do trabalho de conclusão de curso foi ‘Educação Corporativa e Cultura de Inovação: como a educação corporativa apoia a difusão da cultura de inovação’.',
        image: content_18_guest_01,
        alt: 'Letícia Sayuri',
      },

      {
        type: 'text',
        text: '<strong>Em sua passagem pelo Trampapo ela deixou uma #DicaExtracurricular:</strong>',
      },

      {
        type: 'text',
        text: 'A sua  indicação foi o projeto onde é cofundadora, <a href="https://www.instagram.com/mulhereslbtq/" target="_blank">@mulhereslbtq</a> - <a href="https://www.mulhereslbtq.com.br/" target="_blank">Rede Brasileira de Mulheres LBTQ+</a>.<br />Segundo Letícia, o projeto foi criado em 2019, com o objetivo de conectar mulheres que estão dentro desse espectro, mas não estão sendo ouvidas e não têm espaço dentro da pauta LGBT+. Falam de oportunidades de trabalho, Network, indicação de vagas, articulação política, oportunidades de negócios e acolhimento.',
      },
      {
        type: 'text',
        text: 'Nosso querido apresentador, <strong>Ricardo Morais</strong>, também não ficou de fora! Suas indicações são:',
      },
      {
        type: 'text',
        text: '<strong>Livro: Guardei no armário: a experiência de um jovem homossexual negro e ex-evangélico na sétima maior cidade do mundo, por Samuel Gomes </strong>',
      },

      {
        type: 'text',
        text: 'Segundo Ricardo, o livro conta a história e vivência na luta por igualdade racial, social, sexual e de gênero. Relato de um jovem nascido na em uma periferia de São Paulo que superou o racismo e a homofobia em prol dos próprios direitos e os de outras pessoas. ',
      },

      {
        type: 'text',
        text: '<strong>Blog Carreira & Sucesso da Catho</strong><br />Ricardo também indicou o blog da Catho, que conta com vários conteúdos sobre <strong>carreira</strong>, como começar no mercado, com diversos colunistas. É uma boa pedida para conseguir adquirir conhecimentos sobre o âmbito profissional. Pra ele, é importante para “prender a como dar <strong>o primeiro passo</strong> para se colocar em um lugar assim como o da <strong>Letícia</strong>, de mudar o mundo com uma grande corporação e, da mesma forma como a <strong>Louie</strong>, que como criadora de conteúdo possui o poder de falar com todos os públicos e fazer com que todas as pessoas tenham acesso”.',
      },

      {
        type: 'text',
        text: 'Além disso, Ricardo também faz questão de indicar os perfis de nossas convidadas, então segue as dicas: <a href="https://www.instagram.com/pontolouie/" rel="nofollow" target="_blank"><strong>Louie Ponto</strong></a></strong> e <a href="https://www.instagram.com/mulhereslbtq/" target="_blank"><strong>Leticia Sayuri</strong></a>',
      },

      {
        type: 'text',
        text: 'Além disso, <strong>Ana Paula Xongani</strong>, nossa brilhantíssima apresentadora também deu suas dicas: ',
      },

      {
        type: 'text',
        text: '<strong>Livro: Como ser um líder inclusivo - Liliane Rocha</strong><br />Segundo Xongani, vale muito a pena para quem está pensando em liderança.',
      },

      {
        type: 'text',
        text: 'Ela também indicou o perfil, <a href="https://www.instagram.com/transpreta/" target="_blank"><strong>TransPreta</strong></a>, ressaltando que possui conteúdos de vivências enquanto mulher trans e preta, fala sobre cinema (audiovisual), além de ser um perfil inspirador e uma ótima referência para fazer uma intersessualidade dentro dessa pauta. ',
      },
      {
        type: 'text',
        text: 'Xongani fechou suas dicas com um <strong>super vídeo</strong> onde ela ensina o significado das letras <strong>LGBT+</strong> a sua filha.',
      },
      {
        type: 'text',
        text: 'Segundo ela, foi o vídeo mais visto de sua carreira, alcançou mais de 2 milhões de views e ainda completou que era sua meta ser marcada profissionalmente pelas suas construções e não só pelas suas dores. Assista aqui: <a href="https://www.instagram.com/tv/CB_Dsd8DjTF/" target="_blank">https://www.instagram.com/tv/CB_Dsd8DjTF/</a>',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_18_thumb,
  },
  {
    id: 116,
    date: '07/05/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'potencial-criativo',
    title: 'Como desenvolver potencial criativo em qualquer profissão?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_17_thumb,
        alt: 'Como desenvolver potencial criativo em qualquer profissão?',
      },
      {
        type: 'text',
        text: '<strong>Criatividade</strong>. O que você imagina quando pensa nessa palavra? O que costumamos relacionar é a atividade de criar. O que faz bastante sentido até se analisarmos que a palavra é também uma junção de <strong>“criar + atividade”</strong>. Mas muito mais do que uma palavra, esse é um conceito que define várias outras atividades. “Criatividade é o processo de mudança, de desenvolvimento, de evolução na organização da vida subjetiva”, segundo Ghiselin. E pensando assim, é possível vislumbrar a utilização da criatividade em todos os âmbitos, e não somente quando ela é necessária e não apenas para certos profissionais de criação, como artistas, escritores, roteiristas, designers, etc. Mas vamos entender melhor tudo isso!',
      },
      {
        type: 'text',
        text: '<strong>O que é criatividade e qual seu papel no trabalho? </strong>',
      },
      {
        type: 'text',
        text: 'A criatividade é natural em todos os seres humanos. É uma dimensão onde todos nós temos capacidades que nos permitem pensar modos novos e adaptativos em determinados contextos e, então, criar uma solução inovadora e apropriada. O pensar criativo é fugir das normas já estabelecidas, fazer as mesmas coisas de sempre de forma original e disruptiva. Já ouviu a famosa expressão “pensar fora da caixa”? Pois é! Sair do óbvio é olhar para algo que antes não estava sendo olhado ou olhar de uma forma diferente. É ser autêntico mesmo que se inspirando em algo já visto antes. E é por isso que a criatividade é um recurso insubstituível no contexto do trabalho.',
      },
      {
        type: 'text',
        text: 'A todo momento as empresas se deparam com problemas e oportunidades que podem ajudar seus negócios e em atividades rotineiras. Por vezes o obstáculo para resolver a tal questão é a falta de verba, às vezes é a falta de dados e informações, outras é a falta de capacidade da equipe absorver uma nova tarefa, ou um problema com um fornecedor, com um equipamento, software. Todos os dias milhares de problemas surgem e outros milhares são resolvidos porque as pessoas pensam em soluções em todos os segmentos profissionais. <strong>Não é uma exclusividade dos profissionais de comunicação, publicidade e marketing o pensar criativo</strong>. Não é necessário apenas no desenvolvimento de produtos. A criatividade é uma função de todos os profissionais inseridos no mercado de trabalho porque eles são os recursos humanos que as empresas precisam - e, felizmente, a criatividade continua sendo uma vantagem dos seres humanos exclusivamente',
      },
      {
        type: 'text',
        text: 'Então,  quer dizer que é possível ser criativo ao fritar um ovo? A criatividade pode estar no atendimento ao cliente? No momento de negociar com fornecedores? Ao organizar agendas dos executivos da empresa? Dá pra ser criativo em qualquer profissão ou tarefa? Esperamos que sim! A verdade é que qualquer um pode ser criativo e para isso <strong>é preciso apenas dos estímulos corretos e liberdade para ser</strong>. Essa ferramenta mental pode e deve ser explorada além da relação entre as pessoas e suas profissões. Grande parte da criatividade humana é usada e necessária nas atividades que acontecem cotidianamente - na vida profissional ou no ambiente de trabalho.',
      },
      {
        type: 'text',
        text: 'Tá, mas como saber se algo é criativo ou apenas diferente? É difícil de definir. Como conceito, a  criatividade pode ser observada em casos individuais, mas não é possível formar regras genéricas sobre o que definitivamente é o produto do comportamento criativo. Mas fora o conceito, a avaliação do que é criativo deve ser feita pelo próprio indivíduo. A solução encontrada foi nova para você? Foi diferente do que você conhecia como solução? A ideia surgiu impacto ou apenas era diferente? Se pra você foi útil, o objetivo foi alcançado.',
      },
      {
        type: 'text',
        text: 'Dentro das empresas ela é vista como algo além de uma qualidade. Para certas funções e culturas organizacionais, ter o potencial de ser criativo é mandatório para conseguir a vaga e para realizar as tarefas. Em algumas áreas em especial como nos setores que trabalham com inovação é imprescindível ter criatividade por ela é uma característica indispensável para fazer Inovações, que é basicamente a implementação de um produto, serviço ou processo novo ou significativamente melhorado, criando valor para um negócio, governo ou sociedade.',
      },
      {
        type: 'text',
        text: '<strong>Processo criativo e pensar fora da caixa: como começar?</strong>',
      },
      {
        type: 'text',
        text: 'Nós nascemos criativos e com o tempo somos ensinados a nos restringir e minamos a capacidade de imaginar e criar. Vários estudos já testaram e confirmaram que com o passar dos anos vamos rapidamente perdendo nosso potencial criativo. Os ambientes e julgamentos aos quais somos expostos, o processo educacional escolar e familiar são grandes responsáveis por isso. Por isso, é importante buscar intencionalmente recuperar e restabelecer a criatividade. Veja algumas dicas:',
      },

      {
        type: 'text',
        text: '<strong>Reconheça seu nível de criatividade</strong><br />Tente entender as situações em que a sua criatividade deu as caras e busque relembrar como foram as condições como os horários, locais e contextos em que você mais teve ideias e tente reproduzir o mesmo cenário.',
      },
      {
        type: 'text',
        text: '<strong>Expanda seus horizontes</strong><br />Poucas coisas podem ser comparadas à arte quando o assunto é criatividade. Uma de suas bases para existir é o criar e disso nasce a obra. A arte é inspirada e inspira. Por trás dela existe criatividade, sentimento, problemas, soluções, informações e originalidade. Não há como manter-se criativo sem consumir a arte e suas ramificações como a literatura, o cinema, a pintura, a música, a dança e todas as suas linguagens. É fundamental nutrir a mente com conteúdo, informação e inspiração.',
      },
      {
        type: 'text',
        text: '<strong>Tenha uma vida pessoal produtiva</strong><br />Mesmo que sua rotina seja corrida, se você quer ser criativo, precisa começar a pensar em como encontrar espaço para si mesmo. Ter algum hobby como tocar instrumentos, fazer jardinagem, artesanato, ou praticar algum esporte que lhe traga prazer acaba sendo necessário para trazer fontes de prazer ao cérebro, melhorar seu funcionamento e balancear equilibradamente suas atividades e compromissos profissionais, educacionais, sociais e familiares. Todos os tipos de conhecimento e dedicação contribui para o enriquecimento das suas futuras ideias e para a sua estruturação mental. Por isso, não negligencie seu descanso, suas férias e lazer. Ser um profissional que nunca pára não faz bem para o corpo e para a cabeça.',
      },
      {
        type: 'text',
        text: '<strong>Use recursos estimulantes</strong><br />Explorar cores, sons, sentimentos, sensações e o que for preciso é uma dica. Se possível, coloque elementos com esse objetivo em seu ambiente de trabalho.',
      },
      {
        type: 'text',
        text: '<strong>Observe muito</strong><br />O simples ato de observar pode fazer a diferença pra quem quer encontrar soluções e entregas de qualidade. Nas telas temos a todo momento materiais que podem “dar à luz” a novas ideias, mas também atente-se para não ter a sua atenção sempre presa a essas telas. Se não nos policiarmos, não vamos olhar ao nosso redor tanto quanto o necessário. Tire momentos para descansar e busque também viver o momento presente, mantendo a mente vazia e observando todos os pensamentos que aparecem.',
      },
      {
        type: 'text',
        text: '<strong>Fique sempre informado e atualizado</strong><br />O mercado já adotou com força o conceito de lifelong learning, que é a ideia de fazer a educação continuada. Com a agilidade dos fatos, da tecnologia e da informação, estudar na faculdade e nunca mais parar pra se qualificar já não serve para as empresas. Com tudo se transformando rapidamente, é preciso dedicar frequentemente algumas horas para aprender e então entender o que vem sendo feito, quais são as soluções criativas que estão sendo usadas pelo mercado e desenvolver e melhorar competências. Essa expansão de conhecimento permite associar a criatividade com o contexto atual da vida.',
      },

      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST 🎧</strong>',
      },

      {
        type: 'text',
        text: '<strong>#29 Criatividade para ter sucesso profissional</strong><br />A criatividade é uma ferramenta que pertence ao nosso repertório desde a infância, mas por motivos estruturais e sociais desaprendemos a exercitar e começamos a depender de fórmulas de "sucesso garantido". O que nos leva a uma pergunta: como ser criativo no trabalho? Neste Trampapo, Ana Paula Xongani recebe <strong>Luiz Eduardo Serafim, Gerente de Marketing Corporativo na 3M Brasil</strong>, professor universitário e palestrante. Ele defende que a criatividade não é só fundamental para o mercado de trabalho, mas sim para todos os âmbitos da nossa vida e que ela pode nos trazer muita felicidade. A criatividade pode ser estimulada quando expandimos nosso repertório cultural e intelectual, mas também observando as necessidades à nossa volta e encontrando soluções criativas para os problemas que surgem. Já no ambiente empresarial, essa qualidade precisa ser encorajada, as lideranças precisam garantir a confiança e a segurança entre seus colaboradores. Essa é a base para criar soluções inovadoras e de grande impacto! Quer fazer parte desta conversa? Aperte o play!',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.linkedin.com/in/luiz-serafim-347157/?originalSubdomain=br" rel="nofollow">Luiz Serafim</a> | Head de Marketing, eCommerce & Insights na 3M do Brasil</strong> <br />Luiz Serafim é reconhecidamente um defensor da inovação e representa a 3M no Brasil, uma das empresas mais inovadoras do mundo. O autor, pesquisador, professor universitário e palestrante fala sobre os conceitos de invenção, criação e inovação e dá dicas de como criar um ambiente favorável ao processo criativo nas empresas. Ele é um entusiasta da liderança inovadora e das pesquisas. Serafim é também administrador de empresas (FGV-SP) e publicitário (ECA-USP), pós-graduado em marketing (FGV-SP) e tem especialização em desenvolvimento do potencial humano (PUCCAMP). Atua como palestrante e professor de Gestão de Marketing e Inovação nos cursos de MBA da Fundace USP Ribeirão Preto, ESAMC/Campinas e Inova Business School. Colabora com os programas de empreendedorismo da Endeavor desde 2013 até os dias de hoje. É autor do livro “O Poder da Inovação” – Como alavancar a inovação na sua empresa. É idealizador do Projeto audiovisual Inspira.mov sobre Criatividade (TV Cultura SP).',
        image: content_17_guest_01,
        alt: 'Christiane Silva Pinto',
      },
      {
        type: 'text',
        text: '<strong>Suas dicas extracurriculares no episódio #29 foram:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Livro: Inovação Prioridade N1</strong><br />Segundo Luiz essa obra mostra que a inovação precisa de um efeito sistêmico, isso significa que para empresas serem inovadoras é preciso um sistema que traga resultados para um bom número de pessoas.',
      },
      {
        type: 'text',
        text: '<strong>Livro: Gestão e Inovação na Prática</strong><br />Uma das referências que ajudou o Luiz a escrever seu próprio livro que também é a próxima dica, O Poder Da Inovação. Todos querem saber como controlar a criatividade. É impossível. Criatividade vem da liberdade e não do controle.',
      },
      {
        type: 'text',
        text: 'Além de obras incríveis, ele também indicou conceitos para te ajudar a se tornar criativo e inovador.',
      },
      {
        type: 'text',
        text: '<strong>Conceito Flow| Mihaly Csikszentmihalyi</strong><br />Consiste em um  estado mental que acontece quando uma pessoa realiza uma atividade e se sente totalmente absorvida em uma sensação de energia, prazer e foco total no que está fazendo.',
      },
      {
        type: 'text',
        text: '<strong>Conceito Da Vulnerabilidade |  Brene Brown</strong><br /><strong>Luiz Serafim</strong> ressaltou que a vulnerabilidade não é o berço da criatividade, mas é vital para ela.  Esse conceito é definido como aquilo que experimentamos em momentos de incerteza, risco e exposição. Ela nos deixa ansiosos e com medo. O problema, segundo <strong>Brené</strong>, é quando evitamos situações e relações porque provocam esse sentimento.',
      },
      {
        type: 'text',
        text: 'Fechando as dicas <strong>Luiz Serafim</strong>, conclui que uma das essências da criatividade é provocar emoções e fazer a vida mais feliz.',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_17_thumb,
  },
  {
    id: 115,
    date: '30/04/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'profissoes-habilidades-formatos-futuro',
    title: 'Profissões, habilidades e formatos de trabalho do futuro',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_16_thumb,
        alt: 'Profissões, habilidades e formatos de trabalho do futuro',
      },
      {
        type: 'text',
        text: 'Desde o início dos tempos, profissões morrem e nascem. As necessidades das sociedades mudam e os recursos tecnológicos avançam com velocidade, e o resultado disso no mercado de trabalho é a transformação das atividades que podem ser feitas e as habilidades profissionais e comportamentais que os trabalhadores precisam desenvolver e aplicar. Mas de uns tempos para cá, o desenvolvimento de tecnologias passou a ser desenfreado. A todo momento, são inventadas formas de automatizar processos, principalmente aqueles que são funções mais mecanizadas ou repetitivas. Mais cedo ou mais tarde, os trabalhos que são facilmente automatizados acabam, mas dão lugar a outras profissões que empregam quem seja capaz de operar esses novos maquinários, realizar manutenções e criar outras tecnologias que façam o mesmo processo. Para dar uma uma perspectiva da velocidade do surgimento de novos trabalhos, um dado bastante alarmante indicado pelo Institute For The Future (IFTF), em 2018, é que provavelmente 85% dos empregos que vão existir em 2030 não existem ainda nos dias de hoje. <strong>Confira mais abaixo 21 novas profissões que devem surgir</strong> e veja o que vai ser necessário para executá-las.',
      },
      {
        type: 'text',
        text: '<strong>Mudanças no modo de trabalho</strong>',
      },
      {
        type: 'text',
        text: 'O surgimento e extinção de profissões é uma das certezas sobre o futuro do mercado de trabalho, mas não é a única grande mudança para a qual devemos estar preparados. Com o aumento da expectativa de vida das populações, mudanças nas legislações trabalhistas e reformas previdenciárias e a própria mutação constante do ambiente de trabalho, precisamos encarar que  as relações de trabalho como entendemos hoje também devem mudar. As carreiras estáveis, as aposentadorias precoces e até mesmo a garantia de ter uma renda no futuro após uma certa idade devem ser cada vez mais raras. <strong>Devemos nos preparar para trabalhar por muitos anos</strong>, por isso precisamos de planejamento a longo prazo, investir em qualificação para manter-se competitivo e, bem rapidamente, evoluir nossas capacidades comportamentais, emocionais e de relacionamento. É preciso desenvolver as habilidades que já são exigidas no mercado de trabalho de hoje, mas que no futuro serão ainda mais.',
      },
      {
        type: 'text',
        text: 'Outro ponto de atenção é com relação a <strong>precarização do trabalho e a informalidade</strong>. Além de todo o histórico que temos em nosso país, que é um dos que mais realiza o trabalho informal, cerca de 40% em 2019 segundo o IBGE, durante a pandemia estamos tendo uma amostra viva de como esse fenômeno cresce e funciona. Com a emergência da pandemia de Covid-19, suas dimensões ganharam visibilidade, agravando, em especial, a questão da saúde dos trabalhadores. A conta entre a alta do desemprego e a economia abalada resulta em um combate ao desemprego pela via da precarização e informalização. No caso da precarização, que muitas vezes também é estar em um trabalho informal, podemos ver empresas que conseguem baratear seus custos com profissionais, sem a necessidade de incluí-los na Consolidação das Leis do Trabalho (CLT) e ainda aumentar seus serviços, comercialização e lucros. E esse é um movimento que não volta. Uma vez “abertos os portões” e em tempos de afrouxamento das proteções trabalhistas, podemos constatar que essa é uma tendência de futuro. Esses são componentes que se destacam na conjuntura pandêmica, inclusive provocando reações dos trabalhadores contra esse processo em manifestações durante a pandemia. Pensando nisso, o alerta é para se preparar para “atacar” áreas e profissões que exigem maior qualificação, pois nestas as chances de precarização são menores, além de dar atenção a esses aspectos, sobretudo, das lutas da classe trabalhadora. Precisamos permanecer atentos ao risco de precarização do trabalho, gritando sempre por direitos que garantam o mínimo aos trabalhadores.',
      },
      {
        type: 'text',
        text: 'Por outro lado, considerando que os <strong>formatos de trabalho flexíveis</strong> também trarão benefícios. Do ponto de vista das empresas, o <strong>home office</strong> por exemplo vai ajudar decisivamente as empresas a reduzirem custos operacionais e aumentar a produtividade. Para as pessoas, os efeitos podem ser o aumento da produtividade e a satisfação profissional. Trabalhadores remotos têm quase o dobro de probabilidade de afirmarem que amam seus trabalhos, quando comparados com outros profissionais do mesmo setor que trabalham em espaços tradicionais. Essa flexibilização também valerá para a quantidade de horas trabalhadas. A jornada de trabalho de 40 horas semanais surgiu na Primeira Revolução Industrial, mas hoje para alguns profissionais o trabalho pode ser realizado a qualquer hora, de qualquer lugar. É uma tendência que mais pessoas também possam ter essa liberdade no futuro e, talvez, tenhamos semanas de trabalho de apenas 4 dias, deixando a querida sexta-feira mais querida ainda.',
      },
      {
        type: 'text',
        text: 'As hierarquias também tendem a perder força. Apesar de serem importantes, as hierarquias podem burocratizar atividades que cada vez mais precisam de agilidade. No futuro e nas empresas que já estão antenadas a isso, um modelo de organização baseado em auxílio mútuo e confiança será mais utilizado. ',
      },
      {
        type: 'text',
        text: '<strong>Habilidades profissionais para o futuro</strong>',
      },
      {
        type: 'text',
        text: 'Agora, considerando tudo isso, é imprescindível também saber como se aderir a tantas novidades. Como estar preparado para atender às novas demandas e necessidades do mercado, transitar nesses novos ambientes e fugir de trabalhos precarizados e com poucos benefícios? O segredo está no aprendizado. A primeira exigência para qualquer trabalhador é disposição para aprender. Mudanças - de rotina e de profissão - vão se tornar mais comuns para todos nós.',
      },
      {
        type: 'text',
        text: 'Para se tornar um bom profissional agora e principalmente no futuro, recomendamos entender mais profundamente três temas: o futuro do trabalho, competências profissionais do futuro e o futuro da educação para o trabalho, pois assim é possível pensar em competências que possam servir às profissões de uma forma geral. Logo, é essencial esse aprendizado ser muito focado em habilidades comportamentais, as famosas Soft Skills. Elas dizem respeito às competências subjetivas dos trabalhadores. Quer saber quais e porquê? A Revista da Você S/A fez uma análise de estudos do Fórum Econômico Mundial, Group, Capgemini e Falcone e, com base no cruzamento destas pesquisas, apresentou as que seriam <strong>as cinco Soft Skills mais importantes</strong>. Veja quais são.',
      },
      {
        type: 'text',
        text: '<strong>1. Comunicação</strong> – É importante ter uma boa comunicação falada e escrita, saber administrar grandes volumes de informação, desenvolver escuta ativa e falas assertivas baseadas em técnicas como storytelling e dominar o inglês.',
      },
      {
        type: 'text',
        text: '<strong>2. Resolução de Problemas</strong> – Tomada de decisão é um dos principais requisitos. Para isso, é necessário desenvolver a capacidade analítica através não só do domínio de técnicas e metodologias diversas mas também da obtenção de bagagem cultural diversificada, exercer a resiliência e ser disciplinado para seguir processos.',
      },
      {
        type: 'text',
        text: '<strong>3. Atenção a detalhes</strong> – Com uma gama tão grande de dados e informações, é importante aprender a diferenciar detalhes relevantes dos inúteis, ser paciente, organizado e manter o foco.',
      },
      {
        type: 'text',
        text: '<strong>4. Pensamento Digital</strong> – É preciso mais do que saber navegar nos sistemas. É necessário ter a tecnologia como ponto de partida para a solução de problemas e aproveitamento de oportunidades.',
      },
      {
        type: 'text',
        text: '<strong>5. Poder de Adaptação</strong> – Já estamos em um mundo cheio de incertezas e complexidade, mas cada vez estaremos mais. Neste mundo é necessário ter rapidez no aprendizado e controle emocional para lidar com tantas mutações e exigências.',
      },
      {
        type: 'text',
        text: 'Além dessas habilidades, não podemos deixar de citar a criatividade. A fronteira mais dificilmente alcançada pela tecnologia é a <strong>criatividade</strong>, que propicia as artes, invenções e a criação do futuro que queremos.  Por isso essa qualidade vai ser cada vez mais demandada no ambiente de trabalho, mas o bom é que essa qualidade é da natureza do ser humano. Somos criativos na infância e ao longo da vida podemos estimular a criatividade ou acabar com ela. Pensando no futuro, nossa dica é investir no seu potencial criativo. A soma da criatividade com a sensibilidade humana, é algo intrinsecamente nosso. Aproveite enquanto essas são habilidades exclusivas nossas.',
      },
      {
        type: 'text',
        text: '<strong>21 Profissões do futuro</strong>',
      },
      {
        type: 'text',
        text: 'Talvez você tenha estudado para se qualificar pensando no atual mercado de trabalho e nas profissões que existem hoje. Muitas delas e talvez a sua ainda vão continuar existindo. Mas como falamos antes, vários fatores influenciam na aparição de novas profissões. É possível, com certa assertividade, prever o que está por vir. A empresa de tecnologia e negócios Cognizant produziu um relatório que mostra diversas tendências sobre o futuro do trabalho, entre elas, as previsões para novas profissões e trabalhos que devem ganhar força até 2028. ',
      },
      {
        type: 'text',
        text: '<strong>Saiba quais são as 21 profissões do futuro:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Técnico em saúde informatizada</strong>: Responsável por examinar, diagnosticar, administrar e prescrever tratamentos para pacientes com o auxílio da inteligência artificial e de médicos acessíveis remotamente. Para fazer esse trabalho, é preciso ter formação em enfermagem ou similares e ter experiência anterior na área de saúde, além de habilidades interpessoais e capacidade de trabalhar sob pressão e com ferramentas digitais.',
      },
      {
        type: 'text',
        text: '<strong>Analista de cidades cibernéticas</strong>: Profissional deverá trabalhar com informações que incluam dados dos cidadãos e dos recursos dos municípios. Para fazer esse trabalho, é preciso ter qualificações em engenharia digital, conhecimentos sobre circuitos eletrônicos e metodologias de startup enxuta e experiência com impressão 3-D. É preciso saber ler e interpretar dados em analytics.',
      },
      {
        type: 'text',
        text: '<strong>Diretor de genoma</strong>: Profissional vai criar e executar uma estratégia para aumentar o portfólio de produtos que envolvam a ciência da vida. Para fazer esse trabalho, é preciso ter graduação em campo relacionado à genômica (mestrado é uma vantagem). Experiência de pelo menos uma década e habilidades de comunicação, liderança e negociação, além de um perfil analítico, também são necessários.',
      },
      {
        type: 'text',
        text: '<strong>Gerente de man-machine</strong>: Profissional trabalha na colaboração entre homem e a máquina, responsável pela identificação de tarefas, processos, sistemas e experiências que possam ser melhorados com a tecnologia. Para fazer esse trabalho, é preciso ter formação em psicologia ou neurociência e qualificação posterior em ciência da computação, engenharia ou recursos humanos. Além de experiência em áreas relacionadas, como machine learning ou interação entre humanos e robôs. Experiência com UI/UX é um diferencial.',
      },
      {
        type: 'text',
        text: '<strong>Coach de saúde financeira</strong>: O profissional terá a função de um coach, e vai orientar sobre questões financeiras, os melhores investimentos e aplicações. Para fazer esse trabalho, é preciso ter formação em finanças ou negócios, um bom ambiente para trabalhar de maneira remota, experiência com análise de métricas financeiras e conhecimentos sobre a indústria financeira.',
      },
      {
        type: 'text',
        text: '<strong>Alfaiate digital</strong>: Com o avanço crescente do e-commerce, será necessário um profissional que vá até a casa do usuário, pegue suas medidas com um sistema digital e faça os ajustes necessários em suas roupas e sapatos comprados na web. Para fazer esse trabalho, é preciso ter experiência com moda, artes ou design de interiores, estar confortável com tecnologia e ter um bom tino comercial.',
      },
      {
        type: 'text',
        text: '<strong>Chief Trust Officer</strong>: Trabalhará ao lado de equipes internas de finanças e relações públicas. Vai gerenciar e aumentar a presença pública e privada em toda a esfera financeira, e sempre trabalhar com transparência nas finanças de uma organização. Para fazer esse trabalho, é preciso ter anos de experiência relevante (com criptomoedas, blockchain, bolsa de valores), mestrado na área, conhecimentos regulatórios e perfil analítico.',
      },
      {
        type: 'text',
        text: '<strong>Analista de computação quântica</strong>: O profissional atuará na área de machine learning (“aprendizado de máquina”, em inglês), principalmente com a integração com o aspecto quântico. Para fazer esse trabalho é preciso ter um perfil criativo e uma pós-graduação na área, além de anos de experiência com machine learning, computação quântica ou data science.',
      },
      {
        type: 'text',
        text: '<strong>Sherpa de loja virtual</strong>: Sherpas ajudam escaladores a subir montanhas. No futuro, sherpas virtuais vão guiar consumidores pelas lojas virtuais, auxiliando em compras dentro de cenários complexos. Para fazer esse trabalho, é preciso ter experiência com vendas, boas habilidades de comunicação e um bom ambiente de trabalho remoto.',
      },
      {
        type: 'text',
        text: '<strong>Corretor de dados pessoais</strong>: Profissional será responsável por monitorar e comercializar dados pessoais, empresas e governos deixados na internet. Para fazer esse trabalho, é preciso perfil analítico, conhecimento do ambiente regulatório global, saber trabalhar sob pressão.',
      },
      {
        type: 'text',
        text: '<strong>Curador de memória pessoal</strong>: Profissional consultará uma série de públicos específicos, a mídia e fontes históricas para refazer e formular experiências do passado, para reduzir o estresse ou a ansiedade que a perda de memória provoca.  Para fazer esse trabalho, é preciso ter perfil criativo e nível excepcional de inteligência emocional, ótimas habilidades de comunicação, empatia, capacidade narrativa e vontade de inovar.',
      },
      {
        type: 'text',
        text: '<strong>Construtor de realidade aumentada</strong>: Ele vai projetar, escrever, criar, calibrar, construir e personalizar viagens em realidade aumentada para as pessoas. Para fazer esse trabalho, é preciso ter perfil criativo, anos de experiência com jogos competitivos multiplayer, familiaridade com tecnologias como Microsoft HoloLens e Facebook Oculus e com metaversos de conteúdo.',
      },
      {
        type: 'text',
        text: '<strong>Controlador de tráfego autônomo</strong>: Gerenciador de tráfego de veículos autônomos e de drones. Para fazer esse trabalho, é preciso ter aptidão para o trabalho é mais importante que qualificações ou experiências anteriores. É preciso ter habilidades de comunicação, tomada de decisão, organização e resolução de problemas. Saber trabalhar sob pressão também é essencial.',
      },
      {
        type: 'text',
        text: '<strong>Oficial de diversidade</strong>: Profissional vai facilitar a rentabilidade e a produtividade de uma organização e, ao mesmo tempo, promoverá um ambiente de inclusão genética, operando de acordo com as leis e guias relacionados à força de trabalho geneticamente aprimorada. Para fazer esse trabalho, é preciso ter grau avançado de estudos em biologia ou genômica, anos de experiência com igualdade genética ou similares. Habilidades interpessoais, de gestão e de comunicação também são essenciais.',
      },
      {
        type: 'text',
        text: '<strong>Investigador de dados</strong>: Assíduo analista de dados, o profissional precisará interpretá-los da melhor maneira possível. Além disso, deve ser curioso, analítico e multitarefa. Para fazer esse trabalho, é preciso saber sobre finanças, matemática e data science, mas ser um cientista de dados não é necessário. Conhecimentos legais são uma vantagem.',
      },
      {
        type: 'text',
        text: '<strong>Facilitador de TI</strong>: Profissional vai gerar flexibilidade para os usuários com o uso de aplicativos e infraestrutura, desenvolvidos dentro da empresa ou em ambientes de nuvem. Ter formação em TI, ciências da computação, engenharia, ciências naturais ou administração de empresas. Para fazer esse trabalho, é preciso ter habilidades de comunicação e liderança também são necessárias.',
      },
      {
        type: 'text',
        text: '<strong>Gerente de ética</strong>: Investiga, acompanha, negocia e faz acordos sobre o fornecimento de produtos e serviços, para garantir o alinhamento nos contratos relacionados a questões éticas de um público estratégico. Para fazer esse trabalho, é preciso ter experiência com ética em ambientes corporativos, habilidades interpessoais e de comunicação, capacidade de trabalhar em grupo. Conhecimentos de negócios, lei, gestão pública ou filosofia são diferenciais.',
      },
      {
        type: 'text',
        text: '<strong>Gerente de negócio em I.A.</strong>: O trabalho será próximo às áreas de vendas, marketing e sócios no segmento de inteligência artificial. Para fazer esse trabalho, é preciso ter experiência com vendas e desenvolvimento de negócios em grandes organizações, além de experiência corporativa com plataformas de IA, machine learning e computação em nuvem.',
      },
      {
        type: 'text',
        text: '<strong>Especialista em edge computing</strong>: A computação em nuvem está gradualmente abrindo caminho para a próxima grande evolução: a edge computing, que desencadeia o potencial de dispositivos de hardware conectados e os descentraliza. Para fazer esse trabalho é preciso ter doutorado na área ou em áreas relacionadas, experiência com segurança e protocolo de internet das coisas (IoT), entre outros assuntos. Capacidade de arquitetar e projetar ambientes de computação em nuvem ou edge computing.',
      },
      {
        type: 'text',
        text: '<strong>Walker-Talker</strong>: O profissional será responsável por passar um tempo com os clientes, e sua principal atividade vai ser prestar atenção no que eles dizem. Para fazer esse trabalho, é preciso ter mobilidade para visitar clientes em casa quando for necessário.',
      },
      {
        type: 'text',
        text: '<strong>Consultor fitness</strong>: Profissional responsável por motivar a atividade física, melhorar a nutrição e fazer com que o indivíduo adote um estilo de vida mais saudável. Para fazer esse trabalho é preciso ter experiência com nutrição ou educação física e credenciais (obtidas em cursos online) em modalidades esportivas como CrossFit ou yoga. Saber lidar com ambientes culturalmente diversos também é necessário.',
      },
      {
        type: 'text',
        text: 'E aí, curtiu alguma das opções de profissões e está pensando em apostar em uma? Não é má ideia!<br />Apesar do medo que o futuro causa em alguns profissionais, é possível ver com otimismo o fim do modo industrial de trabalho, que aprisiona corpos e mentes em tarefas burocráticas e repetitivas. Pra quem for possível se preparar, estudar, aprender e aproveitar, pode ser uma boa oportunidade para trabalhar de maneira mais livre e criativa. E o quanto antes você tiver consciência sobre as oportunidades que vão oferecer mais vagas e melhores remunerações, mais você pode se preparar para ter uma carreira sólida no futuro.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST</strong> 🎧',
      },
      {
        type: 'text',
        text: '<strong>#28 Como eu vou trabalhar no futuro?</strong><br />O que esperar do futuro? Como se preparar para aquilo que ainda não é realidade e para profissões que ainda não existem? Neste Tampapo, Xongani recebe <strong>Vanessa Mathias, fundadora da White Rabbit</strong>, agência especializada em observar tendências e sinais futuros, e Cristiane Silva Pinto, gerente de marketing e soluções no <strong>Google Brasil e fundadora do AfroGooglers</strong>, para descobrir o que vai acontecer, considerando que está sendo criado hoje.<br />Estudos indicam que 15% das carreiras que conhecemos podem desaparecer nos próximos 5 anos e apenas 7% serão criadas neste futuro emergente. Será que a sua profissão é uma delas? Trabalhos voltados para o desenvolvimento de capacidades, cuidado e relacionamento com outras pessoas continuarão sendo fundamentais para a sociedade, porém, as funções manuais e de fácil automatização passarão por um processo de especialização. A evolução do trabalho pode mudar o mercado que conhecemos em questão de meses, assim como a criação de serviços de streaming abalou as locadoras de filmes ao redor do mundo e os apps de caronas mudaram a forma como nos locomovemos. Quer conhecer os sinais do que está por vir e as habilidades que serão indispensáveis para o profissional do futuro? Dê o play no site!',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.linkedin.com/in/christianepinto/" rel="nofollow">Christiane Silva Pinto</a> | <a href="https://www.instagram.com/chocochris/" rel="nofollow">@chocochris</a></strong><br />Chris é formada em jornalismo pela Universidade de São Paulo, onde fez estágio em jornalismo e no Museu de Ciências. Ela também foi estagiária da revista <strong>Capricho/Abril</strong> e, após entrar no <strong>Google Brasil</strong> e atuar por 6 anos no RH da empresa, hoje gerencia a estratégia de marketing das soluções para Micro e Pequenas Empresas no Brasil. Por lá, comanda projetos como o <strong>YouTube Black Brasil</strong>, que incentiva criadores negros de conteúdo e serviu de modelo para ações parecidas em outras sedes da empresa pelo mundo e o <strong>comitê AfroGooglers<strong>, grupo formado voluntariamente por funcionários de diversas áreas (negros e aliados) que tem por objetivo educar e gerar conscientização interna em relação à justiça racial, assim como apoiar e desenvolver os atuais profissionais negros na empresa, além de trabalhar com a comunidade externa visando sua capacitação e crescente representação de profissionais negros no mercado de trabalho. <strong>Suas dicas de conteúdos foram<strong>:',
        image: content_16_guest_01,
        alt: 'Christiane Silva Pinto',
      },
      {
        type: 'text',
        text: '<strong>Treinamentos Google</strong><br />Treinamentos, mentorias e cursos voltados para carreira, empreendedorismo, autônomos, profissionais informais e muito mais estão disponíveis nas plataformas do Google gratuitamente.',
      },
      {
        type: 'text',
        text: '<strong>Clóvis Moura | Sociólogo</strong><br />A dica da Chris é para acompanhar o trabalho desse intelectual. Segundo ela, seus estudos sobre o nosso processo de democratização, político e econômico são importantes para conhecermos o passado e refletir sobre o futuro que queremos.',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.linkedin.com/in/vamathias/" rel="nofollow">Vanessa Mathias</a> | <a href="https://www.instagram.com/whiterabbittrends/" rel="nofollow">@whiterabbittrends</a></strong><br />É consultora de tendências futuras e inovação, mapeando cenários possíveis para a sociedade e empresas. Com 15 anos de experiência em pesquisa de mercado, mídia, imagem de marca e posicionamento em grandes empresas, é especialista em como as pessoas se conectam umas às outras através da tecnologia e o universo digital. É facilitadora profissional; fundadora, editora e escritora da Chicken or Pasta, revista de estilo de vida focada em tendências. É co-fundadora da @whiterabbittrends, uma agência de exploração de tendências e foresight para co-criação de futuros desejáveis. Tem bacharel em ADM pela FEA/USP, pós-graduação em Pesquisa de Tendências pelo Instituto Europeu de Pós-Graduação - IEP e MBM - Master in Business and Management na FGV. <strong>Sua dica de conteúdo foi</strong>:',
        image: content_16_guest_02,
        alt: 'Vanessa Mathias',
      },
      {
        type: 'text',
        text: '<a href="https://www.ted.com/talks/sir_ken_robinson_do_schools_kill_creativity?language=pt-BR" rel="nofollow">Ted Talk: Ken Robinson: Será que as escolas matam a criatividade?</a><br />Sir Ken Robinson defende de maneira divertida e profunda a criação de um sistema educacional que estimula (em vez de enfraquecer) a criatividade. O especialista em criatividade desafiou a maneira como educamos nossos filhos, defendendo um repensar radical de como nossos sistemas escolares cultivam a criatividade e reconhecem vários tipos de inteligência.',
      },
      {
        type: 'text',
        text: '<strong><a href="https://instagram.com/anapaulaxongani" rel="nofollow">Ana Paula Xongani</a></strong>: Nossa querida host recomendou 3 episódios do Trampapo que têm tudo a ver com o tema.',
      },
      {
        type: 'text',
        text: '<a href="https://www.trampapo.com.br/episodes/efeitos-da-pandemia-o-novo-normal-do-mercado-de-trabalho" rel="nofollow">#13 Efeitos da pandemia: o novo normal do mercado de trabalho</a><br />Quantas vezes você já leu ou ouviu, desde o início da pandemia, que estamos no "novo normal"? Nesses tempos difíceis tivemos que encarar vários transtornos e transformações, e no mercado de trabalho não foi diferente! No episódio de estreia da 2ª temporada do Trampapo, Ana Paula Xongani e Ricardo Morais conversam com Renato Carvalho, Fundador do Movimento Officeless, sobre a adaptação das empresas que adotaram o home office, mudanças nas rotinas do trampo, a empatia de chefe com funcionário e os impactos sociais. E a saúde mental, como fica nisso tudo? Qual é o impacto positivo que a pandemia está trazendo para a diversidade no mercado? ',
      },
      {
        type: 'text',
        text: '<a href="https://www.trampapo.com.br/episodes/como-ser-um-profissional-de-alta-performance" rel="nofollow">#24 Como ser um profissional de alta performance</a><br />O mercado de trabalho mudou rapidamente e as qualidades que as empresas mais valorizam também mudaram. Quais são as características dos profissionais de alta performance, que conseguem promoções e propostas de trabalho com frequência? Neste episódio, Ana Paula Xongani recebe Lisiane Lemos, gerente de desenvolvimento de negócios, advogada, professora de MBA e conselheira; e Priscylla Haddad, gerente de desenvolvimento organizacional, talento e cultura na Coca-Cola FEMSA; que compartilham suas histórias de erros e acertos, dão dicas de como se qualificar gratuitamente e alcançar melhores resultados de forma saudável, sem ultrapassar os limites do corpo e da mente. Elas contam também que estar atento ao que acontece no mundo e aberto para desaprender e aprender é uma regra. ',
      },
      {
        type: 'text',
        text: '<a href="https://www.trampapo.com.br/episodes/afrofuturismo-e-antirracismo-no-mercado-de-trabalho" rel="nofollow">#25 Afrofuturismo e antirracismo no mercado de trabalho</a><br />Como será o amanhã? Será que depois de tantas desigualdades raciais, a sociedade vai continuar avançando às custas da desvalorização de certas vidas? Será que no futuro a tecnologia vai ser acessível para todos ou as soluções superinteligentes vão continuar sendo um privilégio de poucos grupos? Será que no futuro a população preta vai estar integrada aos desenvolvimentos científicos, tecnológicos e sociais? E se esse futuro pudesse, desde já, ser pensado também para e pela a comunidade negra? Neste episódio, Ana Paula Xongani e Ricardo Morais recebem o escritor afrofuturista, Ale Santos, e o CEO da Infopreta, Akin Abaz, para debater o atual cenário para pessoas negras no mercado de trabalho e as soluções para torná-lo antirracista a partir das perspectivas afrofuturistas. Quer entender mais sobre esse conceito e saber como podemos transformar o futuro e o presente do mercado de trabalho? ',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_16_thumb,
  },
  {
    id: 114,
    date: '23/04/2021',
    categories: ['DIVERSIDADE'],
    slug: 'o-que-e-capacitismo',
    title: 'Você sabe o que é capacitismo?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_15_thumb,
        alt: 'Você sabe o que é capacitismo?',
      },
      {
        type: 'text',
        text: '<strong>Você sabe o que é o capacitismo? Quer saber como lutar junto na causa pelos direitos das pessoas com deficiência? Quer conhecer 10 situações em que você provavelmente já reproduziu o capacitismo?</strong><br> Vamos lá!',
      },
      {
        type: 'text',
        text: 'O mercado de trabalho é cheio de exemplos de como a capacidade de alguém é avaliada. Para ser contratado, para ganhar a confiança dos líderes, para conseguir um aumento, uma promoção… Para todas essas situações, o profissional tem suas capacidades julgadas. O que parece ser bem justo, afinal, quem tem as melhores entregas e melhor desempenho, merece ter reconhecimento.',
      },
      {
        type: 'text',
        text: 'Mas o capacitismo não se trata disso. Não é dar a oportunidade de alguém ir lá, mostrar do que é capaz e, somente depois disso, avaliar suas capacidades. O problema é outro. O capacitismo é uma pré-concepção sobre as capacidades de alguém a julgar apenas o seu corpo, sua aparência ou sua deficiência. Mesmo antes de buscar entender a complexidade da deficiência, das limitações da pessoa com deficiência e sua realidade, o preconceito se apresenta e cria uma falsa visão sobre o que a PcD pode ou não pode e consegue ou não consegue fazer.',
      },
      {
        type: 'text',
        text: 'Para facilitar o entendimento, podemos dizer que o capacitismo está para as pessoas com deficiência assim como o machismo está para as mulheres, o racismo para os negros, a LBGTfobia para as pessoas da comunidade. O capacitismo é o conjunto de ações que discriminam as pessoas com deficiência, seja em termos de falta de acessibilidade ou em exclusão social. O termo sugere que há uma construção social de um corpo considerado “padrão”, “perfeito”, denominado como “normal”; e que pautado nisso, há uma subestimação da capacidade e valor das pessoas que carregam alguma atipicidade ou deficiências. Em resumo, esse é o conceito para o preconceito sofrido por esse grupo, que inclui inúmeros estereótipos negativos, estigmas, exclusões, negações de direitos, invisibilização e outras situações que muitas vezes são veladas ou naturalizadas na sociedade. O pensamento capacitista está tão enraizado e é tão estrutural que se torna inconsciente, sendo reproduzido por grande parte das pessoas que não têm deficiência e refletindo em negativas consequências para as PcD em termos de empregabilidade, educação e afetividade, por exemplo.',
      },
      {
        type: 'text',
        text: 'Pensando nisso, considerando que mesmo sem intenção todos podemos acabar sendo responsáveis por discriminar pessoas com deficiência, listamos dez situações em que você exerce o capacitismo. Vá de peito e mente abertos para aprender e se atentar aos seus comportamentos.',
      },
      {
        type: 'text',
        text: '<strong>É CAPACITISMO QUANDO...</strong>',
      },
      {
        type: 'text',
        text: '<strong> 1- Você é invasivo com a pessoa com deficiência</strong><br>Ter curiosidade não é ter passe livre para ser invasivo. Os questionamentos feitos para e sobre as pessoas com deficiência muitas vezes extrapolam aquilo que seria considerado permitido para pessoas sem deficiência. Independente de quem seja a pessoa, as perguntas que possam ser constrangedoras devem ser evitadas, certo? Não conhece? Tem curiosidade? Pesquise! Não saia por aí perguntando como a pessoa vai ao banheiro, como é isso ou aquilo, a menos que você tenha certeza que pode fazer esses questionamentos e tocar em assuntos que podem ser delicados.',
      },
      {
        type: 'text',
        text: '<strong>2- Você minimiza ou invalida a deficiência de alguém</strong><br> Você não está sendo legal quando você diz “que bom que nem parece que você tem deficiência”, “seu problema nem é tão ruim assim”, ou coisas do tipo. Por mais que a intenção por trás da fala possa ser boa, você pode estar invalidando uma questão da qual não tem conhecimento e/ou colocando a pessoa em posição de vulnerabilidade. Afinal, não é porque <span style="text-decoration: underline">você</span> enxerga aquela deficiência como um defeito  “<span style="text-decoration: underline"><i>tão ruim</i></span>” que a pessoa que tem aquela deficiência vai achar o mesmo. Possivelmente, ela apenas considera a deficiência uma de suas características mesmo, não um defeito.',
      },
      {
        type: 'text',
        text: '<strong>3- Você toca a pessoa sem permissão, achando que está “ajudando”</strong><br>“Meu corpo, minhas regras”. A regra vale para todos. Segurar a pessoa que tem deficiência sem permissão é exercer um controle sobre ela. O seu corpo, sendo dependente ou não de terceiros, não anula a necessidade de pedir consentimento para tocar. O corpo da pessoa com deficiência não é de domínio público.',
      },
      {
        type: 'text',
        text: '<strong>4- Você julga que a pessoa com deficiência não consegue fazer X coisa</strong><br>Como vimos mais acima, o capacitismo parte principalmente desse julgamento que une a ideia de capacidade ao “corpo capaz”. Logo, se você olha para alguém que tem deficiência e instantaneamente cria um julgamento sobre o que ela consegue ou não fazer, sem ao menos perguntar a ela ou conhecer suas dificuldades, você está julgando aquela pessoa como incapaz exclusivamente por causa da deficiência. Antes de tomar a ação de fazer algo por essa pessoa ou “ajudá-la”, pergunte se ela precisa mesmo da sua ajuda.',
      },
      {
        type: 'text',
        text: '<strong>5- Você infantiliza ou objetifica a pessoa com deficiência</strong><br>É comum que as pessoas que não têm deficiência infantilizem a pessoa com deficiência, achando que elas não têm a própria autonomia, vontade, personalidade. Ou ainda que são fofinhas, ingênuas, fracas, assexuadas e etc. Tratar a pessoa com deficiência como criança não mostra que você simpatiza com ela, e sim que você não enxerga ela. Enxerga só a deficiência. O mesmo acontece se você reduz a pessoa a essa deficiência. Se você se refere a ela como “o cadeirante”, “o autista”, “o cego”, e assim por diante.',
      },
      {
        type: 'text',
        text: '<strong>6- Você acredita que as pessoas com deficiência “tiram vantagens”</strong><br>Quem nunca ouviu alguém falando que as cotas e os benefícios sociais são formas de favorecimento de alguns grupos? E que essas ajudas são custos para o governo e tiram oportunidades dos demais?  Mas se você ainda acredita nisso, te convidamos a revisitar esse pensamento. No caso das pessoas com deficiência, há uma necessidade essencial de oferecer suporte em diferentes âmbitos, incluindo cuidados de saúde, financeiro e estímulo ao acesso ao mercado de trabalho e à educação. Repare que mesmo com a Lei de Cotas, que nasceu há quase 30 anos atrás para aumentar a entrada de pessoas com deficiência no mercado de trabalho, segundo os dados Relação Anual de Informações Sociais, apenas cerca de 1% delas têm empregos formais, com carteira assinada. O que seria se não tivesse nem mesmo essa lei? O que seria dos outros que não conseguem um emprego se não tivessem um auxílio financeiro? O que acontece com aqueles profissionais com deficiência que não conseguem nem emprego nem o benefício? Não existem vantagens. Existem medidas para tentar tornar a balança menos injusta. Ainda é preciso ampliar os recursos.',
      },
      {
        type: 'text',
        text: '<strong>7- Você vê a deficiência ou a pessoa com deficiência como um “fardo”</strong><br>O olhar de piedade pode ser um dos mais capacitistas que você pode lançar, seja para a pessoa com deficiência, para a mãe, pai, parceiro ou parceira dela. Você não precisa dizer que vai torcer pra que a pessoa melhore, que ela consiga superar, que ela consiga seguir a vida apesar da deficiência. O olhar piedoso só existe porque acredita-se que a posição que a pessoa com deficiência ocupa é inferior, que é uma vida infeliz e que só lhe resta lamentar por essa situação.',
      },
      {
        type: 'text',
        text: '<strong>8- Você contrata a pessoa com deficiência apenas para cumprir cota</strong><br>Se você está em posição de contratar alguém para o seu negócio próprio ou para a empresa que você trabalha, mas nunca considerou trazer uma pessoa com deficiência para somar nas entregas, você está sendo excludente. Além da inclusão proporcionar ganhos e riqueza de ideias e produtividade, ela também é o caminho para integrar as PcD à sociedade, oferecer meios para autonomia financeira e maior participação social. Atente-se para incluir de verdade quando receber esses profissionais. Não reserve uma vaga com atividades de baixa complexidade para pessoas com deficiência para apenas cumprir a cota. Encare elas como quaisquer outros profissionais, porque de fato são.',
      },
      {
        type: 'text',
        text: '<strong>9- Você acha que deficiência ou transtornos são xingamentos razoáveis </strong><br>Infelizmente ainda é bastante corriqueiro ouvir por aí na boca do povo “xingamentos” com os nomes de algumas deficiências ou doenças. “Nossa, fulano parece até retardado”. “Seu esquisito, parece até autista”. “Você parece louco, esquizo. Coisa de esquizofrênico”. “Não tá vendo não, seu cego?”. “Tá surda? Lave esses ouvidos”. “Fulano deu uma de joão-sem-braço , é preguiçoso”. Todas essas expressões e frases são exemplos de como o capacitismo se dá. Mesmo que não sejam usadas para ofender as pessoas com deficiência, elas reforçam estigmas sobre elas.<br>Essas condições foram diagnosticadas nas pessoas com deficiência. Sendo assim, atrelar esses diagnósticos a algo negativo para tentar ofender alguém é uma atitude altamente capacitista.',
      },
      {
        type: 'text',
        text: '<strong>10- Você não se preocupa com acessibilidade</strong><br>Só o capacitismo explica a falta de investimento e preocupação com acessibilidade. Toda a nossa estrutura é feita para não incluir as pessoas com deficiência. Podemos observar problemas de acessibilidade em serviços essenciais e também de lazer. Faltam rampas, elevadores, carros adaptados, intérpretes de libras, ensino de libras, ensino de braille, sinalizações táteis, visuais e sonoras adequadas, calçadas e vias públicas regulares, recursos de acessibilidade digital… A acessibilidade ainda é vista como um “luxo”, um “plus”. Mas na verdade ela é o básico pra uma grande parte da população. Sem ela, o acesso é negado. O direito de ir, vir e consumir passa a ser um privilégio que não está ao alcance de todos. A liberdade é limitada.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST 🎧</strong><br><strong>#27 PcD e o Mito da incapacidade</strong><br>O que define capacidade no mercado de trabalho? Para muitos, a única resposta deveria ser o currículo, mas não podemos deixar de lembrar que o preconceito ainda limita o desenvolvimento da sociedade. É fácil perceber que as pessoas com deficiência estão sendo deixadas de lado quando elas não conseguem acessar fisicamente certos espaços e também quando notamos que não as temos em nosso entorno nas empresas e círculos sociais, mas precisamos também treinar o olhar para perceber que as pessoas com deficiência não são incapazes. A capacidade de um profissional não pode ser atrelada a uma deficiência ou a um corpo que é considerado “normal”. Pessoas são pessoas e cada uma tem alguma dificuldade, mas para que todos tenham o mesmo desempenho, é preciso que as condições e oportunidades sejam as mesmas para todos. Por isso, neste episódio, Ana Paula Xongani convida Tatiana Hennemann, gerente de Supply Chain na Natura, uma empresa exemplar no quesito inclusão, e Beto Maia, que é desenvolvedor, paratleta e criador de conteúdo, que comenta suas experiências e seus empregos sendo uma pessoa com deficiência. Quer fazer parte desta conversa que abre oportunidades de inclusão e respeito? Corra para ouvir o episódio! ',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.tiktok.com/@betomaiafilho?">Beto Maia</a> | <a href="https://www.instagram.com/betomaiafilho/">@betomaiafilho</a></strong><br> Beto é influenciador digital e criador de conteúdo. Começou a criar vídeos no Tiktok porque sentia a baixa representatividade nas redes para pessoas com deficiência. Em seus conteúdos, ele fala sobre suas experiências com bom humor, mas também traz reflexões importantes sobre capacitismo. Além disso, Beto é paratleta de vôlei sentado, cantor e desenvolvedor de soluções web focadas em acessibilidade, para tornar a internet um ambiente mais democrático e disponível a todos. <strong>Suas dicas de conteúdos foram:</strong><br><br> <strong>Capacitismo: O mito da capacidade, Livro por Victor Di Marco</strong><br>O livro de cabeceira do Beto traz um apanhado de palavras e de situações que você tem que saber acerca do tema. Unindo conceitos junto de suas memórias, o autor explora até onde o mito do capacitismo adentra na vida de uma pessoa com deficiência e busca achar em si respostas que por tanto tempo foram apagadas.  ',
        image: content_15_guest_01,
        alt: 'Beto Maia',
      },
      {
        type: 'text',
        text: '<strong>Crip Camp: Revolução pela Inclusão | Filme Netflix </strong><br>É sobre a história de como se iniciou o movimento pela inclusão e direitos das pessoas com deficiência nos Estados Unidos. Um acampamento de verão para adolescentes com deficiência motiva um grupo de jovens a criar um movimento em busca de novos caminhos para um mundo com mais igualdade.',
      },
      {
        type: 'text',
        text: '<strong>Fuja | Filme Netflix </strong><br>Contra o “crip fake”, que é a prática de colocar pessoas sem deficiência para interpretar pessoas com deficiência nas produções audiovisuais, o Beto recomendou esse filme que é estrelado por uma atriz que realmente tem deficiência. Como ele citou, dificilmente os atores PcDs são incluídos nesse setor.',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.instagram.com/pcdvale/"> Vale PcD | Coletivo  </a></strong><br>Grupo do qual o Beto faz parte e que traz a interseccionalidade - pessoas com deficiência e também da comunidade LGBTIA+. Entre as missões do grupo, estão:<br>🏳️‍🌈 Mapear de locais LGBTQI+ amigáveis com foco na acessibilidade;<br>♿ Protagonizar PcD LGBTIA+;<br>🌈 Incentivar inclusão e visibilidade para todes.<br>',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.linkedin.com/in/tatiana-ribeiro-hennemann-a8630230/">Tatiana Ribeiro Hennemann</a></strong> é gerente de Supply Chain na Natura, sendo responsável por liderar o maior Centro de Distribuição Logístico da marca, que também é um exemplo de práticas inclusivas, tendo 21% de seus cargos preenchidos por pessoas com deficiência. <strong>Suas dicas no episódio #27 foram:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Amor no Espectro | Série Netflix</strong><br>Um reality show que mostra a experiência de pessoas com autismo em busca de parceiros românticos. Para ela, a série é muito importante pra mostrar a singularidade de cada indivíduo, mostrando que as deficiências não são todas iguais nem devem limitar ninguém.<br><strong>O primeiro da classe | Filme</strong><br> O filme é voltado aos preconceitos sofridos pelo personagem principal, que tem Síndrome de Tourette, nas suas experiências dentro e fora de casa. <strong><br>Um Antropólogo em Marte, Livro por Oliver Sacks</strong><br>Nesse livro, o autor traz 7 estudos sobre condições neurológicas que parecem paradoxais. Para ela, ele é cativante porque derruba mitos sobre a capacidade de algumas pessoas com deficiência.',
        image: content_15_guest_02,
        alt: 'Tatiana Ribeiro Hennemann',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani: </strong><br><strong><a href="https://www.tiktok.com/@betomaiafilho?">Beto Maia | Criador de conteúdo</a></strong><br>Nossa host destacou o convidado da vez, Beto Maia, como uma das referências de pessoas com deficiência que criam conteúdos sobre acessibilidade e inclusão nas redes.',
      },
      {
        type: 'text',
        text: '<strong><a href="https://www.youtube.com/watch?v=-bgA4r-Qnbg"> Inclusão e acessibilidade: Flávio Arruda at TEDxFortaleza</a></strong><br>FLÁVIO ARRUDA, Publicitário e especialista em gestão de trânsito e transporte urbano. Caçula de sete irmãos e irmãs, aos 21 anos sofreu um acidente de carro que o deixou tetraplégico. Amante do audiovisual, da literatura, da cultura e das lutas populares, é pesquisador do campo de estudos das deficiências, autor de trabalhos sobre publicidade inclusiva e acessibilidade na política de mobilidade urbana. Técnico com atuação no setor de transporte público, usa a própria experiência de vida no trabalho e busca contribuir para que Fortaleza seja uma cidade cada vez mais acessível, inclusiva e que respeite a diversidade de sua gente.',
      },
      {
        type: 'text',
        text: '<strong>71 leões: Uma história sobre maternidade, dor e renascimento, Livro por Lau Patrón</strong><br> Xongani disse que esse livro foi um dos passos iniciais para ela passar a buscar informações acerca das pautas das pessoas com deficiência. Veja o resumo: ‘Este livro é um diário intenso e sincero dos setenta e um dias que Lau morou no hospital, muitos ao lado de seu filho em coma em um box de UTI, esperando a vida ou a morte. Um relato comovente e poderoso onde Lau nos dá uma dimensão real das pessoas, das emoções fluidas, da não existência de heróis. Do amor, como fonte de escolhas. Do olhar para a dor, sem medo, e com alguma ternura. De não matar leões por dia e, sim, recebê-los com reverência. Um por dia, um a mais na matilha. Para avançar. Para ir avante. “Agora sei: ninguém nunca está pronto pra maternidade. Medo é parte da viagem. Ser mãe é construir um foguete em pleno voo. Este é um livro sobre a coragem”.’',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_15_thumb,
  },
  {
    id: 113,
    date: '16/04/2021',
    categories: ['DIVERSIDADE'],
    slug: 'ajude-seu-eu-de-amanha',
    title: 'Ajude seu EU de amanhã',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_14_thumb,
        alt: 'Ajude seu EU de amanhã',
      },
      {
        type: 'text',
        text: '<strong>Você já ouviu falar em idadismo, etarismo, ageísmo ou “velhofobia”?</strong>',
      },
      {
        type: 'text',
        text: 'Todos esses nomes podem ser usados para o processo de criar estereótipos ou discriminar uma pessoa ou grupos de pessoas pela idade. Esse preconceito, que afeta principalmente quem chega às idades mais avançadas, ainda é pouco debatido e pode ser notado em atitudes e pensamentos discriminatórios, mas também nas políticas institucionais que excluem ou limitam a participação dos idosos e também de quem vai se aproximando da terceira idade em ocasiões sociais e no <strong>mercado de trabalho</strong>.',
      },
      {
        type: 'text',
        text: 'A discriminação contra os mais velhos pode ser considerada uma marginalização. Junto do envelhecimento, uma série de <strong>estigmas e tabus</strong> passam a ser associados à figura dos mais velhos em nossos meios de comunicação e na população de um modo geral. Uma pesquisa da Organização Mundial da Saúde (OMS), em 2016, ouviu 83 mil pessoas em 57 países e mostrou que 60% dos entrevistados possuíam visão negativa sobre o envelhecimento. Podemos listar, dentre esses estereótipos, características negativas como serem <strong>lentos, fracos, desatualizados e desinformados</strong>, por exemplo. E essas falsas percepções geram prejuízos aos afetados. A American Psychological Association afirma que o preconceito etário é uma questão a ser tratada da mesma forma que a discriminação baseada em gênero, etnia ou orientação sexual, aumentando a necessidade de criar consciência pública sobre os problemas que ele pode causar. ',
      },
      {
        type: 'text',
        text: 'Pensando nisso, <strong>coloque-se no “seu lugar”</strong>. Não é necessário ficar apenas no exercício empático. Considere que a ordem natural da vida vai te colocar nessa posição quando envelhecer. O preconceito em relação à idade gera exclusão, baixa autoestima, problemas de saúde e abuso. O etarismo é uma construção social e por isso cabe a nós combatê-lo, senão, além de endossar as violências praticadas contra os mais velhos, no futuro seremos as vítimas desse comportamento, podendo impactar negativamente nossa saúde, desempenho cognitivo e qualidade de vida.<br /><strong>No campo do trabalho</strong>, por exemplo, essa discriminação por idade pode gerar disparidades salariais, assédios morais, falta de apoio, problemas para conseguir promoções, mudar de carreira e ter maior dificuldade de encontrar emprego. De acordo com a US Equal Opportunity Commission, quase ¼ das reclamações apresentadas por trabalhadores estão relacionadas à discriminação com base na idade. Já um estudo da organização AARP relata quase 65% dos trabalhadores dizem que sofreram discriminação com base na idade no trabalho e 58% dos entrevistados afirmaram que o etarismo se tornou mais aparente para eles pós os 50 anos. Mas podemos observar aqui no Brasil, que aos 30 anos, já estamos falando de velhice. A nossa população tem medo de envelhecer porque sempre tivemos a cultura da juventude, do corpo jovem que é considerado belo e saudável. Então, além da discriminação dos mais velhos, a “velhofobia” inclui também esse pânico com relação ao envelhecimento, como se fosse o vencimento, o fim da validade de uma pessoa. O valor para a sociedade é perdido. Os mais velhos são considerados descartáveis, um custo para a economia e invasores dos espaços dos mais jovens,  não sendo por acaso que somos o país que mais faz cirurgias plásticas no mundo todo.',
      },
      {
        type: 'text',
        text: 'Fazendo um <strong>recorte por gênero</strong>, também percebemos que as mulheres são mais afetadas por esse mecanismo de opressão, afinal, se ser mulher já é um um desafio a mais, ser velha é um lástima diante da sociedade, podendo ser ainda mais agravado se a mulher foi negra, LGBTI+ ou parte de algum outro grupo minorizado. A pressão estética é somada como um problema enfrentado principalmente pelas mulheres. A indústria antienvelhecimento alimenta essa pressão por interesses financeiros e estigmatiza os processos naturais do envelhecimento, que é o surgimento dos cabelos branco e grisalhos e de rugas, e empurra ainda mais pensamentos de baixa autoestima, falta de autoaceitação, abalos psicológicos, sociais ou físicos. Além disso, há uma cobrança em uma série de expressões e imposições. “Você deve se comportar de acordo com sua idade”. “Você já passou da idade de usar essa roupa”. “Sua idade não permite que você faça isso ou seja assim”. E, no fim, de fato a mensagem é recebida como uma verdade. De tanto ouvi-las, elas acreditam, se culpam e sem reprimem.',
      },
      {
        type: 'text',
        text: 'Recentemente, tivemos uma amostra muito esclarecedora de como o idadismo funciona no nosso país. <strong>Diante da pandemia da COVID-19</strong>, em que as pessoas mais idosas são as mais vulneráveis com relação aos riscos da doença, pudemos observar incontáveis discursos discriminatórios e desumanos, considerando essa população como um peso para a sociedade. Políticos dos mais baixos aos mais altos escalões, empresários, comerciantes e muitos jovens opinaram sobre a iminente morte dos mais velhos como um pretexto para não tomarmos medidas preventivas contra a expansão do contágio do vírus. Que “mais cedo ou mais tarde” eles vão partir, mesmo. Esse comportamento escancara esses preconceitos e violências. As pessoas mais velhas ficam sujeitas a exposições que afetam sua moral e saúde. Não são prioridade para receber tratamentos e leitos, além de ficarem mais fragilizados durante o isolamento social.',
      },
      {
        type: 'text',
        text: 'Considerando tudo isso, fica nítida a necessidade de fazermos algo HOJE. Tanto por aqueles que já são idosos, quanto por nós mesmos, que no futuro estaremos em posição igual. Temos que encarar o idadismo como uma <strong>questão de respeito e ética</strong>, mas também de <strong>qualidade de vida e saúde pública</strong>. Por meio de atividades intergeracionais, precisamos conscientizar e combater esse  preconceito. Precisamos de uma transformação na maneira como enxergamos as pessoas mais velhas e suas capacidades, mas isso vai requerer muito atitudes proativas. É preciso questionar os padrões e imposições estéticos e de beleza atrelados aos mais jovens.  É preciso conversar, incluir e integrar os mais velhos nos ambientes de trabalho, criminalizar o etarismo e se posicionar contra qualquer manifestação discriminatória.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST</strong> 🎧<br /><strong>#26 50+ no mercado: talentos não envelhecem</strong><br />Experientes, conectados e conhecem a solução para boa parte dos problemas. Os profissionais maduros têm praticamente todas as qualidades que o mercado deseja. Então, por que a idade seria um problema? Neste Trampapo, Ana Paula Xongani e Ricardo Morais recebem Amanda Fernandes, coordenadora de RH da CashMe, e Layla Vallias, mercadóloga e co-fundadora do Guia Longevidade & Hype50+, para uma conversa sobre o mercado de trabalho para os profissionais com mais de 50 anos. Com o aumento da população idosa, as mudanças previdenciárias e da sociedade, é emergencial pensar em soluções para acabar com os preconceitos etários e com a cultura que exclui e invalida os mais experientes das empresas. Como podemos incentivar a permanência e a entrada dos idosos no mercado? Como eles podem contribuir para a saúde e inovação dos negócios? Quer fazer parte dessa conscientização e movimento? Ouça aqui mesmo, no site do Trampapo, ou nas principais plataformas de podcast.',
      },
      {
        type: 'text',
        text: '<a href="https://www.linkedin.com/in/laylavallias/" rel="nofollow"><strong>Layla Vallias</strong></a>:<br />Layla é uma empreendedora da indústria prateada, está na lista Forbes Under 30 de 2021, fundou o  Hype50+ em 2016, uma consultoria de marketing pioneira especializada no consumidor sênior e da Janno, startup que está criando diversas soluções para os desafios da longevidade. Foi uma das idealizadoras e coordenadoras do Tsunami60+, maior estudo sobre Economia Prateada e Raio-X do público maduro no Brasil, com quase 2.500 entrevistados entre 55 e 90 anos de idade, além de diretora do Aging2.0 São Paulo, organização de apoio a empreendedores com soluções para o envelhecimento em mais de 20 países. Mercadóloga de formação, com especialização em marketing digital pela Universidade de Nova York, trabalhou também com desenvolvimento de produto na Endeavor Brasil.',
        image: content_14_guest_01,
        alt: 'Layla Vallias',
      },
      {
        type: 'text',
        text: '<strong>Sua dica no episódio #26 foi</strong>: <br /><a href="https://www.netflix.com/br/title/80017537" rel="nofollow"><strong>Grace and Frankie | Site Oficial Netflix</strong></a><br />“A minha dica para quem tem Netflix, é assistir Grace and Frankie, gente, que é o melhor seriado, assim, você vai quebrar todos os preconceitos, todos os tabus, quando você ver duas mulheres acima de 80 anos, vendendo vibrador, assim, você quebra tudo, tudo cai por terra. Então assim, assistam.<br />Quem não tem Netflix, sigam a Rita Lee no Instagram, que já é também, Vera Holtz, que é maravilhosa. Então assim, só vai, gente, sigam pessoas mais velhas e inspiradoras, que vão te dar, te dar gás para chegar lá bem.”',
      },
      {
        type: 'text',
        text: '<a href="https://www.linkedin.com/in/amanda-fernandes-ela-ella-she-b00ab998/" rel="nofollow"><strong>Amanda Fernandes</strong></a>:<br />A atual Coordenadora de Recursos Humanos da CashMe, a fintech do Grupo Cyrela, tem experiência na gestão e em processos de Recrutamento e Seleção de diversas marcas; atuando como Business Partner, apoiando os gestores nas tomadas de decisões e prestando um papel consultivo; desenvolvendo Planos de Treinamentos; acompanhamento do período de experiência dos novos colaboradores; conduzindo ciclos de avaliação de desempenho, de desenvolvimento organizacional, endomarketing e comunicação interna. <strong>Sua dica no episódio #26 foi</strong>:',
        image: content_14_guest_02,
        alt: 'Amanda Fernandes',
      },
      {
        type: 'text',
        text: '<strong>CBN Gerações Podcast</strong><br />“Por coincidência depois eu me dei conta de que ela estaria aqui entre nós, é o podcast da CBN Gerações, então acho que é muito legal não só a gente falar do tema em si, de hoje, mas de gerações de uma forma geral, por que não tem como falar só de gente mais velha, só de gente mais nova, a gente tem que falar de todas as gerações no mesmo espaço. Então acho que são histórias e trajetórias legais de aprendizados, de profissionais, enfim, de diferentes idades. Então deixo essa aí a minha dica para vocês.”',
      },
      {
        type: 'text',
        text: '<strong>Veja as dicas de conteúdos que os hosts deixaram</strong>:',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais</strong>: <br /><a href="http://youtube.com/channel/UCPaLH-5sjIoxe6Cm1llYtjA" rel="nofollow"><strong>Avós da Razão | Canal do Youtube</strong></a><br />“Tem um canal que tem no youtube, “Avós da Razão”, é um programa apresentado por três mulheres, a Gilda de 78, a Sandra de 83 e a Helena de 92. Elas respondem perguntas enviadas pelos internautas, assim através de vídeos mandados por WhatsApp, e elas colocam sempre no canal delas no YouTube. E aí tem toda sorte de opinião, conselho, muito engraçado e perspicaz, porque elas contam com a história delas, as três amigas se conhecem há mais de 50 anos, então elas colocam, tiram esse estereótipo da vovó e você tem aquele conselho de alguém com muita experiência. É fenomenal, é fenomenal, assim, vale à pena.”',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani</strong>: <br /><a href="https://youtu.be/hJ8MB05jOOs" rel="nofollow"><strong>ENVELHECER | Por Maíra Lemos</strong></a><br />“Vou indicar também um vídeo do YouTube, da Maíra Lemos, ela traz várias reflexões sobre os idosos e sobre essa fase da vida.”<br /><a href="https://www.instagram.com/crismendoncacris/" rel="nofollow"><strong>Cris Mendonça | @crismendoncacris</strong></a><br />“E quero indicar também minha bloguerinha preferida, Cris, minha mãe, que como eu disse, está no Instagram, e também está arrasando muito mais do que eu no Tik Tok. Para a gente se aproximar dessas referências, desse cotidiano de pessoas sexagenárias, como eu disse, é o jeito que ela gosta de ser lembrada. É muito bom para a gente se aproximar mesmo, gerar afeto, empatia e levar para o mercado de trabalho.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_14_thumb,
  },
  {
    id: 112,
    date: '09/04/2021',
    categories: ['DIVERSIDADE'],
    slug: 'comece-por-voce-antirracismo',
    title: 'Comece por você: ambientes de trabalho antirracistas',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_13_thumb,
        alt: 'Comece por você: ambientes de trabalho antirracistas',
      },
      {
        type: 'text',
        text: 'No episódio #25 do Trampapo a gente abordou um tema mais do que necessário. Com uma ótica afrofuturista, discutimos como podemos fazer um mercado de trabalho antirracista. Ou seja, um mercado de trabalho que seja igualmente aberto e propício para a população negra, que ofereça oportunidades de entrada, qualificação, crescimento, boa remuneração e relacionamentos tanto quanto oferece aos grupos que mais acessam e controlam as empresas: homens cis, brancos e heterossexuais.',
      },
      {
        type: 'text',
        text: 'Aí talvez você se questione se isso citado acima é verdade. Afinal, talvez você seja uma mulher e esteja bem empregada. Talvez você seja uma pessoa não hétero e não cisgênera e esteja em um bom trabalho. Talvez você seja uma pessoa negra que também tem um bom emprego ou, talvez, você apenas não concorda, pois já trabalhou com várias pessoas diferentes ao longo da sua vida.',
      },
      {
        type: 'text',
        text: 'Mas o assunto aqui é outro. O ponto em discussão é sobre o <strong>geral</strong>. É olhar para o todo e pensar “por que a população negra, que é maioria em número de pessoas aqui no Brasil, ocupa menos espaço no mercado de trabalho? Por que ganham menos? Por que trabalham mais em posições subalternas? Por que são a maioria dos analfabetos, a maioria morando nas ruas, a maioria na pobreza e no encarceramento?”',
      },
      {
        type: 'text',
        text: 'Não estamos falando da sua experiência individual nem da experiência do colega ao lado, e sim de milhões de pessoas que sequer vão ter a oportunidade de adentrar uma empresa no mundo corporativo. Que sequer vão frequentar uma faculdade e, mesmo que frequente, vai ter que pagar um dobrado para ter uma posição elevada no mercado de trabalho.',
      },
      {
        type: 'text',
        text: 'Pensando nisso, é urgente a importância de construirmos um mercado de trabalho antirracista - que é justamente ter atitudes que possam colocar as pessoas negras em pé de igualdade. Logo, se as empresas são feitas por pessoas, são elas que, uma a uma, precisam rever seus conceitos, se aplicar em aprender sobre as lutas etnico raciais e exercer o papel de aliadas na causa antirracismo. É claro também que é papel das empresas se posicionarem contra o racismo e promoverem medidas afirmativas para tornar seus ambientes mais diversos e a sociedade mais democrática, mas hoje o papo é sobre o que está ao alcance de cada indivíduo. Vamos lá?!',
      },
      {
        type: 'text',
        text: '<strong>Comece por você: ambientes de trabalho antirracistas</strong>',
      },
      {
        type: 'text',
        text: '<strong>Reconheça seus privilégios</strong><br />Perante à nossa constituição, nós somos todos iguais em direitos e deveres. Mas se pensarmos melhor sobre esse lance dos “direitos”, podemos ver que alguns deles são privilégios. A partir do momento que um direito só está ao alcance de alguns, ele passa a ser um privilégio de quem o detém. O direito à moradia, saúde, alimentação e ao trabalho, todos deveriam ter, mas na prática não é bem assim. Por isso, principalmente se você for branco, é importante que as pessoas façam a reflexão do que elas têm que as outras pessoas não têm. E também sobre o que elas passam/passaram ao longo da vida, e que os outros não tiveram a mesma oportunidade. Ser branco não significa que tudo vai ser fácil pra você, nem que você nunca vai ficar desempregado, nunca vai ser discriminado nem passar por apuros. Mas significa que sua cor de pele e seus traços, que não são negróides, não vão ser a <strong>razão</strong> dessas dificuldades.',
      },
      {
        type: 'text',
        text: 'Uma boa forma de fazer essas reflexões é a partir do <strong>jogo do privilégio branco</strong>. Esse jogo ilustra como funciona a desigualdade racial no país.<br />“Imagine um jogo onde os próprios participantes são peças que se movem de acordo com suas histórias de vida. Cada passo para frente ou para trás reflete a dinâmica racial da nossa sociedade. Até onde uma pessoa consegue avançar? O Instituto Identidades do Brasil (ID_BR) criou a dinâmica Jogo do Privilégio Branco para mostrar como a desigualdade racial é uma desvantagem em todos os aspectos da vida de uma pessoa negra. O resultado da dinâmica é a tomada de consciência e o convite para todos dizerem Sim à Igualdade Racial e mudar essa realidade.”. <a href="https://www.youtube.com/watch?v=MuOE3IJZoZU" target="_blank">Clique aqui e assista ao jogo</a> do ID_BR.',
      },
      {
        type: 'text',
        text: '<strong>Se posicione</strong><br />Você já reparou que as pessoas que menos se posicionam são as mais privilegiadas? Até no convívio com os mais íntimos, quem não sofre opressões costuma dizer que não gosta de entrar em polêmicas e que opinião política “cada um tem a sua”. Talvez seja um pouco de medo de errar. Quando você se posiciona, você dá margem para o erro e isso é ruim, nosso ego não gosta. Mas como você pode ajudar os outros e também ser uma pessoa melhor se você não se permitir errar e ficar sempre em silêncio? Se isentar diante de violências e opressões é compactuar com elas. Quem está sofrendo isso na pele, que talvez sejam pessoas que você ama, precisam da sua ajuda. O seu silêncio, seja por medo de se posicionar ou por desinteresse, consente com a agressão. Mas quando você se posiciona, você gera uma reação em cadeia, você inspira outros a fazerem igual, você influencia outras pessoas e também fortalece quem precisa do seu apoio, além de ensinar aquele que cometeu a opressão. Posicione-se, mesmo que o beneficiado não seja diretamente você.',
      },
      {
        type: 'text',
        text: '<strong>Consuma e apoie conteúdos e obras de pessoas negras</strong><br />Pesquise sobre as questões raciais do Brasil e do mundo. Aprenda através do que as pessoas negras dispõem. Há um perigo quando nos informamos apenas a partir da intelectualidade branca e eurocêntrica, que é o perigo da história única. É importante entender sobre o racismo sob a perspectiva de quem o sofre e quem o quer exterminado, e não de quem o criou e que, por algum motivo, possa se beneficiar desse mecanismo. O protagonismo sobre a negritude deve ser dos negros, portanto, consuma livros, filmes, artigos e conteúdos no geral elaborados por vozes negras.',
      },
      {
        type: 'text',
        text: '<strong>Não acredite em “racismo reverso”</strong><br />O racismo não se trata exclusivamente da injúria racial. Não é apenas o ato de “ofender” a cor de alguém que define o racismo, porque ele, na verdade, é um mecanismo que faz parte da estrutura da sociedade, e não um ato isolado. O racismo é fruto de um mito criado sobre as pessoas negras que construiu também a atribuição de diversas características negativas para essa população como um todo. Por muito tempo, existiu o racismo científico, que inclusive era uma tentativa de provar a inferioridade biológica dessas pessoas em comparação às brancas, e até hoje há quem acredite que os negros são uma “classe amaldiçoada”, “suja”, “violenta”, “feia”, “incapaz” e etc. E todas essas crenças definiram de fato diferenças entre esses grupos na sociedade. Como citamos no começo do texto, é a população preta a maioria nas cadeiras, nas ruas, nas filas de desemprego, nas favelas, em subempregos, na informalidade e em várias situações degradantes. As estatísticas não mentem. Quando a cor é preta, as chances de estar em uma dessas tristes situações são maiores.',
      },
      {
        type: 'text',
        text: 'Considerando isso, você deve compreender que apesar de algumas vezes as pessoas brancas receberem ofensas relacionadas ao tom de pele e aos traços físicos, essas ofensas nunca as vão colocar nessas posições. Brancos não deixam de conseguir um emprego por serem brancos. Brancos não vão ser perseguidos por seguranças por serem brancos. Brancos não precisam raspar o cabelo bem curtinho pra serem aceitos em uma vaga. Brancos não são desacreditados como se não soubessem de um assunto só por serem brancos. Entende? Não vai ser uma situação que houve o xingamento “branquelo azedo” que vai fazer da pessoa branca alguém oprimida e marginalizada na sociedade. Pare de comparar o racismo estrutural com uma ofensa isolada. Não são situações iguais.',
      },
      {
        type: 'text',
        text: '<strong>Aceite que você tem atitudes racistas e mude-as</strong><br />O racismo encontra-se internalizado e enraizado na sociedade. Entenda que desde a infância todos nós recebemos estímulos racistas e também criamos julgamentos a partir desses estímulos. Portanto, ao longo da vida, você precisa buscar desconstruir esses pensamentos inconscientes. É uma tarefa a ser feita ativamente, e que se não for trabalhada te manterá como alguém que reproduz o racismo. Aceite e mude suas ações. Ouça quando alguém indica racismo em suas atitudes, se desculpe, aprenda e não repita.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST 🎧</strong><br /><strong>#25 Afrofuturismo e antirracismo no mercado de trabalho</strong><br/>Como será o amanhã? Será que depois de tantas desigualdades raciais, a sociedade vai continuar avançando às custas da desvalorização de certas vidas? Será que no futuro a tecnologia vai ser acessível para todos ou as soluções superinteligentes vão continuar sendo um privilégio de poucos grupos? Será que no futuro a população preta vai estar integrada aos desenvolvimentos científicos, tecnológicos e sociais? E se esse futuro pudesse, desde já, ser pensado também para e pela a comunidade negra? Neste episódio, <strong>Ana Paula Xongani e Ricardo Morais recebem o escritor afrofuturista, Ale Santos, e o CEO da Infopreta, Akin Abaz</strong>, para debater o atual cenário para pessoas negras no mercado de trabalho e as soluções para torná-lo antirracista a partir das perspectivas afrofuturistas. Quer entender mais sobre esse conceito e saber como podemos transformar o futuro e o presente do mercado de trabalho? Ouça já esse episódio!',
      },
      {
        type: 'text',
        text: '<strong>Veja as dicas de conteúdos que os participantes deixaram:</strong>',
      },
      {
        type: 'text',
        text: `<a rel="no-follow" href="https://www.linkedin.com/in/savagefiction/" target="_blank"><strong>Ale Santos</strong></a> | <a rel="no-follow" href="https://www.instagram.com/savagefiction/" target="_blank">@savagefiction</a> O Ale é um dos principais nomes do afrofuturismo brasileiro. Ele construiu sua reputação nas redes sociais com o Savage Fiction, contando sagas dos descendentes de africanos na história do Brasil que não aparecem nos livros escolares. A Savage Fiction se tornou uma consultoria de Entretenimento Estratégico de produção de cursos e palestras sobre Gamificação e Storytelling Interativo, onde o Ale é consultor. Ele também é autor do livro finalista do prêmio Jabuti, “Rastros de resistência: Histórias de luta e liberdade do povo negro”, lançado em 2019. Além disso, ele é host do podcast Infiltrados no Cast, é professor de entretenimento fantástico na ESPM, escreve para a Ponte Jornalismo, Superinteressante e The Intercept.  É o Game Master e Designer da gamificação do maior evento de TI da América Latina, o IT Forum, trabalhou com a Storytellers Brand'Fiction, o primeiro escritório de Innovative Storytelling do Brasil.<br /><strong>Suas dicas extras no EP#25 foram:</strong>`,
        image: content_13_guest_01,
        alt: 'Ale Santos',
      },
      {
        type: 'text',
        text: '<strong>- Edição Afrofuturo da revista FORBES </strong><br />“A próxima edição da Forbes é uma edição especial sobre afrofuturo, vocês que estão ouvindo esse podcast, vão pegar a lista de muita gente preta desenvolvendo tecnologia e inovação na educação, no mercado financeiro, na comunicação, na música, uma edição feita por pessoas negras, desde o ilustrador, até os articulistas, eu escrevi ali também na introdução da revista, e fui do Conselho Consultivo, muito bacana, uma edição inovadora assim, uma edição que vai, que a gente espera que vire tendência no mercado, por que ela foi, é muito disruptiva, cara, de estar, de ter esse envolvimento e ter de entregar toda essa narrativa para a mão de tantas pessoas negras numa revista de tamanho nome assim, de tão importância para o mercado que é a Forbes, é uma construção que já está rolando há vários meses, eu acho que desde o ano passado, com várias pessoas pretas lá dentro conversando, e os editores abraçando todas essas ideias. Essa é uma das minhas dicas”',
      },
      {
        type: 'text',
        text: '<strong>Ale emendou mais dicas: </strong> “Eu já falei aí do Criando Dion, que é uma série muito bacana aí, de superpoderes, e assim, para crianças, que eu gosto para caramba, eu gosto do Twilight Zone do Jordan Peele por que eu também tenho essa sacada que eu tenho esse meu lado nerd de literatura assim, de ficção científica. E Twilight Zone é clássico da década de 60, de ficção científica, mas foi reimaginado pelo Jordan Peele que é o cara que escreveu Corra, e esse também que é fantástico assim, eu amo tudo que ele faz, é um cara que eu me inspiro bastante como escritor assim, e um álbum amarelo do Emicida também, meu grande parceiro, amigo que eu amo e tudo que ele faz. E um livro que eu preciso indicar para todo mundo, porque é um livro que mudou muito a minha vida, que é o Sapiens, do Yuval Harari, e gosto muito dele, é um livro que ajuda bastante as pessoas a entenderem o poder de uma narrativa, o poder de uma história, impacto dela no nosso mundo. Ah, e também para fechar, eu tenho uma série de podcast, sobre afrofuturismo, que estavam infiltrados no Cast, três episódios, um sobre afrofuturismo na música, outro sobre afrofuturismo no cinema, e outro na literatura. Está tudo lá no Spotify.',
      },
      {
        type: 'text',
        text: `<a rel="no-follow" href="https://www.instagram.com/infopreta/?hl=pt-br" target="_blank"><strong>Akin Abaz</strong></a> | <a rel="no-follow" href="https://www.instagram.com/akinabaz/?hl=pt-br" target="_blank">@akinabaz</a> | <a href="https://www.instagram.com/infopreta/?hl=pt-br" target="_blank" rel="no-follow">@infopreta</a> Além de criador e CEO da <a href="https://infopreta.com.br" target="_blank">InfoPreta</a>, empresa precursora da diversidade no universo tecnológico, Akin também é palestrante, conselheiro com foco em pequenos e médios negócios e empreendedores, consultor de tecnologia e inovação com foco em diversidade e colunista na @tilt_uol. Costuma aliar criatividade e tecnologia da informação para inovar cada vez mais. A InfoPreta é a primeira empresa especialista em tecnologia no Brasil que tem por objetivo inserir pessoas negras, LGBTQI+ e mulheres no mercado de tecnologia. Tendo como serviço principal a manutenção de hardware e software de computadores de todas as marcas, alia a geração de lucros com projetos sociais de grande impacto, como o #NoteSolidárioDaPreta. Também recebe lixo eletrônico para efetuar o descarte correto em parceria com uma cooperativa sem fins lucrativos. A empresa possui mais de 30 colaboradores e promove consultoria e suporte presencial e remoto para empresas de pequeno e médio porte e desenvolve palestras, cursos e oficinas de tecnologia, inovação e empreendedorismo com foco em relações étnico-raciais, gênero e diversidade.<br /><strong>As dicas do CEO da Infopreta no EP#25 foram:</strong>`,
        image: content_13_guest_02,
        alt: 'Akin Abaz',
      },
      {
        type: 'text',
        text: '<strong>Akin:</strong> “Começar com música, porque um artista que sempre me acompanha, me acompanha desde o início, é Kamauu, o Rapper, quando você está assim, poxa, sem nada, impossível comer quando consegue tirar a essência. Bom, de leitura, cara, eu sou muito nerd, eu indico Scott Pilgrim, que não sei te explicar, eu amo demais o contexto do desenho, o modo perfeito, os quadrinhos, sou um cara meio estranho. E para estudo referente assim, eu vou bater o pé de novo na Nina da Hora, porque o quanto essa mina me ensinou a ter referência de algoritmo, referência de robótica, de estruturamento, de objetos, Nina sempre maravilhosa, em todas as dúvidas que eu peço para ela. Mas isso assim.”',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais: Wolo.tv</strong><br /> “Basicamente é a Netflix da diversidade, feita por brasileiros, focado para população negra e diversidade. Então apoiar a cultura nacional, apoiar mais, ter um pouco mais dessa visibilidade que a gente sempre falou aqui. “',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong><br /> “Hoje eu vou indicar o Instagram do Akin, que ele falou que não é um criador de conteúdo, mas ele é, então estou indicando. Vou indicar o TED da Nátaly Neri sobre afrofuturismo, que chama As Necessidades das Novas Utopias. E eu quero indicar, a gente falou sobre racismo intelectual, tem um livro que me lembrei, que é o Memórias da Plantação da Grada Kilomba, que fala muito sobre isso, e é uma ótima referência assim, indico todo mundo a ler.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_13_thumb,
  },
  {
    id: 111,
    date: '02/04/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'demissao-ou-crescimento',
    title: 'Seu comportamento vai te levar à demissão ou ao crescimento?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_12_thumb,
        alt: 'Seu comportamento vai te levar à demissão ou ao crescimento?',
      },
      {
        type: 'text',
        text: 'A vida do proletariado não é fácil. E no mundo corporativo, para não ser apenas um “sobrevivente”, é preciso ter uma boa competitividade. Do contrário, as experiências profissionais podem se resumir a ocupações temporárias que não proporcionam de verdade o crescimento da carreira.',
      },
      {
        type: 'text',
        text: 'Uma carreira bem desenvolvida nunca para de se desenvolver. Um profissional bem sucedido é aquele que está em constante aprendizado porque entende que, de tempos em tempos, as necessidades das empresas e do mercado mudam e, por consequência, as habilidades que os profissionais devem ter também se alteram. Considerando isso, os profissionais que ainda não despertaram para essa exigência são <strong>sobreviventes</strong>. Eles conseguem adentrar as empresas, entregar as tarefas previstas, ter boas relações interpessoais e, algumas vezes, obter bons reconhecimentos dos colegas, líderes e também reconhecimentos remunerados. Mas o <strong>padrão mediano é sobreviver</strong>. É trabalhar para pagar as contas e apenas isso. É deixar de lado a chance de extrair o melhor de si e dar o melhor de si e, assim, apenas sobreviver apesar da dificuldade que é levantar cedo para ir ao trabalho, apesar de não gostar do trabalho que realiza, apesar de detestar o próprio ambiente de trabalho, de não se identificar com o que você faz com as aquelas pessoas 08 horas por dia. Nada disso ultrapassa a linha do sobreviver no mercado corporativo. E o que separa a realidade de quem apenas sobrevive para a de quem protagoniza a própria vida profissional é o comportamento. As pessoas que protagonizam suas vidas profissionais encontram meios de ter prazer em suas atividades profissionais, usam suas personalidades e qualidades naturais para ter bons resultados, gerar negócios, parcerias, indicações e também para ter um trabalho propositivo. E por isso, elas ficam sempre <strong>longe das listas de demissões e no alvo dos líderes e recrutadores</strong>.',
      },
      {
        type: 'text',
        text: '<strong>Você quer sobreviver ou crescer no mercado de trabalho?</strong>',
      },
      {
        type: 'text',
        text: 'Já deu pra entender que um dos caminhos disponíveis é ter um trabalho que pague as contas e que o outro é ter uma carreira. E se você está em um caminho e quer mudar para o outro, mais promissor e satisfatório, você vai precisar se atentar aos seus comportamentos e passar por significativas mudanças de hábitos.<br />Se pra você, falando do mundo corporativo principalmente, ter uma carreira bem sucedida é alcançar cargos e salários altos, trabalhar em empresas famosas ou inovadoras, liderar pessoas, não ficar desempregado e ter constantes oportunidades de trabalho, seja na empresa atual ou em outras, você tem que entender que as habilidades técnicas serão fundamentais, mas as competências comportamentais	 e emocionais não são menos importantes. <strong>Enriquecer seu intelecto é sua obrigação</strong> se essa carreira bem sucedida é o que você almeja. Mas você só fará isso se você tiver um comportamento que <strong>possibilite tal aprendizado e enriquecimento</strong>.',
      },
      {
        type: 'text',
        text: 'As empresas e os líderes não valorizam o comportamento só porque é bonito ter bom comportamento, mas sim porque é ele que permite soluções para situações das mais diferentes naturezas. O encontro de novas ideias, produtos, formas diferentes e recentes de fazer as coisas só pode acontecer se quem idealiza tiver conhecimento suficiente e atualizado para fazer assim. Para solucionar conflitos, por exemplo, as pessoas precisam ter habilidades como empatia e compreensão, e surpreendentemente essas são habilidades por vezes raras nos ambientes corporativos. Para garantir o atingimento de metas, o controle de gastos, as operações, prazos e processos, são necessárias qualidades como organização, constante atualização de conhecimentos, inovação, capacidade de gerenciamento de pessoas, e por aí vai. <strong>O comportamento é desejável pois é por meio dele que se alcança o resultado palpável e o conhecimento</strong>.',
      },
      {
        type: 'text',
        text: 'Sendo assim, vamos para as dicas práticas de como evitar demissões e reverter o “simples emprego” em uma oportunidade de desenvolvimento da sua carreira.',
      },
      {
        type: 'text',
        text: '<strong>Quais motivos mais levam as pessoas à demissão?</strong>',
      },
      {
        type: 'text',
        text: '<strong>Mau desempenho</strong><br />Pense comigo: se você foi convidado para a entrevista com base no seu currículo, que tinha lá as informações técnicas sobre você, significa que essa primeira etapa você já passou, certo? Você já atendeu aos requisitos colocados e foi convidado a participar da entrevista. Depois, você foi contratado. Ou seja, as pessoas que te admitiram concordam que você tem os conhecimentos necessários para executar o trabalho em questão., tem os cursos que precisa ter, as certificações estão em dia e suas experiências têm relação com o que vai fazer no trabalho. A partir daí, do primeiro dia de trabalho em diante, o que passa a valer é o seu comportamento. É o modo como você apresenta suas ideias, organiza suas tarefas, atende aos pedidos, se relaciona com os parceiros de trabalho… Logo, se você conhece tudo tecnicamente, suas falhas podem ser, por exemplo, não continuar se qualificando sobre os temas que lhe competem, não buscar inovar, não atender aos prazos, não ter comprometimento… Percebe como sempre, mesmo quando o motivo da demissão é o baixo desempenho, o problema está no comportamento e não na sua capacidade? Há um jargão forte nas empresas que diz que admite-se pelas competências e demite-se pelo comportamento. E é perfeitamente aplicável aqui.',
      },
      {
        type: 'text',
        text: '<strong>Mau relacionamento</strong><br />Os aspectos comportamentais geram reflexos positivos e negativos nas equipes. Algumas atitudes são fundamentais para o convívio, entre elas destacam-se o equilíbrio emocional, comunicação clara e direta, perguntar, ser amigável e solícito, solucionar rapidamente dúvidas e problemas, evitar o mau humor e a antipatia, por exemplo. As relações interpessoais são muitas vezes um termômetro de como vão as entregas da equipe, do seu potencial de inovação, de otimização e sinergia. Se os membros da equipe atuam separadamente ou competitivamente o resultado é comprometido e os líderes levam a falta de habilidade de resolver essas questões em conta na hora de decidir por uma demissão ou não.',
      },
      {
        type: 'text',
        text: '<strong>Atrasos e faltas</strong><br />Outro fator óbvio que causa demissões é o volume de faltas dos funcionários. Os gestores entendem que imprevistos acontecem e todos têm problemas pessoais que nem sempre podem ser resolvidos fora do horário de trabalho, mas existe um limite. Para que o trabalho seja desenvolvido com fluidez, os funcionários precisam estar presentes, sem prejudicar o trabalho em equipe. Os profissionais que não atentam a esse comprometimento passam a mensagem de serem irresponsáveis e esse comportamento vai te levar a perder alguns empregos.',
      },
      {
        type: 'text',
        text: '<strong>Motivos que levam à promoção do emprego</strong>',
      },
      {
        type: 'text',
        text: '<strong>Bom desempenho</strong><br />Não despropositadamente, listamos qualidades que levam à promoção que são exatamente os antônimos das características negativas que levam à demissão. Porque ao mesmo tempo que o comportamento pode afundar alguém, ele também é o responsável por enaltecer e elevar as pessoas bem sucedidas. Então, ao contrário do mau desempenho, para ter promoções e subir o nível de hierarquia, você precisa ter boas entregas, bons resultados e um alto desempenho. Se você tiver em seu comportamento uma postura proativa, curiosa, autodidata, interessada em aprender e buscar soluções inteligentes, você vai alcançar resultados acima dos esperados pela organização  e ser notado como um profissional em destaque.',
      },
      {
        type: 'text',
        text: '<strong>Bom relacionamento</strong><br />O relacionamento na vida profissional é inseparável	 da necessidade de trabalhar. Mesmo nas profissões mais solitárias, é preciso interagir e será por meio das interações sociais que você vai moldar suas oportunidades futuras. Sejam elas oportunidades de negócios, ao construir relacionamentos que lá na frente podem gerar uma proposta de trabalho, venda ou parceria, sejam oportunidades de aprendizado, ao ter que se relacionar com os outros para aprender, sejam também as oportunidades sociais, afinal, o trabalho representa um grande papel na vida de todo mundo e ter pessoas com quem trocar e nos relacionar é essencial para ter mais alegria e leveza.',
      },
      {
        type: 'text',
        text: '<strong>Comprometimento</strong><br />Quem não gosta de olhar pra uma pessoa e ver aquela carinha de que é “alguém que dá pra confiar”? É uma sensação incrível quando isso acontece na nossa vida pessoal mas também na vida profissional. Quem tem “senso de dono” no trabalho, que é a preocupação genuína com os resultados que serão alcançados fica muito contente quando encontra outros profissionais que vão vestir a camisa da empresa junto, que vão se comprometer a fazer, botar pra acontecer, aprender se for preciso e dar o melhor de si. Essas pessoas que demonstram comprometimento são tidas pelas outras como uma opção certeira de que tudo vai ser feito dentro dos conformes, com qualidade e no prazo. Elas não têm atrasos e faltas injustificadas e mesmo quando não sabem a solução certa para um problema, elas se comprometem a tentar descobrir. E isso as levam para altos níveis de avaliação e empregabilidade.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! PODCAST 🎧</strong><br /><strong>#24 Como ser um profissional de Alta performance</strong></br>O mercado de trabalho mudou rapidamente e as qualidades que as empresas mais valorizam também mudaram. Quais são as características dos profissionais de alta performance, que conseguem promoções e propostas de trabalho com frequência? No EP #24, Ana Paula Xongani recebeu Lisiane Lemos, gerente de desenvolvimento de negócios, advogada, professora de MBA e conselheira; e Priscylla Haddad, gerente de desenvolvimento organizacional, talento e cultura na Coca-Cola FEMSA; que compartilharam suas histórias de erros e acertos, deram dicas de como se qualificar gratuitamente e alcançar melhores resultados de forma saudável, sem ultrapassar os limites do corpo e da mente. Elas contaram também que estar atento ao que acontece no mundo e aberto para desaprender e aprender é uma regra. Quer saber mais?<br /><strong>Veja as dicas de conteúdos que as participantes deixaram:</strong>',
      },
      {
        type: 'text',
        text: '<a rel="no-follow" href="https://www.linkedin.com/in/lisianelemos/" target="_blank"><strong>Lisiane Lemos</strong></a> | <a rel="no-follow" href="https://www.instagram.com/lisianelemos/" target="_blank">@lisianelemos</a> Ela é líder de vendas com experiência em growth marketing e transformação digital, professora de MBA em Big Data, advogada, digital influencer, entre outras coisas. No Google, entrou como Gerente de Novos Negócios e hoje é Gerente de Parceiros, focando as atividades em ações estratégicas e escaláveis com resultados de curto prazo, mas sustentáveis. Na Microsoft, trabalhou na liderança de vendas disruptivas. Seu aprendizado sobre liderança foi complementado através de trabalhos voluntarios, co-fundando a Rede de Profissionais Negros, um coletivo que possui 15 mil membros, co-liderarando o Comitê de Igualdade Racial do Grupo Mulheres do Brasil, que hoje conta com 70 mil mulheres e, durante a pandemia, criou o Conselheira 101, para inclusão de mulheres negras em conselhos de administração. Por esta trajetória, ingressou no conselho consultivo do Fundo de Populações da ONU (UNFPA) e representou o Brasil em eventos como o Fórum Mundial da Juventude (WYF 2017). Também foi reconhecida pela Revista Forbes e MIPAD onu como uma das pessoas mais influentes no Brasil e no mundo. <strong>Suas dicas extras no EP#24 foram:<strong>',
        image: content_12_guest_02,
        alt: 'Lisiane Lemos',
      },
      {
        type: 'text',
        text: '<strong>TED Business | Podcast </strong><br />“Esse TEDx é liderado por uma africana, que é professora de Columbia, chamada Modupe Akinola. E o meu episódio favorito, eu gosto muito de esportistas, é o do Russell Wilson, que para mim número 1, ele é um investidor de Super Bowl, e ele fala sobre treinamento, o episódio chama Treine você mesmo para brilhar sob estresse. Porque um profissional de alta performance está constantemente sob estresse e isso é muito importante.”',
      },
      {
        type: 'text',
        text: '<a rel="no-follow" href="https://www.instagram.com/marthagabriel/" target="_blank"><strong>Martha Gabriel | Pessoa referência</strong></a><br />“Eu gosto muito de assinar newsletters e seguir profissionais de inovação. Então acho que no Brasil, a Martha Gabriel é uma super referência e eu gosto de seguir no Instagram.”',
      },
      {
        type: 'text',
        text: '<strong>Garra: O Poder da Paixão e da Perseverança</strong>, livro por Angela Duckworth<br />“É de uma pesquisadora americana, que diz que não são os mais inteligentes que vencem, são os mais persistentes. E que persistência é um músculo que pode ser adquirido e treinado. Então acho que a dica é para mostrar assim, ‘eu não sou mais inteligente ou sou mais rica, mas eu venço na força da raiva’. São as dicas para hoje, e escutar o Trampapo sempre.”',
      },
      {
        type: 'text',
        text: '<a rel="no-follow" href="https://www.linkedin.com/in/priscyllahaddad/" target="_blank"><strong>Priscylla Haddad</strong></a> Tem 24 anos de experiência na área de Recursos Humanos em empresas líderes no segmento do negócio (Química & Agro, Bens de Consumo e Alimentos & Bebidas), exercendo liderança de pessoas, motivando e engajando-as. Tem vivência em processos de transformação digital, reestruturação e construção de startups no Brasil e em outros países da América Latina.  Trabalha atualmente na Coca-Cola FEMSA como Gerente Desenvolvimento Organizacional, Talento e Cultura, mas já passou também pela Nestlé, nas funções de Gerente Sênior Corporativo de Recursos Humanos,  Gerente Internacional Sênior de RH Américas / Nespresso Suíça e Gerente de RH América Latina / Nespresso.<br /><strong>Sua dica extra no EP #24 foi:</strong>',
        image: content_12_guest_01,
        alt: 'Priscylla Haddad',
      },
      {
        type: 'text',
        text: '<strong>Descubra seus Pontos Fortes</strong><br />“Muitas vezes a gente fica: preciso desenvolver aquilo que eu não sou boa, tenho uma deficiência aqui, eu preciso aprender. E cara, você saber aquilo que você é bom é, aquilo que pode te levar para cima. Você tem que reconhecer e conhecer aquilo que você é bom também. Então esse Descubra seus Pontos Fortes, é muito bacana.” ',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani</strong>: Nossa host também deixou suas dicas:',
      },
      {
        type: 'text',
        text: '<strong>Trampapo | EP #20 Mulheres na tecnologia: you code girl!</strong><br />“Minha dica de hoje vai ser o episódio que é o preferido inclusive da minha mãe, mas eu acho que tem a ver com a gente também, porque é mulheres, nós, na tecnologia. E é um dos episódios preferidos até hoje dessa jornada que a gente está fazendo no Trampapo.”',
      },
      {
        type: 'text',
        text: '<strong>A Coragem de ser Imperfeito</strong>, livro por Brené Brown<br />“Muita gente está indicando esse livro, esse livro é muito legal, mas faz muito sentido para você que quer empreender, para você que está pensando num mundo corporativo, e principalmente para você que quer aprender a contar bem a sua própria história.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br />Até a próxima!',
      },
    ],
    thumbnail: content_12_thumb,
  },
  {
    id: 110,
    date: '26/03/2021',
    categories: ['DIVERSIDADE'],
    slug: 'identidades-trans-estigmas-desafios',
    title: 'Identidades trans: estigmas e desafios no mercado de trabalho',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_11_thumb,
        alt: 'Identidades trans: estigmas e desafios no mercado de trabalho',
      },
      {
        type: 'text',
        text: 'Talvez você já saiba. <br /> O Trampapo se propõe a falar da pluralidade do mercado de trabalho. Buscamos trazer as mais diversas versões de profissionais, profissões, realidades e caminhos em nossos programas de podcast e posts. O objetivo é fazer com que todos se sintam provocados e incentivados a irem mais longe na carreira. Isto é,  trabalhar com aquilo que ofereça satisfação e tenha vínculo com os próprios valores pessoais, ter acesso a boa remuneração e bons benefícios, além de ocupar um ambiente de trabalho seguro em todos os níveis.',
      },
      {
        type: 'text',
        text: 'E até este momento, abordamos diferentes realidades nos conteúdos que puderam, de fato, ajudar profissionais a se posicionarem melhor no mercado de trabalho. Mas com a chegada do tema <strong>#23 Pessoas Trans: trampos e vivências</strong>, confessamos que o sentimento é que o desafio é grande demais. O mercado está mais receptivo para pessoas trans, mas ainda estamos a uma enorme distância de oferecer a qualidade de vida que merecem e precisam.',
      },
      {
        type: 'text',
        text: 'No Brasil a população trans tem sua existência envolvida por dificuldades e barreiras, a começar pela preservação da própria vida. Dados da União Nacional LGBT mostraram que o tempo médio de vida de uma pessoa trans no país é de apenas 35 anos, enquanto a expectativa da população em geral é de 75 anos, de acordo com o IBGE. Esse fato é o sintoma de muitas violências para com essas pessoas. Afinal, somos o país que mais mata trans no mundo e essa mesma transfobia que mata também exclui. ',
      },
      {
        type: 'text',
        text: 'Após identificarem que são de um gênero diferente do que lhes foi atribuído no nascimento, elas têm que lidar com inúmeros obstáculos para viverem sua identidade, como, por exemplo, o risco iminente de ser vítima de agressões físicas, verbais e emocionais, o abandono e exclusão familiar e das comunidades como escolas, igrejas e grupos de convívio, a falta de legislação que as protejam, a ausência de oportunidades no mercado de trabalho formal, dificuldade para acessar serviços de saúde e múltiplas rejeições. ',
      },
      {
        type: 'text',
        text: 'Para quem se enquadra nas definições de identidades trans, se depara com limitações extras na entrada do mercado de trabalho. Essas limitações não são as mesmas apresentadas para pessoas cisgêneras, afinal, uma série de julgamentos e estigmas acompanham a figura de cada pessoa trans, o que acaba por contribuir ainda mais para a marginalização desses grupos, retroalimentando a exclusão social e profissional que elas sofrem.',
      },
      {
        type: 'text',
        text: '<strong>Mercado de trabalho e pessoas trans</strong>',
      },
      {
        type: 'text',
        text: 'A transfobia, que é a discriminação contra as identidades transgêneras, e a ausência de legislação específica que garanta espaço no mercado de trabalho faz com que as peessoas desses grupos tenham que ter como opção de sobrevivência a prostituição nas ruas. Um levantamento realizado pela Associação Nacional de Travestis e Transexuais (Antra), com base em dados colhidos nas diversas regionais da entidade, informa que 90% das pessoas trans recorrem a essa profissão ao menos em algum momento da vida e que somente 4% desse grupo tem acesso ao mercado de trabalho formal.',
      },
      {
        type: 'text',
        text: 'As discussões acerca das questões de gênero têm tido cada vez mais espaço nos ambientes empregatícios, o que é positivo e importante para evidenciar uma nova prática realizada pelas organizações que não esperam apenas promover apenas a diversidade, mas também exercer o papel social de contribuir com a equidade e a inclusão de transgêneros no mercado. Aos poucos, nós como sociedade e as organizações passamos por mudança de valores, crenças e comportamentos, mas dentro do mercado e no meio corporativo essas mudanças ainda dão margem para a segregação, a exclusão e a desumanização, sem de fato incluir a todos e todas.',
      },
      {
        type: 'text',
        text: '<strong>Quais os estigmas que acompanham as pessoas trans?</strong>',
      },
      {
        type: 'text',
        text: 'Uma série de estigmas são as razões para que o cenário de exclusão e violência esteja tão presente na nossa nação. Nossa cultura é extremamente sexista, LGBTfóbica e transfóbica. De um modo geral, há a supervalorização de um único padrão tido como normal, belo, respeitoso, ético e capaz, padrão esse que norteia todos os aspectos sociais, desde o planejamento de produtos, criação e aplicação de leis, até conveniências arquitetônicas e comunicações gerais, por exemplo. No entanto, em diversos lugares do mundo e muito aqui no Brasil, esse padrão imposto e favorecido é representado por homens, brancos, heterossexuais, sem deficiência, magro e que tenham até certa idade. Logo, as extremidades mais distantes destas especificações são as mais desvalorizadas, desprezadas e subjugadas, tendo a elas a atribuição de valores depreciativos, imorais e que associam a vivência dessas pessoas à incapacidade, à insanidade, ao crime, à promiscuidade e a tantas outras crenças negativas e estereótipos que formam o senso comum a respeito da comunidade. Travestis e transexuais são tão capazes e competentes quanto as outras pessoas, mas esses estigmas em torno de suas identidades de gênero promove a intolerância.',
      },
      {
        type: 'text',
        text: '<strong>Barreiras educacionais para pessoas trans</strong>',
      },
      {
        type: 'text',
        text: 'O preconceito é também o que alimenta as barreiras educacionais para a população trans. Além da baixa escolaridade e falta de capacitação ser um problema geral no Brasil, considerando que a educação é pouco priorizada, a dificuldade específica da população trans em se qualificar é o preconceito. ',
      },
      {
        type: 'text',
        text: 'A Secretaria Municipal de Direitos Humanos e Cidadania mapeou em uma pesquisa que mais da metade dos entrevistados (57%) não está preparada para o ingresso no mercado, pois para ter acesso a cursos profissionalizantes é preciso concluir o Ensino Médio, o que não inclui boa parte dessa parcela. Analisando a história pessoal de tantas pessoas trans, fica claro que assumir a transgeneridade nos ambientes de estudo durante a adolescência interfere diretamente no sofrimento do bullying transfóbico que também leva à desistência dos estudos.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong><br />No EP #23 do Trampapo, <strong>PESSOAS TRANS: TRAMPOS E VIVÊNCIAS</strong>, nossa host Ana Paula Xongani recebeu duas mulheres trans do mundo corporativo, Danielle Torres, da <a href="https://home.kpmg/br/pt/home.html" target="_blank">KPMG</a>, e Yasmin Vitória, da <a href="https://www.salesforce.com/br/" target="_blank">Salesforce Brasil</a>, que contaram suas vivências, jornadas e lutas neste mercado de trabalho que ainda é tão fechado para a transgeneridade. Para elas, o certo é inegociável. Precisamos de medidas para alcançar o respeito e uma transformação social definitiva, afinal, ainda faltam oportunidades nas empresas, falta conhecimento e empatia por parte dos líderes e colegas de trabalho. Quer entender alguns dos motivos que fazem essa desigualdade existir e por que essa conversa é mais que importante para todas as pessoas?  Corre na home do site e dê o play!',
      },
      {
        type: 'text',
        text: 'Se liga nas <strong>dicas extracurriculares</strong> deixadas pelas participantes do podcast!',
      },
      {
        type: 'text',
        text: '<strong><a rel="no-follow" href="https://www.linkedin.com/in/ddftorres/" target="_blank">Danielle Torres</a></strong> | <a rel="no-follow" href="https://www.instagram.com/d.danielletorres/" target="_blank">@d.danielletorres</a> | trabalha no Departamento de Prática Profissional da KPMG. É uma profissional experiente com habilidades em contabilidade, auditoria e ciência de dados.<br />Ela busca sempre se envolver com sua comunidade. Em várias ocasiões, palestrou sobre diversidade no local de trabalho - especialmente inclusão feminina e transgênero - já tendo falado para milhares de profissionais em todas as Américas.<br />Do ponto de vista acadêmico, ela tem Bacharel em Ciências Contábeis e Administração, Pós em Filosofia e Direitos Humanos, MBA em Gestão de Negócios, MBA em Tecnologia pra Negócios e está buscando um mestrado em Analytics.<br />Além disso, ela é escritora, maquiadora e colunista Marie Claire BR.',
        image: content_11_guest_02,
        alt: 'Danielle Torres',
      },
      {
        type: 'text',
        text: '<strong>Suas dicas extras no EP#23 foram:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Mulheres que Correm com os Lobos,</strong> livro por Clarissa Pinkola Estés<br />Dani sobre o livro: “ Eu não sei quanto a vocês, mas às vezes, muitas vezes eu lia aquele livro, eu ficava meio sem ar, que ela trata com uma profundidade sobre os arquétipos femininos assim que realmente mexe muito com a minha estrutura”. ',
      },
      {
        type: 'text',
        text: '<strong>E sou Jazz</strong>, livro por Jessica Herthel e Jazz Jennings<br/>“A vida de Jazz Jennings também eu acho fantástica. A história de uma garota trans americana, que hoje em dia já é mulher. Ela conta e traz de uma maneira muito sensível como é crescer sendo transgênero, o que é aquela exclusão, algumas crueldades que a pessoa passa, que você fala gente, toca muito, toca muito, a gente se enxerga muito lá, é muito complicado. Mas ao mesmo tempo, foi uma leitura que me ajudou muito também.” Emendou a segunda indicação de livro.',
      },
      {
        type: 'text',
        text: '<strong>O Conde de Monte Cristo</strong>, livro por Alexandre Dumas<br />Dani finalizou suas dicas: “Por que eu acho lindo O Conde de Monte Cristo? O Edmond Dantes é preso. E ele encontra uma libertação, digamos, espiritual por uma pessoa que é guia. Ele encontra uma libertação espiritual em primeiro lugar numa pessoa que é guia. E aí,  por muitos anos da minha vida, eu sempre me enxerguei nessa prisão e eu sempre me questionava muito, ‘o que é que eu faço depois dessa prisão?’, porque o Edmond Dantes, como a gente bem conhece, ele desenvolve uma história de vingança. Mas eu acho que ali é um aprendizado maravilhoso, porque o que eu guiei a minha vida, eu falo bom, depois que eu sair dessa minha própria prisão pessoal de estar presa em mim mesma, o que eu quero é ter uma visão de empatia, né. Então para mim é um livro que ele me mostra muito do que é uma vida que a gente constrói com base num sentimento de ‘agora é a minha vez’, e uma vida que a gente pode construir com base num sentimento de agregar, é uma obra maravilhosa, não dá para eu esgotar ela falando em um, dois minutos, é uma obra que dá, deve ter teses e teses a esse respeito, mas eu estou falando só da parte que me tocou”.',
      },
      {
        type: 'text',
        text: '<a rel="no-follow" href="https://www.linkedin.com/in/yasmin-vit%C3%B3ria-yas-3b097b137/" target="_blank"><strong>Yasmin Vitória</strong></a> | <a rel="no-follow" href="https://www.instagram.com/yasmin.vitoriaoficial/" target="_blank">@yasmin.vitoriaoficial </a> | é associada de sucesso do cliente LATAM na Salesforce Brasil. Tem mais de 5 anos na área de atendimento em empresa multinacional e nacional, sendo responsável pela trajetória do cliente doméstico e crossborder desde a compra contratação até a experiência pós venda. Tem perfil analítico, multitarefa e visão holística para traduzir ações em resultados efetivos nas áreas de Customer Service e Care, à nível de planejamento estratégico, contingencial e de sucesso para o usuário.<br />Nos últimos 2 anos, tem levando autenticidade, coragem, posicionamento e representatividade da comunidade LGBTQI+, com recorte à população de TRANS e TRAVESTI, para os espaços privilegiados e de poder que a foram renegados um dia.',
        image: content_11_guest_01,
        alt: 'Yasmin Vitória',
      },
      {
        type: 'text',
        text: '<strong>Sua dica foi:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Quem Tem Medo do Feminismo Negro?</strong>, livro por Djamila Ribeiro<br/>Yas argumentou sua indicação: “A gente sabe que tem algumas pautas de alguns recortes que precisam ser faladas, né? E esses recortes são de pessoas trans, o feminismo trans, o feminismo travestigênero, o feminismo negro... que estão reivindicando, que estão pleiteando, que estão lutando, que está pedindo muitas outras coisas, além daquele do que o feminismo, que infelizmente algumas pessoas acham que acaba sendo homogêneo, mas que não é. A gente tem diversas outras pessoas que enquanto você está pedindo por um direito x, a pessoa está buscando pelo direito -A. Então, ela está lá no outro lado da ponta desse ecossistema todo de desigualdade social que a gente vive. É importante a gente falar assim desses recortes. Acho que esse livro traz bastante essa perspectiva de uma narrativa contada por nós, para nós, e como a gente pode a partir desses conhecimentos múltiplos, trazer isso um pouco mais para a nossa realidade”.',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong>',
      },
      {
        type: 'text',
        text: 'Nossa host indicou alguns perfis do Instagram para seguir e se informar sobre o assunto:<br/>- <a href="https://www.instagram.com/luziarosa.luz/?hl=pt-br" rel="no-follow" target="_blank">Rosa Luz</a>, “uma artista completa, maravilhosa e que tem muito dizer, acho que as influencers têm um papel muito bacana de divulgar, facilitar, termos conceitos, ideias e vivências, então, Rosa Luz é uma das minhas grandes referências” <br />- <a href="https://www.instagram.com/bixarte/?hl=pt-br" target="_blank" rel="no-follow">Bixarte</a> ”que é uma Poeta, uma das minhas poetas preferidas”<br />- <a href="https://www.instagram.com/transpreta/?hl=pt-br" target="_blank" rel="no-follow">Transpreta</a>,  “sempre traz suas vivências, traz também suas teorias sobre a vivência trans, eu gosto muito”<br />- <a href="https://www.instagram.com/arethasadick/?hl=pt-br" target="_blank" rel="no-follow">Aretha Sadick</a>, “eu adoro as contações de história, ela conta as histórias de religiões de matriz africana, sempre trazendo a intersecção da sua vivência enquanto uma mulher preta e trans.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3<br/>Até a próxima!',
      },
    ],
    thumbnail: content_11_thumb,
  },
  {
    id: 109,
    date: '19/03/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'psicologia-positiva-trabalho',
    title: 'A psicologia positiva pode te ajudar a ser mais feliz no trabalho?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_10_thumb,
        alt: 'A psicologia positiva pode te ajudar a ser mais feliz no trabalho?',
      },
      {
        type: 'text',
        text: '<strong>Quem nunca</strong> quis parar os ponteiros do relógio no final de semana, querendo adiar a chegada da segunda-feira?<br>Quem nunca ficou pra baixo ao final da noite de domingo, porque está chegando de novo a hora de deixar de lado por uns dias a própria vida, os momentos de lazer e ficar “preso” ao trabalho, em compromissos desconfortáveis que só fazem sentido porque fazem parte do ganha-pão mensal?<br> Quem nunca sonhou em ganhar uma bolada na loteria, largar o emprego no dia seguinte e não precisar responder pra chefe algum por toda a vida?<br>',
      },
      {
        type: 'text',
        text: 'Pois é. Se você nunca, parabéns!',
      },
      {
        type: 'text',
        text: 'Porque grande parte das pessoas já enfrentaram a chamada <strong>síndrome da segunda-feira</strong>, que são aqueles sintomas de ansiedade, desânimo e tristeza que surgem quando as coisas não vão tão bem no trabalho - ou nas emoções do indivíduo, que se afloram com o trabalho.',
      },
      {
        type: 'text',
        text: 'Mas por que isso acontece com tanta gente? Se no nosso país, com altos índices de desemprego que só crescem, com tanta desigualdade social, por que quem está vencendo as estatísticas e tem um emprego pra chamar de seu fica infeliz? Por que a sensação de saber que vai conseguir pagar os boletos, ter comida na mesa e um teto sob a cabeça não é suficiente para superar aquele luto do final de semana? Por que as pessoas acordam reclamando quando o despertador às lembram que devem levantar pro trabalho? Por quê? Essas perguntas não são críticas. A gente não quer que você se sinta uma pessoa ingrata por não estar agradecendo a cada minuto a oportunidade que tem. Até porque, mesmo os profissionais mais realizados que existem, nos cargos de maior notoriedade nas empresas, na mídia ou nos esportes, por exemplo, têm seus momentos de insatisfação, cansaço, decepções, incertezas e até “ranços” momentâneos. Afinal, todas as carreiras têm seus ônus e bônus. Nem tudo vai ser um mar de rosas e, geralmente, cada escolha significa também uma renúncia.',
      },
      {
        type: 'text',
        text: 'Então, diga: como que a maioria das pessoas, que normalmente não têm poder de escolha de suas profissões e empregos, vão ficar felizes com um despertador às 06 horas da manhã em plena segunda-feira? É complicado. Ainda mais que é logo depois de passar dois dias em casa descansando, se divertindo com a família e esquecendo que bate cartão de segunda à sexta. Não há proletariado que ame deixar tudo isso de lado para precisar correr atrás do ônibus em dias muito quentes, ou muito frios, ou chuvosos… Muitas vezes é quase impossível manter a saúde mental, a produtividade, a motivação e qualidade de entregas quando a ida ao trabalho ou o convívio com chefes e colegas é um martírio, um sofrimento.',
      },
      {
        type: 'text',
        text: 'Realmente, essas perguntas não devem ter respostas simples. Não vai ser um exercício fácil ver com positividade as situações que de fato são negativas. Mas há o que se fazer se você não quiser pirar, chorar a cada despertar ou largar tudo de vez e viver fora da civilização, onde o trabalho e a renda não sejam tão necessários.',
      },
      {
        type: 'text',
        text: '<strong>É aí que pode entrar Psicologia Positiva</strong>',
      },
      {
        type: 'text',
        text: 'A Psicologia Positiva é um campo da Psicologia que concentra os estudos naquilo que pode <strong>trazer felicidade às pessoas</strong>. Ela propõe práticas para buscar mais sensações de bem estar pessoal, mas também pode ser aplicada à vida profissional.',
      },
      {
        type: 'text',
        text: 'Esse segmento acabou sendo muito disseminado nos últimos tempos com o crescimento das práticas de coaching e de conteúdos sobre o tema nas redes sociais. Mas nem por isso a Psicologia Positiva trata-se de um discurso vago e sem fundamentos. Ela concentra-se em aspectos positivos da existência humana para desenvolver uma psique mais positiva, usando ferramentas como a autorresponsabilidade para dar controle e autonomia para cada um mudar aquilo que pode mudar em sua realidade. ',
      },
      {
        type: 'text',
        text: 'Assim, na prática, adotar esse conceito sugere que, embora todos vivenciamos situações difíceis, o foco deve estar na construção de emoções positivas, em construir um modelo mental otimista, que veja as coisas com leveza e sempre o “copo meio cheio”, exercitando mecanismos de resiliência, gratidão e propósito.',
      },
      {
        type: 'text',
        text: 'Segundo o autor Martin Seligman, em 2020, no livro Felicidade Autêntica, quem consegue pôr em prática a felicidade autêntica defendida pela psicologia positiva, tem as mais desejadas recompensas:<br><br> 1.&nbsp;&nbsp;Sente e consegue expressar gratidão<br> 2.&nbsp;&nbsp;É otimista quanto ao futuro <br> 3.&nbsp;&nbsp;Não se compara com outras pessoas, pois reconhece que é um ser único <br> 4.&nbsp;&nbsp;Pratica a gentileza e cortesia, adotando uma postura altruísta<br> 5.&nbsp;&nbsp;Dedica boa parte do tempo à família e amigos<br> 6.&nbsp;&nbsp;Se torna resiliente, aprendendo a enfrentar e superar as adversidades<br> 7.&nbsp;&nbsp;Aprende a perdoar de modo sincero<br> 8.&nbsp;&nbsp;Busca o aperfeiçoamento e total dedicação à tarefa que desempenham no momento, desfrutando do aqui e agora<br> 9.&nbsp;&nbsp;Aprecia os prazeres da vida<br> 10.&nbsp;&nbsp;É comprometido com suas metas e sonhos<br> 11.&nbsp;&nbsp;Desenvolve a espiritualidade, concentrando-se no autoconhecimento e em um motivo superior para suas tarefas<br> 12.&nbsp;&nbsp;Cuida bem da saúde, tanto mental quanto física.<br>',
      },
      {
        type: 'text',
        text: '<strong>E no seu trabalho…</strong>',
      },
      {
        type: 'text',
        text: 'Ainda estamos longe de esgotar os motivos que podem deixar alguém infeliz no trabalho. São muitos. Mas no trabalho também é interessante aplicar o conceito da Psicologia Positiva para minimizar ou reverter o mal estar. A <strong>tríade da felicidade</strong> é uma das principais metodologias para aplicar a disciplina. Ela insiste na busca por uma vida prazerosa, vida engajada e vida significativa.',
      },
      {
        type: 'text',
        text: 'Passo 1 - A <strong>vida prazerosa</strong> no trabalho é alcançada com um clima positivo, que oferece bem estar emocional. Logo, um ambiente de alta pressão, estresse, medo, hostilidade e insegurança é incompatível com essa vida prazerosa buscada.',
      },
      {
        type: 'text',
        text: 'Passo 2 - Ter uma <strong>vida engajada</strong> significa manter-se motivado com o desempenho das tarefas, que devem levar a um estado de plenitude, concentração e sensação de bem estar com a produtividade.',
      },
      {
        type: 'text',
        text: 'Passo 3 -  A <strong>vida significativa</strong> não pode ser desconectada de uma vida feliz. É preciso buscar e descobrir um propósito vinculado aos seus valores morais e tentar alinhá-lo ao trabalho. Somar o útil ao agradável, que é ganhar dinheiro e ocupar seu tempo com uma tarefa que contribua com algo importante para você, para a sociedade, clientes ou família. ',
      },
      {
        type: 'text',
        text: 'Apesar de desenvolver as habilidades da vida prazerosa, engajada e significativa serem subjetividades e podem não ter o efeito pleno diante de dificuldades impostas pela vida e sociedade, aplicar o conceito da tríade da felicidade no trabalho pode se tornar uma fonte diária de prazer e realização. <strong>A psicologia positiva é uma ciência sobre o que faz a vida valer a pena.</strong>',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong><br> O episódio #22 do Trampapo, <strong>COMO NÃO ODIAR AS SEGUNDAS-FEIRAS? mergulhou</strong> de cabeça nesse assunto da <strong>felicidade no trabalho</strong>. Os convidados explicaram que o trabalho não pode ser a nossa única atividade e que devemos buscar satisfação e equilíbrio em todos os campos da vida. Mas como encontramos entusiasmo nas empresas? Como lidar com empregos que não gostamos? Como superar os problemas com a liderança e de relacionamentos no trabalho? Além disso, tem várias histórias contadas pelos convidados para você refletir sobre propósito, valores, felicidade e motivação. Corra na home do site e escute! Se liga nas <strong>dicas extracurriculares</strong> deixadas pelas participantes do podcast!',
      },
      {
        type: 'text',
        text: '<strong>Cauê Oliveira é </strong>Diretor de Capacitação no Great Place to Work Brasil, Palestrante TEDx  e Facilitador de treinamentos de Liderança. <br> <strong>Suas dicas extras no EP#22 foram:</strong><br> <a href="https://www.ted.com/talks/caue_de_oliveira_como_criar_a_melhor_empresa_para_trabalhar?language=pt-br">Como criar a melhor empresa para trabalhar | Cauê de Oliveira | TEDxSaoPauloSalon</a><br> “Nele eu dou algumas dicas bem legais de como as pessoas podem entender o que é um ótimo ambiente de trabalho”, comentou. <strong>Comece pelo porquê, livro por Simon Sinek</strong><br> “Fala sobre propósito. Tem até um vídeo muito famoso dele que é o tal do Golden Circle, o Círculo Dourado, que tem no centro desse círculo o ‘porquê’, depois o ‘como’ e depois o ‘porquê’, na parte mais da extremidade”, indicou. ',
        image: content_10_guest_01,
        alt: 'Cauê Oliveira',
      },
      {
        type: 'text',
        text: '<strong>Talita Tiemi</strong> é Psicóloga, Gerente de Recursos Humanos, Fundadora e Consultora na UP! Capital Humano.<br> <strong>Suas dicas foram:</strong><br> <strong>Parasita, filme de 2019</strong><br> “É um filme coreano, parece que não tem nada a ver com o ambiente organizacional, mas é uma família que decide trabalhar na casa de uma outra família. E eles elaboram toda uma estratégia para que todos sejam contratados. Toda uma estratégia para se manter ali, e depois ao longo do filme, vão acontecendo várias coisas. E aí no final tem um final bem surpreendente, e aí eu acho que passa muito uma mensagem de não desistir de perseverança, de manter a motivação, apesar dos percalços”, recomendou Talita.',
        image: content_10_guest_02,
        alt: 'Talita Tiemi',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong><br><strong>À Procura da Felicidade, filme de 2006</strong><br> “É um ótimo filme para a gente falar sobre mudança de rota, mudança de trajetória, buscar novos caminhos para encontrar o sucesso”, finalizou a host.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3 <br> Até a próxima!',
      },
    ],
    thumbnail: content_10_thumb,
  },
  {
    id: 108,
    date: '13/03/2021',
    categories: ['DIVERSIDADE'],
    slug: 'tire-seu-machismo-da-minha-profissao',
    title: 'Tire o seu machismo da minha profissão',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_09_thumb,
        alt: 'Tire o seu machismo da minha profissão',
      },
      {
        type: 'text',
        text: 'Como a maioria das mães, pais e famílias nos aconselham: o estudo abre portas.',
      },
      {
        type: 'text',
        text: 'E, para as mulheres que buscam se qualificar, os estudos realmente podem ser a chave para entrar em um determinado mercado. Mas depende.',
      },
      {
        type: 'text',
        text: 'Se esse mercado foi predominantemente e tradicionalmente masculino, vai depender também de vencer outros obstáculos, ter resiliência, comunicação assertiva, foco… Os mercados que antes eram frequentados só por homens hoje até são mais ocupados por mulheres, e a força da representatividade feminina em novos ramos é essencial para desmistificar os estereótipos de gênero que estão postos na nossa educação e construção social desde cedo.',
      },
      {
        type: 'text',
        text: 'Como tantas outras áreas da vida social, as relações de trabalho vêm sendo alteradas a partir do aumento de medidas afirmativas para as mulheres. E a entrada massiva de mulheres no mercado de trabalho, em especial em profissões que são tidas como masculinas, e também na educação superior, nas organizações e como chefes de família, mudou e ressignificou a questão da escolha profissional e os processos de formação da identidade profissional para ambos os gêneros.',
      },
      {
        type: 'text',
        text: '<strong>Só que a desigualdade continua.</strong>',
      },
      {
        type: 'text',
        text: 'Ainda é preciso lutar por reconhecimento e direitos para as mulheres nesses espaços. É bastante comum que as mulheres desses segmentos tenham que abandonar suas carreiras ou tenham menos reconhecimento e crescimento porque os ambientes não são pensados para incluí-las, protegê-las e acolhê-las. Afinal, mesmo que muitas mulheres vejam “normalidade” em participar do mercado de trabalho, para elas, a carreira ainda representa uma posição secundária em suas identidades femininas. E, na primeira posição, ainda prevalecem os papéis de cuidado para com a família e o lar.',
      },
      {
        type: 'text',
        text: 'O entendimento geral de “homem” e “mulher” é historicamente construído. E embora haja verdadeiras diferenças biológicas, este único ponto é muito usado como ideologia para fazer crer que a divisão dos papéis entre eles é natural e justa. E essa visão influencia em desigualdades na execução de tarefas domésticas e com a família, nos postos de trabalho que cada pessoa ocupa, na satisfação profissional, no valor dos salários, no acesso aos recursos econômicos, culturais, sociais e políticos.',
      },
      {
        type: 'text',
        text: 'Essa divisão se constrói muito a partir da noção que a figura feminina é diretamente associada ao ato de cuidar, atribuindo quase que exclusivamente às mulheres as responsabilidades com a limpeza e organização da casa, com o preparo das refeições e com o afeto e criação dos filhos. Já em relação ao entendimento do que é o papel masculino, as noções são fortemente associadas ao trabalho, à autoridade, ao treinamento para a autossuficiência, ao conhecimento de disciplinas e estratégias de ascensão social. Desde os feitos escolares, nas brincadeiras, na força física e em vários âmbitos, há um estímulo externo e uma busca interna dos homens por recompensas que vão reafirmar a masculinidade deles, e isso acaba sendo um motor para comportamentos nocivos a todas as pessoas, homens e mulheres, além de dar continuidade a crenças e condicionamentos que limitam oportunidades para todos e todas.',
      },
      {
        type: 'text',
        text: 'Alguns exemplos de áreas que são tradicionalmente feminilizadas e que têm a maior parte de universitários mulheres são as áreas de Pedagogia, Serviço Social, Enfermagem, Nutrição, Psicologia, Letras e demais Licenciaturas. E justamente por serem historicamente profissões mais ocupadas por mulheres, há uma desvalorização das profissões e carreiras ligadas ao cuidado. Por outro lado, apenas 30% das universitárias escolhem carreiras relacionadas à ciência, tecnologia ou matemática, segundo dados da Unesco. O peso dos papéis de gênero se reflete na escolha da profissão - ou na falta de escolha.',
      },
      {
        type: 'text',
        text: '<strong>Aprendendo os papéis de gênero</strong>',
      },
      {
        type: 'text',
        text: 'Mas, de fato, como tudo isso acontece? Por que a sociedade ainda faz uma divisão tão demarcada do que é o papel masculino e o papel feminino?<br> Serem humanos aprendem sinestesicamente. Ou seja, aprendemos com a mistura de sensações relacionadas aos sentidos: tato, audição, olfato, paladar e visão. Sendo assim, essa relação entre todos os planos sensoriais que podem proporcionar aprendizado é responsável por armazenar informações, de forma consciente e inconsciente, sobre tudo. Inclusive sobre o que significa ser homem e o que significa ser mulher.<br> Tanto pelo lado consciente quanto pelo lado inconsciente, aprendemos com falas e comportamentos que transmitem a ideia do papel femino ligado ao cuidado e o papel masculino à força e ao poder. A observação que a maioria das crianças podem fazer dentro de casa é de uma mãe mais presente, cuidadosa e afetuosa, responsável pelos detalhes cuidadosos do cotidiano. E, no caso das meninas, elas passam a ser treinadas para reproduzirem esses papéis. E mesmo quando as meninas se enxergam trabalhando fora no futuro, tendem a se ver em espaços como escolas, hospitais, creches, etc. O potencial delas acaba não sendo totalmente estimulado para que ocupem outros espaços.',
      },
      {
        type: 'text',
        text: 'Na mesma medida, os meninos são socializados para crerem em suas capacidades lógicas, intelectuais, de liderança e afirmação. Além dos ensinos paternos, além do senso comum que coloca o homem como provedor financeiro e das representações midiáticas, os meninos também tendem a acreditar que há uma superioridade masculina. Em uma sociedade capitalista, o valor do dinheiro é inquestionadamente maior do que o valor do trabalho doméstico. Observe também que ambos, meninos e meninas, se deparam com materiais, livros, filmes, atividades que colocam os homens como protagonistas, inventores e detentores de conhecimento. Essas ações enfatizam as diferenças.',
      },
      {
        type: 'text',
        text: 'Assim, escolher uma profissão para a mulher é uma tarefa duplamente difícil, pois em seu processo de decisão profissional é preciso primeiro diluir os condicionamentos do que uma mulher deve fazer profissionalmente, é preciso avaliar as condições mercadológicas, se há abertura para mulheres, se há disparidade salarial e, fora isso, equilibrar a vida profissional com as demandas endereçadas às mulheres na sociedade.',
      },
      {
        type: 'text',
        text: '<strong>E o machismo, como fica?</strong>',
      },
      {
        type: 'text',
        text: 'O machismo não se trata somente de ofender mulheres, considerá-las inferiores aos homens ou de uma expressão machista. Na verdade, ele é um mecanismo que estrutura a sociedade. O patriarcado é o responsável principal por criar os papéis de gênero. O machismo não é apenas uma ofensa. É o mecanismo que regula os comportamentos sociais e as demais características. Determina quem trabalha com o que, quanto ganha cada gênero, quem realiza as tarefas domésticas e quem tem responsabilidade de educar os filhos.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong><br> O episódio #21 do Trampapo, <strong>PROFISSÃO TEM GÊNERO?</strong>, foi uma conversa fundamental sobre como a relação gênero x profissão coloca barreiras no caminho das pessoas, limita capacidades de aprendizado e prejudica sonhos. Qual é o papel das empresas no combate do machismo e da masculinidade tóxica? Como o acesso à informação tem diminuído a desigualdade e valorizado as qualidades humanas? Se liga nas dicas extracurriculares deixadas pela convidada da vez e pelos hosts do Trampapo!',
      },
      {
        type: 'text',
        text: '<strong>Sheynna Hakim Rossignol</strong> tem experiência nacional e internacional como Diretora em empresas do setor financeiro e tecnologia, nas indústrias de pagamentos e seguros, responsável por áreas comerciais, de produtos e desenvolvimento de negócios, marketing, RH e atendimento. É executiva de negócios, conselheira de digital, investidora de Startups Mercado Financeiro e General Manager Brasil no Chama the App.<br> É formada em Engenharia Civil, mestre em administração, tem MBA e pós-graduação em gestão estratégica e em controle de riscos. <br> <strong>Suas dicas extras no EP#21 foram:</strong><br><br> <a href="https://www.ted.com/talks/angela_lee_duckworth_grit_the_power_of_passion_and_perseverance?language=pt-br"><strong>TED Talk - Garra: O Poder do Entusiasmo e da Perseverança, da Angela Duckworth</strong></a><br> <strong>Livro - Faça Acontecer:  Mulheres, Trabalho e a Vontade de Liderar, por Nell Scovell e Sheryl Sandberg</strong><br> “Eu tenho duas recomendações, uma de TED Talk e outra de livro para fazer. Foram mulheres que influenciaram a minha vida, e que inclusive nas empresas, eu coloco o livro, eu faço uma biblioteca coletiva e coloco esses livros à disposição. Então o livro dela é incrível e ela fala sobre essas dificuldades e ela fala como sobressair, como resolver várias delas, então recomendo o livro da Sheryl. E recomendo o TED Talk da Angela Duckworth, Garra: O Poder do Entusiasmo e da Perseverança, da Angela Duckworth. Super vale a pena, me inspirou, eu recomendo.”',
        image: content_09_guest_01,
        alt: 'Sheynna Hakim Rossignol',
      },
      {
        type: 'text',
        text: '<strong>Já nossos hosts, indicaram:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais</strong><br> <strong>Série: Liberdade de Gênero, Globoplay</strong><br> “Ela conta a história de diversas pessoas ali com o foco mais de recorte transgênero para debater essas questões de gênero aqui no país. É um conteúdo bem importante para a gente falar como essas pessoas procuram mais respeito, e como é que pode ser mais acolhedor a equidade de gênero.”<br> <strong>Filme: Mercado de Capitais, 2016</strong><br> “Retrata o ambiente de uma executiva em Wall Street, e aí fala todos os desafios que uma mulher passa no mercado financeiro. O que é legal, porque esse filme, ele tem uma história muito grande por trás, primeiro ele foi subsidiado inicialmente por mulheres que trabalham em Wall Street, ele foi dirigido pela primeira vez com uma mulher, é diretora e faz um filme sobre Wall Street. E também por que dentro, por trás das câmeras, é um dos principais filmes onde as mulheres tiveram a maior parte dos papéis da produção. Então assim, é mostrar de ponta a ponta, da produção até uma história, o poder da mulher, é um mercado extremamente competitivo e bastante machista.”',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong><br>  <strong>A Menina que brinca de tudo, da Carolina Magalhães</strong><br> <strong>Deixa eu te contar, menina / Deixa eu te contar, menino, por Thaís Vilarinho e Raíssa Bulhões</strong><br> “Minhas dicas de hoje vão para mães, pais, criadores e educadores, dois livros infantis para apoiar na educação das crianças. O primeiro é: A Menina que brincava de tudo, é da Carolina Magalhães, e o segundo livro, é um livro muito bonitinho, um lado de menina, outro lado de menino, chama Deixa eu te contar menina, e deixa eu te contar menino, que fala sobre a liberdade, fala sobre sentimentos, fala sobre profissões, então deixa esses dois livros nas estantes dos seus filhos.”',
      },
      {
        type: 'text',
        text: 'Beijos, da equipe Trampapo <3 <br> Até a próxima!',
      },
    ],
    thumbnail: content_09_thumb,
  },
  {
    id: 107,
    date: '05/03/2021',
    categories: ['DIVERSIDADE'],
    slug: 'mulheres-tech-e-empresas',
    title: 'O que as mulheres na tecnologia devem buscar nas empresas?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_08_thumb,
        alt: 'O que as mulheres na tecnologia devem buscar nas empresas?',
      },
      {
        type: 'text',
        text: 'Numa área de tecnologia que é predominantemente masculina, as mulheres que buscam se posicionar neste mercado enfrentam diversos e grandes desafios, a começar pela falta de estímulo durante a infância e a fase escolar, a pouca representatividade feminina no setor e o machismo que acaba por afastá-las das áreas tecnológicas.',
      },
      {
        type: 'text',
        text: 'O machismo, em especial, é o mecanismo que mais dificulta a inserção feminina na área tech. As salas de aulas dos cursos de ciências da computação, por exemplo, podem ser ambientes potencialmente prejudiciais emocionalmente para elas. Não faltam relatos de mulheres que foram discriminadas por professores e colegas, que duvidaram de suas capacidades de aprendizado e de performance simplesmente por causa do gênero, contribuindo muito com a desistência de inúmeras graduandas. Sinal disso é que apenas 13% dos alunos matriculados neste curso, de acordo com uma pesquisa do IME-USP, são mulheres.',
      },
      {
        type: 'text',
        text: 'Outros dados alarmantes que indicam a desigualdade de gênero neste segmento foram levantados pela Catho. De acordo com a Pesquisa Salarial Catho 2021, apenas 19% dos profissionais de TI são mulheres e elas ganham até 11% a menos que os homens. Olhando para a área de programação, apenas 14% são mulheres.<br>E considerando tudo isso, concluímos que…',
      },
      {
        type: 'text',
        text: '<strong>O futuro está sendo programado por homens.</strong>',
      },
      {
        type: 'text',
        text: 'As mulheres estão sendo excluídas do mercado de tecnologia no Brasil. Enquanto elas representam menos de ¼, os homens dominam a participação, chegando a quase 80%. E se nos atentarmos ao fato que o futuro já é e cada vez será mais digital e tecnológico, percebemos que o que devemos esperar, lá na frente, é mais machismo. Se a grande maioria dos desenvolvimentos de equipamentos, softwares, produtos, soluções e tudo mais, são os homens que realizam, porque a tecnologia seria inclusiva para todxs? Como poderíamos ter soluções tecnológicas pensadas para mulheres? Além disso tudo, a conscientização a respeito da pauta ainda é muito recente. Apesar do forte esforço criado por coletivos de mulheres da área, empresas que nasceram com o propósito de equilibrar a balança e empresas que buscaram diversificar seus times, falta muito para o debate transcender em práticas consistentes. Afinal, dentre as empresas que não estão engajadas com as causas de gênero, que infelizmente ainda são muitas, o assunto não é nem mesmo discutido. Quantos desses executivos acreditam que as mulheres deveriam mesmo ter oportunidades iguais às deles? Quantos ainda acham que elas devem tomar conta de casa e que eles precisam ser os provedores financeiros? Quantos CEOs e líderes que são homens desejam abrir mão do seu privilégio e serem liderados por mulheres? ',
      },
      {
        type: 'text',
        text: 'Sabendo disso, as mulheres da área precisam estar atentas na hora de escolher uma oportunidade de trabalho, <strong>quando for possível fazer uma escolha.</strong>',
      },
      {
        type: 'text',
        text: '<strong>O que as mulheres de tecnologia devem buscar numa empresa?</strong>',
      },
      {
        type: 'text',
        text: 'Então, mais do que uma vaga aberta em algum dos setores de TI e Devel da empresa, elas precisam buscar outras características nas companhias para poderem ter uma experiência plena, aplicar seus conhecimentos com segurança, aprender e crescer profissionalmente.',
      },
      {
        type: 'text',
        text: '<strong>1-) Representatividade e proporcionalidade:</strong><br> Chegar em uma empresa como a nova funcionária de tecnologia e não ser a única mulher da área já é um bom sinal e um alívio. É muito importante que as mulheres estejam representadas na área, mas também é fundamental olhar para a proporcionalidade. Além de você, mais uma ou duas mulheres, existem outras? Por quem são ocupados os cargos de liderança? Existem mulheres nas cadeiras que dirigem a empresa? As outras áreas também são abertas para as mulheres? Qual a proporção de mulheres em cada setor, na tecnologia e nas lideranças?<br> A desproporção pode apontar uma falsa prática de equidade pautada no pilar de gênero que, no fim, não inclui de fato as mulheres no mercado de trabalho nem acolhe suas ideias e identidades verdadeiramente.',
      },
      {
        type: 'text',
        text: '<strong>2-) Diversidade por completo:</strong><br> A busca por maior participação feminina na TI não se limita a conquistar cargos. É preciso conquistar voz e escuta e não se contentar com meias justiças. O mercado de trabalho é excludente com os mais variados grupos sociais, que são menosprezados durante os processos educacionais, de recrutamento pelas empresas e também nos relacionamentos dentro dos ambientes de trabalho. E se a empresa que você está cogitando ir trabalhar “só” se importa com as mulheres em tecnologia, desconfie. Diversidade, como o próprio nome já diz, é diversa. Procure empresas que queiram também incluir pessoas negras, pessoas com deficiência, mães, lésbicas, gays, bissexuais, transgêneres, idosos, refugiados e todas as pessoas em seus quadros de funcionários e em todos os níveis. Isso é um importante indicador do quanto você terá de voz e liberdade.',
      },
      {
        type: 'text',
        text: '<strong>3-) Salários Iguais:</strong><br> A diferença salarial apontada pela Catho entre homens e mulheres em tecnologia é de, em média, 11%. Isso significa que temos um peso, duas medidas. Mulheres que realizam os mesmos serviços que os homens e recebem menos por isso. Não dá, né?<br> Se possível, busque por empresas que tenham uma política mais transparente quanto aos salários. Boa parte das empresas complica o processo de negociação salarial, questionando quanto as mulheres ganham atualmente, qual foi o último salário ou quanto desejam ganhar, ao invés de falar logo: “pago tanto”. E isso também pode ser uma forma de tentar pescar o que tem por trás dessa negociação. O recrutador está tentando pagar o mínimo aceitável pela candidata ou o quanto ele realmente dispõe como teto para o profissional ideal para aquela vaga em questão?',
      },
      {
        type: 'text',
        text: '<strong>4-) Reconhecimento: financeiros e mimos</strong><br> Ser reconhecida não é ter promoção após anos e anos dando o suor sem receber outras formas de reconhecimento. Faz parte também receber agradecimentos, feedbacks e elogios públicos. E aumentos e promoções. O verdadeiro reconhecimento é ter o trabalho valorizado sempre, sem ter as habilidades postas em xeque. É ter o nome como uma das referências da equipe naquilo que se faz. É ser lembrada quando vai rolar alguma ação especial e divertida, quando vai ter presentes pros colaboradores que mais se destacaram no período. É conquistar altos cargos e salários. <br> Só o dinheiro não resolve. Só elogios não resolvem. Reconhecimento profissional tem que ser por completo.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong>',
      },
      {
        type: 'text',
        text: 'O episódio #20 do Trampapo, <strong>MULHERES NA TECNOLOGIA: YOU CODE, GIRL!</strong>, abordou a vivência de mulheres mães no mercado de trabalho. Vem ver quem são as convidadas da vez e conferir as dicas de conteúdos delas!',
      },
      {
        type: 'text',
        text: '<strong>Karen Santos</strong> é Product Designer no Quinto Andar, CEO da UX para Minas Pretas, uma iniciativa por e para mulheres negras com foco em UX que promove a equidade de mulheres negras no mercado de tecnologia por meio de ações de formação, empoderamento, compartilhamento de conhecimento e articulação em rede. Também coordena, desenvolve e atua em iniciativas voltadas ao Design, Tecnologia e Feminismo/Movimento Negro. Premiada pela Forbes - Under 30 a lista Forbes 30 Under 30 destaca os mais brilhantes empreendedores, criadores e game-changers abaixo dos 30 anos, que revolucionam os negócios e transformam o mundo. Premiada como Rising Star pela Globant Awards - Women that Build Edition é um prêmio que busca reconhecer as mulheres que tenham construído suas carreiras em tecnologia, gerenciando projetos de inovação e colaboração e promovendo a diversidade e a inclusão.',
        image: content_08_guest_01,
        alt: 'Karen Santos',
      },
      {
        type: 'text',
        text: 'A <strong>#DicaExtracurricular</strong> dela é, justamente, a <a href="https://medium.com/uxmpretas"><strong>UX para Minas Pretas:</strong></a> “se você é mulher preta, parda, indígena, cis ou trans, será muito bem-vinda na nossa comunidade. Ela tem o foco em UX, na área de tecnologia, então se você deseja conhecer mais, aprender, ter um espaço para trocar com outras mulheres pretas, vai ser super bacana te receber. Basta entrar em contato com a gente através das redes sociais com o nome UX Minas Pretas.”, recomendou.',
      },
      {
        type: 'text',
        text: '“Tem também o Pretux, que é focado em UX e é uma comunidade que é um pouco mais geral, para homens, mulheres e todos os gêneros. Tem designers negros do Brasil, que também tem uma cartilha gigantesca, tem um site que é uma vitrine com diversos profissionais na área de UX.”, completou Karen.',
      },
      {
        type: 'text',
        text: '<strong>Cynthia Zanoni</strong> é Estrategista de tecnologia de parceiros na Microsoft, Fundadora da WoMakersCode, uma comunidade sem fins lucrativos com o objetivo de apoiar mulheres na área de tecnologia. Atualmente, é uma das principais comunidades #WomenInTech no Brasil<br><br> Suas dicas extracurriculares para o Trampapo foram:<br><br> <a href="https://womakerscode.org/"><strong>WoMakersCode:</strong></a> Cynthia convidou mais mulheres para fazer parte da comunidade: “acompanhe as comunidades de tecnologia, venha fazer parte da. Hoje a gente está presente em vários lugares aqui do Brasil, não somente no eixo aqui do Sul e Sudeste, mas a gente tem uma força muito grande no Nordeste também. Todas as formações são bastante direcionadas, tanto para essa parte de soft skills, mas como também programação, com tecnologias abertas e ciência de dados. Nós formamos uma turma de cientistas de dados, do qual a gente teve mais de 50% de mulheres pretas e todas elas foram empregadas em várias startups”, explicou.',
        image: content_08_guest_02,
        alt: 'Cynthia Zanoni',
      },
      {
        type: 'text',
        text: '<strong>Essencialismo: A disciplinada busca por menos,  Livro por Greg McKeown</strong><br> Cynthia comentou: “Fala sobre práticas que a gente pode adotar no nosso dia a dia para melhorar o nosso foco e tentar ser uma pessoa mais direcionada para os nossos objetivos. E isso é muito especial para quando a gente pensa em mulheres que estão vendo agora na tecnologia a carreira que vai dar oportunidade para trazer o sustento para a sua casa, para sua vida, tem tanta modernidade, tanta coisa, que muitas vezes na transição de carreira, você fica perdida e não sabe qual área ir. É um livro que transformou bastante a minha vida em 2020, tenho certeza que pode ser bem interessante aí para quem está nesse caminho de transformação ali e autoconhecimento também.”',
      },
      {
        type: 'text',
        text: '<strong>Já nossos hosts, indicaram:</strong>',
      },
      {
        type: 'text',
        text: '<strong>Newsletter The Brief</strong><br> <strong>Ricardo Morais:</strong> “É uma newsletter que fala sobre tecnologia, mundo de startup, novidades que gira em torno desse mundo. O que é que eu acho muito legal neles? A pegada fala muito de igualdade, eles trazem um olhar muito sobre as pessoas que estão começando na carreira, e ali você tem notícias que muitas vezes estão somente em meios lá de fora, ou estão em língua inglesa, eles traduzem bonitinho”.',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong> “Eu quero indicar a Infopreta, que é uma empresa de informática e que também oferece computadores para mulheres negras ingressarem nas faculdades de tecnologia. Quero indicar também a Lisiane Lemos que sem dúvida é uma das minhas grandes referências quando eu penso em tecnologia. Ela também cria conteúdo sobre esse tema, sobre o mundo corporativo, como eu disse, é uma das minhas grandes referências, então parabéns, amiga.”',
      },
      {
        type: 'text',
        text: '<strong>A Gente se Vê Ontem, filme de 2019</strong><br> <strong>Ana:</strong> “É um filme de ficção científica que tem como protagonista uma menina preta, então ela é uma menina, gosta muito de ciência e usa a tecnologia para fazer diversas coisas. É um filme ótimo para você assistir, para você assistir com as crianças mais perto de você, para você assistir em família, para criar esse imaginário possível para mulheres negras dentro da tecnologia.',
      },
    ],
    thumbnail: content_08_thumb,
  },
  {
    id: 106,
    date: '26/02/2021',
    categories: ['CAUSAS'],
    slug: 'empregos-a-prova-de-maes',
    title: 'Empregos e os “filtros à prova de mães”',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_07_featured,
        alt: 'Empregos e os filtros à prova de mães',
      },
      {
        type: 'text',
        text: '<strong>Já ouviu falar de “filtros à prova de mães”?<strong>',
      },
      {
        type: 'text',
        text: '(no RH de uma empresa não tão distante de você)',
      },
      {
        type: 'text',
        text: '<p> &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; “Time, hoje teremos diversas entrevistas para o processo seletivo daquela vaga de Gerente Comercial. Estão todos preparados?” <br /> &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; “Sim, estamos!” <br /> &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; “Legal! Lembrando que queremos alguém com total dedicação, flexibilidade de horário, que possa sempre estar disponível para resolver os problemas lá no ponto de venda, ok?” <br />  &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; “Certo!” <br /> &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp;  “Então, só pra garantir que vamos encontrar a pessoa certa, vocês devem seguir aquela listinha de perguntas quando for mulher, tá bem? Não podemos correr riscos de novo de encontrar alguém que não dê conta. Não dá mais pra ter desfalques lá no comercial nem podemos ficar trocando de Gerente a cada trimestre. Vamos lá, time!” <br /> &nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; “Combinado, chefe.” </p>',
      },
      {
        type: 'text',
        text: 'Tá vendo esse diálogo? Ele é ficcional, mas não ache que isso retrata uma fantasia. Infelizmente, é muito comum que as empresas, sejam elas as pequenas, as médias ou as grandes, tenham condutas discriminatórias com as mulheres. Em especial com as que são mães.',
      },
      {
        type: 'text',
        text: '<strong>Não é incomum que muitos empregos tenham “filtros à prova de mães” em seus processos seletivos.</strong> Seja pela própria cultura da empresa, que é quando a alta liderança compactua, seja pela conduta individual de líderes e entrevistadores, é certo que as mães são estigmatizadas no mercado de trabalho.',
      },
      {
        type: 'text',
        text: 'Por um lado, os empresários ficam preocupados com possíveis prejuízos financeiros, afinal, como eles poderiam continuar bancando o salário de uma pessoa por meses, sem que ela vá ao trabalho, sendo que eles não têm culpa alguma da colaboradora ter engravidado? Como eles poderiam lidar com possíveis horas de atraso? Como iriam fazer nas diversas vezes que ela estiver de atestado para poder acompanhar os filhos no médico? Já diz o ditado: tempo é dinheiro.',
      },
      {
        type: 'text',
        text: 'Mas, do outro lado, estão as mães, profissionais que precisam de trabalho, dinheiro, oportunidades e dignidade. Conciliar maternidade e trabalho é um desafio inalcançável para muitas mulheres aqui no Brasil. Além do fardo de, muitas vezes, a gestação ter sido compulsória - quando não há escolha sobre o fato de engravidar ou não -, a maternidade solo é muito frequente por aqui, ou seja, são milhões de mães lutando diariamente e solitariamente para educar seus filhos com o maior conforto possível. Só pra gente ter uma ideia em números, de acordo com o Censo Escolar, realizado pelo Conselho Nacional de Justiça em 2013, há 5,5 milhões de crianças brasileiras sem o nome do pai na certidão de nascimento. Isso significa que a sobrecarga dessas mulheres ocorre principalmente pela falta de pessoas que compartilhem os cuidados com as crianças e serviços domésticos, em especial, a falta dos próprios pais.',
      },
      {
        type: 'text',
        text: '<strong>E pelo problema causado por eles, elas pagam o pato.</strong>',
      },
      {
        type: 'text',
        text: '(Em uma entrevista não tão longe de você)',
      },
      {
        type: 'text',
        text: '“Você tem filhos?”<br/>  “Você pretende ter filhos?”<br/> “Quantos anos têm os seus filhos?”<br/> “Com quem eles ficam enquanto você trabalha?”<br/> “Quando eles ficam doentes, quem leva ao médico?”<br/> “Seu esposo não liga de você deixar os filhos de lado para trabalhar fora?”<br/> “Você não acha que ter um filho neste momento pode atrapalhar sua carreira?”',
      },
      {
        type: 'text',
        text: 'Se você nunca ouviu nenhuma dessas perguntas em uma entrevista de emprego, é quase certo que você é homem. Ou então é uma mulher com um pouco mais de sorte que a maioria.',
      },
      {
        type: 'text',
        text: 'A entrevista de emprego é um momento de avaliação. O entrevistador ou entrevistadora analisa se a pessoa candidata se encaixa ou não ao perfil da vaga e à equipe da qual fará parte. Logo, qualquer pergunta que for feita tem como objetivo fazer essa verificação. Agora, diga: por qual motivo essas perguntas são feitas para as mães, a não ser para saber se elas ‘dão conta do recado’? ',
      },
      {
        type: 'text',
        text: 'Esses tipos de questionamentos partem do pressuposto que as mães não são capazes de se dedicarem tanto ao emprego em comparação com homens ou mulheres sem filhos, já que, na sociedade brasileira, as atividades domésticas e de cuidado com os filhos são vistas quase como exclusivamente femininas.',
      },
      {
        type: 'text',
        text: 'Para as mulheres, as entrevistas de emprego são muito mais invasivas por este motivo. As empresas que não têm um olhar humanizado querem saber de quem vai dar lucro, e não quem vai precisar de apoio, acolhimento e oportunidade. E apesar da legislação brasileira proibir perguntas sobre essas questões numa entrevista de emprego, justamente por serem perguntas discriminatórias, a realidade é bem diferente. Não perguntam apenas se elas têm filhos, mas também como elas vão se virar quando tiver uma emergência com as crianças. E o resultado disso é o agravamento da desigualdade de gênero no mercado de trabalho.',
      },
      {
        type: 'text',
        text: '<strong>Cadê o emprego que tava aqui? A dupla-jornada levou!</strong><br>',
      },
      {
        type: 'text',
        text: 'Toda essa discriminação é pautada principalmente na dupla jornada das mães. Com a forte cultura patriarcal que temos, os papéis de gênero são quase que inquestionáveis para muitos: “cuidar da casa e cuidar dos filhos é coisa de mulher!”<br /> Se o senso comum, aquilo que quase toda a sociedade acha, é que as mulheres são as exclusivas responsáveis pelos filhos, podemos entender o porque as empresas acham que as mães são piores profissionais do que as outras pessoas. Elas sabem que há uma desproporção entre as atividades domésticas entre os gêneros. Mas mesmo com esse entendimento, culpabilizam as mães por não terem a mesma disponibilidade de tempo livre que os demais. Responsabilizam as mães por serem mães.',
      },
      {
        type: 'text',
        text: 'Uma pesquisa da Catho revelou que 21% das mulheres levam mais de três anos para retornar ao trabalho após ter filho. Já com os homens, a taxa é de apenas 2% de casos. O desemprego é estruturalmente mais alto entre as mulheres com filhos. Depois da maternidade, muitas tentam voltar, mas o mercado não abre portas, as demitem, não as promovem. Isso tudo são barreiras que dificultam a chegada delas em cargos de liderança.',
      },
      {
        type: 'text',
        text: 'Pensando nisso, além da resolução da falta crônica de vagas em creches e pré-escolas, fica nítido que um dos caminhos para resolver esse cenário é a reconstrução dos papéis de gênero na sociedade, no exercício da paternidade ativa e da parentalidade. Se tivéssemos pais mais presentes, talvez não fosse tão difícil para elas voltarem ao mercado de trabalho nem galgar outras posições. É imprescindível que exista uma rede apoio às mães para que elas tenham condições de trabalhar fora. Até por isso, a Organização das Nações Unidas (ONU) estabeleceu a divisão de tarefas domésticas como meta para alcançar igualdade de gênero.',
      },
      {
        type: 'text',
        text: '<strong>Filhos: ônus na carreira dela, bônus na carreira dele</strong><br>',
      },
      {
        type: 'text',
        text: 'Até que ponto o problema são os filhos? O machismo constrói preconceitos em cima das características femininas e os usa para justificar atitudes bastante controversas. Quantas mulheres que não foram contratadas por serem mães ou que foram demitidas após virarem mães você conhece? E quantos homens na mesma situação?',
      },
      {
        type: 'text',
        text: 'Isso traz um debate de como os homens são vistos em suas vidas profissionais. Essa mesma visão machista e patriarcal é eficaz em reforçar o papel que faz referência ao pai provedor. Diversos estudos mostram que a paternidade geralmente é acompanhada por uma bonificação salarial e por uma consciência que diz que os homens que viram pais se tornam mais responsáveis, enquanto a maternidade prejudica o crescimento e a remuneração das mães, aliado à crença de que elas não dão conta do trabalho.',
      },
      {
        type: 'text',
        text: 'Reverter esse quadro histórico é um trabalho grandioso. São muitas as facetas que promovem essa desigualdade de oportunidades e julgamentos. Mas há muito a se fazer para amenizar esse problema social. As culturas corporativas, por meio das lideranças, devem se sensibilizar com as demandas das mulheres, precisam se organizar para lidar com licenças, de qualquer um, seja homem, seja mulher, precisam aderir ao conceito de equidade e propiciar condições iguais de crescimento para todos.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong><br> O episódio #19 do Trampapo, <strong>TRABALHO DE MÃE</strong>, abordou a vivência de mulheres mães no mercado de trabalho. Vem ver quem são as convidadas da vez e conferir as dicas de conteúdos delas!',
      },
      {
        type: 'text',
        text: '<strong>Vivian Abukater</strong> é mãe, graduada em propaganda e marketing, pós-graduada em Administração e em Metodologia Científica da Educação.<br /> Ela se dedica ao estudo do empreendedorismo e seu ecossistema, trabalha como consultora e mentora com foco em famílias empreendedoras em projetos de planejamento e gestão para seus negócios. <br> Atua como associada da Rede Maternativa, o maior grupo de empreendedorismo materno do Brasil, desenvolvendo uma gestão focada no impacto social. Como executiva e consultora, atuou nas áreas de marketing, comunicação, gestão de negócios e vendas para empresas líderes em seus segmentos como, Bunge, Souza Cruz, VIVO, Absolut, L&apos;Oreal, Hershey&prime;s, Kibon, Lexmark ,Toyota e Trussardi. <br/>  <br /> Suas dicas para o quadro <strong>#DicaExtracurricular</strong> foram: <br /> <strong>Relatórios sobre parentalidade e primeira infância:</strong> <a href="https://promundo.org.br/recursos/?tipo=relatorios">PROMUNDO</a> e Accenture, sobre a experiência dos colaboradores nas organizações e os impactos da chegada dos filhos nas carreiras das mulheres e dos homens.',
        image: content_07_guest_01,
        alt: 'Vivian Abukater',
      },
      {
        type: 'text',
        text: '<strong>Mariana Luz</strong> é mãe, profissional experiente em relações institucionais e responsabilidade social. Trabalha  com assuntos governamentais, sustentabilidade, comunicação, cooperação internacional, política comercial, inovação e P&D, bem como formulação de políticas públicas e organizações sem fins lucrativos. Jovem Líder Global do Fórum Econômico Mundial, e CEO da Fundação Maria Cecilia Souto Vidigal, que promove ações que colaboram para o desenvolvimento integral da criança, com foco na primeira infância. É bacharel e MBA em Relações Internacionais, mestre em artes, estudou Liderança Global e Políticas Públicas para o Século 21 na Harvard Kennedy School e Liderança transformacional na Universidade de Oxford. <br> Suas dicas extracurriculares para o Trampapo foram: <br /> <br /> <strong>Filmes:</strong> <a href="https://www.fmcsv.org.br/pt-BR/impacto/o-comeco-da-vida/"> O Começo da vida, 1 e 2</a><br> O Começo da Vida é um filme-convite. As histórias desses bebês de diferentes continentes, por sua força, nos convocam a ser um agente de mudança na nossa sociedade. Sem querer, pensamos: estamos cuidando bem dos primeiros anos de vida, que definem tanto o presente quanto o futuro da humanidade? Um movimento global a partir de um documentário.',
        image: content_07_guest_02,
        alt: 'Camila Almeida',
      },
      {
        type: 'text',
        text: '<strong>Já as dicas dos nossos queridos e incríveis hosts foram:</strong><br>',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais:</strong> <br /> <strong>Documentário:  The mask you live in</strong>, que aborda a crise das crianças americanas e como educar uma geração de homens saudáveis na perspectiva de especialistas e acadêmicos. Fala sobre a masculinidade tóxica, como ela afeta os homens com relação ao cuidado dos filhos, sentimentos, conexão e afeto no geral. Disponível na Netflix.',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong><br><strong>Livro: O ponto zero da revolução: trabalho doméstico, reprodução e luta feminista,</strong> por Silvia Federici. <br /> O livro contempla pesquisas e teorizações sobre a natureza do trabalho doméstico, da reprodução social e da luta feminista para construir e reconstruir, nos territórios e coletivamente, alternativas às relações capitalistas e patriarcais que oprimem as mulheres há séculos ',
      },
    ],
    thumbnail: content_07_thumb,
  },
  {
    id: 105,
    date: '19/02/2021',
    categories: ['QUALIFICAÇÃO'],
    slug: 'educacao-limitou-ou-libertou',
    title: 'Sua educação te limitou ou libertou?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_06_featured,
        alt: 'Sua educação te limitou ou libertou?',
      },
      {
        type: 'text',
        text: 'Muito provavelmente, durante sua fase escolar,  você já ouviu alguém falar, ou até mesmo você já falou, que não entendia porque tal matéria era aplicada, já que não sabia como aquilo poderia ser útil na vida adulta. “Saber calcular fórmula de bhaskara e ângulos ou decorar todas as tabuadas pra que? Quando vou precisar disso no dia a dia? Hoje em dia as máquinas calculam tudo eletronicamente”.',
      },
      {
        type: 'text',
        text: 'E, em partes, aqueles que faziam estes questionamentos estavam certos.',
      },
      {
        type: 'text',
        text: '<Strong>Pra que ‘educamos’?</Strong>',
      },
      {
        type: 'text',
        text: 'Nosso sistema de educação, em especial o sistema público, por todo o mundo, está baseado em habilidades acadêmicas e hierarquiza as disciplinas. Ou seja, há uma ordem de importância dentre as matérias ensinadas que prioriza a matemática e as línguas, as ciências, depois as humanas e, por fim, as artes. E há uma razão para isso.',
      },
      {
        type: 'text',
        text: 'Talvez você logo pense: “É claro que há uma razão. As escolas precisam ensinar e preparar os alunos para, no futuro, poderem trabalhar e produzir economicamente para si próprios e para a sociedade”. E é justamente esse o ponto onde queremos chegar.',
      },
      {
        type: 'text',
        text: 'Essa noção de que a escola serve para produzir profissionais foi constituída para atender as demandas da industrialização, no séc. XIX, e assim vigora até os dias de hoje. E então foram elencadas as disciplinas por ordem de importância.<br/> No topo da hierarquia estão as disciplinas mais úteis para o trabalho e, como consequência, teorias e práticas focadas em ciências humanas e nas artes são consideradas menos importantes, merecem menos horas de estudos e menor investimento, até porque, nessa lógica, são reforçadas as ideias que dizem que “artes não dão dinheiro”, “operários não pensam, executam” e, aos grandes gênios habilidosos com os números, dizem que “merecem os melhores empregos e salários”.',
      },
      {
        type: 'text',
        text: 'No entanto, essa lógica não proporciona idealmente um ambiente de aprendizagem. Se desde a infância as pessoas são conduzidas a um rumo que pretende formar profissionais produtivos financeiramente, quais são as perspectivas para alavancar outros conhecimentos e potencialidades? Quantos talentos voltados para outras áreas deixam de ser estimulados? Como incentivar o pensamento crítico sobre as relações educacionais e sociais? Como podemos acolher as múltiplas características da inteligência se a avaliação desconsidera as aptidões naturais de cada um? Como exigimos inovação e diversidade de pensamento se os moldes são iguais para todos? Como não limitar a mente humana durante as práticas de educação se a linha de chegada é única?',
      },
      {
        type: 'text',
        text: 'Condicionando as crianças e jovens a ideia de aprender para lucrar, desperdiçamos a riqueza da criatividade humana que, por sinal, é muito perceptível nos mais novos. Deixamos de lado a valorização da originalidade e damos lugar ao ato de memorizar. Memorizar fórmulas, tabuadas, regras, datas… E, assim, minamos o futuro, a inovação, talentos e novas possibilidades, sem dar a devida importância à criatividade.',
      },
      {
        type: 'text',
        text: '<strong>Efeitos no mercado de trabalho</strong>',
      },
      {
        type: 'text',
        text: 'E qual o efeito disso no mercado de trabalho? Formamos profissionais que dificilmente terão ideias originais porque não foram estimulados e porque não estão capacitados para lidar com os próprios erros, afinal, sempre foram avaliados por acertos e erros pré-determinados pelo sistema educacional. As respostas são prontas e engessadas - são certas ou erradas. Basta decorar, estudar.',
      },
      {
        type: 'text',
        text: 'Com isso tudo, chegamos a uma conclusão: a educação, como a conhecemos hoje, tem o objetivo de extrair recursos específicos para fabricar outros recursos capazes de produzir riquezas de uns, mas isso ocorre em detrimento das individualidades e satisfação de outros.<br /> Aqueles que conseguem o sucesso, que alcançam as melhores posições nas empresas, poderes públicos e na sociedade são aqueles capazes de se adequar ao currículo de ensino, mas não necessariamente são aqueles com mais inteligência, talentos ou que fizeram mais esforços. O sistema educacional público é uma estrada para o ingresso à universidade e ao mundo corporativo e a aptidão acadêmica, que controla a visão geral sobre o que é a inteligência, define quem são as mentes brilhantes, valoriza determinados acertos e estigmatiza as pessoas que não se adequam a ela, fazendo-as a acreditarem que não são boas e inteligentes.',
      },
      {
        type: 'text',
        text: '<strong>Como mudar? A educação pode ser libertadora ? </strong> <br /> Sim, podemos ter uma educação que seja libertadora, que deixe de buscar exclusivamente por acertos e busque também valorizar a capacidade humana em toda sua totalidade, dando espaço para erros, criações e a livre expressão. Para isso é preciso reconstituir a concepção da educação, os princípios elementares de como as crianças são ensinadas, permitidas e limitadas.',
      },
      {
        type: 'text',
        text: 'Quer se aprofundar no assunto com leveza e entender como a educação e a qualificação se relacionam com a empregabilidade? No episódio #18 do Trampapo, Ana Paula Xongani e Ricardo Morais falam com as convidadas Patricia Anunciada e Camila Almeida sobre como a qualificação pode te libertar para uma vida profissional mais feliz e para um mercado de trabalho mais diverso. No podcast abordamos a educação humanizada e seu processo de inovação, desigualdades sociais, meritocracia e escola do futuro. Como podemos romper as barreiras e ajudar com a ascensão das pessoas menos favorecidas? As empresas podem contribuir para isso? Quais são as desvantagens de se adotar um sistema EAD? Se você ainda não ouviu, corre na home do site e ouça já!',
      },
      {
        type: 'text',
        text: '<strong> Vem saber mais! #DicaExtracurricular  </strong><br>',
      },
      {
        type: 'text',
        text: '<strong>Patricia Anunciada</strong> é  formada em Letras pela PUC/SP e pós-graduada em Língua e Literatura pela UNICAMP. Trabalha como professora de Português e Inglês, desenvolve estudos na área de Literatura Afro-brasileira e Educação para as Relações Étnico-Raciais e também cria conteúdos para o <a href="http://blogueirasnegras.org/author/patricia-anunciada/">Blogueiras Negras</a> e <a href="https://www.youtube.com/channel/UC-teln-OIMkYXxRWH9jq-hw">Letras Pretas, no Youtube.</a> Suas dicas foram: <br /> <a href="https://www.ted.com/talks/chimamanda_ngozi_adichie_the_danger_of_a_single_story?language=pt"><strong>Vídeo: TED 2009 | Chimamanda Ngozi Adichie | O perigo da história única</strong></a> <br> Nossas vidas, nossas culturas, são compostas por muitas histórias sobrepostas. Chimamanda conta a história de como descobriu a sua voz cultural — e adverte que, se ouvirmos apenas uma história sobre outra pessoa ou país, corremos o risco de cometer um erro crítico.',
        image: content_06_guest_01,
        alt: 'Patricia Anunciada',
      },
      {
        type: 'text',
        text: '<strong>Camila Almeida</strong> é formada em Desenho Industrial com Habilitação em Projeto de Produto pelo Mackenzie e tem pós-graduação em Design Estratégico e Inovação pelo Instituto Europeu de Design - IED São Paulo. Atualmente é Líder da Arco Academy | Design Estratégico na Arco Hub de Inovação e mentora voluntária de negócios na TODXS Impacto. Suas dicas foram: <br> <a href="https://www.ted.com/talks/sir_ken_robinson_do_schools_kill_creativity?language=pt-BR">Vídeo: TED 2006 - Ken Robinson | Será que as escolas matam a criatividade?</a> <br> Nesta apresentação, Ken Robinson defende de maneira divertida e profunda a criação de um sistema educacional que estimula (em vez de enfraquecer) a criatividade. <br> <strong>Livro: Confiança Criativa - Libere Sua Criatividade e Implemente Suas Ideias, por David Keller e Tom Kelley </strong> <br> Para Camila, esta leitura tem o potencial de resgatar a criatividade dos adultos para que eles sejam mais inventivos, inovadores e curiosos.',
        image: content_06_guest_02,
        alt: 'Camila Almeida',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais,</strong> nosso host e gerente sênior de marketing e comunicação da Catho, indicou plataformas de cursos online e gratuitos. <br /> <strong>1 - Google Primer </strong> - certificações do Google para o mercado de marketing digital e empreendedorismo digital <br /> <strong>2 - Facebook Blueprint</strong> - certificações para as operar com eficiência as ferramentas e campanhas de Facebook e Instagram',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani</strong>, apresentadora do Trampapo, indicou dois livros e um podcast:<br /> <strong>Livro: Ensinando a Transgredir: A educação como prática de liberdade, por bell hooks</strong> <br /><strong>Livro: Ensinando pensamento crítico: Sabedoria prática, por bell hooks</strong><br /> Ambos os livros abordam as práticas de ensino e aprendizagem sob uma perspectiva crítica ao sistema educacional e incentiva o questionamento, a liberdade e a consciência crítica, social, racial e de gênero. <br /> <strong>Podcast: Nada Sei,</strong> do Instituto Ayrton Senna e apresentado também pela Ana Paula Xongani, que faz uma viagem sobre diferentes formas de aprender: ouvindo, observando, estudando, fazendo, errando, ensinando e pela emoção. São abordados temas como educação integral, competências socioemocionais, protagonismo juvenil, educação à distância, projeto de vida, educação por projetos, alfabetização e vários outros, sempre debatidos entre especialistas em educação e personagens da sociedade que têm muito a contar sobre o tema.',
      },
    ],
    thumbnail: content_06_thumb,
  },
  {
    id: 104,
    date: '15/02/2021',
    categories: ['LIDERAÇÃO'],
    slug: 'chefes-autoritarios',
    title: 'Chefes autoritários, que desmotivam e não valorizam: como lidar',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_05_featured,
        alt: 'Chefes autoritários, que desmotivam e não valorizam: como lidar',
      },
      {
        type: 'text',
        text: '<strong>Chefes tóxicos são aquela história: se você nunca teve, você ainda vai ter.</strong><br><strong>Ou, pior, você é um deles!</strong>',
      },
      {
        type: 'text',
        text: 'Não sei o quanto você está por dentro da missão do Trampapo, mas vale dizer: nesta 2ª temporada, queremos engajar, empoderar e estimular pessoas a conquistarem seu espaço – e sua voz – no mercado de trabalho. O grande objetivo é mostrar a realidade de diferentes grupos da sociedade no mercado de trabalho e repensar, juntos dos ouvintes e convidados, em alternativas para tornar as oportunidades de emprego mais democráticas e as empresas mais diversas.',
      },
      {
        type: 'text',
        text: 'Pensando nisso, fica claro que uma das partes mais importantes para a construção de um mercado de trabalho mais humanizado é exigir que as empresas tornem suas lideranças mais preparadas para lidar com toda a complexidade que é cuidar de seus colaboradores. E quando dizemos ‘cuidar’, nos referimos a remunerar devidamente, sim, mas não apenas isso.',
      },
      {
        type: 'text',
        text: 'Uma boa liderança precisa ir além das habilidades estratégicas que conduzem as empresas para o lucro. Os líderes precisam ter foco nas pessoas, conhecer individualmente os membros do time e agir para gerenciar essa equipe para que realizem um trabalho integrado, com resultados, mas também com bem estar, segurança, aprendizado, confiança e perspectiva de futuro. E tudo isso depende dos líderes terem ou desenvolverem habilidades e competências de comunicação, didática, empatia, respeito e compreensão. <strong>Liderar é uma questão de habilidade comportamental!</strong>',
      },
      {
        type: 'text',
        text: 'Mas, como dissemos, se você nunca teve um chefe tóxico, é muito provável que você venha a ter. Ou a ser. ',
      },
      {
        type: 'text',
        text: '<Strong>Chefes tóxicos estão por todos os lugares.</Strong>',
      },
      {
        type: 'text',
        text: 'Infelizmente, essa é a realidade. Grande parte das empresas, ainda têm a visão de que chefes são melhores que líderes porque a figura de chefe, dentro das organizações, compactua com a ideia de que pressão traz mais resultados do que acolhimento; que funcionários são apenas números, e não pessoas reais com particularidades e sentimentos; que o caminho para tirar mais produtividade do time é apertar, sobrecarregar, assediar, ameaçar…',
      },
      {
        type: 'text',
        text: 'Isso acontece porque, em um curto prazo, a conduta de chefe autoritário, agressivo e extremamente pragmático pode dar resultados financeiros. Esse tipo de chefe consegue fazer com que seus colaboradores entreguem resultados rapidamente. Mas, no médio e longo prazo, a tendência é que esses mesmos colaboradores deixem de apresentar bons resultados por já estarem emocionalmente e psicologicamente abalados, vulneráveis, desmotivados e esgotados. E, por consequência, muitos deles acabam deixando seus empregos para buscar um ambiente de trabalho mais saudável, com liderança humanizada.',
      },
      {
        type: 'text',
        text: 'Mas, e então, para quem não quer arriscar trocar de empresa em um momento de crise econômica, o que resta é aceitar um chefe abusivo e tóxico e calar-se diante das injustiças? Vamos ver!',
      },
      {
        type: 'text',
        text: '<strong>Chefes autoritários, que desmotivam e não valorizam: como lidar?</strong>',
      },
      {
        type: 'text',
        text: 'No episódio #17 do Trampapo, “<strong>Chefe x Líder: a humanização da gestão</strong>”, a convidada Bianca Machado, da Catho Empresas, comentou que ela e a grande maioria dos gestores começam suas carreiras como chefes tóxicos e, muitas vezes, esse estilo de gestão é uma reprodução da atitude de outros chefes que já passaram pela carreira desses novos líderes, ou que fazem parte da mesma empresa que eles/elas. E, então, caberá ao novo líder se autoperceber como alguém que reproduz abusos, que é autoritário, ou ausente, ou vaidoso, ou indeciso… ou tudo isso junto.',
      },
      {
        type: 'text',
        text: 'No entanto, a própria falta de referências em suas carreiras e nas empresas pode agir como um ponto cego, onde os líderes não conseguem ter essas autocríticas porque não enxergam exemplos próximos a eles de como deveria ser uma boa liderança, humanizada, empática e colaborativa. Além disso, como registramos mais acima, é comum ainda encontrarmos empresas que são permissivas e compatíveis com esse estilo de gestão de pessoas, e por isso a cultura organizacional delas acaba dando margem para esse tipo de comportamento, inclusive, em alguns casos, chegando a exigir que seus supervisores, coordenadores, gerentes e diretores ajam de tal maneira para atingir determinada lucratividade.',
      },
      {
        type: 'text',
        text: '<strong>Considerando tudo isso, vamos para as dicas do Trampapo!</strong>',
      },
      {
        type: 'text',
        text: '<strong>1 - Seu chefe e sua empresa ‘dão match’?</strong><br> Se você está passando por uma situação complicada com seu chefe e se sente emocionalmente abalado, profissionalmente estagnado e psicologicamente desmotivado por causa das atitudes que ele tem com você, o primeiro passo que deve ter é observar se a cultura da empresa é permissiva com esse tipo de comportamento. Ou seja, se os valores e missão da empresa comunicam que ela preza pelo respeito e qualidade de vida dos seus funcionários, você já tem um indício de que sua possível queixa terá um respaldo, caso você busque ajuda dentro da empresa. Por outro lado, se a companhia para a qual você trabalha não propaga uma cultura humanizada e a própria alta liderança, os dirigentes dessa empresa, praticam atos duvidosos ou abusivos, está claro que seu gestor direto também é cobrado para que aja da mesma forma e, caso você leve suas insatisfações adiante, dificilmente terá o respaldo desejado.',
      },
      {
        type: 'text',
        text: '<strong>2 - Feedbacks também são para chefes</strong><br> Independente do grau de insatisfação com a relação com o seu chefe, sempre é válido colocar as cartas na mesa, abrir o coração e expor seus pensamentos. É preciso naturalizar o feedback em todos os sentidos: de líder para liderado, de liderado para líder, de colega para colega e assim por diante porque esse é o melhor caminho para alinhar expectativas e comportamentos. Entenda o feedback como uma forma de comunicação que deve ser constante para que você possa sempre falar sobre o que você achou de determinada situação, da forma como seu chefe agiu, falou, cobrou… Muitas vezes, os gestores têm a vontade de ouvir seus pontos falhos para que possam aprimorar a forma de conduzir o time e lidar com as pessoas, mas a falta de referências, treinamentos e feedbacks podem ser as peças que faltam para que eles se autoavaliem e transformem em ótimos líderes. Não tenha medo! Pense, planeje o que deseja falar e como, convide-o para um bate-papo e explique o que te incomoda, como isso te afeta e, se for o caso, qual a solução que você enxerga para esses problemas.',
      },
      {
        type: 'text',
        text: '<strong>3 - Busque ajuda dentro da empresa</strong> <br>Se você avaliou que a cultura da sua empresa não corrobora com as atitudes inadequadas do seu gestor direto e se o feedback não tiver funcionado, você tem a opção de buscar a área responsável por lidar com esse tipo de situação. Normalmente, o setor de Recursos Humanos conta com um profissional ou atendimento específico para cuidar e amparar profissionais que foram assediados de alguma forma. Neste caso, você deve organizar, mesmo que mentalmente, o histórico de como tudo aconteceu para explicar o que vem sofrendo. Se você tiver provas como mensagens, emails ou testemunhas, reúna também para que esse setor responsável analise com precisão e saiba como agir para reverter o quadro, que pode ser acompanhar mais proximamente a relação da equipe e gestor em questão, oferecer treinamentos, movimentar integrantes de uma equipe para outra e, em alguns casos, até realizar o desligamento desse chefe abusivo.',
      },
      {
        type: 'text',
        text: '<strong>4 - Saiba a hora de abandonar o barco</strong><br> Em alguns casos, a melhor forma de sobreviver é largar a embarcação. Mesmo que seja para aquele bote salva-vidas que parece inseguro quando comparado à grandeza de um navio. <br> Dizemos isso porque acreditamos que alguns quadros emocionais e psíquicos podem ser muito graves e até irreversíveis quando o sofrimento causado pelos chefes é intenso. Doenças e distúrbios como depressão, burnout, que é o que chamamos de esgotamento profissional, estresse, insônia, ansiedade e tantos outros podem ser o resultado de permanecer em um ambiente tóxico e com mais pressão do que você pode aguentar. Então, mesmo que o mercado de trabalho não esteja tão propício, foque em conseguir outro emprego o mais rápido possível. Não espere estar completamente infeliz e abalado para agir e deixar para trás o que te faz mal e te faz se sentir menos seguro do que estaria se não estivesse naquele emprego.',
      },
      {
        type: 'text',
        text: 'O Trampapo acredita que falar sobre liderança é fundamental para que as pessoas que formam as equipes saibam reconhecer sob qual gestão estão. Os trabalhadores precisam estar atentos com relação a forma como são tratados ou destratados, incentivados ou desmotivados, respeitados ou desumanizados. E gestores e empresas precisam se conscientizar sobre a importância da humanização da gestão, afinal, é esse o caminho para que escritórios, lojas, agências, fábricas e todos os ambientes de trabalho sejam mais inclusivos, diversificados, produtivos, seguros e felizes.',
      },
      {
        type: 'text',
        text: '<strong>Vem saber mais! #DicaExtracurricular</strong>',
      },
      {
        type: 'text',
        text: '<strong>Bianca Machado</strong> é Gerente Sênior na Catho Empresas. Há 22 anos lidera times multidisciplinares entre áreas de vendas e recursos humanos. Gerenciou times de até 200 colaboradores, é criadora de conteúdo no instagram @bia_praticandoateoria, onde fala sobre liderança humanizada, recursos humanos e temas do mundo corporativo. É graduada em Direito, especialista em gestão estratégica de vendas e está cursando MBA em marketing na USP. <br /><br />Como dica extracurrícular no podcast, indicou o vídeo: <br/> <a href="https://www.youtube.com/watch?v=Pp4FjE8BJq4">Jack Welch Qual O Papel do Líder</a>.<br> Para ela, a mensagem que Jack Welch passa nesse vídeo sobre a generosidade que os líderes devem ter é fundamental para todos.',
        image: content_05_guest_01,
        alt: 'Bianca Machado',
      },
      {
        type: 'text',
        text: '<strong>Vitor Cruz é</strong> treinador de líderes e palestrante nas áreas de Liderança, Comunicação e Produtividade Pessoal e de Equipes, fundador do Instituto Brasileiro de Estratégias para Carreira e Vida - IBECAV, fundador do Nota11 Educação Online e autor de 12 livros. Além disso, ele também cria conteúdos sobre esses temas no instagram @vitorcruz_construindo_lideres. Sua dica de conteúdo foi: <br /><br /> <strong>Livro: Liderança Tóxica: Você é um líder contagiante ou contagioso? descubra o que a neuroliderança pode fazer por você - Alessandra Assad</strong> <br /> Dicas para reformular os ambientes de trabalho e eliminar as toxicidades causadas pelos chefes.',
        image: content_05_guest_02,
        alt: 'Renata Prestes',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais</strong>, nosso host e gerente sênior de marketing e comunicação da Catho, indicou um filme que mostra como o respeito, carinho, empatia tem um potencial transformador.<br /> <strong>Filme: Ao Mestre com Carinho (1967)</strong>',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani</strong>, host número 1 do Trampapo, indicou uma série dramática que mostra erros e acertos de uma liderança conturbada, com atitudes tóxicas mas também inspiradoras.<b /> <strong><br /> Série: How to get away with murder (2014)</strong>',
      },
    ],
    thumbnail: content_05_thumb,
  },
  {
    id: 103,
    date: '08/02/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'papo-reto-primeiro-emprego',
    title: 'Papo reto pra quem quer o primeiro emprego',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_04_featured,
        alt: 'Papo reto pra quem quer o primeiro emprego',
      },
      {
        type: 'text',
        text: '<strong>Papo reto pra quem quer o primeiro emprego.</strong><br>Se você chegou até aqui nessa tela do Trampapo, significa que você é uma pessoa jovem dedicada ao seu futuro #AMOOO 💜 <br> Esse é o primeiro passo para ter uma carreira promissora e, por isso, desejamos que essa leitura te ajude de verdade a encontrar seu primeiro emprego. Vamos lá?!',
      },
      {
        type: 'text',
        text: 'Para muitos, a primeira experiência é desafiadora. É difícil conseguir a contratação, é confuso escolher um caminho para seguir, saber ao certo o que colocar no currículo, como funciona um ambiente de trabalho e também como é na prática responder para um, ou vários gestores...',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Onde procurar o primeiro emprego? <br>&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Qual primeiro emprego escolher? <br>&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; O que colocar no currículo para primeiro emprego? <br>&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Como se comportar no ambiente de trabalho?',
      },
      {
        type: 'text',
        text: 'Todas essas dúvidas, a gente esclarece um pouquinho pra você!',
      },
      {
        type: 'title',
        title: 'Onde procurar o primeiro emprego?',
      },
      {
        type: 'text',
        text: 'Existem vários caminhos para procurar a primeira oportunidade. Uma das mais fáceis é utilizar sites e apps como a Catho, que anunciam vagas em ótimas empresas para todos os tipos de trabalhos, inclusive os que podem ser feitos por iniciantes, aprendizes e estagiários.<br> Mas você também não deve deixar de lado sua rede de contatos. Colegas da escola ou da faculdade que estão empregados são uma boa opção para pedir uma forcinha como uma indicação ou o contato para enviar seu currículo.<br>Aproveite também grupos de vagas de emprego em redes sociais como Facebook, Telegram, Whatsapp e todos os caminhos que podem aumentar sua visibilidade no mercado de trabalho.',
      },
      {
        type: 'title',
        title: 'Qual primeiro emprego escolher?',
      },
      {
        type: 'text',
        text: 'Muitas vezes, é difícil ter algum poder de escolha na hora da busca do primeiro emprego. Geralmente, a falta de experiência faz com que os jovens aceitem qualquer tipo de oportunidade por saberem que as empresas têm muitos outros candidatos à disposição.<br> Mas, na medida do possível, tente direcionar suas buscas de vagas para empregos que te interessem e possam te oferecer oportunidades a curto, médio e longo prazo.<br> Se você tem um desejo particular de trabalhar em uma determinada função, tudo fica mais fácil. A nossa dica é que você busque emprego em empresas que, de alguma forma, possam te preparar para essa função no futuro ou até mesmo que você tenha a chance de ir crescendo, até alcançar seu alvo. É muito comum essa movimentação onde uma pessoa é contratada para ser jovem aprendiz, atendente ou auxiliar e, com o tempo, ela vai galgando novos patamares em diferentes áreas da corporação.<br> Agora, se você é do time que não tem ideia do que quer fazer no futuro, você deve buscar seu emprego em companhias que tenham valores com os quais você se identifique. Procure empresas que tenham a cultura adequada para você e, depois da contratação, esteja atento à dinâmica organizacional para descobrir quais trabalhos existem ali, o que faz cada profissional, cada área... essas observações vão te ajudar a descobrir o que você gosta ou não de fazer para que você planeje os próximos passos da sua carreira e qualificação.',
      },
      {
        type: 'title',
        title: 'O que colocar no currículo para primeiro emprego?',
      },
      {
        type: 'text',
        text: 'O currículo é uma etapa importantíssima na busca de um trabalho. Você deve fazer um documento organizado, claro e que destaque seus diferenciais, mesmo que você ainda não tenha experiência. Atente-se ao cargo que está no objetivo do currículo, porque ele é quem direciona o olhar dos recrutadores. E, tudo isso, sem deslizar no português, certo?<br> <a href="https://www.catho.com.br/carreira-sucesso/modelo-curriculo/cv-jovem-aprendiz/" target="_blank">Acesse aqui para baixar os modelos de currículos feitos pelos assessores da Catho e criar o seu!</a>',
      },
      {
        type: 'title',
        title: 'Como se comportar no ambiente de trabalho?',
      },
      {
        type: 'text',
        text: 'Na vida é aquela coisa: tudo depende. Você precisa se adaptar à cultura organizacional da empresa que te contratou. Existem ambientes mais conservadores, outros mais inovadores e livres.<br> Por isso, assim que você começa em seu primeiro emprego, seu foco deve ser o <strong>aprendizado</strong> que pode absorver daquela experiência e o <strong>autoconhecimento</strong>, para entender se aquelas funções que você está desenvolvendo têm a ver com o seu jeito de ser, suas vontades de trabalhar e seus talentos. Agora, o <strong>modo</strong> como você vai fazer isso, precisa estar <strong>compatível com a cultura da empresa</strong> - lembre-se: a cultura organizacional é um conjunto de valores, crenças e ações que as empresas estabelecem para conduzir seus negócios.',
      },
      {
        type: 'text',
        text: 'Isso significa que o modo como você vai se comunicar com as pessoas, tirar suas dúvidas, expor suas ideias, se vestir e interagir com todos depende do quão liberal é a cultura da empresa.<br> Existem muitas agências e até empresas como a <strong>Catho</strong>, por exemplo, que têm como parte da cultura propor um ambiente seguro para sugestões de ideias e feedbacks, sem fazer distinção entre cargo ou idade do colaborador, e também proporcionam espaços físicos de descontração e formas de capacitação. Então, em empresas com essas características, você pode se sentir mais à vontade para dar opiniões, ser colaborativo, pedir demandas e expressar seus sentimentos e comportamentos. A <strong>Bayer</strong> é outro exemplo! A Fernanda e a Renata, que participaram do EP #16 do Trampapo, contaram tudo sobre os programas que essa mega empresa. Vem ouvir!',
      },
      {
        type: 'text',
        text: 'Já em empresas mais conservadoras, é necessário muitas vezes respeitar hierarquias e burocracias que impedem a expressão tão espontânea de pensamentos e ideias, mas que ainda assim estimulam o seu desenvolvimento profissional, colaboram com a sua qualificação e proporcionam muito conhecimento e bagagem para o futuro. Só é preciso estar atento aos processos e diretrizes. Não é nenhum bicho de sete cabeças. O importante é expor suas dúvidas e buscar aprender e colaborar! =)',
      },
      {
        type: 'title',
        title: 'Vem saber mais! #DicaExtracurricular',
      },
      {
        type: 'text',
        text: 'No ep <strong>#16 - GenZ: estágio, aprendiz | Trainee</strong>, nossas convidadas e hosts deram dicas de conteúdos. Se liga na #DicaExtracurricular:',
      },
      {
        type: 'text',
        text: '<strong>Fernanda Nagano</strong> é formada em administração e possui MBA em Desenvolvimento Humano de Gestores, além de ter realizado diversos cursos a respeito de comportamento humano, tais como: Programação Neurolinguística, Inteligência Emocional, Análise de Dados e Power BI e Astrologia. Atualmente é Gerente de Aquisição de Talentos na Bayer Brasil. <br><br><strong>Dica de livro: Descubra Seus Pontos Fortes</strong><br> Fernanda recomendou para quem quer saber como desenvolver suas habilidades e descobrir os próprios talentos.',
        image: content_04_guest_01,
        alt: 'Fernanda Nagano',
      },
      {
        type: 'text',
        text: '<strong>Renata Prestes</strong> é formada em Recursos Humanos, área em que atua há alguns anos. Atualmente, encontra-se dentro do guarda-chuva de Aquisição de Talentos da Bayer, liderando processos seletivos e prestando suporte às áreas de Relações com Universidades & Onboarding. Renata se preocupa com as pessoas e as coloca como prioridade a cada processo que realiza. <br><br><strong>Dica de filme: Coach Carter - Treino para a Vida (2005)</strong><br> O filme aborda a história de um treinador de basquete que teve como missão ajudar seus alunos a vencerem tanto no esporte quanto nas notas. É um alerta para a necessidade de estar sempre atento aos conhecimentos!',
        image: content_04_guest_02,
        alt: 'Renata Prestes',
      },
      {
        type: 'text',
        text: '<strong>Dica de filme - Ricardo Morais</strong><br><strong>Um Senhor Estagiário - (2015)</strong><br> Rick comentou que esse filme é, além de muito divertido, uma boa forma de refletir sobre a relação entre as gerações, afinal, no filme, um senhor aposentado volta ao mercado de trabalho para trabalhar em um site de moda com vários colegas muito mais jovens.',
      },
      {
        type: 'text',
        text: '<strong>Dica de TEDx - Ana Paula Xongani</strong><br><a href="https://www.ted.com/talks/monique_evelle_o_potencial_inovador_das_periferias" target="_blank">Monique Evelle·TEDxMauá - O potencial inovador das periferias</a><br> Esse conteúdo foi recomendado porque a Monique Evelle tem várias ideias para abrir a mente dos jovens que estão no começo da jornada profissional.',
      },
    ],
    thumbnail: content_04_thumb,
  },
  {
    id: 102,
    date: '01/02/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'emprego-dos-sonhos-privilegios',
    title: '‘Emprego dos sonhos’ é conversa de privilegiados?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_03_featured,
        alt: 'Emprego dos sonhos é conversa de privilegiados?',
      },
      {
        type: 'text',
        text: '<strong>Dizem por aí que sonhar não custa nada. E é verdade.</strong><br>Podemos mesmo sonhar bem alto, bem longe e profundo, que é grátis! Nossas vontades, habilidades e propósitos não devem ser podados ou limitados.',
      },
      {
        type: 'text',
        text: 'O problema é que não é só sair por aí sonhando e querendo um monte de coisas. É preciso também conseguir bancar os próprios sonhos... E fazer acontecer cada um dos planos que nós construímos não é algo fácil. Afinal, só a chuva cai do céu. As realizações não. Normalmente, elas só são conquistadas com muito esforço, foco, determinação, disciplina e...  privilégios.',
      },
      {
        type: 'text',
        text: 'Pensando nessa temática que foi o <span style="text-decoration:underline">#15º episódio do Trampapo  `Empregos dos sonhos: O que são? Onde estão? Como se reproduzem?`</span> , precisamos ser honestos e dizer que essa história de trabalhar com aquilo que amamos, aqui no Brasil, é uma romantização de algo que parece até um conto de fadas que foi vendido como atingível para qualquer um. Mas a verdade é que empregos bons, que respeitam integralmente os trabalhadores e que podem ser ‘escolhidos’ são restritos a poucas pessoas em posições sociais mais elevadas, até porque, para a população que está preocupada em conseguir colocar a comida na mesa, pagar o aluguel e manter os filhos exclusivamente estudando, sem que eles tenham que trabalhar pra ajudar em casa, é uma utopia pensar em rejeitar uma oportunidade de trabalho sob a desculpa de que esse emprego não vai trazer satisfação profissional.',
      },
      {
        type: 'text',
        text: 'Em nossa sociedade desigual, os trabalhos que, normalmente, ninguém gosta de fazer são empurrados às pessoas que têm menos qualificação, que muitas vezes não tiveram oportunidade de se dedicar aos estudos durante a infância, adolescência e na fase adulta. Esses empregos, que geralmente colocam os trabalhadores em situações degradantes, só existem por um motivo: existem pessoas que PRECISAM sobreviver. Esses empregos oferecem migalhas em troca de serviços pesados porque, para os patrões, “essa quantia e esse tratamento está bom até demais para quem não tem estudo”. E, para quem precisa, é aquele velho ditado ‘é melhor pingar, do que faltar’. E assim segue girando essa roda das relações trabalhistas. Essa roda que, na verdade, está mais para um rolo compressor, passando por cima de sonhos, necessidades e humanidade de milhões de pessoas.',
      },
      {
        type: 'text',
        text: 'Então, quer dizer que pra quem não está em uma situação de privilégio social, sonhar alto e com um emprego que lhe traga satisfação é estar fadado ao fracasso e à frustração?',
      },
      {
        type: 'text',
        text: 'Não! O Trampapo é o mais otimista dos podcasts! A gente sabe que muita coisa está errada e é por isso que nós trazemos tantas questões em pauta que são extremamente problemáticas. Mas nós vemos a realidade como algo em constante movimento. Ontem, as coisas estiveram piores do que hoje. E hoje, piores do que amanhã. ',
      },
      {
        type: 'title',
        title: '‘Emprego dos sonhos’ pode ser, simplesmente, O SEU!',
      },
      {
        type: 'text',
        text: 'Esse não é um pedido para você se conformar com o emprego que tem hoje e sorrir largamente de gratidão como se nada estivesse errado em seu trabalho. Mas é um pedido para você buscar <strong>propósito</strong> em cada simples ação que tomar. Seja fazendo o que você faz hoje para ganhar dinheiro, seja na construção dos seus objetivos ao longo prazo. Tente ter prazer em ajudar as pessoas que cercam sua rotina de trabalho, sejam elas colegas, clientes, pacientes, chefes ou qualquer um. Tente desenvolver novas habilidades que possam, no presente e no futuro, te ajudar a conquistar aquele tal sonho. Tente estar aberto a conhecimentos que sua atual situação profissional te proporciona. Afinal, como otimistas que somos, acreditamos que a jornada que você está percorrendo agora é exatamente a que você precisa percorrer para evoluir para o próximo passo.',
      },
      {
        type: 'title',
        title: 'Mas você também pode sonhar com o melhor. Sonhar alto!',
      },
      {
        type: 'text',
        text: 'Se você usar o momento de agora para agir, o futuro que você quer pode ficar mais próximo e tangível.<br> As oportunidades não serão fáceis se você não nasceu em uma família influente, com negócios ou contatos que te favoreçam na hora de buscar um emprego ou conseguir apoios para começar um novo projeto. Mas não há dúvidas que o sucesso vem também para quem não mede esforços para conquistá-lo',
      },
      {
        type: 'text',
        text: 'Talvez você não queira começar em uma empresa como atendente, auxiliar de produção ou algum cargo com o qual você não se identifique ou, pior, deteste fazer. Mas se você exerga ali uma oportunidade de crescimento e de entrada para aquela área em que você sonha em trabalhar, por que não tentar? Muitas empresas oferecem programas de crescimento internamente.',
      },
      {
        type: 'text',
        text: 'Se você sonha em ter um trabalho menos tradicional, como ser, por exemplo, ser cantor profissional e viver de música e arte, por que não começar a expor suas habilidades nas redes sociais, perder a timidez e dar a cara a tapa?',
      },
      {
        type: 'text',
        text: 'As oportunidades só podem ser vistas por quem as faz acontecer. Mesmo com dificuldades, se você é a pessoa responsável por construir as suas próprias oportunidades e caminhos para resistir, por que você não teria condições de alcançar seus sonhos?',
      },
      {
        type: 'title',
        title: 'Vem saber mais! #DicaExtracurricular',
      },
      {
        type: 'text',
        text: 'No episódio #15, além da Xonani e do Ricardo, que estão mega felizes com os empregos que têm hoje, convidamos doi participantes especiais.<br />           Aproveite para conhecê-los e saber quais foram suas <strong>dicas extracurriculares!</strong><br>',
      },
      {
        type: 'text',
        text: '<br/><br/><br /><strong>Duda Almeida</strong> é roteirista de produções audiovisuais, já escreveu para a Netflix e hoje trabalha na O2 Filmes escrevendo para a Globoplay. Como dica no podcast, ela recomendou: <br /> <br /> <strong>Filme: Café com canela (2017)</strong><br /> Para Duda, em uma das cenas do longa, surge um diálogo com uma mensagem que ela se identifica muito sobre o que é ‘fazer cinema’ e o propósito de quem trabalha nessa área.',
        image: content_03_guest_1,
        alt: 'Duda Almeida',
      },
      {
        type: 'text',
        text: '<br/><br/><strong>Renato Braga</strong> Sacco é treinador das categorias de base do futsal de Taboão da Serra e foi jogador de futebol profissional, chegando a jogar internacionalmente. <br />Como dica de conteúdo, ele sugeriu: <br /> <br /> <strong>Entrevista sobre a história do Ronaldo Fenômeno</strong> Renato conta que acha importante conhecer histórias capazes de nos inspirar a irmos além das dificuldades, sejam elas físicas, financeiras ou quaisquer outras. A história do Ronaldo, para ele, é uma dessas que o motivam a dar o seu melhor. <br /> <br /> <strong>Dicas dos hosts:</strong>',
        image: content_03_guest_2,
        alt: 'Renato Braga Sacco',
      },
      {
        type: 'text',
        text: '<strong>Série: Sintonia</strong><br> Ricardo Morais acha importante a abordagem da série sobre como vencer desafios e materializar os sonhos no contexto da juventude da região periférica de São Paulo.',
      },
      {
        type: 'text',
        text: '<a style="text-decoration:underline" href="https://www.youtube.com/watch?v=t1ROzUeOLKs"><strong>Bate papo com Vitória Ferreira, jogadora da seleção brasileira feminina ⚽ | Não enrola com Xongani</strong></a><br /> Ana Paula Xongani diz que essa entrevista conta sobre os caminhos que essa jovem percorreu para concretizar seu objetivo como mulher no futebol.',
      },
    ],
    thumbnail: content_03_thumb,
  },
  {
    id: 101,
    date: '01/02/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'tem-diversidade-ai',
    title: 'Tem diversidade na sua empresa? Será mesmo?',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_02_featured,
        alt: 'Tem diversidade na sua empresa? Será mesmo?',
      },
      {
        type: 'text',
        text: 'Pra começar, parodiamos um clássico pagode dos anos 90, pra te deixar no clima da diversidade:',
      },
      {
        type: 'text',
        text: 'O que é que eu vou fazer<br> Com essa tal Diversidade?<br> Se na contratação <br> Não pensam em você<br> Eu nunca imaginei <br> Tanta irresponsabilidade <br> O patrão não sabe <br> Como te acolher <br>',
      },
      {
        type: 'text',
        text: 'Ele aprendeu errado<br> Ele pisou na bola<br> Trocou quem mais combinava<br> Pra essa contratação<br> Mas a gente aprende<br>A vida é uma escola<br> Não é assim que acaba<br> A luta pela inclusão<br>',
      },
      {
        type: 'text',
        text: 'Quero te ligar <br>Quero te contratar<br> O mercado te deseja noite e dia<br> Quero reparar começando por você<br> Diversidade é tudo o que eu queria  🎵 🎶<br>',
      },
      {
        type: 'title',
        title: 'Essa tal diversidade...',
      },
      {
        type: 'text',
        text: 'Brincadeiras à parte, queremos que você comece refletindo sobre essa tal diversidade...',
      },
      {
        type: 'text',
        text: 'Esse conceito vem sendo cada vez mais discutido pelas pessoas, empresas, mídia, publicidade, arte... mas, afinal, o que significa diversidade? Discursos bonitos e ensaiados encontramos aos montes. Mas, e na prática, como ela funciona - ou como deveria funcionar?',
      },
      {
        type: 'text',
        text: 'Para entender melhor, bora ver um dos conceitos de diversidade. Esse, de Fleury em 2000, define diversidade como o resultado da interação entre indivíduos com diferentes identidades e que convivem no mesmo sistema social:',
      },
      {
        type: 'text',
        text: '“O tema diversidade cultural pode ser estudado sob diferentes perspectivas: no nível da sociedade, no nível organizacional e no nível do grupo ou indivíduo”.',
      },
      {
        type: 'text',
        text: 'Pensando em organizações, já que o lema do Trampapo é falar de mercado de trabalho de uma forma plural, completamos ainda:',
      },
      {
        type: 'text',
        text: '“O conceito de diversidade está relacionado ao respeito à individualidade dos empregados e ao reconhecimento desta; gerenciar a diversidade implica o desenvolvimento das competências necessárias ao crescimento e sucesso do negócio.”Fleury (2020, p. 23).',
      },
      {
        type: 'text',
        text: 'Ou seja, a regra da diversidade é o respeito à pluralidade de pensamento, de vivências, gênero, cultura, crenças e muito mais. E como consequência, que também é uma das grandes motivações para as empresas buscarem a diversidade, estão os resultados positivos que ela pode gerar.',
      },
      {
        type: 'text',
        text: 'Mas, na prática, apesar do inegável aumento das políticas que visam diversificar os ambientes de trabalho e tornar as oportunidades mais democráticas, ainda não há esforços e conscientização suficientes para preencher as lacunas existentes.',
      },
      {
        type: 'title',
        title: 'E quais lacunas são essas?',
      },
      {
        type: 'text',
        text: 'Muitas. Os grupos que são chamados de ‘diversos’ e ‘minorias’ nada mais são do que a maior parte da população brasileira. São pessoas negras, mulheres, pessoas com deficiência, pessoas LGBTQIA+, refugiados... São tantos grupos que sofrem com a falta de oportunidades profissionais que fica mais fácil dizer quem não é prejudicado pela forma como o sistema funciona hoje: homens cisgêneros, brancos e heterossexuais das classes média e alta. E só pra comprovar, confira esses dados:',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; O salário médio dos homens brancos supera em até 159% o das mulheres negras, mesmo quando ambos têm curso superior, de acordo com o Insper.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Uma pesquisa do Instituto Ethos mostrou que os negros ocupam apenas 4,9% das cadeiras nos Conselhos de Administração das 500 empresas de maior faturamento do Brasil. Entre os quadros executivos, eles são 4,7%. Na gerência, apenas 6,3% dos trabalhadores são negros.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Segundo os dados da Catho, os trabalhadores negros em todos os níveis hierárquicos recebem menos que os brancos. Em cargos de diretoria ganham, em média, 30% a menos e a desigualdade segue em todos outros níveis de atuação.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; A taxa de desemprego entre os negros, segundo o IBGE, é de 16,1%, acima dos brancos, com taxa de 11,5%.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Segundo estudo feito pelo fórum econômico mundial (WEF, na sigla em inglês) em 2016, seriam necessários 95 anos para que o país alcançasse a igualdade de gênero, pois o Brasil ficou na 79ª posição no ranking global de 2016 da igualdade de gêneros.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; O projeto  ‘Demitindo Preconceitos’  indicou que 38% das indústrias e empresas têm restrições para contratar membros da comunidade LGBTQIA+. Além disso, 53% dessas pessoas não se sentem à vontade para relatar a própria orientação sexual no ambiente de trabalho - o que pode limitar o desenvolvimento e expressão destes profissionais.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; Segundo a OAB, 82% das pessoas transexuais ou travestis não concluem os estudos, o que gera muita dificuldade em entrar no mercado de trabalho. Segundo dados da ANTRA (Associação Nacional de Travestis e Transexuais) 99% dos trans estão fora do mercado de trabalho e 90% precisam recorrer à prostituição.',
      },
      {
        type: 'text',
        text: '&nbsp; &nbsp; &nbsp; &nbsp; - &nbsp;&nbsp; A pesquisa da Santo Caos em parceria com a Catho, apresenta que menos de 10% dos postos de liderança são ocupados por profissionais com deficiência. Atualmente, cerca de 25% da população brasileira tem algum tipo de deficiência.',
      },
      {
        type: 'title',
        title: 'E por que tudo isso?',
      },
      {
        type: 'text',
        text: 'Não há uma resposta simples. Historiadores, intelectuais, ativistas e tantas outras pessoas trabalham fortemente há anos para explicar à sociedade os conceitos que formam a desigualdade. Inclusive, recomendamos ao fim deste texto dicas de conteúdos para você contextualizar melhor esse tema. Mas para sintetizar, podemos citar a mentalidade de superioridade que moldou as relações da humanidade.',
      },
      {
        type: 'text',
        text: 'Durante o período de escravidão, durante a indrustrialização e também nos tempos modernos atuais, há o contexto onde X domina Y em busca de enriquecimento. Mas não coincidentemente esse X da questão é composto por homens da elite - e essa hegemonia foi estruturada na ideia de supremacia, culturalmente. De acordo com a classe, gênero e raça, define-se o local social que cada pessoa ocupa geralmente.',
      },
      {
        type: 'text',
        text: 'No caso dos homens da elite, eles ocupam governos, poderes públicos e os maiores cargos empresariais. No caso do “resto”, ocupa-se o que “sobra”: os cargos e posições que produzem a riqueza de quem está acima. ',
      },
      {
        type: 'text',
        text: 'Por isso, pensar como o sistema vem beneficiando economicamente, por toda a história, certa população, ao passo que outras são tratadas como ferramentas que não têm acesso a direitos básicos e à distribuição de riquezas.',
      },
      {
        type: 'title',
        title: 'Tá, mas e a diversidade?',
      },
      {
        type: 'text',
        text: 'A diversidade é um imaginário em construção. Até há protótipos em andamento, projetos prontos e funcionando a todo vapor... Mas ‘diversidade’ é holística e não parcial. E só quando ela for uma realidade em toda esquina - em empresas grandes e pequenas, nas salas de aula, na televisão e em todos os lugares - poderemos parar de debatê-la.',
      },
      {
        type: 'text',
        text: 'Se ainda falamos em incluir e diversificar, é porque a missão ainda não foi concluída. Quer tirar suas próprias conclusões? Questione-se:',
      },
      {
        type: 'text',
        text: 'Como a empresa em que você trabalha, ou qualquer uma das quais você já trabalhou, trata as necessidades das pessoas negras, com deficiência, transgêneras e mães? Essas pessoas estão presentes no escritório? E como fica a proporção no caso dos cargos mais altos? Como esses assuntos são tratados na contratação? Há algum comitê de diversidade ou projeto para melhorar esses números? Há margem para a discriminação e hostilidade para com os grupos vulneráveis? Essas reflexões podem ajudar a avaliar o racismo, o machismo, o capacitismo, a homofobia e a transfobia nos ambientes de trabalho. E, infelizmente, acreditamos que suas respostas para essas perguntas não são animadoras.',
      },
      {
        type: 'title',
        title:
          'Então, não há esperança? A diversidade será sempre um sonho não realizado?',
      },
      {
        type: 'text',
        text: 'Espero que não. A equipe do Trampapo vos escreve com muita esperança no coração. Esperamos que ‘poucas andorinhas façam verão’ e, com a junção de forças, possamos construir um mercado de trabalho mais diverso.',
      },
      {
        type: 'text',
        text: 'Existem incontáveis iniciativas e medidas possíveis para trilharmos um caminho mais humano. Muitas dessas medidas já estão em curso, inclusive. Vamos ver algumas que foram idealizadas e outras que já foram colocadas em práticas por empresas:',
      },
      {
        type: 'text',
        text: '<strong>O MOVIMENTO AR</strong> criou o Manifesto “Vidas Negras Importam – Nós Queremos Respirar” que propõe 10 metas para reduzir o impacto do racismo. Várias dessas metas estão diretamente ligadas ao lugar de homens pretos e mulheres pretas no mercado de trabalho.',
      },
      {
        type: 'text',
        text: '- Essas medidas são: Criação de 500 mil bolsas de estudos para qualificação de jovens negros em graduação, pós-graduação, pesquisa, formação tecnológica, economia criativa, negócios e empreendedorismo.<br> - Criação de 300 mil vagas de estágios, trainees e profissionais negros nas empresas públicas e privadas.<br> - Formação e qualificação de um milhão de quadros corporativos em discriminação e racismo e gestão da diversidade racial.<br> - 300 milhões em compras corporativas do ambiente público e privado, de serviços e produtos de empresas e empresários e profissionais negros.<br> - Fundo Vidas Negras Importam de R$ 200 milhões para o fomento, apoio e financiamento educacional, empreendedor, tecnológico e de economia cultural criativa para jovens negros.<br>- E Implementação integral da Lei da História do Negro e História da África e da disciplina de Relações Étnico Racial em todo ambiente escolar e universitário público e privado do país.<br>',
      },
      {
        type: 'text',
        text: '<strong>A empresa Boston Consulting Group</strong> tem processos de seleção específicos para grupos sub-representados - pessoas com deficiência, mulheres, LGBTQIA+, negros e asiáticos. São fornecidos a esses grupos também cursos intensivos, com o objetivo de prepará-los para o mercado de trabalho. As vagas oferecidas nesses diferentes processos em sua grande maioria são para Estagiários, Trainees e vagas Junior. O objetivo é fazer com que estes colaboradores cresçam junto com a empresa.',
      },
      {
        type: 'text',
        text: 'O <strong>Magazine Luiza</strong> começou em 2013 a criar ações de inclusão das práticas de diversidade. Primeiro, começou com a da inclusão de pessoas com deficiência, oferecendo também uma série de cursos internos para a conscientização de seus funcionários. Entre outras ações, em 2020 a empresa abriu um processo seletivo para trainees direcionado a pessoas negras. O objetivo desse programa era trazer ainda mais diversidade para os cargos de liderança da organização. ',
      },
      {
        type: 'title',
        title: 'Vem saber mais!',
      },
      {
        type: 'text',
        text: 'O episódio #14 Tudo é Feito de Gente, do Trampapo, abordou a temática da diversidade nas empresas e como estamos atualmente frente a esses desafios. Para debater esse tema, trouxemos dois convidados:',
      },
      {
        type: 'text',
        text: '<strong>Bárbara Lima</strong> fez relações-públicas pela FECAP, trabalhou com comunicação para o terceiro setor, <br> com experiência em causas relacionadas à desigualdades sociais e pessoas com deficiência, além <br> de assessoria de imprensa para diversos setores. Hoje coordena a área de Comunicação e Diversidade <br>da Mutato e está se especializando em Gestão da Comunicação Empresarial pelo MBA da Aberje. <br> <br> Durante dois anos, coordenou voluntariamente junto ao Atados o projeto Feminicidade, uma iniciativa para <br>fomentar a discussão sobre o debate de gênero e ocupação do espaço público que foi listada entre as <br> Mulheres Inspiradoras de 2016, pela Think Olga. ',
        image: content_02_guest_1,
        alt: 'Bárbara Lima',
      },
      {
        type: 'text',
        text: 'Marco Ornellas é formado em Psicologia, Dinâmica dos Grupos e Design Thinking. Também é coach e <br>membro do International Coaching Federation (ICF). Pela ORNELLAS CONSULTORIA, desenvolve projetos <br>de Cultura de Inovação desde o Repensar Estratégico até a sensibilização para o Futuro, formação de <br>profissionais em metodologias inovadoras e ágeis e preparação de multiplicadores. Pela 157NEXT.ACADEMY, plataforma de conteúdos e cursos, busca colocar as pessoas na frente das tendências em inovação, pessoas e cultura. <br><br> É autor dos livros DesigneRHs para um Novo Mundo e Nova desordem organizacional.',
        image: content_02_guest_2,
        alt: 'Marco Ornellas',
      },
      {
        type: 'title',
        title: '#DicaExtracurricular',
      },
      {
        type: 'title',
        title:
          'Os participantes do programa deixaram dicas de conteúdos para você se aprofundar no tema:',
      },
      {
        type: 'text',
        text: '<strong>Bárbara Lima:</strong><br>Livro: O ano em que disse sim: Como dançar, ficar ao sol e ser a sua própria pessoa - por Shonda Rhimes e Mariana Kohnert.<br> Segundo ela, ele é importante para sabermos como incluir e acolher pessoas.<br> Palestra: Veronica Dudiman - Não Contrate Mulheres Negras! - TEDxBlumenal <a href="https://www.youtube.com/watch?v=bUHFFJKhM8I">https://www.youtube.com/watch?v=bUHFFJKhM8I</a><br> Para refletir sobre como incluir as mulheres no mercado de trabalho e o que acontece depois da contratação.',
      },
      {
        type: 'text',
        text: '<strong>Marco Ornellas:</strong><br> Série: Inclusão da Diversidade - disponível na Plataforma 157next.academy. <br>A produção aborda a vivência de refugiados, pessoas com espectro autista e etc.',
      },
      {
        type: 'text',
        text: '<strong>Ricardo Morais:</strong><br> Séries Netflix: Atypical  e Amor no Espectro. Ambas abordam a vivência de pessoas autistas na sociedade, amor e estudos.',
      },
      {
        type: 'text',
        text: '<strong>Ana Paula Xongani:</strong><br>Livro: 71 leões: Uma história sobre maternidade, dor e renascimento - por Lau Patrón. Ele aborda a perspectiva de uma mãe de uma pessoa atípica. ',
      },
    ],
    thumbnail: content_02_thumb,
  },
  {
    id: 100,
    date: '04/01/2021',
    categories: ['MERCADO DE TRABALHO'],
    slug: 'home-office-tabu-quebrado',
    title: 'Home office: tabu quebrado',
    type: 'content',
    contents: [
      {
        type: 'banner',
        banner: content_01_featured,
        alt: 'Home office: tabu quebrado',
      },
      {
        type: 'title',
        title: 'Bom, você sabe, 2020 foi um ano intenso...',
      },
      {
        type: 'text',
        text: 'E se você já ouviu o episódio <strong>#13 Efeitos da pandemia: o novo normal do mercado de trabalho</strong>, você sabe que toda essa intensidade de 2020 foi refletida no modo como nós trabalhamos.',
      },
      {
        type: 'text',
        text: 'Ou seja, além dos perrengues, perdas, tristezas e sentimentos angustiantes que a pandemia nos trouxe, tivemos que reaprender a nos comportar na rotina profissional, seja para nos adaptar ao home office, ao medo de nos expor ao vírus durante o percurso e durante as atividades de trabalho ou, até mesmo e infelizmente, para buscar um novo emprego após perder o serviço anterior em meio à quarentena.',
      },
      {
        type: 'title',
        title: 'Mas a verdade é que...',
      },
      {
        type: 'text',
        text: 'A verdade é que, apesar dos imensuráveis ônus da pandemia, ela também nos permitiu ter  uma baita oportunidade de rever o jeitinho brasileiro de trabalhar.',
      },
      {
        type: 'text',
        text: 'Não é a nossa intenção romantizar a pandemia, dizer que é preciso olhar para o copo meio cheio e agradecer por uma doença que tirou e continua tirando milhões de vidas pelo mundo, devastou a renda de incontáveis famílias e aumentou os abismos de desigualdade social.',
      },
      {
        type: 'text',
        text: 'A ideia aqui é falar do que mudou com relação ao antes, durante e pós-pandemia. As cicatrizes que o mercado de trabalho sofreu serão permanentes ou, no mínimo, vão arder por um tempo. Vamos aos fatos?',
      },
      {
        type: 'title',
        title: 'Home office: tabu quebrado',
      },
      {
        type: 'text',
        text: 'Quem é que não sonhava, naqueles tempos tão distantes que foram os que antecederam a chegada da Covid-19, com um emprego que permitisse trabalhar no conforto do próprio lar, com roupas confortáveis, sem necessidade de enfrentar horas no trânsito e no transporte público apertado, com a família no cômodo ao lado e a estrutura ideal para fazer tudo com qualidade e produtividade? Quase todo mundo.',
      },
      {
        type: 'text',
        text: 'Mas aí, isso aconteceu. Aconteceu? <br/> É?!... vamos ver!',
      },
      {
        type: 'text',
        text: 'Aconteceu. Mas não. <br/> Essa virada de chave do escritório para casa foi repentina e a goela a baixo. Ninguém tava 100% preparado. Nem quem já gostava e fazia um pouquinho de home office e muito menos as empresas que não tinham interesse algum em oferecer essa possibilidade aos seus funcionários. A gente sabia que a quarentena poderia vir a acontecer no Brasil. Mas quando ela veio, foi de uma hora pra outra e para ficar. Para alguns, por muito tempo.',
      },
      {
        type: 'text',
        text: 'De acordo com o Agência Brasil, até o mês de julho de 2020, 46% das empresas havia entrado no esquema de home office. Já no mês de setembro, 300 mil pessoas deixaram o home office, o que reduziu de 12,7% para 11,7% o percentual de brasileiros trabalhando de casa.',
      },
      {
        type: 'title',
        title: 'E aí está o tabu quebrado.',
      },
      {
        type: 'text',
        text: 'A grande maioria das empresas nunca se abriram antes ao home office. Esse formato de trabalho estava ainda restrito a algumas profissões e empresas. Normalmente, as empresas que já faziam o home office estavam um passo à frente quando o tema era a qualidade de vida dos seus funcionários e também mais abertas a culturas organizacionais mais livres, inovadoras e com um nível de confiança maior com relação à disciplina de seus colaboradores.',
      },
      {
        type: 'text',
        text: 'E essa é uma questão. A falta de confiança no funcionário, unida ao comportamento controlador dos chefes e à velha cultura de achar que trabalhador produtivo é aquele que não desgruda a cara do computador faziam - e ainda fazem - com que o home office seja visto como um presente cobiçado por trabalhadores que querem moleza. Mas não é nada disso.',
      },
      {
        type: 'text',
        text: 'Mesmo que enfiado goela à baixo, o home office teve que acontecer para esses empresários, que tiveram que achar alternativas para manter as atividades comerciais de seus negócios, manter seus times em segurança e também atender às exigências fiscais e sanitárias. E... deu tudo certo!',
      },
      {
        type: 'text',
        text: 'Deu?! <br/> Deu tudo certo. Mas não do jeito certo.',
      },
      {
        type: 'text',
        text: 'As empresas sacaram que o home office não é nenhum bicho de sete cabeças. Gerentes, diretores, supervisores e patrões no geral vivenciaram na prática um formato de trabalho que tem tudo pra dar certo, que pode manter ou até aumentar a produtividade, além de também valorizar as prioridades pessoais dos colaboradores.',
      },
      {
        type: 'text',
        text: 'Uma pesquisa realizada pela empresa de cibersegurança Fortinet mostrou que, após a pandemia do novo coronavírus, 30% das empresas devem seguir com o home office sendo regime de trabalho, mantendo mais da metade de seus funcionários nesse modelo. Essas empresas planejam aumentar em 90% seus investimentos em estrutura para o teletrabalho, o que resulta em cerca de US$ 250 milhões.',
      },
      {
        type: 'text',
        text: 'Mas, na prática de verdade, isso que aconteceu às pressas não foi um home office bem estruturado. Foi uma gambiarra, assim como a que você precisou fazer para ligar nas poucas tomadas do quarto um notebook, monitor extra e também para dar um jeito num cantinho de trabalho com uma bancada adequada.',
      },
      {
        type: 'text',
        text: 'Um home office adequado não é assim. Não é esse o trabalho remoto com o que sonhamos em a.C. (antes do covid). O formato esperado não era um formato sem o prévio treinamento dos gestores para saberem como lidar com o gerenciamento à distância; sem treinamento para os trabalhadores saberem a maneira mais efetiva de se comunicar, organizar as demandas e entregas, terem suporte tecnológico e administrativo; o home office não pode ser adequado se nem todos têm um cômodo para isso, cadeira e mesa ergonômica e internet de qualidade à disposição; o home office não é uma tarefa simples quando se há crianças precisando de suporte em casa e familiares pedindo seu apoio; e, principalmente, o trabalho em casa não traz muita qualidade de vida em um momento como o que atravessamos com a pandemia.',
      },
      {
        type: 'text',
        text: 'De acordo com a Fiocruz, durante o isolamento, tivemos 90% de aumento nos casos de depressão. 64% dos profissionais têm sentido impacto na saúde mental e, desses 64%, 80% alega ter desenvolvido sintomas de ansiedade. 53% dos trabalhadores têm se sentido desmotivados, 50% apresentam estresse, 48% tristeza, 49% perda de sono e 47% cansaço mental. É muito ou quer mais?',
      },
      {
        type: 'text',
        text: 'Fica claro que as dificuldades enfrentadas na execução do trabalho remoto não foram poucas. Mas uma sementinha foi plantada.',
      },
      {
        type: 'title',
        title: 'Home office: regando a semente',
      },
      {
        type: 'text',
        text: 'Considerando tudo isso, conclui-se uma coisa: esse solo é fértil. <br/> Se mesmo com o cenário desfavorável e a mudança abrupta que foi começar a trabalhar de casa, a experiência foi positiva, o futuro do home office é promissor aqui no Brasil:',
      },
      {
        type: 'text',
        text: '✓ Aos empreendedores, passa a existir a possibilidade de reduzir custos com prédios, instalações e infraestrutura. Não é que essa despesa deixaria de existir, mas é possível remanejar esses investimentos para outros benefícios aos profissionais e também repensar toda a estrutura de atuação comercial. É uma margem para a inovação nas relações trabalhistas, comerciais e no jeito de pensar.',
      },
      {
        type: 'text',
        text: '✓ A confiança se torna obrigatória. Em uma relação à distância, a confiança tem que se fazer presente. E a capacidade de confiar passa a ser uma característica que líderes e colegas de trabalho precisam desenvolver para que tudo flua. Não adianta ficar querendo controlar se fulano está online, na frente do computador trabalhando energicamente ou se ele está fazendo uma pausa pra descansar e tomar um arzinho. Não dá pra ficar olhando se o status de todo mundo está como ‘online’ nem ficar fazendo vídeo-chamada para fiscalizar a cada momento. É preciso se comunicar o quanto for necessário e confiar que o próximo está fazendo o seu melhor e no seu tempo para entregar aquele trabalho. E essa relação de confiança abrirá caminhos para outras vantagens e trocas positivas no relacionamento interpessoal dessas pessoas. Só há o que se ganhar.',
      },
      {
        type: 'text',
        text: '✓ As barreiras geográficas ganham menos peso e abrem caminhos para a democratização de oportunidades de emprego. Sabemos hoje que a desigualdade social e de acesso a bons postos de trabalho se retroalimentam. Os empregos com melhores salários, benefícios, chances de crescer e com mais prestígio são destinados às pessoas que socialmente já possuem mais condições. Seja de qualificação como também de estrutura familiar e de habitação. E nessa mesma medida, sabemos que os empregos abusivos, com condições precárias e degradantes são destinados à população mais pobre, marginalizada, vulnerável e periférica. E essa quebra de barreira geográfica pode ajudar a tornar as coisas menos desiguais, visto que em grandes metrópoles, as empresas e empregos mais cobiçados ficam localizados em regiões centrais e nobres, onde apenas aqueles que já possuem boas condições sociais têm fácil acesso. Claro que ainda há a necessidade de educar empresas e recrutadores para não discriminarem candidatos de regiões periféricas, mas se a distância física deixa de ser um problema, teremos um problema a menos a ser resolvido.',
      },
      {
        type: 'title',
        title: 'Vem saber mais!',
      },
      {
        type: 'text',
        text: 'Uma pessoa que manja T-U-D-O sobre o home office é o Renato Carvalho. Ele esteve no programa de estréia da 2ª temporada do Trampapo, que foi ao ar no dia 18 de janeiro.',
      },
      {
        type: 'text',
        text: 'Nesse papo, o Renato falou sobre sua experiência com empresas que estão se abrindo à cultura do trabalho remoto - tanto antes quanto durante a pandemia. Ele é fundador do movimento Officeless, que é uma plataforma digital que ajuda líderes a adotarem o trabalho remoto para criarem equipes com pessoas mais produtivas, engajadas e realizadas — independente de suas localizações. <br/><br/> Se você não ouviu essa conversa gostosinha ainda, não sabe o que está perdendo! <br/> Além de tudo sobre home office, ele, a Xongani e o Ricardo conversaram sobre saúde mental em meio ao isolamento, oportunidades que surgiram, perspectivas para o futuro, desemprego e muito mais. Corre lá!',
        image: content_01_guest,
        alt: 'Renato Carvalho',
      },
      {
        type: 'title',
        title: '#DicaExtracurricular',
      },
      {
        type: 'text',
        text: 'E se quiser uma prévia do que rolou, veja aqui as dicas dos participantes desse episódio. São conteúdos extras relacionados ao tema do episódio para você se aprofundar e expandir a mente:',
      },
      {
        type: 'text',
        text: 'Renato Carvalho <br/> Documentário: Remote First <br/> Livro: Trabalhe 4h por semana, de Timothy Ferriss <br/> Segundo o Renato, esses conteúdos são inspiradores para conhecer e se engajar com as novas possibilidades de trabalho, mostrando visões de como criar, empreender, inovar e protagonizar a própria carreira.',
      },
      {
        type: 'text',
        text: 'Ricardo Morais <br/> Leituras: comunicação não violenta <br/> Ele deu essa dica porque acha muuuito importante o cuidado com a comunicação nesse momento de relação à distância. Para que as comunicações sejam mais efetivas e afetivas, a dica é saber se comunicar de forma não violenta. Dá um google sobre esse tema!',
      },
      {
        type: 'text',
        text: 'Ana Paula Xongani <br/> Série: Distanciamento social <br/> Livro: Mulheres, raça e classe, de Angela Davis <br/> Segundo a Xongani, o documentário que foi produzido em meio ao distanciamento aborda diversas realidades e como a pandemia interferiu na vida das pessoas, ajudando a ampliar olhares e a nos percebemos como diversos. <br/> Já o livro é para fazer refletir sobre sobre diversidade e interseccionalidade, que são fundamentais para uma empregabilidade mais justa.',
      },
    ],
    thumbnail: content_01_thumb,
  },
];

export const categories = [
  'CARREIRA',
  'PcD',
  'ENTREVISTA',
  'CAUSAS',
  'QUALIFICAÇÃO',
  'CURRÍCULO',
  'MERCADO DE TRABALHO',
  'DIVERSIDADE',
  'LIDERAÇÃO',
].sort();
