import "./extraContent.scss";

import { Row, Col, Container } from "react-bootstrap";
import Mock from "../../mock";
import { Link } from "react-router-dom";

export default function ExtraContent({ hasBottomLine, section }) {
  const episodesContent = Mock.episodes.filter(
    (episode) => episode.type === "content"
  );

  return (
    <section id="extra-content" className={'extra-content ' + (section === 'home' ? 'extra-content-home' : '') }>
      <Container className={hasBottomLine ? "" : "container--no-border"}>
        <header className="d-flex align-items-center justify-content-between">
          <h2>{Mock?.home?.extraContent?.title}</h2>
        </header>
        <div className="extra-content__list gtm-class">
          <Row md={3}>
            {episodesContent.map((episode) => (
              <Col key={episode.id}>
                <div className="extra-content__list__card">
                  <div className="thumb">
                    <Link
                      to={`/conteudo-extra/${episode.slug}`}
                      title={episode.title}
                      data-gtm-event-category="candidatos:catho:trampapo"
                      data-gtm-event-action={section === 'home' ? 'lp-trampapo:conteudos-extras-interacao' : 'lp-trampapo:conteudo-relacionado:interacao'}
                      data-gtm-event-label={'link:' + episode.title}
                    >
                      <img
                        src={episode.thumbnail}
                        alt={episode.title}
                      />
                    </Link>
                  </div>
                  <div className="d-flex extra-content__list__card__description align-items-center">
                    <div>
                      <h3>
                        <Link
                          to={`/conteudo-extra/${episode.slug}`}
                          title={episode.title}
                          data-gtm-event-category="candidatos:catho:trampapo"
                          data-gtm-event-action={section === 'home' ? 'lp-trampapo:conteudos-extras-interacao' : 'lp-trampapo:conteudo-relacionado:interacao'}
                          data-gtm-event-label={'link:' + episode.title}
                        >
                          {episode.title}
                        </Link>
                      </h3>
                      {episode.categories.map((category, index) => {
                        let categoryText = category;

                        const notLastCategory =
                          index + 1 < episode.categories.length;
                        if (notLastCategory) {
                          categoryText = `${categoryText}, `;
                        }

                        return (
                          <span key={category} className="tag">
                            {categoryText}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  );
}
