import { useState } from "react";
import "./newsletter.scss";
import { saveNewsletter } from "../../api";

import { Container, Form, Col, Button, Row } from "react-bootstrap";

import MaskedInput from "react-maskedinput"; // eslint-disable-line

const Newsletter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(""); // eslint-disable-line
  const [msgFeedback, setMsgFeedback] = useState("");

  const nameValidate = (txt) => txt.length > 0;
  const emailValidate = (txt) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const phoneValidate = (txt) => txt.length === 14;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMsgFeedback("");

    if (!nameValidate(name)) {
      setMsgFeedback("O campo nome é obrigatório.");
    } else if (!emailValidate(email)) {
      setMsgFeedback("O campo e-mail deve conter um e-mail válido.");
    } else if (!phoneValidate(phone)) {
      setMsgFeedback("O campo telefone é obrigatório.");
    } else {
      try {
        await saveNewsletter(name, email, phone);
        setMsgFeedback(
          "Cadastro realizado com sucesso. Obrigado por se inscrever!"
        );
      } catch (error) {
        setMsgFeedback(error.message);
      }
    }
  };

  return (
    <section id="newsletter" className="newsletter text-center">
      <Container className="newsletter__container">
        <div className="w-50 m-auto">
          <h4 className="w-75 mx-auto h2">Cadastre-se em nossa newsletter</h4>
          <p className="w-100 mx-auto">
            Quer ficar por dentro das novidades e de todos os episódios? Faça já
            seu cadastro e receba tudo no seu e-mail. Simples assim!
          </p>
        </div>
        <Form onSubmit={handleSubmit} className="newsletter__container__form">
          <Form.Row className="align-items-center justify-content-center gtm-class">
            <Col xs="auto" className="mx-2">
              <Form.Label htmlFor="newsletter-name" srOnly>
                Seu Nome
              </Form.Label>
              <Form.Control
                onChange={(name) => {
                  setName(name.currentTarget.value);
                }}
                className="mb-2"
                id="newsletter-name"
                placeholder="Seu Nome"
                value={name}
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:cadastro-newsletter"
                data-gtm-event-label="onblur:nome"
              />
            </Col>
            <Col xs="auto" className="mx-2">
              <Form.Label htmlFor="newsletter-email" srOnly>
                Seu email
              </Form.Label>
              <Form.Control
                onChange={(email) => {
                  setEmail(email.currentTarget.value);
                }}
                className="mb-2"
                id="newsletter-email"
                placeholder="Seu email"
                value={email}
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:cadastro-newsletter"
                data-gtm-event-label="onblur:email"
              />
            </Col>
            <Col xs="auto" className="mx-2">
              <Form.Label htmlFor="newsletter-phone" srOnly>
                Seu telefone
              </Form.Label>
              <Form.Control
                as={MaskedInput}
                mask="(11) 111111111"
                onChange={(phone) => {
                  setPhone(phone.currentTarget.value.replace(/_/g, ""));
                }}
                className="mb-2"
                id="newsletter-phone"
                placeholder="Seu telefone"
                value={phone}
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:cadastro-newsletter"
                data-gtm-event-label="onblur:telefone"
              />
            </Col>
            <Col xs="auto" className="mx-2">
              <Button
                type="submit"
                className="mb-2 rounded-pill"
                data-gtm-event-category="candidatos:catho:trampapo"
                data-gtm-event-action="lp-trampapo:cadastro-newsletter"
                data-gtm-event-label="botao:enviar"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.707"
                  height="15.347"
                  viewBox="0 0 17.707 15.347"
                >
                  <path
                    id="send"
                    d="M18.117,9.981l-.006,0L2.355,3.443a.869.869,0,0,0-.82.08.909.909,0,0,0-.411.76v4.18a.886.886,0,0,0,.72.869l8.594,1.589a.147.147,0,0,1,0,.29L1.845,12.8a.886.886,0,0,0-.72.869v4.18a.869.869,0,0,0,.391.727A.883.883,0,0,0,2,18.723a.906.906,0,0,0,.351-.071l15.756-6.5.007,0a1.181,1.181,0,0,0,0-2.169Z"
                    transform="translate(-1.125 -3.376)"
                    fill="#5327f2"
                  />
                </svg>
                Enviar
              </Button>
            </Col>
          </Form.Row>
          <Row>
            <Col xs="auto" md={{offset: 2}}>
              <div className="mx-5" style={{ fontSize: 14, textAlign: "left", paddingLeft: 10 }}>
                A Catho trata os dados pessoais fornecidos por você apenas para
                a finalidade <br /> acima e de acordo com nossa{" "}
                <a
                  style={{
                    color: "#fff",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  rel="noreferrer"
                  href="https://seguro.catho.com.br/terms/politica-cookies-privacidade-v5.html#politica-privacidade"
                  title="Politica de privacidade"
                  target="_blank"
                >
                  Politica de privacidade
                </a>
              </div>
            </Col>
          </Row>
          <div>{msgFeedback}</div>
        </Form>
      </Container>
    </section>
  );
};

export default Newsletter;
