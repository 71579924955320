import Mock from "../../mock";
import { Link } from "react-router-dom";

const audioEpisodes = Mock.episodes.filter(
    (episode) => episode.type === "audio"
);

const extraContentEpisodes = Mock.episodes.filter(
    (episode) => episode.type === "content"
);

const SitemapHtml = () => (
    <div className="sitemap-links">
        <div className="links-audio">
            {audioEpisodes.map((episode) => (
                <Link
                    to={`/episodes/${episode.slug}`}
                    title={episode.title}
                >
                </Link>
            ))}
        </div>
        <div className="links-extra-content">
            {extraContentEpisodes.map((episode) => (
                <Link
                    to={`/conteudo-extra/${episode.slug}`}
                    title={episode.title}
                >
                </Link>
            ))}
        </div>
    </div>
)

export default SitemapHtml;