import React from "react";

import "./extra-content.scss";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SocialShare from "~/components/socialShare/socialShare";
import Mock from "../../mock";

import { Link } from "react-router-dom";

export default function ExtraContent({ match, history }) {
  const slug = match?.params?.slug;

  const allTypes = {
    image: RenderImage,
    text: RenderText,
    banner: RenderBanner,
    title: RenderTitle,
  };

  const episodesContent = Mock.episodes.filter(
    (episode) => episode.type === "content"
  );

  const correctEpisode = episodesContent.find(
    (episode) => episode.slug === slug
  );

  let { episodeSlug } = useParams();

  const currentEpisode = Mock.episodes.find(
    (episode) => episode.slug === episodeSlug
  );

  function goToNextEpisode() {
    const currentIndex = Mock.episodes.indexOf(currentEpisode);
    const nextIndex = (currentIndex + 1) % Mock.episodes.length;
    const nextItem = Mock.episodes[nextIndex];

    if (nextItem.type === "content") {
      history.push(`/conteudo-extra/${nextItem.slug}`);
    } else {
      history.push(`/episodes/${nextItem.slug}`);
    }
  }

  if (!correctEpisode) {
    // history.push('/');
    return <div></div>;
  }

  return (
    <section id="extra-content" className="extra-content">
       <Helmet>
        <title>Conteúdo extra: {correctEpisode.title} | Trampapo</title>
        <meta name="description" content="Leia esse conteúdo extra do Trampapo, seu podcast sobre o mercado falado no plural! 💜🎧" />
        <meta
          property="og:description"
          content="Leia esse conteúdo extra do Trampapo, seu podcast sobre o mercado falado no plural! 💜🎧"
        />
        <meta
          property="og:image"
          content={`https://www.trampapo.com.br${correctEpisode.thumbnail}`}
        />
        <meta name="keywords" content={`trampapo,conteudo-extra,conteudo,${correctEpisode.categories.join(',')}`} />
        <meta property="og:url" content={`https://www.trampapo.com.br/conteudo-extra/${correctEpisode.slug}`} />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={`https://www.trampapo.com.br/conteudo-extra/${correctEpisode.slug}`} />

      </Helmet>
      <Container className="extra-content__container">
        <Link to="/" className="back d-none d-lg-block">
          <FontAwesomeIcon icon={faArrowLeft} /> voltar ao inicio
        </Link>

        <Row className="mt-lg-3 extra-content__container__row flex-column-reverse">
          <Col md="12" className="extra-content__container__row__description  order-sm-2">
            <h1>{correctEpisode.title}</h1>
            <span>{correctEpisode.date}</span> •{" "}
            {correctEpisode.categories.map((category, index) => {
              let categoryText = category;

              const notLastCategory =
                index + 1 < correctEpisode.categories.length;
              if (notLastCategory) {
                categoryText = `${categoryText}, `;
              }

              return <span className="tag">{categoryText}</span>;
            })}
          </Col>
        </Row>

        {correctEpisode.contents.map((episode) => {
          const Component = allTypes[episode.type];
          return <Component {...{ ...episode }} />;
        })}
      </Container>
      <SocialShare handleOnPress={goToNextEpisode} contentType='extraContent' />
    </section>
  );
}

function RenderBanner({ banner, alt }) {
  return (
    <Col className="extra-content__container__row__thumb p-0 p-lg-1 order-sm-1">
      <img src={banner} alt={alt} />
    </Col>
  );
}

function RenderTitle({ title }) {
  return (
    <Row className="extra-content__container__about">
      <Col>
        <h2>{title}</h2>
      </Col>
    </Row>
  );
}

function RenderText(content) {
  return (
    <Row className="extra-content__container__about">
      <Col>
        {content.image && 
          <img src={content.image} alt={content.alt} className="guest" />
        }
        <p dangerouslySetInnerHTML={{__html: content.text}} />
      </Col>
    </Row>
  );
}

function RenderImage({ image, alt }) {
  return (
    <Row className="extra-content__container__about">
      <Col className="p-0">
        <img src={image} alt={alt} />
      </Col>
    </Row>
  );
}
