import React from 'react';
import { hydrate, render } from "react-dom";
import './index.scss';
import App from './App';

const rootElement = document.getElementById('root')
const rootProvider = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrate(rootProvider, rootElement);
} else {
  render(rootProvider, rootElement);
}

