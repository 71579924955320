import "./banner.scss";
import logo from "~/images/trampapo-logo-inverse.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as AmazonMusic} from '../../../images/amazon-music.svg'
import {
  faSpotify,
  faYoutube,
  faDeezer,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
import { Button } from "react-bootstrap";

const scrollToEpisodes = () => {
  const element = document.getElementById('episodes');
  window.scrollTo({
    top: element.getBoundingClientRect().top + window.scrollY - 120,
    behavior: 'smooth',
  });
}

export default function HomeBanner() {
  return (
    <div
      id="trampapo-banner"
      className="d-flex flex-row-reverse align-items-center"
    >
      <div className="w-50 trampapo-banner__wrapper">
        <h1>Trampapo</h1>
        <img src={logo} alt="Trampapo - 2ª Temporada" className="mb-5" />
        <h2 className="mb-lg-5">O mercado falado no plural</h2>

        <div className="mt-3 listen-now">
          <p className="d-none d-lg-block">ouça agora</p>
          <div className="social-buttons d-flex mt-3 gtm-class">
            <p className="d-lg-none" style={{ paddingLeft: 5 }}>
              ouça agora
            </p>
            <Button
              href="https://open.spotify.com/show/7qnBiwtPsLqPWzSoFshe5l"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:spotify"
            >
              <FontAwesomeIcon className="fa-lg" icon={faSpotify} /> Spotify
            </Button>
            <Button
              href="https://www.deezer.com/br/show/682412"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:deezer"
            >
              <FontAwesomeIcon className="fa-lg" icon={faDeezer} /> Deezer
            </Button>
            <Button
              href="https://podcasts.apple.com/br/podcast/trampapo/id1488783715"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:apple-podcasts"
            >
              <FontAwesomeIcon className="fa-lg" icon={faPodcast} /> Apple
              Podcasts
            </Button>
            <Button
              href="https://soundcloud.com/user-902624936"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:soundcloud"
            >
              <FontAwesomeIcon className="fa-lg" icon={faSoundcloud} />{" "}
              SoundCloud
            </Button>
            <Button
              href="https://www.youtube.com/playlist?list=PLucmc9GtLTWlXUS_cDxqyVbtPc9rSmvUe"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:youtube"
            >
              <FontAwesomeIcon className="fa-lg" icon={faYoutube} /> Youtube
            </Button>
            
            <Button
              href="https://music.amazon.com.br/podcasts/19dda952-28c7-412e-b380-6220a6d88920/TRAMPAPO"
              target="_blank"
              variant="outline-light"
              className="rounded-pill"
              data-gtm-event-category="candidatos:catho:trampapo"
              data-gtm-event-action="lp-trampapo:banner-interacao"
              data-gtm-event-label="link:amazonmusic"
            >
              <AmazonMusic width={'3rem'} height={'1.6rem'}/> Amazon Music
            </Button>
          </div>
        </div>
      </div>
      <div onClick={scrollToEpisodes} className="scroll-down">
        <FontAwesomeIcon className="fa-lg" icon={faChevronDown} />
        <FontAwesomeIcon className="fa-2x" icon={faChevronDown} />
      </div>
    </div>
  );
}
