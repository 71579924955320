import "./socialShare.scss";

import { Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";


const SocialShare = ({ handleOnPress, contentType }) => (
  <div id="social-chare" className="social-share">
    <Container className="d-flex justify-content-between flex-column flex-lg-row gtm-class">
      <div className="d-flex flex-column flex-lg-row align-items-center social-icons">
        <div className="title mr-0 mr-lg-4">Compartilhe</div>
        <div className="d-flex align-items-center mt-4 mt-lg-0">
          <a
            href={'https://www.facebook.com/sharer/sharer.php?u=https://www.trampapo.com.br' + window.location.pathname}
            target="_blank"
            rel="noreferrer"
            data-gtm-event-category="candidatos:catho:trampapo"
            data-gtm-event-action={ contentType === 'episode' ? 'lp-trampapo:episodios-interacao' : 'lp-trampapo:conteudos-extras-interacao' }
            data-gtm-event-label="link:facebook"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href={'https://twitter.com/intent/tweet?url=https://www.trampapo.com.br' + window.location.pathname}
            target="_blank"
            rel="noreferrer"
            data-gtm-event-category="candidatos:catho:trampapo"
            data-gtm-event-action={ contentType === 'episode' ? 'lp-trampapo:episodios-interacao' : 'lp-trampapo:conteudos-extras-interacao' }
            data-gtm-event-label="link:twitter"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href={'https://api.whatsapp.com/send?text=https://www.trampapo.com.br' + window.location.pathname} data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
            data-gtm-event-category="candidatos:catho:trampapo"
            data-gtm-event-action={ contentType === 'episode' ? 'lp-trampapo:episodios-interacao' : 'lp-trampapo:conteudos-extras-interacao' }
            data-gtm-event-label="link:whatsapp"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
      </div>
      <Button
        onClick={handleOnPress}
        className="rounded-pill mt-5 mt-lg-0"
        data-gtm-event-category="candidatos:catho:trampapo"
        data-gtm-event-action={ contentType === 'episode' ? 'lp-trampapo:episodios-interacao' : 'lp-trampapo:conteudos-extras-interacao' }
        data-gtm-event-label="botao:próximo"
      >
        Ir para o próximo
        <FontAwesomeIcon icon={faForward} className="ml-3" />
      </Button>
    </Container>
  </div>
);

export default SocialShare;

